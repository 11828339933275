import React from 'react'
import Table from './AccountTable';
import {
  get_request
} from './api/api_call'

class Clubs extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            clubs: [],
            activeTab: -1,
            clubUnits: [],
            request_club_units: true,
        };
        this.updateData();
    }

    updateData() {
      get_request(
        process.env.REACT_APP_BACKEND_URI + '/api/v1/management/clubs/' + this.props.clubId,
        this.props.catchError
      ).then(
        json => {
          if (json === undefined) {
            this.props.catchError(401);
            return
          }
          if (this.props.logged_in) {
            this.setState({clubs: json, activeTab: json[0].id})
          }
        }
      )
    }

    updateClubUnits(units) {
        if (units && units.length > 0) {
            this.setState({clubUnits: units, request_club_units: false});
        }
    }

    handleClick = (e) => {
        let id = e.target.id.split('-')[e.target.id.split('-').length - 1]
        this.setState({activeTab: id, request_club_units: true});
    }

    renderClubsTabs() {
        return this.state.clubs.map((club) => {
            return (
                <button id={"club-" + club.id} key={'club-' + club.id} onClick={this.handleClick} className='filter_btn'>{club.name}</button>
            )
        })
    }

    handleHideFilters = (e) => {
        document.getElementById('club_unit_list_id').style.display = 'none';
        document.getElementById('club_list_id').style.display = 'none';
    }

    render() {
        return (
            <div>
                <div className='club_list_wrapper' id='club_list_id'>
                    <div className='hide_drill_clients_list_wrapper'>
                        <input type='button' className=' hide_drill_clients_list' id='hide_club_filters_btn_id' onClick={this.handleHideFilters} value='&#10008;' />
                    </div>
                    <h3>Клубы</h3>
                    {this.renderClubsTabs()}
                </div>
                <ClubUnits
                  logged_in={this.props.logged_in}
                  clubId={this.state.activeTab}
                  catchError={this.props.catchError}
                  updateClubUnits={this.updateClubUnits.bind(this)}
                  clubUnits={this.state.clubUnits}
                  request_club_units={this.state.request_club_units}
                  handleFilterClick={this.handleFilterClick}
                />
            </div>
        )
    }
}

class ClubUnits extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            clubUnits: [],
        };
    }

    getFilterData() {
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/club_units/?club=' + this.props.clubId, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (this.props.logged_in) {
                this.props.updateClubUnits(json);
            }
        })
    }

    renderFilter() {
        if (this.props.logged_in && this.props.request_club_units) {
            this.getFilterData();
        }
        return this.props.clubUnits.map((unit) => {
            return (
                <p key={'p_checkkey-' + unit.id}><input type='checkbox' id={'unit-' + unit.id} key={'checkkey-' + unit.id} name='club_unit_filter' />{unit.name}</p>
            )
        })
    }

    render() {
        return (
            <div>
                <div className='club_unit_list_wrapper' id='club_unit_list_id'>
                    <h4>Филиалы клуба</h4>
                    {this.renderFilter()}
                    <button id='show-units' key='units' onClick={this.props.handleFilterClick} className='filter_btn'>Показать</button>
                </div>
            </div>
        )
    }
}

export default Clubs
