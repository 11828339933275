import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import Select from 'react-select';
import Spinner from './Spinner';
import compose from 'lodash/fp/compose';
import {
  get_request
} from './api/api_call'

var _ = require('lodash');

const select_styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        padding: 20,
        'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        color: '#f7941e',
        border: '1px solid #f7941e',
        'background-color': '#c5c6c8',
        'outline-color': '#f7941e',
    }),
    container: (provided, state) => ({
        ...provided,
        width: '50%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'margin-bottom': '0.5rem',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'background-color': '#c5c6c8',
    }),
}

class Profile extends(React.Component) {
    constructor(props) {
        super(props);
        this.modal_conf_ref = React.createRef();
        this.state = {
            username: '',
            email: '',
            first_name: '',
            last_name: '',
            patronymic: '',
            birth_date: '',
            phone: '',
            trustee_name: '',
            trustee_phone: '',
            type: '',
            logo: null,
            uri: process.env.REACT_APP_BACKEND_URI + '/api/v1/user/account/',
            lock_spinner: false,
            club_promos: [],
            show_tickets_list: false,
            show_videoconfs_list: false,
            user_videoconfs: [],
            new_videoconf: false,
            tickets_info: [],
            show_ticket_info: false,
            choosen_ticket_show: {},
            show_promo_info: false,
            choosen_promo_show: {},
            salary_group_rate: {},
            clients_promo_code_statistic: [],
        }
    }

    handleClickOut = (e) => {
        if (this.modal_conf_ref &&
            this.modal_conf_ref.current &&
            (!this.modal_conf_ref.current.contains(e.target) || e.target.id === 'background_lock_id')
        ) {
            this.setState({new_videoconf: false});
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOut);
        this.getUserInfo();
    }

    getUserInfo() {
      this.setState(
        {
            lock_spinner: true,
        }, () => {
          get_request(
            this.state.uri, this.props.catchError
          ).then(
            json => {
              if (!json) {
                return
              }
              if (this.props.logged_in) {
                var logo = null;
                if (json.logo) {
                  logo = new URL(json.logo);
                  logo = logo.pathname;
                }
                this.setState({
                  username: json.username,
                  email: json.email,
                  first_name: json.first_name,
                  last_name: json.last_name,
                  patronymic: json.patronymic,
                  birth_date: json.birth_date,
                  phone: json.phone,
                  trustee_name: json.trustee_name,
                  trustee_phone: json.trustee_phone,
                  type: json.text_type,
                  logo: logo,
                  lock_spinner: false,
                });
              }
            }
          )
        }
      )
    }

    getTickets() {
      var uri = new URL(
        process.env.REACT_APP_BACKEND_URI + '/api/v1/management/tickets/'
      )
      get_request(uri.href, this.props.catchError).then(
        json => {
            if (this.props.logged_in && json) {
              this.setState({tickets_info: json})
            }
        }
      )
    }

    getPromos(club_unit) {
      var uri = new URL(
        process.env.REACT_APP_BACKEND_URI + '/api/v1/management/promo/'
      )
      uri.searchParams.set('club_unit', club_unit)
      get_request(uri.href, this.props.catchError).then(
        json => {
          if (this.props.logged_in && json) {
              this.setState({
                  club_promos: json.results,
              });
          }
        }
      )
    }

    getVideoconfs() {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/videoconf/');
        fetch(uri.href, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          }
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (this.props.logged_in) {
                this.setState({
                    user_videoconfs: json,
                    show_videoconfs_list: true,
                });
            }
        })
    }

    getGroupRate() {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/payment/salary/group/');
        fetch(uri.href, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          }
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (this.props.logged_in) {
                this.setState({
                    salary_group_rate: json,
                });
            }
        })
    }

    handleHideUserInfo = (e) => {
        var card = document.getElementById('user_info_card_id');
        var card_btn = document.getElementById('user_info_btn_id');
        if (getComputedStyle(card).display === 'block' && getComputedStyle(card_btn).display === 'none') {
            card.style.display = 'none';
            card_btn.style.display = 'block';
        }
        else {
            card.style.display = 'block';
            card_btn.style.display = 'none';
        }
    }

    handleCreateVideoconf = (data) => {
      fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/videoconf/', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status === 401) {
            this.props.catchError(res.status);
            return
        }
        const response = res.json();
        return response;
      })
      .then(json => {
        if (!json) {
            return
        }
        let user_videoconfs = this.state.user_videoconfs;
        user_videoconfs.push(json)
        this.setState(
          {
            user_videoconfs: user_videoconfs,
            new_videoconf: false,
          }
        );
      })
    }

    renderClientTicketPromoCodeStatistic() {
      return this.state.clients_promo_code_statistic.map((promo_code) => {
        return (
          <div
            key={promo_code.promo_code}
            style={
              {
                marginTop: '0.25rem',
                marginBottom: '0.25rem',
                width: 'fit-content',
                marginRight: 'auto',
                marginLeft: 'auto',
              }
            }
          >
            <span
              style={
                {
                  fontWeight: '500',
                }
              }
            >
              {promo_code.promo_code}:
            </span> {promo_code.amount}
          </div>
        )
      })
    }

    renderCoachesTicketPrice(coach_drill_cost) {
      return coach_drill_cost.map((cost) => {
        return (
          <div
            key={"cost-" + cost.id}
          >
            <div
              style={
                {
                  textDecoration: 'underline',
                }
              }
            >
              {cost.skill_name}
            </div>
            <div>
              Цена: {cost.price} руб.
            </div>
            <div>
              Выгода: {cost.profit} руб.
            </div>
          </div>
        )
      })
    }

    renderCoachRate(coach_rate) {
      return Object.entries(coach_rate).map(([clients, amount]) => {
        return (
          <div
            key={"rate_" + amount}
            style={
              {
                display: 'flex',
              }
            }
          >
            <div>
              {clients}:
            </div>
            <div
              style={
                {
                  marginLeft: '0.25rem',
                }
              }
            >
              {amount}
            </div>
          </div>
        )
      })
    }

    renderCoachSkillsRate() {
      return Object.entries(this.state.salary_group_rate).map(([coach_skill, coach_rate]) => {
        return (
          <div
            key={"coach_skill_" + coach_skill}
          >
            <div
              style={
                {
                  fontSize: '1.25rem',
                  fontWeight: '500',
                  width: 'fit-content',
                  marginLeft: '1rem',
                }
              }
            >
              {coach_skill}
            </div>
            <div
              style={
                {
                  fontSize: '1rem',
                  width: 'fit-content',
                  marginLeft: '2rem',
                }
              }
            >
              {this.renderCoachRate(coach_rate)}
            </div>
          </div>
        )
      })
    }

    renderUserVideoconfs() {
      return this.state.user_videoconfs.map((videoconf, index) => {
        return (
          <div
            key={"videoconf_container-" + videoconf.id}
          >
            <div
              style={
                {
                  marginTop: '0.5rem',
                  marginBottom: '0.5rem',
                  marginLeft: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                }
              }
            >
              <div
                style={
                  {
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    marginRight: '0.25rem',
                  }
                }
              >
                >
              </div>
              <div>
                <a
                  href={videoconf.conf_link}
                  target="_blank"
                  style={
                    {
                      color: '#000',
                    }
                  }
                >
                  {videoconf.conf_name
                    ? <span>{videoconf.conf_name}</span>
                    : <span>Конференция {index + 1}</span>
                  }
                </a>
              </div>
            </div>
          </div>
        )
      })
    }

    render() {
        return (
            <div>
                <div className='statistic_wrapper'>
                    <div
                      className='tickets_statistic_container'
                      style={
                        {
                          minWidth: '65%',
                        }
                      }
                    >
                        <div className='head_ticket_info'
                          onClick={
                            () => {
                              this.setState(
                                {
                                  show_tickets_list: !this.state.show_tickets_list,
                                  lock_spinner: true,
                                }, () => {
                                  if (Object.keys(this.state.salary_group_rate).length === 0) {
                                    this.getGroupRate()
                                  }
                                  if (this.state.tickets_info.length === 0) {
                                    this.getTickets()
                                  }
                                  this.getPromos('')
                                  this.setState({lock_spinner: false})
                                  Object.entries(this.state.salary_group_rate).map((k, v) => {console.log(k, v)})
                                }
                              )
                            }
                          }
                        >
                            Абонементы
                        </div>
                        {this.state.show_tickets_list
                          ? <div className='tickets_stat_wrapper user_tickets_stat_wrapper'>
                              <div
                                style={
                                  {
                                    marginTop: '0.5rem',
                                    marginBottom: '0.5rem',
                                  }
                                }
                              >
                                <div>
                                  <Select
                                      options={
                                          Array.from(this.state.tickets_info, (val) => new Object(
                                              {
                                                  "value": val.id,
                                                  "label": (val.ticket) ? val.ticket + ' (' + val.club_unit[0].name + ')' : val.name + ' (' + val.club_unit.name + ')',
                                                  "ticket_info": val,
                                              }
                                          ))
                                      }
                                      onChange={
                                          (e) => {
                                            this.setState(
                                              {
                                                choosen_ticket_show: e["ticket_info"],
                                                show_ticket_info: true,
                                              }
                                            )
                                          }
                                      }
                                      styles={select_styles}
                                      placeholder={"Абонемент"}
                                      menuPosition={'fixed'}
                                  />
                                </div>
                                {this.state.show_ticket_info
                                  ? <div>
                                      {this.state.choosen_ticket_show.ticket
                                        ? <div>
                                            {parseInt(localStorage.getItem('user_type')) != 1 && this.state.choosen_ticket_show.coach_drill_cost.length > 0
                                              ? <div>
                                                  {this.renderCoachesTicketPrice(this.state.choosen_ticket_show.coach_drill_cost)}
                                                </div>
                                              : <div>
                                                  <div>
                                                    Цена: {this.state.choosen_ticket_show.price} руб.
                                                  </div>
                                                  <div>
                                                    Выгода: {this.state.choosen_ticket_show.profit} руб.
                                                  </div>
                                                </div>
                                            }
                                            <div>
                                              Разрешена заморозка: {(this.state.choosen_ticket_show.freeze) ? '✔' : '✘'}
                                            </div>
                                            <div
                                              style={
                                                {
                                                  whiteSpace: 'pre-line',
                                                }
                                              }
                                            >
                                              Бонусы: {this.state.choosen_ticket_show.bonus}
                                            </div>
                                            <div>
                                              Действует: {this.state.choosen_ticket_show.days} дней
                                            </div>
                                          </div>
                                        : <div>
                                            <div>
                                              Описание: {this.state.choosen_ticket_show.description}
                                            </div>
                                            <div>
                                              Действует: {this.state.choosen_ticket_show.days} дней
                                            </div>
                                          </div>
                                      }
                                    </div>
                                  : ''
                                }
                              </div>
                              <div
                                style={
                                  {
                                    marginTop: '0.5rem',
                                    marginBottom: '0.5rem',
                                  }
                                }
                              >
                                <div>
                                  <Select
                                      options={
                                          Array.from(this.state.club_promos, (val) => new Object(
                                              {
                                                  "value": val.id,
                                                  "label": (val.ticket) ? val.ticket + ' (' + val.club_unit[0].name + ')' : val.name + ' (' + val.club_unit.name + ')',
                                                  "ticket_info": val,
                                              }
                                          ))
                                      }
                                      onChange={
                                          (e) => {
                                            this.setState(
                                              {
                                                choosen_promo_show: e["ticket_info"],
                                                show_promo_info: true,
                                              }
                                            )
                                          }
                                      }
                                      styles={select_styles}
                                      placeholder={"Акция"}
                                      menuPosition={'fixed'}
                                  />
                                </div>
                                {this.state.show_promo_info
                                  ? <div>
                                      {this.state.choosen_promo_show.ticket
                                        ? <div>
                                            {parseInt(localStorage.getItem('user_type')) != 1 && this.state.choosen_promo_show.coach_drill_cost.length > 0
                                              ? <div>
                                                  {this.renderCoachesTicketPrice(this.state.choosen_promo_show.coach_drill_cost)}
                                                </div>
                                              : <div>
                                                  <div>
                                                    Цена: {this.state.choosen_promo_show.price} руб.
                                                  </div>
                                                  <div>
                                                    Выгода: {this.state.choosen_promo_show.profit} руб.
                                                  </div>
                                                </div>
                                            }
                                            <div>
                                              Разрешена заморозка: {(this.state.choosen_promo_show.freeze) ? '✔' : '✘'}
                                            </div>
                                            <div
                                              style={
                                                {
                                                  whiteSpace: 'pre-line',
                                                }
                                              }
                                            >
                                              Бонусы: {this.state.choosen_promo_show.bonus}
                                            </div>
                                            <div>
                                              Действует: {this.state.choosen_promo_show.days} дней
                                            </div>
                                          </div>
                                        : <div>
                                            <div>
                                              Описание: {this.state.choosen_promo_show.description}
                                            </div>
                                            <div>
                                              Действует: {this.state.choosen_promo_show.days} дней
                                            </div>
                                          </div>
                                      }
                                    </div>
                                  : ''
                                }
                              </div>
                              {parseInt(localStorage.getItem('user_type')) > 0
                                ? <div>
                                    {parseInt(localStorage.getItem('user_type')) >= 2
                                      ? <div>
                                          {this.renderCoachSkillsRate()}
                                        </div>
                                      : <div>
                                          {this.renderCoachRate(this.state.salary_group_rate)}
                                        </div>
                                    }
                                  </div>
                                : ''
                              }
                            </div>
                          : ''
                        }
                    </div>
                    <div
                      className='tickets_statistic_container'
                      style={
                        {
                          minWidth: '35%',
                        }
                      }
                    >
                        <div className='head_ticket_info'
                          onClick={
                            () => {
                              if (this.state.user_videoconfs.length === 0 && !this.state.show_videoconfs_list) {
                                this.getVideoconfs()
                              }
                              else {
                                this.setState({show_videoconfs_list: !this.state.show_videoconfs_list})
                              }
                            }
                          }
                        >
                          Видеоконференции
                        </div>
                        {this.state.show_videoconfs_list
                          ? <div className='tickets_stat_wrapper user_tickets_stat_wrapper'>
                              <div
                                style={
                                  {
                                    border: '2px solid #f7941e',
                                    borderRadius: '10%',
                                    padding: '0.25rem',
                                    width: 'fit-content',
                                    marginTop: '0.25rem',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    cursor: 'pointer',
                                  }
                                }
                                onClick={
                                  () => {
                                    this.setState({new_videoconf: true})
                                  }
                                }
                              >
                                Создать
                              </div>
                              {this.renderUserVideoconfs()}
                            </div>
                          : ''
                        }
                    </div>
                </div>
                <div className='user_info_btn_wrapper' id='user_info_btn_id'>
                    <div className='user_info_btn'  onClick={this.handleHideUserInfo}>
                        <img src='./icons/helm.png'
                           alt='user_logo'
                           className='profile_logo'
                           height='50px'
                        />
                    </div>
                </div>
                <div className='full_user_info_wrapper' id='user_info_card_id'>
                    <div className='hide_drill_clients_list_wrapper'>
                        <div className=' hide_drill_clients_list' id='hide_user_info_btn_id' onClick={this.handleHideUserInfo}>&#10008;</div>
                    </div>
                    <div className='user_logo_wrapper'>
                        {this.state.logo
                            ? <img src={process.env.REACT_APP_BACKEND_URI + this.state.logo}
                                 alt='user_logo'
                                 className='profile_logo'
                              />
                            : <img src='./icons/helm.png'
                                 alt='user_logo'
                                 className='profile_logo'
                                 height='100px'
                              />
                        }
                    </div>
                    <div className='user_info_wrapper'>
                        <div className='text_user_info text_user_info_type'>
                            <span className='user_info_value user_info_type'>{this.state.type}</span>
                        </div>
                        <div className='text_user_info'>
                            <span className='user_info_key'>Логин: </span>
                            <span className='user_info_value'>{this.state.username}</span>
                        </div>
                        <div className='text_user_info'>
                            <span className='user_info_key'>Имя: </span>
                            <span className='user_info_value'>{this.state.first_name}</span>
                        </div>
                        <div className='text_user_info'>
                            <span className='user_info_key'>Фамилия: </span>
                            <span className='user_info_value'>{this.state.last_name}</span>
                        </div>
                        <div className='text_user_info'>
                            <span className='user_info_key'>Отчество: </span>
                            <span className='user_info_value'>{this.state.patronymic}</span>
                        </div>
                        <div className='text_user_info'>
                            <span className='user_info_key'>Email: </span>
                            <span className='user_info_value'>
                                <a className='user_info_value' href='mailto:{this.state.email}'>
                                    {this.state.email}
                                </a>
                            </span>
                        </div>
                        <div className='text_user_info'>
                            <span className='user_info_key'>Телефон: </span>
                            <span className='user_info_value'>
                                <a className='user_info_value' href='tel:{this.state.phone}'>
                                    {this.state.phone}
                                </a>
                            </span>
                        </div>
                        {this.state.trustee_name
                            ? <div className='text_user_info'>
                                <span className='user_info_key'>Представитель: </span>
                                <span className='user_info_value'>{this.state.trustee_name}</span>
                              </div>
                            : ''
                        }
                        {this.state.trustee_phone
                            ? <div className='text_user_info'>
                                <span className='user_info_key'>Телефон представитель: </span>
                                <span className='user_info_value'>{this.state.trustee_phone}</span>
                              </div>
                            : ''
                        }
                    </div>
                </div>
                {this.state.lock_spinner
                    ? <Spinner />
                    : <div></div>
                }
                {this.state.new_videoconf
                  ? <CreateVideoconf
                      logged_in={this.props.logged_in}
                      handleCreateVideoconf={this.handleCreateVideoconf.bind(this)}
                    />
                  : ''
                }
            </div>
        )
    }
}

class CreateVideoconf extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
          users: [],
          selected_users: [],
          lock_spinner: false,
        }
    }

    searchUser = (e) => {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_clients/');
                uri.searchParams.set("limit", 20);
                uri.searchParams.set("offset", 0);
                uri.searchParams.set("search", encodeURIComponent(e.target.value));
              fetch(uri.href, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('token')}`
                }
              })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (this.props.logged_in) {
                        this.setState(
                            {
                                users: json.results,
                                lock_spinner: false,
                            }
                        );
                    }
                })
            }
        )
    }

    renderSelectedUsers() {
      return this.state.selected_users.map((user) => {
        return (
          <div
            key={'choosen-user-' + user.id}
            style={
              {
                display: 'flex',
                border: '1px solid #f7941e',
                borderRadius: '5px',
                width: 'fit-content',
                paddingLeft: '0.25rem',
                paddingRight: '0.25rem',
                paddingTop: '0.1rem',
                paddingBottom: '0.1rem',
              }
            }
          >
            <div>
              {user.last_name} {user.first_name[0]}.
            </div>
            <div
              style={
                {
                  borderLeft: '1px solid #f7941e',
                  marginLeft: '0.35rem',
                  cursor: 'pointer',
                }
              }
              onClick={
                () => {
                  console.log(user);
                  var selected_users = this.state.selected_users
                  for (var u in selected_users) {
                    if (parseInt(selected_users[u].id) === parseInt(user.id)) {
                      selected_users.splice(u, 1)
                      break
                    }
                  }
                  this.setState({selected_users: selected_users})
                }
              }
            >
              &#10008;
            </div>
          </div>
        )
      })
    }

    renderFoundUsers() {
      return this.state.users.map((user) => {
        return (
          <div
            key={'custom-select-' + user.id}
            className='custom_select'
            onClick={
              () => {
                var selected_users = this.state.selected_users
                var exist = false
                for (var u in selected_users) {
                  if (parseInt(selected_users[u].id) === user.id) {
                    exist = true
                    break
                  }
                }
                if (!exist) {
                  selected_users.push(user)
                  this.setState(
                    {selected_users: selected_users},
                    () => {
                      document.getElementById('conf_fts_input_id').value = ''
                      this.setState({users: []})
                    }
                  )
                }
              }
            }
          >
            {user.last_name} {user.first_name}
          </div>
        )
      })
    }

    render() {
        return (
            <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_conf_ref}>
                <div className='new_drill_form_wrapper' ref={this.props.modal_conf_ref}>
                  <h3 ref={this.props.modal_setting_ref}>
                      Список участников
                  </h3>
                    <div className='fts_input_wrapper'>
                        <input
                          placeholder='Название'
                          className='conf_name'
                          id='conf_name_id'
                        />
                    </div>
                  <div>
                    <div className='fts_input_wrapper'>
                        <input
                          placeholder='Имя Фамилия'
                          className='fts_input'
                          id='conf_fts_input_id'
                          onChange={
                            compose(
                              _.debounce(this.searchUser, 300),
                              _.clone
                            )
                          }
                        />
                        {this.state.selected_users.length > 0
                          ? <div
                              style={
                                {
                                  width: '80%',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                }
                              }
                            >
                              {this.renderSelectedUsers()}
                            </div>
                          : ''
                        }
                        {this.state.users.length > 0
                          ? <div
                              style={
                                {
                                  border: '1px solid #000',
                                  position: 'relative',
                                  backgroundColor: '#fff',
                                  width: '80%',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                }
                              }
                            >
                              {this.renderFoundUsers()}
                            </div>
                          : ''
                        }
                    </div>
                  </div>
                  <button
                      id='add-user-ticket'
                      key='user-traffic-accept'
                      className='drill_traffic_btn'
                      data-club_unit={this.state.clubUnitId}
                      onClick={
                        (e) => {
                          this.props.handleCreateVideoconf(
                              {
                                participants: this.state.selected_users.map((user) => user.id),
                                'conf_name': document.getElementById('conf_name_id').value,
                              }
                          )
                        }
                      }
                  >
                      &#10004;
                  </button>
                </div>
            </div>
        )
    }
}

export default Profile
