// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product_desc_label {
  display: block;
}

.multiple_upload_input {
  background-color: #c5c6c8;
  border: 1px solid;
  border-color: #f7941e;
  font-size: 1.25rem;
  max-width: 100%;
}

.add_item_border {
  border-right: 1px solid;
  border-color: #f7941e;
}

.products_filters {
  width: 200px;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.product_card_wrapper {
  display: flex;
  border: 2px solid;
  border-color: #f7941e;
  box-shadow: 0 0 10px #f7941e;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  box-sizing: border-box;
}
.product_img_wrapper {
  width: 125px;
  height: 125px;
  position: relative;
}
.product_img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/products_service.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,qBAAqB;EACrB,4BAA4B;EAC5B,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,oBAAoB;EACpB,uBAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,MAAM;EACN,SAAS;EACT,YAAY;AACd","sourcesContent":[".product_desc_label {\n  display: block;\n}\n\n.multiple_upload_input {\n  background-color: #c5c6c8;\n  border: 1px solid;\n  border-color: #f7941e;\n  font-size: 1.25rem;\n  max-width: 100%;\n}\n\n.add_item_border {\n  border-right: 1px solid;\n  border-color: #f7941e;\n}\n\n.products_filters {\n  width: 200px;\n  margin-left: 0.25rem;\n  margin-right: 0.25rem;\n}\n\n.product_card_wrapper {\n  display: flex;\n  border: 2px solid;\n  border-color: #f7941e;\n  box-shadow: 0 0 10px #f7941e;\n  margin-left: 1rem;\n  margin-right: 1rem;\n  margin-top: 0.5rem;\n  margin-bottom: 0.5rem;\n  padding-top: 0.25rem;\n  padding-bottom: 0.25rem;\n  box-sizing: border-box;\n}\n.product_img_wrapper {\n  width: 125px;\n  height: 125px;\n  position: relative;\n}\n.product_img {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
