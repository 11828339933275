import React from 'react';
import AddTicket from './AddTicket';
import compose from 'lodash/fp/compose';
import Select from 'react-select'
import Spinner from './Spinner';

const select_styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        padding: 20,
        'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#f7941e',
        border: '1px solid #f7941e',
        'background-color': '#c5c6c8',
        'outline-color': '#f7941e',
    }),
    container: (provided, state) => ({
        ...provided,
        width: '100%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'margin-bottom': '0.5rem',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'background-color': '#c5c6c8',
    }),
}

var _ = require('lodash');

class CoachesList extends(React.Component) {
    constructor(props) {
        super(props);
        this.remove_user_modal_ref = React.createRef();
        this.state = {
            users: [],
            coaches: [],
            coaches_ids: [],
            page: 0,
            limit: 10,
            offset: 0,
            processing: false,
            correct: false,
            discipline: [],
            addTicket: false,
            addTicketUserId: '',
            form: '',
            fts: '',
            uri: process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_coaches/?limit=' + 20 + '&offset=' + 0,
            disciplines: [],
            coaches: [],
            club_tickets: [],
            clubs: [],
            club_units: [],
            discipline_id: '',
            coach_id: '',
            club_ticket_id: '',
            club: '',
            club_unit: '',
            remove_user: false,
            remove_coach_id: null,
            change_coach_id: null,
            coach_skills: {},
            lock_spinner: false,
            show_choose_coach_source: false,
            coach_from_user: false,
        };
    }

    handleClickOut = (e) => {
        if (this.remove_user_modal_ref &&
            this.remove_user_modal_ref.current &&
            (!this.remove_user_modal_ref.current.contains(e.target) || e.target.id === 'background_lock_id')
        ) {
            this.setState(
                {
                    remove_user: false,
                    show_choose_coach_source: false,
                    coach_from_user: false,
                }
            );
        }
    }

    getData() {
        if (!this.state.uri) {
          return
        }
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                var uri = this.state.uri;
                if (this.props.drill) {
                  uri = this.state.uri + '&drill=' + this.props.drill;
                }
                fetch(uri, {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                  }
                })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (this.props.logged_in) {
                        var uri = null;
                        if (json.next) {
                            uri = new URL(json.next);
                            uri = process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_coaches/' + uri.search;
                        }
                        var users = json.results;
                        var coaches_ids = [];
                        if (this.props.drill) {
                            for (let i = 0; i < users.length; i++) {
                                if (users[i]['drill_ticket']) {
                                    coaches_ids.push(users[i]['id']);
                                }
                            }
                        }
                        this.setState(
                            {
                                users: this.state.users.concat(users),
                                coaches_ids: this.state.coaches_ids.concat(coaches_ids),
                                processing: true,
                                uri: uri,
                                lock_spinner: false,
                            }
                        );
                    }
                })
            }
        );
    }

    getSkillChoices() {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/coach_skill_choices/', {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                  }
                })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (this.props.logged_in) {
                        this.setState(
                            {
                                coach_skills: json,
                                lock_spinner: false,
                            }
                        );
                    }
                })
            }
        )
    }

    searchUser = (e) => {
        var fts = '';
        fts = document.getElementById('fts_input_id').value;
        this.setState({fts: fts});
        var uri = process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_coaches/?limit=' + 20 + '&offset=' + 0 + '&search='
            + fts + '&discipline=' + this.state.discipline_id +  '&club=' + this.state.club + '&club_unit=' + this.state.club_unit;
        if (this.props.drill) {
            uri = uri + '&drill=' + this.props.drill;
        }
      fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (this.props.logged_in) {
                this.setState({users: json.results});
            }
        })
    }

    handleRemoveUser = (e) => {
        this.setState(
            {
                remove_user: true,
                remove_coach_id: e.target.dataset.coach_id,
            }
        );
    }

    removeUser = (e) => {
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/account/' + e.target.dataset.coach_id + '/', {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
          .then(res => res.json())
          .then(json => {
            var users = this.state.users;
            for (let i = 0; i < users.length; i++) {
                if (parseInt(users[i]["id"]) === parseInt(json.id)) {
                    users.splice(i, 1);
                    break
                }
            }
            this.setState({users: users, remove_user: false, remove_coach_id: null});
          })
    }

    handleDeclineRemoveUser = (e) => {
        document.getElementById('background_lock_id').style.display = 'none';
        this.setState({remove_user: false, remove_coach_id: null});
    }

    scrollListener = (e) => {
        if (this.state.processing &&
             document.documentElement.scrollTop >= (document.documentElement.scrollHeight - window.innerHeight) * 0.9) {
            let p = this.state.page + 1;
            this.setState({page: p, processing: false, offset: p * this.state.limit});
            this.getData();
        }
    }

    componentDidMount() {
        document.addEventListener('scroll', this.scrollListener);
        document.addEventListener('mousedown', this.handleClickOut);
        let p = this.state.page + 1;
        this.setState(
            {
                page: p,
                offset: p * this.state.limit,
            }, () => {
                this.getData();
                this.getFiltersData();
                this.getSkillChoices();
            }
        );
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', function(e) {});
        document.removeEventListener('mousedown', this.handleClickOut);
    }

    handleChangeUserInfo = (e) => {
        const form_data = new FormData();
        var coach_id = '';
        if (e.constructor == Object) {
            coach_id = e['coach_id'];
            form_data.append('skill', parseInt(e["value"]));
        }
        else {
            coach_id = e.target.dataset.coach_id;
            if (e.target.id.includes('user_name')) {
                form_data.append('first_name', e.target.value.split(' ')[0]);
                form_data.append('last_name', e.target.value.split(' ')[1]);
            }
            if (e.target.id.includes('user_phone')) {
                form_data.append('phone', e.target.value);
            }
            if (e.target.id.includes('user_email')) {
                form_data.append('email', e.target.value);
            }
            if (e.target.id.includes('user_trustee_phone')) {
                form_data.append('trustee_phone', e.target.value);
            }
            if (e.target.id.includes('user_trustee_name')) {
                form_data.append('trustee_name', e.target.value);
            }
            if (e.target.id.includes('user_birth_date')) {
                form_data.append('birth_date', e.target.value);
            }
            if (e.target.id.includes('user_telegram')) {
                form_data.append('telegram', e.target.value);
            }
            if (e.target.id.includes('user_whatsapp')) {
                form_data.append('whatsapp', e.target.value);
            }
            if (e.target.id.includes('user_instagram')) {
                form_data.append('instagram', e.target.value);
            }
            if (e.target.id.includes('user_vk')) {
                form_data.append('vk', e.target.value);
            }
            if (e.target.id.includes('user_facebook')) {
                form_data.append('facebook', e.target.value);
            }
        }
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/account/' + coach_id + '/', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: form_data
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            else if (res.status === 406) {
                res.json().then((json) => {
                    this.props.updateCurrentShift(json);
                });
                return
            }
            const response = res.json();
            return response;
        })
    }

    addChangeCoach = (data) => {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                var new_coach = data["new_coach"];
                fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/coach/slug/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify(data)
                })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    else if (res.status === 406) {
                        res.json().then((json) => {
                            this.props.updateCurrentShift(json);
                        });
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (new_coach) {
                        var users = this.state.users;
                        users.push(json.coach);
                        this.setState(
                            {
                                lock_spinner: false,
                                coach_from_user: false,
                                users: users,
                            }
                        );
                    }
                    else {
                        this.setState(
                            {
                                lock_spinner: false,
                                coach_from_user: false,
                            }
                        );
                    }
                })
            }
        )
    }

    handleChangeCoachClubs = (e, i) => {
        var coach_id = null;
        if (i["removedValue"]) {
            coach_id = i["removedValue"]['coach_id'];
        }
        else if (i["option"]) {
            coach_id = i["option"]['coach_id'];
        }
        var data = {coach: coach_id, units: Array.from(new Array(e.length), (v, i) => e[i]['value'])}
        if (!coach_id) {
            return
        }
        this.addChangeCoach(data);
    }

    renderDisciplines(disciplines) {
        return disciplines.map((discipline) => {
            return (
                <div
                  key={'discipline-' + discipline}
                  className='userinfo_cell_wrapper'
                >
                    <div className='editable_userinfo_cell_wrapper'>
                        <span>{discipline}</span>
                    </div>
                </div>
            )
        })
    }

    renderClubUnits(club) {
        return club.units.map((unit) => {
            return (
                <div
                  key={'unit-' + unit}
                  className='coach_club_info_cell_wrapper'
                >
                    <div className='non_editable_userinfo_cell_wrapper'>
                        {unit.name} ({club.name})
                    </div>
                </div>
            )
        })
    }

    renderClubs(clubs) {
        return clubs.map((club) => {
            return (
                <div key={'club-' + club}>
                    {this.renderClubUnits(club)}
                </div>
            )
        })
    }

    renderTableData() {
        if (this.props.update_data === 'yes') {
            this.getData();
        }
        return this.state.users.map((coach) => {
            var flname = coach.first_name + ' ' + coach.last_name;
            var coach_clubs = [];
            for (let i in coach.clubs) {
                for (let j in coach.clubs[i]['units']) {
                    coach_clubs.push(
                        {
                            value: coach.clubs[i]['units'][j]['id'],
                            label: coach.clubs[i]['units'][j]['name'] + '(' + coach.clubs[i]['name'] + ')',
                            coach_id: coach.id,
                        }
                    )
                }
            }
            return (
                <div
                    key={'coach-' + coach.id}
                    className='client_row_wrapper'
                >
                    <div className='client_col_name_wrapper clients_column_0'>
                        <div className='clients_row_name_wrapper'>
                            <div
                                key={'remove_client-' + coach.id}
                                id={'remove_client-' + coach.id}
                                data-coach_id={coach.id}
                                className='notify_client_btn'
                                onClick={this.handleRemoveUser}
                            >
                                <img src='./icons/trash.png' alt='notify' width='15px' data-coach_id={coach.id} />
                            </div>
                            <input className='text_edit_input client_name'
                                   type='text'
                                   defaultValue={flname}
                                   data-coach_id={coach.id}
                                   id={'user_name-' + coach.id}
                                   size={flname.length}
                                   onChange={
                                    compose(
                                        _.debounce(this.handleChangeUserInfo, 500),
                                        _.clone
                                    )
                                   }
                            />
                        </div>
                        <div className='user_type_select_wrapper'>
                            <Select
                                options={
                                    Array.from(this.state.coach_skills, (skill) => new Object(
                                        {
                                            "value": skill.id,
                                            "label": skill.name,
                                            "coach_id": coach.id,
                                        }
                                    ))
                                }
                                defaultValue={{value: coach.skill, label: coach.text_skill}}
                                onChange={this.handleChangeUserInfo}
                            />
                        </div>
                    </div>
                    <div className='client_col_contact_wrapper clients_column_1'>
                        <div className='userinfo_cell_wrapper'>
                            <div>Тел: </div>
                            <div className='editable_userinfo_cell_wrapper'>
                                {coach.phone
                                    ? <input className='text_edit_input client_contact'
                                           type='text'
                                           defaultValue={coach.phone}
                                           data-coach_id={coach.id}
                                           id={'user_phone-' + coach.id}
                                           size={coach.phone.length}
                                           onChange={
                                            compose(
                                                _.debounce(this.handleChangeUserInfo, 500),
                                                _.clone
                                            )
                                           }
                                      />
                                    : <input className='text_edit_input client_contact'
                                           type='text'
                                           defaultValue={coach.phone}
                                           data-coach_id={coach.id}
                                           id={'user_phone-' + coach.id}
                                           size='1'
                                           onChange={
                                            compose(
                                                _.debounce(this.handleChangeUserInfo, 500),
                                                _.clone
                                            )
                                           }
                                      />
                                }
                            </div>
                        </div>
                        <div className='userinfo_cell_wrapper'>
                            <div>Email: </div>
                            <div className='editable_userinfo_cell_wrapper'>
                                {coach.email
                                    ? <input className='text_edit_input client_contact'
                                             type='text'
                                             defaultValue={coach.email}
                                             data-coach_id={coach.id}
                                             id={'user_email-' + coach.id}
                                             size={coach.email.length}
                                             onChange={
                                              compose(
                                                  _.debounce(this.handleChangeUserInfo, 500),
                                                  _.clone
                                              )
                                             }
                                      />
                                    : <input className='text_edit_input client_contact'
                                             type='text'
                                             defaultValue={coach.email}
                                             data-coach_id={coach.id}
                                             id={'user_email-' + coach.id}
                                             size='1'
                                             onChange={
                                              compose(
                                                  _.debounce(this.handleChangeUserInfo, 500),
                                                  _.clone
                                              )
                                             }
                                      />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='client_col_ticket_wrapper clients_column_2'>
                        {this.renderDisciplines(coach.disciplines)}
                    </div>
                    <div className='client_col_ticket_wrapper clients_column_3'>
                        <div>
                            <Select
                                isMulti
                                defaultValue={coach_clubs}
                                name="colors"
                                options={
                                    Array.from(
                                        new Array(this.state.club_units.length),
                                        (v, i) => Object(
                                            {
                                                value: this.state.club_units[i]['id'],
                                                label: this.state.club_units[i]['name'] + '(' + this.state.club_units[i]['club'] + ')',
                                                coach_id: coach.id,
                                            }
                                        )
                                    )
                                }
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleChangeCoachClubs}
                                menuPosition={'fixed'}
                            />
                        </div>
                    </div>
                    <div className='client_col_birthday_wrapper clients_column_4'>
                        <div className='userinfo_cell_wrapper'>
                            <div>ДР: </div>
                            <div className='editable_userinfo_cell_wrapper'>
                                <input className='text_edit_input client_contact'
                                       type='date'
                                       defaultValue={coach.birth_date}
                                       data-coach_id={coach.id}
                                       id={'user_birth_date-' + coach.id}
                                       size='12'
                                       onChange={
                                        compose(
                                            _.debounce(this.handleChangeUserInfo, 500),
                                            _.clone
                                        )
                                       }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='client_col_social_wrapper clients_column_5'>
                        <ul className='client_socials_list'>
                            <div className='userinfo_cell_wrapper'>
                                <div>Telegram: </div>
                                <div className='editable_userinfo_cell_wrapper'>
                                    {coach.telegram
                                        ? <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={coach.telegram}
                                                 data-coach_id={coach.id}
                                                 id={'user_telegram-' + coach.id}
                                                 size={coach.telegram.length}
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                        : <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={coach.telegram}
                                                 data-coach_id={coach.id}
                                                 id={'user_telegram-' + coach.id}
                                                 size='1'
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                    }
                                </div>
                            </div>
                            <div className='userinfo_cell_wrapper'>
                                <div>Whatsapp: </div>
                                <div className='editable_userinfo_cell_wrapper'>
                                    {coach.whatsapp
                                        ? <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={coach.whatsapp}
                                                 data-coach_id={coach.id}
                                                 id={'user_whatsapp-' + coach.id}
                                                 size={coach.whatsapp.length}
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                        : <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={coach.whatsapp}
                                                 data-coach_id={coach.id}
                                                 id={'user_whatsapp-' + coach.id}
                                                 size='1'
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                    }
                                </div>
                            </div>
                            <div className='userinfo_cell_wrapper'>
                                <div>Instagram: </div>
                                <div className='editable_userinfo_cell_wrapper'>
                                    {coach.instagram
                                        ? <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={coach.instagram}
                                                 data-coach_id={coach.id}
                                                 id={'user_instagram-' + coach.id}
                                                 size={coach.instagram.length}
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                        : <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={coach.instagram}
                                                 data-coach_id={coach.id}
                                                 id={'user_instagram-' + coach.id}
                                                 size='1'
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                    }
                                </div>
                            </div>
                            <div className='userinfo_cell_wrapper'>
                                <div>ВК: </div>
                                <div className='editable_userinfo_cell_wrapper'>
                                    {coach.vk
                                        ? <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={coach.vk}
                                                 data-coach_id={coach.id}
                                                 id={'user_vk-' + coach.id}
                                                 size={coach.vk.length}
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                        : <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={coach.vk}
                                                 data-coach_id={coach.id}
                                                 id={'user_vk-' + coach.id}
                                                 size='1'
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                    }
                                </div>
                            </div>
                            <div className='userinfo_cell_wrapper'>
                                <div>Facebook: </div>
                                <div className='editable_userinfo_cell_wrapper'>
                                    {coach.facebook
                                        ? <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={coach.facebook}
                                                 data-coach_id={coach.id}
                                                 id={'user_facebook-' + coach.id}
                                                 size={coach.facebook.length}
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                        : <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={coach.facebook}
                                                 data-coach_id={coach.id}
                                                 id={'user_facebook-' + coach.id}
                                                 size='1'
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                    }
                                </div>
                            </div>
                        </ul>
                    </div>
                    <div className='client_col_comment_wrapper clients_column_6'>
                        <textarea
                            rows='5'
                            cols='30'
                            id={'coach_sketch-' + coach.id}
                            className='message_input'
                        >
                            {coach.sketches}
                        </textarea>
                        <input type='button' className='add_sketch_btn' id={'add_user_sketch-' + coach.id} onClick={this.props.handleUpdateSketches} defaultValue='&#10004;' />
                    </div>
                </div>
            )
        })
    }

    renderTableHeader() {
        let header = ['ФИО', 'КОНТАКТЫ', 'ДИСЦИПЛИНЫ', 'ФИЛИАЛ (КЛУБ)', 'ДАТА РОЖДЕНИЯ', 'СОЦСЕТИ', 'ЗАМЕТКИ'];
        return header.map((key, index) => {
            return (
                <div
                    key={index}
                    className={'clients_table_container_border clients_table_container_head clients_column_' + index}
                >
                    {key}
                </div>
            )
        })
    }

    checkTicketExistence(tickets) {
        if (tickets.length === 0) {
            return false
        }
        return true
    }

    renderTicketSelector(props) {
        return props.tickets.map((ticket) => {
            if (parseInt(ticket.id) === props.choosen_ticket){
                return (
                    <option key={'drill_ticket_option-' + props.user + '-'}
                            id={'drill_ticket_option-' + props.user + '-'}>
                        Выбери абонемент
                    </option>
                )
            }
            else {
                return (
                    <option key={'drill_ticket_option-' + props.user + '-' + ticket.ticket.id}
                            id={'drill_ticket_option-' + props.user + '-' + ticket.id}>
                        {ticket.ticket.ticket}
                    </option>
                )
            }
        })
    }

    handleNewUserTicket = (e) => {
        this.setState({addTicketUserId: e.target.dataset.user_id, addTicket: true, form: ''});
    }

    renderUsersList() {
        return this.state.users.map((user, index) => {
            return (
                <tr key={'coach_tr-' + user.id}>
                    <td><label className='client_name_label'>{index + 1}</label></td>
                    <td><label htmlFor={'coach_arrive-' + user.id} className='client_name_label'>{user.first_name} {user.last_name}</label></td>
                    <td key={'coach_arrive_row-' + user.id}>
                        {(() => {
                            if (this.state.coaches_ids.includes(user.id)) {
                                    return (
                                        <div className='client_arrive_chbox_wrapper'>
                                        <input type='checkbox' id={'coach_arrive-' + user.id} key={'coach_arrive-' + user.id} name='user-arrive' className='arrive_chbox'
                                        checked={true} onChange={this.handleUpdateTraffic} />
                                        <span className='client_arrive_chbox_span' onChange={this.handleUpdateTraffic}></span>
                                        </div>
                                    )
                            }
                            else {
                                    return (
                                        <div className='client_arrive_chbox_wrapper'>
                                        <input type='checkbox' id={'coach_arrive-' + user.id} key={'coach_arrive-' + user.id} name='user-arrive' className='arrive_chbox'
                                        checked={false} onChange={this.handleUpdateTraffic} />
                                        <span className='client_arrive_chbox_span' onChange={this.handleUpdateTraffic}></span>
                                        </div>
                                    )
                            }
                        })()}
                    </td>
                    <td>
                        {(() => {
                            if (this.checkTicketExistence(user.tickets)) {
                                if (localStorage.getItem('user_type') > 1) {
                                    var ticket = 'Выбери абонемент';
                                    var ticket_id = '';
                                    var ticket_key = ''
                                    if (user.drill_ticket) {
                                        for (let i = 0; i < user.tickets.length; i++) {
                                            if (parseInt(user.tickets[i]['id']) === parseInt(user.drill_ticket[1])) {
                                                ticket = user.tickets[i]['ticket']['ticket'];
                                                ticket_key = user.tickets[i]['ticket']['id'];
                                                ticket_id = user.tickets[i]['id'];
                                            }
                                        }
                                    }
                                    return (
                                        <select key={'drill_ticket_select-' + user.id + '-' + this.props.drill} onChange={this.handleUpdateTraffic}
                                            id={'drill_ticket_select-' + user.id}
                                            name='drill-tickets-selector' className='tickets_select_tag'>
                                            <option key={'drill_ticket_option-' + user.id + '-' + ticket_key}
                                                id={'drill_ticket_option-' + user.id + '-' + ticket_id}>{ticket}</option>
                                            {this.renderTicketSelector({'user': user.id, 'tickets': user.tickets, 'choosen_ticket': ticket_id})}
                                        </select>
                                    )
                                }
                                else if (parseInt(localStorage.getItem('user_type')) === 1) {
                                    if (user.drill_ticket) {
                                        var t = '';
                                        for (let i = 0; i < user.tickets.length; i++) {
                                            if (user.drill_ticket[1] === user.tickets[i]['id']) {
                                                t = user.tickets[i]['ticket']['ticket'];
                                            }
                                        }
                                        return (
                                            <div key={'drill_ticket_label-' + user.id}><span>{t}</span></div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div key={'drill_ticket_label-' + user.id}></div>
                                        )
                                    }
                                }
                                else {
                                    return (<div key={'drill_ticket_label-' + user.id}></div>)
                                }
                            }
                            else {
                                if (localStorage.getItem('user_type') > 1) {
                                        return(
                                            <button id={'add_ticket-' + user.id} key={'add_ticket-' + user.id} onClick={this.handleNewUserTicket} className='add_ticket_btn'
                                                    data-user_id={user.id}>Новый абонемент</button>
                                        )
                                }
                                else {
                                    return (<div></div>)
                                }
                            }
                        })()}
                    </td>
                </tr>
            )
        })
    }

    handleModalClick = (e) => {
        console.log();
    }

    handleCorrectArriveList = (e) => {
        var selectors = document.getElementsByName('drill-tickets-selector');
        for (let i = 0; i < selectors.length; i++) {
            selectors[i].disabled = false;
        }
        this.setState({correct: true});
    }

    handleUpdateTraffic = (e) => {
        document.getElementById('lock_modal_content_id').style.display = 'block';
        var data = {coaches: [], remove_coaches: [], drill: this.props.drill}
        var id = e.target.id;
        var cl_st = document.getElementById(id).checked;
        if (e.target.nodeName === 'SELECT' && e.target.value !== 'Выбери абонемент') {
            cl_st = true;
        }
        else if (e.target.nodeName === 'SELECT' && e.target.value === 'Выбери абонемент') {
            cl_st = false;
        }
        id = id.split('-')[id.split('-').length - 1];
        if (cl_st === true) {
            var drill_selector = document.getElementById('drill_ticket_select-' + id);
            let drill_id = null;
            if (drill_selector && drill_selector.options[drill_selector.selectedIndex].id !== '') {
                drill_id = drill_selector.options[drill_selector.selectedIndex].id.split('-')[drill_selector.options[drill_selector.selectedIndex].id.split('-').length - 1];
            }
            let coach_body = {};
            coach_body['id'] = id;
            coach_body['ticket'] = drill_id;
            data['coaches'].push(coach_body);
        }
        else if (cl_st === false) {
            data['remove_coaches'].push(id);
        }
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/traffic/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(data)
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            else if (res.status === 406 || res.status === 500 || res.status === 400) {
                document.getElementById('lock_modal_content_id').style.display = 'none';
                if (res.status === 406) {
                    res.json().then((json) => {
                        this.props.updateCurrentShift(json);
                    });
                    return
                }
                return null;
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (!json) {
                return
            }
            if (this.props.logged_in && json) {
                var users = this.state.users;
                var coaches_ids = this.state.coaches_ids;
                if (cl_st === true) {
                    coaches_ids.push(parseInt(id));
                    this.props.handleUpdateDrillCoaches({drill: this.props.drill, counter: 1});
                }
                else if (cl_st === false) {
                    coaches_ids = coaches_ids.filter(x => x !== parseInt(id));
                    this.props.handleUpdateDrillCoaches({drill: this.props.drill, counter: -1});
                }
                for (let i = 0; i < json.coaches.length; i++) {
                    for (let j = 0; j < users.length; j++) {
                        if (json.coaches[i]['id'] === users[j]['id']) {
                            users[j] = json.coaches[i];
                            break
                        }
                    }
                }
                this.setState({users: users, coaches_ids: coaches_ids}, () => { document.getElementById('lock_modal_content_id').style.display = 'none'; });
            }
        })
        if (this.props.logged_in) {
            this.setState({correct: false});
        }
    }

    getFiltersData(filters_list = null) {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/filters_info/', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                .then(res => res.json())
                .then(json => {
                    var clubs = []
                    var club_units = []
                    for (let i = 0; i < json.clubs.length; i++) {
                        clubs.push({ id: json.clubs[i]['id'], name: json.clubs[i]['name'] });
                        for (let j = 0; j < json.clubs[i]['units'].length; j++) {
                            club_units.push({
                                id: json.clubs[i]['units'][j]['id'],
                                name: json.clubs[i]['units'][j]['name'],
                                club: json.clubs[i]['name'],
                            });
                        }
                    }
                    this.setState(
                        {
                            disciplines: json.disciplines,
                            coaches: json.coaches,
                            club_tickets: json.tickets,
                            clubs: clubs,
                            club_units: club_units,
                            lock_spinner: false,
                        }, () => {
                            if (filters_list){
                                filters_list.style.display = 'flex';
                            }
                        }
                    );
                });
            }
        )
    }

    handleShowFilters = (e) => {
        var filters_list = document.getElementById(this.props.list_type + '_filters_list_id');
        if (filters_list.style.display === 'flex' || filters_list.style.display === 'block') {
            filters_list.style.display = 'none';
        }
        else {
            if (this.state.disciplines.length === 0 || this.state.coaches.length === 0 || this.state.club_tickets.length === 0 ||
                this.state.clubs.length === 0 || this.state.club_units.length === 0) {
                this.getFiltersData(filters_list);
            }
            else {
                filters_list.style.display = 'flex';
            }
        }
    }

    renderDisciplinesList() {
        return this.state.disciplines.map((discipline) => {
            return (
                <option key={'discipline_list-' + discipline.id} id={'discipline_list-' + discipline.id}
                        data-discipline={discipline.id}>
                    {discipline.name}
                </option>
            )
        })
    }

    renderClubUnitsList() {
        return this.state.club_units.map((club_unit) => {
            return (
                <option key={'club_units_list-' + club_unit.id} id={'club_units_list-' + club_unit.id}
                        data-ticket={club_unit.id}>
                    {club_unit.name}
                </option>
            )
        })
    }

    renderClubsList() {
        return this.state.clubs.map((club) => {
            return (
                <option key={'clubs_list-' + club.id} id={'clubs_list-' + club.id}
                        data-ticket={club.id}>
                    {club.name}
                </option>
            )
        })
    }

    handleApplyFilters = (e) => {
        var discipline_selector = document.getElementById(this.props.list_type + '_discipline_filter_selector');
        var club_selector = document.getElementById(this.props.list_type + '_club_filter_selector');
        var club_unit_selector = document.getElementById(this.props.list_type + '_club_units_filter_selector');
        let discipline_id = '';
        let club_id = '';
        let club_unit_id = '';
        if (discipline_selector && discipline_selector.options[discipline_selector.selectedIndex].id !== '') {
            discipline_id = discipline_selector.options[discipline_selector.selectedIndex].id.split('-')[discipline_selector.options[discipline_selector.selectedIndex].id.split('-').length - 1];
        }
        if (club_selector && club_selector.options[club_selector.selectedIndex].id !== '') {
            club_id = club_selector.options[club_selector.selectedIndex].id.split('-')[club_selector.options[club_selector.selectedIndex].id.split('-').length - 1];
        }
        if (club_unit_selector && club_unit_selector.options[club_unit_selector.selectedIndex].id !== '') {
            club_unit_id = club_unit_selector.options[club_unit_selector.selectedIndex].id.split('-')[club_unit_selector.options[club_unit_selector.selectedIndex].id.split('-').length - 1];
        }
        this.setState({discipline_id: discipline_id, club: club_id, club_unit: club_unit_id}, () => { this.searchUser(); });
    }

    handleChooseCoachSource = (e) => {
        if (e.target.dataset.source === 'exist') {
            this.setState(
                {
                    show_choose_coach_source: false,
                    coach_from_user: true,
                }
            )
        }
        else if (e.target.dataset.source === 'new') {
            this.setState(
                {
                    show_choose_coach_source: false,
                }, (e) => {
                    this.props.handleAddUser(e);
                }
            )
        }
    }

    renderUsersFiltersList() {
        return (
            <div className='users_filters_wrapper'>
                <div className='show_filters_btn_wrapper'>
                    <input type='button' className='add_ticket_btn' id='show_filters_btn_id' onClick={this.handleShowFilters} defaultValue='Фильтры' />
                </div>
                <div className='filters_list_wrapper' id={this.props.list_type + '_filters_list_id'}>
                    <div className='filter_btn_wrapper'>
                        <select key='club_selector' id={this.props.list_type + '_club_filter_selector'} className='tickets_select_tag'>
                            <option key='ticket_list'>Все клубы</option>
                                {this.renderClubsList()}
                        </select>
                    </div>
                    <div className='filter_btn_wrapper'>
                        <select key='club_units_selector' id={this.props.list_type + '_club_units_filter_selector'} className='tickets_select_tag'>
                            <option key='ticket_list'>Все филиалы</option>
                                {this.renderClubUnitsList()}
                        </select>
                    </div>
                    <div className='filter_btn_wrapper'>
                        <select key='discipline_selector' id={this.props.list_type + '_discipline_filter_selector'} className='tickets_select_tag'>
                            <option key='ticket_list'>Все дисциплины</option>
                                {this.renderDisciplinesList()}
                        </select>
                    </div>
                    <div className='show_filters_btn_wrapper'>
                        <div className='filter_apply_btn' id='apply-filters-id' onClick={this.handleApplyFilters}>
                            <span>Применить</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <div className='fts_input_wrapper'>
                    <input placeholder='Имя Фамилия' className='fts_input' id='fts_input_id' onChange={_.debounce(this.searchUser, 300)} />
                </div>
                {this.renderUsersFiltersList()}
                <div className='table_container'>
                    <div className='table_wrapper'>
                        <div className="table_head_container_wrapper">
                            {this.renderTableHeader()}
                        </div>
                        <div className="table_rows_container_wrapper">
                            {this.renderTableData()}
                        </div>
                    </div>
                </div>
                <div className='add_drill_wrapper'>
                    <div
                        type='button'
                        className='add_drill_btn plus_btn'
                        id='add_drill_btn_id'
                        onClick={
                            () => {
                                this.setState({show_choose_coach_source: true});
                            }
                        }
                    >
                        &#10010;
                    </div>
                </div>
                {this.props.add_coach_form
                    ? <div className='background_lock_wrapper' id='background_lock_id'>
                        <div className='add_new_user_form_wrapper'>
                            <div className='hide_drill_clients_list_wrapper'>
                                <input
                                    type='button'
                                    className='hide_drill_clients_list'
                                    id='hide_drill_coaches_list_btn_id'
                                    onClick={this.props.handleBackgroundClick}
                                    defaultValue='&#10008;'
                                />
                            </div>
                            {this.props.displayed_form}
                        </div>
                      </div>
                    : ''
                }
                {this.props.add_client_form
                    ? <div className='background_lock_wrapper' id='background_lock_id'>
                        <div className='add_new_user_form_wrapper'>
                            <div className='hide_drill_clients_list_wrapper'>
                                <input type='button' className=' hide_drill_clients_list' id='hide_drill_clients_list_btn_id' onClick={this.props.handleBackgroundClick} defaultValue='&#10008;' />
                            </div>
                            {this.props.displayed_form}
                        </div>
                      </div>
                    : ''
                }
                {this.state.remove_user
                    ? <AcceptRemoveUser
                        coach_id={this.state.remove_coach_id}
                        handleDeclineRemoveUser={this.handleDeclineRemoveUser.bind(this)}
                        remove_user_modal_ref={this.remove_user_modal_ref}
                        removeUser={this.removeUser.bind(this)}
                      />
                    : ''
                }
                {this.state.lock_spinner
                    ? <Spinner />
                    : <div></div>
                }
                {this.state.show_choose_coach_source
                    ? <ChooseNewCoachSource
                        remove_user_modal_ref={this.remove_user_modal_ref}
                        handleChooseCoachSource={this.handleChooseCoachSource.bind(this)}
                      />
                    : ''
                }
                {this.state.coach_from_user
                    ? <CoachFromExistUser
                        remove_user_modal_ref={this.remove_user_modal_ref}
                        club_units={this.state.club_units}
                        catchError={this.props.catchError}
                        logged_in={this.props.logged_in}
                        updateCurrentShift={this.props.updateCurrentShift}
                        addChangeCoach={this.addChangeCoach.bind(this)}
                      />
                    : ''
                }
            </div>
        )
    }
}

class AcceptRemoveUser extends(React.Component) {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.remove_user_modal_ref}>
                <div className='new_drill_form_wrapper'>
                    <h3>Удалить пользователя?</h3>
                    <button id='remove-client-accept' key='remove-client-accept' className='drill_traffic_btn' data-coach_id={this.props.coach_id} onClick={this.props.removeUser}>&#10004;</button>
                    <button id='remove-client-decline' key='remove-client-decline' className='drill_traffic_btn' onClick={this.props.handleDeclineRemoveUser}>&#10008;</button>
                </div>
            </div>
        )
    }
}

class ChooseNewCoachSource extends(React.Component) {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.remove_user_modal_ref}>
                <div className='new_drill_form_wrapper'>
                    <div
                        className='add_drill_btn show_ticket_info_btn circle_gaps'
                        data-source='new'
                        onClick={this.props.handleChooseCoachSource}
                    >
                        Новый пользователь
                    </div>
                    <div
                        className='add_drill_btn show_ticket_info_btn circle_gaps'
                        data-source='exist'
                        onClick={this.props.handleChooseCoachSource}
                    >
                        Выбрать из зарегистрированных
                    </div>
                </div>
            </div>
        )
    }
}

class CoachFromExistUser extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            coach_clubs: [],
            new_coach: null,
            users: [],
        };
    }

    getUsers= (e) => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_clients/');
        uri.searchParams.set('search', document.getElementById('fts_input_new_coach_id').value);
        uri.searchParams.set('limit', 20);
        uri.searchParams.set('offset', 0);
        fetch(uri.href, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (this.props.logged_in) {
                this.setState({users: json.results});
            }
        })
    }

    renderUsersList() {
        return this.state.users.map((user) => {
            return (
                <div
                    className='new_coach_item'
                    id={'new_coach_id-' + user.id}
                    key={'new_coach_key-' + user.id}
                    data-user_id={user.id}
                    data-user_name={user.first_name + ' ' + user.last_name}
                    onClick={
                        (e) => {
                            var element = e.target;
                            this.setState(
                                {
                                    new_coach: parseInt(e.target.dataset.user_id),
                                    users: [],
                                }, () => {
                                      document.getElementById('fts_input_new_coach_id').value = element.dataset.user_name;
                                }
                            );
                        }
                    }
                >
                    {user.first_name} {user.last_name}
                    <hr
                        style={{width: '80%'}}
                    />
                </div>
            )
        })
    }

    render() {
        return (
            <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.remove_user_modal_ref}>
                <div className='new_drill_form_wrapper'>
                    <div
                        className='half_width circle_gaps'
                    >
                        <div>
                            <Select
                                isMulti
                                name="colors"
                                options={
                                    Array.from(
                                        new Array(this.props.club_units.length),
                                        (v, i) => Object(
                                            {
                                                value: this.props.club_units[i]['id'],
                                                label: this.props.club_units[i]['name'] + '(' + this.props.club_units[i]['club'] + ')',
                                            }
                                        )
                                    )
                                }
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={
                                    (e) => {
                                        this.setState({coach_clubs: Array.from(new Array(e.length), (v, i) => e[i]['value'])})
                                    }
                                }
                                styles={select_styles}
                                menuPosition={'fixed'}
                                placeholder={"Клуб"}
                                isClearable={false}
                            />
                        </div>
                        <div>
                            <div>
                                <input
                                    placeholder='Имя Фамилия'
                                    className='orange_border client_name no_shadow_input'
                                    id='fts_input_new_coach_id'
                                    onChange={_.debounce(this.getUsers, 300)}
                                />
                            </div>
                            {this.state.users.length > 0
                                ? <div
                                    className='new_coach_list_wrapper orange_border'
                                  >
                                    {this.renderUsersList()}
                                  </div>
                                : ''
                            }
                        </div>
                        {this.state.new_coach && this.state.coach_clubs.length > 0
                            ? <div
                                className='add_drill_btn show_ticket_info_btn circle_gaps'
                                onClick={
                                    () => {
                                        var data = {
                                            coach: this.state.new_coach,
                                            units: this.state.coach_clubs,
                                            new_coach: true,
                                        };
                                        this.props.addChangeCoach(data);
                                    }
                                }
                              >
                                Добавить тренера
                              </div>
                            : ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default CoachesList
