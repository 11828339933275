import React from 'react';
import Select from 'react-select';
import { get_request } from './api/api_call'

const select_styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        padding: 20,
        'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#f7941e',
        border: '1px solid #f7941e',
        'background-color': '#c5c6c8',
        'outline-color': '#f7941e',
    }),
    container: (provided, state) => ({
        ...provided,
        width: '50%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'margin-bottom': '0.5rem',
        'margin-top': '1rem',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'background-color': '#c5c6c8',
    }),
}

class AddTicket extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            tickets: [],
            disciplines: [],
            coaches: [],
            promos: [],
            clubUnitId: null,
            ticket_start_date: (this.props.ticket) ? this.props.ticket.start_date: '',
            ticket_expire_date: (this.props.ticket) ? this.props.ticket.expire_date : '',
            ticket_discount: (this.props.ticket) ? parseInt(this.props.ticket.discount * 100) : 0,
            ticket_promos: [],
            ticket_disciplines: (this.props.disciplines) ? Array.from(this.props.disciplines, (d) => d.value) : [],
            ticket_coaches: (this.props.coaches) ? Array.from(this.props.coaches, (c) => c.value) : [],
            ticket_active: (this.props.ticket) ? this.props.ticket.active : true,
            froze_ticket: false,
            promo_code: (this.props.ticket) ? this.props.ticket.promo_code : null,
            payment_type: (this.props.ticket) ? this.props.ticket.payment_type : null,
            payed_amount: (this.props.ticket) ? this.props.ticket.payed_amount : null,
            promo: null,
            reason: null,
            ticket_ticket: null,
            payment_types: [],
            lock_spinner: false,
            ticket_price: (this.props.ticket) ? this.props.ticket.price : null,
        };
    }

    componentWillMount() {
        if (this.state.clubUnitId) {
            this.getData();
        }
        else if (this.props.clubUnitId && this.props.ticket && this.props.ticket.id) {
            this.setState(
                {
                    clubUnitId: this.props.clubUnitId,
                }, () => {
                    this.getData();
                }
            )
        }
    }

    getData() {
        this.setState(
            {lock_spinner: true},
            () => {
                var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/drill_creation_data/')
                uri.searchParams.set('club_unit', this.state.clubUnitId)
                get_request(uri.href, this.props.catchError).then(json => {
                    if (json === undefined) {
                        this.props.catchError(401);
                        return
                    }
                    if (this.props.logged_in) {
                        this.setState({
                            tickets: json.tickets,
                            coaches: json.coaches,
                            disciplines: json.disciplines,
                            promos: json.promos,
                            get_data: false,
                            payment_types: json.payment_types,
                            lock_spinner: false,
                        });
                    }
                })
            }
        )
    }

    handleUpdateClubUnit = (e) => {
        var unit_id = e.target.id.split('-')[e.target.id.split('-').length - 1];
        this.setState(
            {
                clubUnitId: unit_id
            }, () => {
                localStorage.setItem("user_ticket_club_units", JSON.stringify([this.state.clubUnitId]));
                this.getData();
            }
        );
    }

    renderFilter() {
        return this.props.club_units.map((unit) => {
            return (
                <p key={'unit-' + unit.id}>
                    <input
                        type='radio'
                        id={'unit-' + unit.id}
                        key={'checkkey-' + unit.id}
                        data_unit_id={unit.id}
                        onClick={this.handleUpdateClubUnit}
                    />
                    {unit.name}
                </p>
            )
        })
    }

    renderCoachesList() {
        return this.state.coaches.map((coach) => {
            if (this.props.coaches && this.props.coaches.includes(coach.id.toString())) {
                return (
                    <option key={'coach_list-' + coach.id} id={'coach_list-' + coach.id} value={coach.id}
                            data-coach={coach.id} selected>
                        {coach.first_name} {coach.last_name}
                    </option>
                )
            }
            else {
                return (
                    <option key={'coach_list-' + coach.id} id={'coach_list-' + coach.id} value={coach.id}
                            data-coach={coach.id}>
                        {coach.first_name} {coach.last_name}
                    </option>
                )
            }
        })
    }

    renderTicketPromos() {
        return this.state.ticket_promos.map((promo) => {
            return (
                <span>
                    <input
                        type='radio'
                        name='promo_choosen'
                        key={'promo-' + promo.id}
                        data-promo_id={promo.id}
                        onChange={
                          () => {
                            this.setState({promo: promo.id})
                          }
                        }
                    />
                    {promo.name}
                </span>
            )
        })
    }

    handleChangeDate = (e) => {
        if (e.target.id === 'ticket_date_stop') {
            this.setState({ ticket_expire_date: '' });
        }
        else if (e.target.id === 'ticket_date_start') {
            this.setState({ ticket_start_date: '' });
        }
    }

    render() {
        if (this.props.accept) {
            return (
                <div className='ticket_creation_wrapper'>
                    {!this.props.remove_ticket
                        ? <h3>Закрыть абонемент?</h3>
                        : <h3>Удалить абонемент?</h3>
                    }
                    <button
                        id='add-user-ticket'
                        key='user-traffic-accept'
                        className='drill_traffic_btn'
                        data-club_unit={this.state.clubUnitId}
                        onClick={this.props.handleAddUserTicket}
                    >
                        &#10004;
                    </button>
                    <button id='decline-user-ticket' key='user-traffic-decline' className='drill_traffic_btn' onClick={this.props.handleDeclineUserTicket}>&#10008;</button>
                </div>
            )
        }
        if (!this.state.clubUnitId && (!this.props.ticket || !this.props.ticket.id)) {
            return (
                <div className='background_lock_wrapper' id='background_lock_id'>
                    <div className='new_drill_form_wrapper'>
                        {this.renderFilter()}
                    </div>
                </div>
            )
        }
        return (
            <div>
            {this.state.lock_spinner
                ? <div className='ticket_creation_wrapper'>
                    <div
                      className="loader spinner1"
                      style={
                        {
                          position: 'absolute',
                          top: '25%',
                          left: '50%',
                        }
                      }
                    >
                    </div>
                  </div>
                : <div className='ticket_creation_wrapper'>
                      <h3>Новый абонемент</h3>
                      <div className='ticket_selector_wrapper'>
                          <Select
                              options={
                                  Array.from(this.state.tickets, (val) => new Object(
                                      {
                                          "value": val.id,
                                          "label": (!val.illusive_promo) ? val.ticket : val.illusive_promo,
                                          "illusive": val.illusive,
                                          "type": "ticket",
                                      }
                                  ))
                              }
                              onChange={
                                  (e) => {
                                      if (!e["illusive"]) {
                                          var ticket_promos = [];
                                          for (let i = 0; i < this.state.promos.length; i++) {
                                              for (let j = 0; j < this.state.promos[i]['tickets'].length; j++) {
                                                  if (parseInt(this.state.promos[i]['tickets'][j]["ticket"]["id"]) === parseInt(e["value"])) {
                                                      ticket_promos.push(this.state.promos[i]);
                                                  }
                                              }
                                          }
                                          this.setState(
                                            {
                                              ticket_promos: ticket_promos,
                                              ticket_ticket: e["value"],
                                              promo: null,
                                            }
                                          )
                                      }
                                      else {
                                          this.setState(
                                            {
                                              ticket_discount: false,
                                              ticket_ticket: e["value"],
                                              promo: null,
                                            }
                                          );
                                      }
                                  }
                              }
                              styles={select_styles}
                              placeholder={"Абонемент"}
                              defaultValue={
                                  (this.props.correct_ticket)
                              }
                          />
                      </div>
                      {this.state.ticket_promos.length > 0 && !this.state.promo_code
                          ? <div className='add_ticket_promos_wrapper'>
                                {this.renderTicketPromos()}
                            </div>
                          : ''
                      }
                      <div className='add_ticket_promos_wrapper'>
                        <label htmlFor='ticket_discount'>Промокод: </label>
                        <input
                          key='ticket_discount'
                          id='ticket_discount'
                          type='text'
                          placeholder='Промокод'
                          className='add_ticket_btn'
                          defaultValue={(this.state.promo_code) ? this.state.promo_code : ''}
                          onChange={
                            (e) => {
                              this.setState({promo_code: e.target.value})
                            }
                          }
                        />
                      </div>
                      {!this.state.promo && parseInt(localStorage.getItem("user_type")) > 1
                          ? <div className='add_ticket_promos_wrapper'>
                              <label htmlFor='ticket_discount'>Скидка: </label>
                              <input
                                key='ticket_discount'
                                id='ticket_discount'
                                type='number'
                                placeholder='Размер скидки'
                                className='add_ticket_btn'
                                defaultValue={this.state.ticket_discount}
                                onChange={
                                  (e) => {
                                    this.setState({ticket_discount: e.target.value})
                                  }
                                }
                              />
                            </div>
                          : ''
                      }
                      <div className='discipline_selector_wrapper'>
                          <Select
                              isMulti
                              options={
                                  Array.from(this.state.disciplines, (val) => new Object(
                                      {
                                          "value": val.id,
                                          "label": val.name,
                                          "type": "discipline",
                                      }
                                  ))
                              }
                              onChange={
                                (e) => {
                                  this.setState({ticket_disciplines: Array.from(e, (val) => val["value"])})
                                }
                              }
                              styles={select_styles}
                              placeholder={"Дисциплины"}
                              defaultValue={
                                  (this.props.disciplines)
                                  ? Array.from(this.props.disciplines, (val) => new Object(
                                      {
                                          "value": val.value,
                                          "label": val.label,
                                          "type": "discipline",
                                      }
                                  ))
                                  : []
                              }
                          />
                      </div>
                      <div className='discipline_selector_wrapper'>
                          <Select
                              isMulti
                              options={
                                  Array.from(this.state.coaches, (val) => new Object(
                                      {
                                          "value": val.id,
                                          "label": val.first_name + " " + val.last_name,
                                          "type": "coach"
                                      }
                                  ))
                              }
                              onChange={
                                (e) => {
                                  this.setState({ticket_coaches: Array.from(e, (val) => val["value"])})
                                }
                              }
                              styles={select_styles}
                              placeholder={"Тренеры"}
                              defaultValue={
                                  (this.props.coaches)
                                  ? Array.from(this.props.coaches, (val) => new Object(
                                      {
                                          "value": val.value,
                                          "label": val.label,
                                          "type": "coach"
                                      }
                                  ))
                                  : []
                              }
                          />
                      </div>
                      <div className='ticket_date_valid_wrapper'>
                          <div className='new_bid_child_check_wrapper'>
                              <div className='client_arrive_chbox_wrapper'>
                                  <input
                                      type='checkbox'
                                      id='active_ticket'
                                      key='active_ticket'
                                      name='user-arrive'
                                      className='arrive_chbox'
                                      defaultChecked={!this.state.ticket_active}
                                      onChange={
                                          (e) => {
                                              if (e.target.checked) {
                                                  this.setState({ticket_active: false});
                                              }
                                              else {
                                                  this.setState({ticket_active: true});
                                              }
                                          }
                                      }
                                  />
                                  <span
                                      className='client_arrive_chbox_span'
                                      onChange={
                                          () => {
                                              if (document.getElementById('active_ticket').checked) {
                                                  this.setState({ticket_active: false});
                                              }
                                              else {
                                                  this.setState({ticket_active: true});
                                              }
                                          }
                                      }
                                  >
                                  </span>
                              </div>
                              <div
                                className='chbox_label'
                              >
                                Не активирован
                              </div>
                          </div>
                      </div>
                      {this.state.ticket_active
                          ? <div className='ticket_date_valid_wrapper'>
                                <label>Дата начала</label>
                                {this.state.ticket_start_date
                                    ? <input
                                        key='ticket_date_start'
                                        id='ticket_date_start'
                                        type='date'
                                        placeholder='Дата начала'
                                        className='add_ticket_btn'
                                        defaultValue={this.state.ticket_start_date}
                                        onChange={
                                          (e) => {
                                            this.setState({ ticket_start_date: e.target.value })
                                          }
                                        }
                                      />
                                    : <input
                                        key='ticket_date_start'
                                        id='ticket_date_start'
                                        type='date'
                                        placeholder='Дата начала'
                                        className='add_ticket_btn'
                                        onChange={
                                          (e) => {
                                            this.setState({ ticket_start_date: e.target.value })
                                          }
                                        }
                                      />
                                }
                            </div>
                          : ''
                      }
                      {(this.state.ticket_active && parseInt(localStorage.getItem('user_type')) > 2) || (this.props.ticket && this.props.ticket.id)
                          ? <div className='ticket_date_valid_wrapper'>
                                <label>Дата окончания</label>
                                {this.state.ticket_expire_date
                                  ? <input
                                      key='ticket_date_stop'
                                      id='ticket_date_stop'
                                      type='date'
                                      placeholder='Дата окончания'
                                      className='add_ticket_btn'
                                      defaultValue={this.state.ticket_expire_date}
                                      onChange={
                                        (e) => {
                                          this.setState({ ticket_expire_date: e.target.value })
                                        }
                                      }
                                    />
                                  : <input
                                      key='ticket_date_stop'
                                      id='ticket_date_stop'
                                      type='date'
                                      placeholder='Дата окончания'
                                      className='add_ticket_btn'
                                      onChange={
                                        (e) => {
                                          this.setState({ ticket_expire_date: e.target.value })
                                        }
                                      }
                                    />
                                }
                            </div>
                          : ''
                      }
                      {parseInt(localStorage.getItem("user_type")) > 1
                          ? <div className='add_ticket_promos_wrapper'>
                              <label htmlFor='ticket_discount'>Оплаченная сумма: </label>
                              <input
                                key='ticket_payed_amount'
                                id='ticket_payed_amount'
                                type='number'
                                placeholder='Оплаченная сумма'
                                className='add_ticket_btn'
                                defaultValue={this.state.payed_amount}
                                onChange={
                                  (e) => {
                                    this.setState({payed_amount: e.target.value})
                                  }
                                }
                              />
                            </div>
                          : ''
                      }
                      {parseInt(localStorage.getItem("user_type")) > 2
                          ? <div className='add_ticket_promos_wrapper'>
                              <label htmlFor='ticket_discount'>Стоимость абонемента: </label>
                              <input
                                key='ticket_price'
                                id='ticket_price'
                                type='number'
                                placeholder='Стоимость абонемента'
                                className='add_ticket_btn'
                                defaultValue={this.state.ticket_price}
                                onChange={
                                  (e) => {
                                    this.setState({ticket_price: e.target.value})
                                  }
                                }
                              />
                            </div>
                          : ''
                      }
                      {this.state.payment_types.length > 0 && parseInt(localStorage.getItem("user_type")) > 1
                          ? <div className='ticket_selector_wrapper'>
                                <Select
                                    options={
                                        Array.from(this.state.payment_types, (val) => new Object(
                                            {
                                                "value": val.id,
                                                "label": val.shortcut,
                                            }
                                        ))
                                    }
                                    onChange={
                                        (e) => {
                                            if (e) {
                                                this.setState({payment_type: e["value"]})
                                            }
                                        }
                                    }
                                    styles={select_styles}
                                    placeholder={"Тип оплаты"}
                                    defaultValue={
                                        (this.state.payment_type) ? Array.from(
                                            this.state.payment_types.filter(
                                                pt => parseInt(pt.id) === parseInt(this.state.payment_type)
                                            ), (val) => new Object(
                                              {
                                                  "value": val.id,
                                                  "label": val.shortcut,
                                              }
                                            )
                                        )[0] : null
                                    }
                                    menuPosition={'fixed'}
                                />
                            </div>
                          : ''
                      }
                      {(this.props.ticket && this.props.ticket.id)
                          ? <div className='ticket_date_valid_wrapper'>
                                <div className='new_bid_child_check_wrapper'>
                                    <div className='client_arrive_chbox_wrapper'>
                                        <input
                                            type='checkbox'
                                            id='frozen_ticket'
                                            key='frozen_ticket'
                                            name='user-arrive'
                                            className='arrive_chbox'
                                            onChange={
                                              () => {
                                                  this.setState({froze_ticket: !this.state.froze_ticket});
                                              }
                                            }
                                        />
                                        <span
                                            className='client_arrive_chbox_span'
                                        >
                                        </span>
                                    </div>
                                    <div
                                      className='chbox_label'
                                    >
                                      Заморозить
                                    </div>
                                </div>
                                {this.state.froze_ticket
                                  ? <div className='ticket_date_valid_wrapper'>
                                      <input
                                          key='ticket_froze_days'
                                          id='ticket_froze_days'
                                          type='number'
                                          placeholder='Кол-во дней заморозки'
                                          className='add_ticket_btn'
                                          onChange={
                                            () => {
                                                this.setState({froze_ticket: !this.state.froze_ticket});
                                            }
                                          }
                                      />
                                    </div>
                                  : ''
                                }
                            </div>
                          : ''
                      }
                      {parseInt(localStorage.getItem('user_type')) < 3 && this.props.ticket && this.props.ticket.id
                          ? <div
                              className='client_col_ticket_wrapper'
                              style={
                                  {
                                      marginLeft: 'auto',
                                      marginRight: 'auto',
                                      marginTop: '2rem',
                                      marginBottom: '1rem',
                                  }
                              }
                            >
                                Причина редактирования
                                <textarea
                                  rows='5'
                                  cols='35'
                                  id='ticket_change_reason'
                                  className='message_input'
                                  style={
                                      {
                                          marginTop: '1rem',
                                      }
                                  }
                                  onChange={
                                    (e) => {
                                      this.setState({reason: e.target.value})
                                    }
                                  }
                                >
                                </textarea>
                            </div>
                          : ''
                      }
                      <button
                          id='add-user-ticket'
                          key='user-traffic-accept'
                          className='drill_traffic_btn'
                          data-club_unit={this.state.clubUnitId}
                          onClick={
                            () => {
                              this.props.handleAddUserTicket(
                                {
                                  promo: this.state.promo,
                                  start_date: this.state.ticket_start_date,
                                  expire_date: this.state.ticket_expire_date,
                                  payed: true,
                                  active: this.state.ticket_active,
                                  club_units: JSON.parse(localStorage.getItem("user_ticket_club_units")),
                                  ticket: this.state.ticket_ticket,
                                  discipline: this.state.ticket_disciplines,
                                  coaches: this.state.ticket_coaches,
                                  reason: this.state.reason,
                                  promo_code: this.state.promo_code,
                                  discount: this.state.ticket_discount,
                                  payment_type: this.state.payment_type,
                                  payed_amount: this.state.payed_amount,
                                  price: this.state.ticket_price,
                                }
                              )
                            }
                          }
                      >
                          &#10004;
                      </button>
                      <button
                          id='decline-user-ticket'
                          key='user-traffic-decline'
                          className='drill_traffic_btn'
                          onClick={this.props.handleDeclineUserTicket}
                      >
                          &#10008;
                      </button>
                  </div>
            }
            </div>
        )
    }
}

export default AddTicket
