import React from 'react'
import compose from 'lodash/fp/compose';
import {
  get_request,
  post_request,
} from '../api/api_call'

var _ = require('lodash');

class UserDebts extends(React.Component) {
  constructor(props) {
    super(props)
    this.state = {
      lock_spinner: false,
      client_debts: [],
    }
  }

  componentDidMount() {
    this.getClientDepts()
  }

  getClientDepts() {
    this.setState(
      {lock_spinner: true},
      () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/client/debts/')
        uri.searchParams.set('client_id', this.props.client_id)
        get_request(uri.href, this.props.catchError).then(
          json => {
            if (this.props.logged_in && json) {
              this.setState({
                client_debts: json,
                lock_spinner: false,
              })
            }
          }
        )
      }
    )
  }

  renderDebts() {
    return this.state.client_debts.map((debt, index) => {
      return (
        <div
          key={'debt_' + index}
          className='client_debt_row client_debt_body'
        >
          <div className='client_dept_col_1'>
            {debt.item}
          </div>
          <div className='client_dept_col_2'>
            {(debt.start_date === debt.expire_date) ? debt.start_date : `${debt.start_date} - ${debt.expire_date}`}
          </div>
          {debt.categories.length === 1 && debt.categories[0] !== 'Абонемент'
            ? <div
                className='client_dept_col_3'
              >
                <div
                  style={
                    {
                      display: 'flex',
                      width: 'fit-content',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }
                  }
                >
                  <div>
                    {debt.price}
                  </div>
                  <div>
                    <input
                      type='number'
                      placeholder='Размер оплаты'
                      className='add_ticket_btn'
                      style={
                        {
                          width: '100px',
                        }
                      }
                      defaultValue={debt.payed_amount}
                      onChange={
                        compose(
                          _.debounce(
                            (e) => {
                              this.setState(
                                {lock_spinner: true},
                                () => {
                                  var uri = new URL(
                                    process.env.REACT_APP_BACKEND_URI + '/api/v1/market/product/items/'
                                  )
                                  post_request(
                                    uri.href,
                                    {
                                      item_pk: debt.id,
                                      payed_amount: e.target.value,
                                    },
                                    this.props.catchError,
                                    'PATCH',
                                  ).then(
                                    json => {
                                      if (!json) {
                                        document.getElementById('toast-body-id').innerHTML = "Серверная ошибка";
                                        var x = document.getElementById("toast-wrapper-id");
                                        x.className = 'show';
                                        setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                                        this.setState({
                                          lock_spinner: false,
                                        });
                                      }
                                      else if (this.props.logged_in && json) {
                                        this.setState({
                                          lock_spinner: false,
                                          client_debts: this.state.client_debts.map(
                                            (debt) => {
                                              if (parseInt(debt.id) === parseInt(json.id)) {
                                                debt.payed_amount = json.payed_amount
                                              }
                                              return debt
                                            }
                                          )
                                        });
                                      }
                                    }
                                  )
                                }
                              )
                            }, 1000
                          ),
                          _.clone
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            : <div className='client_dept_col_3'>
                {`${debt.price}(${debt.payed_amount})руб.`}
              </div>
          }
          <div className='client_dept_col_4'>
            {debt.categories.join()}
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        {this.state.lock_spinner
          ? <div
              className="loader spinner1"
              style={
                {
                  position: 'absolute',
                  top: '25%',
                  left: '50%',
                }
              }
            >
            </div>
          : <div>
              <div>
                Долги:
              </div>
              <div
                className='client_debt_row client_debt_header'
              >
                <div className='client_dept_col_1'>
                  Название
                </div>
                <div className='client_dept_col_2'>
                  Дата
                </div>
                <div className='client_dept_col_3'>
                  Цена(Оплата)
                </div>
                <div className='client_dept_col_4'>
                  Категории
                </div>
              </div>
              <div>
                {this.renderDebts()}
              </div>
            </div>
        }
      </div>
    )
  }
}

export { UserDebts }
