// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom_select {
  cursor: pointer;
}
.custom_select:hover {
  background-color: #f7941e;
}

.conf_name {
    background-color: transparent;
    border-top: none;
    border-bottom: 2px solid #f7941e;
    border-left: none;
    border-right: none;
    outline: none;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.conf_name:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #f7941e;
    outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/profile.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,gCAAgC;IAChC,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,gCAAgC;IAChC,aAAa;AACjB","sourcesContent":[".custom_select {\n  cursor: pointer;\n}\n.custom_select:hover {\n  background-color: #f7941e;\n}\n\n.conf_name {\n    background-color: transparent;\n    border-top: none;\n    border-bottom: 2px solid #f7941e;\n    border-left: none;\n    border-right: none;\n    outline: none;\n    text-align: center;\n    font-size: 18px;\n    font-weight: 600;\n}\n.conf_name:focus {\n    border-top: none;\n    border-left: none;\n    border-right: none;\n    border-bottom: 2px solid #f7941e;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
