import React from 'react';

class AcceptModal extends(React.Component) {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='new_drill_form_wrapper'>
                <h3>{this.props.accept_question}</h3>
                <button
                    id='remove-accept'
                    key='remove-accept'
                    className='drill_traffic_btn'
                    data-accept_id={this.props.accept_id}
                    onClick={this.props.Accept}
                >
                    &#10004;
                </button>
                <button
                    id='remove-decline'
                    key='remove-decline'
                    className='drill_traffic_btn'
                    onClick={this.props.Decline}
                >
                    &#10008;
                </button>
            </div>
        )
    }
}

class CashboxModal extends(React.Component) {
  constructor(props) {
    super(props);
    this.state = {
      cashbox_amount: 0.0,
    }
  }

  render() {
    return (
      <div
        className='background_lock_wrapper'
        id='background_lock_id'
        ref={this.props.chat_ref}
      >
        <div className='modal_window_wrapper'>
          <h3>Остаток в кассе</h3>
          <div className='promo_text_wrapper'>
            <input
              key='cahbox_amount'
              type='number'
              placeholder='Остаток в кассе'
              className='promo_text'
              defaultValue='0.0'
              onChange={
                (e) => {this.setState({cashbox_amount: e.target.value})}
              }
            />
          </div>
          <button
            id='remove-accept'
            key='remove-accept'
            className='drill_traffic_btn'
            onClick={
              () => {
                this.props.updateBoxOffice(this.state.cashbox_amount)
              }
            }
          >
            &#10004;
          </button>
        </div>
      </div>
    )
  }
}

export { AcceptModal, CashboxModal }
