import React from 'react';

class Spinner extends(React.Component) {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='background_lock_wrapper' id='background_lock_id'>
                <div className='drill_clients_wrapper' id='drill_clients_wrapper_id'>
                    <div className='lock_modal_content_wrapper  lock_modal_continuous_content_wrapper' id='lock_modal_content_id'>
                         <div className="loader"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Spinner
