import React from 'react';
import { ResponsiveLine } from '@nivo/line'
import { Bar } from 'react-chartjs-2';

class SailsStatisticPlot extends (React.Component) {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div
        className='graphic_wrapper'
        style={
          {
            display: 'block',
            height: '500px',
          }
        }
      >
        <ResponsiveLine
          data={this.props.data}
          margin={{ top: 50, right: 110, bottom: 100, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: false,
              reverse: false,
              max: this.props.y_max,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          lineWidth={4}
          axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Дата',
              legendOffset: 60,
              legendPosition: 'middle',
              tickRotation: 60,
          }}
          axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Сумма',
              legendOffset: -50,
              legendPosition: 'middle'
          }}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
              {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                      {
                          on: 'hover',
                          style: {
                              itemBackground: 'rgba(0, 0, 0, .03)',
                              itemOpacity: 1
                          }
                      }
                  ]
              }
          ]}
        />
      </div>
    )
  }
}

class CoachesDrillsPlot extends (React.Component) {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef()
  }

  render() {
    var labels = this.props.stat_data.map(d => d.day)
    var datasets = [
      {
        label: 'Проведенные тренировки',
        data: this.props.stat_data.map(d => d.amount),
        backgroundColor: 'rgb(1, 131, 23)',
      },
    ]
    var title = `${this.props.plot_text}: ${datasets[0].data.reduce((ps, d) => ps + d, 0)}`
    return(
      <div
        className='graphic_wrapper'
        style={
          {
            height: 'fit-content',
            marginBottom: '1rem',
            display: 'block',
          }
        }
      >
        <Bar
          ref={this.chartRef}
          data={
            {
              labels: labels,
              datasets: datasets,
            }
          }
          options={
            {
              plugins: {
                title: {
                  display: true,
                  text: title,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                  min: 0,
                  ticks: {
                    stepSize: 1,
                  },
                },
              },
            }
          }
        />
      </div>
    )
  }
}

export { SailsStatisticPlot, CoachesDrillsPlot }
