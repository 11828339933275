import React from 'react';
import AddTicket from './AddTicket';
import CreateNotify from './CreateNoty';
import Spinner from './Spinner';
import compose from 'lodash/fp/compose';
import Select from 'react-select';
import {
  Link
} from "react-router-dom";
import FullUsersTable from './interface/UsersList'
import BaseUsersList from './base_objs/UsersList'
import {UserDebts} from './interface/UserInfo';
import { delete_request, post_request } from './api/api_call'
import { locales } from '../helpers'

const select_styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        padding: 20,
        'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#f7941e',
        border: '1px solid #f7941e',
        'background-color': '#c5c6c8',
        'outline-color': '#f7941e',
    }),
    container: (provided, state) => ({
        ...provided,
        width: '100%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'margin-bottom': '0.5rem',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'background-color': '#c5c6c8',
    }),
}

var _ = require('lodash');

class UsersList extends(BaseUsersList) {
    constructor(props) {
        super(props);
        this.modal_ref = React.createRef();
    }

    componentDidMount() {
        if (this.props.list_type === 'full') {
            document.addEventListener('scroll', this.scrollListener);
            document.addEventListener('mousedown', this.handleClickOut);
            this.getClubDepartments()
        }
        else if (this.props.list_type === 'tickets') {
            var d = document.getElementsByClassName('clients_table_wrapper');
            if (d) {
                d[0].addEventListener('scroll', this.modalScrollListener);
            }
        }
        let p = this.state.page + 1;
        this.setState(
            {
                page: p,
                offset: p * this.state.limit,
                lock_spinner: true,
            }, () => {
                if (
                    this.props.history.location &&
                    this.props.history.location.state &&
                    this.props.history.location.state.users &&
                    this.props.history.location.state.users.length > 0
                  ) {
                  this.setState(
                    {
                      users: this.props.history.location.state.users,
                      clients_ids: this.props.history.location.state.clients_ids,
                      processing: this.props.history.location.state.processing,
                      uri: this.props.history.location.state.uri,
                      lock_spinner: false,
                    }, () => {
                      this.props.history.replace(
                        '/clients',
                        {users: []},
                      )
                    }
                  )
                }
                else {
                  this.getData();
                }
            }
        );
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', function(e) {});
        document.removeEventListener('mousedown', this.handleClickOut);
    }

    scrollListener = (e) => {
        if (this.state.processing &&
             document.documentElement.scrollTop >= (document.documentElement.scrollHeight - window.innerHeight) * 0.9) {
            let p = this.state.page + 1;
            this.setState({page: p, processing: false, offset: p * this.state.limit});
            this.getData();
        }
    }

    modalScrollListener = (e) => {
        if (
            this.props.list_type === 'tickets' &&
            this.state.processing &&
            (document.getElementsByClassName('clients_table_wrapper')[0].scrollHeight - document.getElementsByClassName('clients_table_wrapper')[0].scrollTop) * 0.9 <=
             document.getElementsByClassName('clients_table_wrapper')[0].offsetHeight) {
            let p = this.state.page + 1;
            this.setState({page: p, processing: false, offset: p * this.state.limit});
            this.getData();
        }
    }

    handleClickOut = (e) => {
        if (this.modal_ref &&
            this.modal_ref.current &&
            (!this.modal_ref.current.contains(e.target) || e.target.id === 'background_lock_id')
        ) {
            this.setState(
                {
                    remove_user: false,
                    show_expired_tickets: false,
                    client_expire_tickets: null,
                    show_client_debts: false,
                }
            );
        }
    }

    getClientsData() {
      fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_clients/?drill='
        + this.props.drill + '&sort=' + this.state.sort_key, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (this.props.logged_in) {
                var clients = json.results;
                var clients_ids = [];
                for (let i = 0; i < clients.length; i++) {
                    clients_ids.push(clients[i]['id']);
                }
                this.setState({clients: clients, clients_ids: clients_ids});
            }
        })
    }

    replaceNonActiveType(type) {
        this.setState(
            {
                non_active_type: type,
                replace_expired_tickets: true,
            }, () => {
                this.getExpiredTickets();
            }
        );
    }

    getExpiredTickets() {
        if (
            !this.state.replace_expired_tickets &&
            this.state.expired_tickets.length > 0 &&
            parseInt(this.state.expired_tickets[0]["user"]["id"]) === parseInt(this.state.client_expire_tickets)
        ) {
            this.setState(
              {
                  show_expired_tickets: true,
                  lock_spinner: false,
              }
            );
        }
        else {
            var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/user_tickets/');
            if (this.state.non_active_type === 'expired') {
                uri.searchParams.set("expired", '1');
            }
            else if (this.state.non_active_type === 'non-active') {
                uri.searchParams.set("active", 'False');
            }
            else if (this.state.non_active_type === 'frozen') {
                uri.searchParams.set("frozen", 'True');
            }
            uri.searchParams.set("user", this.state.client_expire_tickets);
            fetch(uri.href, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            })
              .then(res => {
                  if (res.status === 401) {
                      this.props.catchError(res.status);
                      return
                  }
                  const response = res.json();
                  return response;
              })
              .then(json => {
                  if (this.props.logged_in) {
                      this.setState(
                        {
                            expired_tickets: json,
                            show_expired_tickets: true,
                            lock_spinner: false,
                            replace_expired_tickets: false,
                        }
                      );
                  }
              })
        }
    }

    removeTicketFromExpiredList(ticket_id) {
        for (let i = 0; i < this.state.expired_tickets.length; i++) {
            if (parseInt(this.state.expired_tickets[i]["id"]) === parseInt(ticket_id)) {
                var expired_tickets = this.state.expired_tickets;
                expired_tickets.splice(i, 1);
                this.setState(
                    {
                        expired_tickets: expired_tickets,
                    }
                );
                break
            }
        }
    }

    searchUser = (e) => {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                var fts = '';
                if (this.props.list_type === 'tickets') {
                    fts = document.getElementById('fts_input_modal_id').value;
                }
                else if (this.props.list_type === 'full') {
                    fts = document.getElementById('fts_input_id').value;
                }
                this.setState({fts: fts}, () => {this.props.handleReplaceFts(fts)});
                var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_clients/');
                uri.searchParams.set("limit", 20);
                uri.searchParams.set("offset", 0);
                uri.searchParams.set("search", encodeURIComponent(fts));
                uri.searchParams.set("discipline", this.state.discipline_id);
                uri.searchParams.set("coach", this.state.coach_id);
                uri.searchParams.set("club", this.state.club);
                uri.searchParams.set("ticket", this.state.club_ticket_id);
                uri.searchParams.set("club_unit", this.state.club_unit);
                uri.searchParams.set("sort", this.state.sort_key);
                uri.searchParams.set("client_type", this.state.client_type);
                uri.searchParams.set("ticket_expire_date", this.state.ticket_expire_date);
                uri.searchParams.set("employee", this.state.employee);
                if (this.props.drill) {
                    uri.searchParams.set("drill", this.props.drill);
                }
              fetch(uri.href, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('token')}`
                }
              })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (this.props.logged_in) {
                        var uri = null;
                        if (json.next) {
                            uri = new URL(json.next);
                            uri = process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_clients/' + uri.search;
                        }
                        this.setState(
                            {
                                users: json.results,
                                uri: uri,
                                lock_spinner: false,
                            }
                        );
                    }
                })
            }
        )
    }

    removeUser = (e) => {
      this.setState(
        {lock_spinner: true},
        () => {
          var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/account/' + e.target.dataset.client_id + '/')
          delete_request(uri.href, this.props.catchError).then(
            json => {
              var users = this.state.users;
              for (let i = 0; i < users.length; i++) {
                if (parseInt(users[i]["id"]) === parseInt(json.id)) {
                  users.splice(i, 1);
                  break
                }
              }
              this.setState(
                {
                  users: users,
                  remove_user: false,
                  remove_client_id: null,
                }
              );
            }
          )
        }
      )
    }

    handleDeclineRemoveUser = (e) => {
        document.getElementById('background_lock_id').style.display = 'none';
        this.setState({remove_user: false, remove_client_id: null});
    }

    renderNewTicketBtn(id) {
        if (parseInt(localStorage.getItem('user_type')) > 1) {
            return (
                <div className='ticket_btn_info_wrapper'>
                    <div
                        id={'add_client_ticket-' + id}
                        key={'add_client_ticket-' + id}
                        onClick={this.handleNewUserTicket}
                        className='add_drill_btn add_client_ticket_btn'
                        data-user_id={id}
                    >
                        <span data-user_id={id}>&#10010;</span>
                    </div>
                </div>
            )
        }
    }

    removeTicket() {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/user_tickets/' + this.state.editing_ticket.id + '/', {
                  method: 'DELETE',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                  },
                })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    else if (res.status === 406) {
                        return
                    }
                    else if (res.status === 200) {
                        if (this.props.logged_in) {
                            var users = this.state.users;
                            for (let i = 0; i < users.length; i++) {
                                if (parseInt(users[i]['id']) === parseInt(this.state.addTicketUserId)) {
                                    for (let j = 0; j < users[i]['tickets'].length; j++) {
                                        if (parseInt(users[i]['tickets'][j]['id']) === parseInt(this.state.userTicketId)) {
                                            users[i]['tickets'].splice(j, 1);
                                            break
                                        }
                                    }
                                }
                            }
                            this.setState(
                                {
                                    addTicket: false,
                                    correct: false,
                                    users: users,
                                    accept: false,
                                    lock_spinner: false,
                                    addTicketUserId: '',
                                    userTicketId: '',
                                    update_ticket: false,
                                    remove_ticket: false,
                                    promo_code: null,
                                    ticketClubUnitId: '',
                                    ticketStartDate: '',
                                    ticketExpireDate: '',
                                    ticketId: null,
                                    correct_ticket: {},
                                    name: '',
                                    ticketDisciplines: [],
                                    ticketCoaches: [],
                                    userTicketId: null,
                                }
                            );
                        }
                        return
                    }
                })
            }
        )
    }

    handleCorrectTicket = (ticket) => {
        var disciplines = Array.from(
            ticket.discipline, (discipline) => new Object(
                {
                    value: discipline.id,
                    label: discipline.name,
                }
            )
        )
        var coaches = Array.from(
            ticket.coaches, (coach) => new Object(
                {
                    value: coach.id,
                    label: `${coach.first_name} ${coach.last_name}`
                }
            )
        )
        this.setState(
            {
                editing_ticket: ticket,
                update_ticket: true,
                ticketClubUnitId: ticket.ticket.club_unit[0],
                correct_ticket: {
                    value: ticket.id,
                    label: (!ticket.illusive) ? ticket.ticket.ticket : ticket.ticket.illusive_promo,
                    illusive: ticket.illusive,
                    type: 'ticket',
                },
                ticketDisciplines: disciplines,
                ticketCoaches: coaches,
                new_ticket_data: {
                    user: parseInt(ticket.user_id),
                    ticket: parseInt(ticket.ticket.id),
                    discipline: Array.from(disciplines, (val) => val['value']),
                    coaches: Array.from(coaches, (val) => val['value']),
                    start_date: ticket.start_date,
                    expire_date: ticket.expire_date,
                    expired: ticket.expired,
                    discount: parseFloat(ticket.discount),
                },
            }
        );
    }

    handleUpdateUserTicket(data) {
        if (this.state.accept) {
            data = {expired: this.state.accept};
        }
        else {
            data["user"] = this.state.editing_ticket.user_id;
        }
        this.updateTicket(this.state.editing_ticket.id, data, true);
    }

    updateTicket(ticket_id, data, update_dom, func) {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                var uri = new URL(
                    process.env.REACT_APP_BACKEND_URI + '/api/v1/user/user_tickets/' + ticket_id + '/'
                )
                post_request(
                    uri.href,
                    data,
                    this.props.catchError,
                    'POST',
                ).then(json => {
                    if (!json) {
                        return false
                    }
                    if (this.props.logged_in) {
                        var users = this.state.users;
                        var exist_tickets = [];
                        for (let i = 0; i < users.length; i++) {
                            if (
                                (
                                    parseInt(users[i]['id']) === parseInt(this.state.addTicketUserId)
                                    || parseInt(users[i]['id']) === parseInt(json.user)
                                )
                                && !data["expired"]
                            ) {
                                for (let j = 0; j < users[i]['tickets'].length; j++) {
                                    exist_tickets.push(parseInt(users[i]['tickets'][j]["id"]));
                                    if (parseInt(users[i]['tickets'][j]["id"]) === parseInt(json.id) && !json.frozen && json.active) {
                                        users[i]['tickets'][j] = json;
                                        break
                                    }
                                    else if (parseInt(users[i]['tickets'][j]["id"]) === parseInt(json.id) && (json.frozen || !json.active)) {
                                        users[i]['tickets'].splice(j, 1);
                                        break
                                    }
                                }
                                if (!exist_tickets.includes(parseInt(json.id)) && !json.frozen && json.active) {
                                    users[i]['tickets'].push(json);
                                    break
                                }
                                break
                            }
                        }
                        this.setState(
                            {
                                addTicket: false,
                                update_ticket: false,
                                correct: false,
                                users: users,
                                accept: false,
                                lock_spinner: false,
                                editing_ticket: null,
                            }, () => {
                                if (parseInt(localStorage.getItem('user_type')) < 3) {
                                    document.getElementById('toast-body-id').innerHTML = "Запрос на редактирование создан";
                                    var x = document.getElementById("toast-wrapper-id");
                                    x.className = 'show';
                                    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                                }
                                if (json.payed && !json.frozen && document.getElementById('show_ticket_info_id-' + json.id)) {
                                    document.getElementById('show_ticket_info_id-' + json.id).classList.remove('warning_font');
                                }
                                else if (!json.payed && !json.frozen && document.getElementById('show_ticket_info_id-' + json.id)) {
                                    document.getElementById('show_ticket_info_id-' + json.id).classList.add('warning_font');
                                }
                                if (data['expired']) {
                                    if (document.getElementById('ticket_info-' + json.id)) {
                                        document.getElementById('ticket_info-' + json.id).style.display = 'none';
                                    }
                                }
                            }
                        );
                    }
                })
            }
        )
    }

    checkTicketExistence(tickets) {
        if (tickets.length === 0) {
            return false
        }
        return true
    }

    renderTicketSelector(props) {
        return props.tickets.map((ticket) => {
            if (parseInt(ticket.id) === props.choosen_ticket){
                return (
                    <option key={'drill_ticket_option-' + props.user + '-'}
                            id={'drill_ticket_option-' + props.user + '-'}>
                        Выбери абонемент
                    </option>
                )
            }
            else {
                return (
                    <option key={'drill_ticket_option-' + props.user + '-' + ticket.ticket.id}
                            id={'drill_ticket_option-' + props.user + '-' + ticket.id}>
                        {ticket.ticket.ticket} {ticket.start_date}
                    </option>
                )
            }
        })
    }

    renderNewUserTicket() {
        return (
            <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_ref} data-test='test1'>
            <div className='add_new_user_form_wrapper'>
                <AddTicket
                    ticket={this.state.editing_ticket}
                    clubUnitId={this.props.clubUnitId}
                    handleAddUserTicket={this.handleAddUserTicket.bind(this)}
                    club_units={this.props.club_units}
                    handleDeclineUserTicket={this.handleDeclineUserTicket.bind(this)}
                    logged_in={this.props.logged_in}
                    handleSetUserTicketData={this.handleSetUserTicketData.bind(this)}
                    ticket_active={true}
                />
            </div>
            </div>
        )
    }

    renderUsersList() {
        return this.state.users.map((user, index) => {
            return (
                <div
                    className='clients_tickets_table_body_wrapper'
                    key={'client_tr-' + user.id}
                >
                    <div
                        className='client_ticket_num client_ticket_col_0'
                    >
                        {index + 1}
                    </div>
                    <div
                        className='client_ticket_name client_ticket_col_1'
                        onClick={this.handleUpdateTraffic}
                        data-client_id={user.id}
                    >
                        {user.first_name} {user.last_name}
                    </div>
                    <div
                        className='client_ticket_arrive client_ticket_col_2'
                    >
                        {(() => {
                            if (this.state.clients_ids.includes(user.id)) {
                                return (
                                    <div className='client_arrive_chbox_wrapper'>
                                    <input type='checkbox' id={'client_arrive-' + user.id} key={'client_arrive-' + user.id} name='user-arrive' className='arrive_chbox'
                                    checked={true} onChange={this.handleUpdateTraffic} />
                                    <span className='client_arrive_chbox_span' onChange={this.handleUpdateTraffic}></span>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className='client_arrive_chbox_wrapper'>
                                    <input type='checkbox' id={'client_arrive-' + user.id} key={'client_arrive-' + user.id} name='user-arrive' className='arrive_chbox'
                                    checked={false} onChange={this.handleUpdateTraffic} />
                                    <span className='client_arrive_chbox_span' onChange={this.handleUpdateTraffic}></span>
                                    </div>
                                )
                            }
                        })()}
                    </div>
                    <div
                        className='client_ticket_ticket client_ticket_col_3'
                    >
                        {(() => {
                            if (this.checkTicketExistence(user.tickets)) {
                                if (localStorage.getItem('user_type') > 1) {
                                    var ticket = 'Выбери абонемент';
                                    var ticket_id = '';
                                    var ticket_key = '';
                                    var ticket_start_date = '';
                                    if (user.drill_ticket) {
                                        for (let i = 0; i < user.tickets.length; i++) {
                                            if (parseInt(user.tickets[i]['id']) === parseInt(user.drill_ticket[1])) {
                                                ticket = user.tickets[i]['ticket']['ticket'];
                                                ticket_key = user.tickets[i]['ticket']['id'];
                                                ticket_id = user.tickets[i]['id'];
                                                ticket_start_date = user.tickets[i]['start_date'];
                                            }
                                        }
                                    }
                                    return (
                                        <select key={'drill_ticket_select-' + user.id + '-' + this.props.drill} onChange={this.handleUpdateTraffic}
                                            id={'drill_ticket_select-' + user.id}
                                            name='drill-tickets-selector' className='tickets_select_tag'>
                                            <option key={'drill_ticket_option-' + user.id + '-' + ticket_key}
                                                id={'drill_ticket_option-' + user.id + '-' + ticket_id}>
                                                    {ticket} {ticket_start_date}
                                                </option>
                                            {this.renderTicketSelector({'user': user.id, 'tickets': user.tickets, 'choosen_ticket': ticket_id})}
                                        </select>
                                    )
                                }
                                else if (parseInt(localStorage.getItem('user_type')) === 1) {
                                    if (user.drill_ticket) {
                                        var t = '';
                                        for (let i = 0; i < user.tickets.length; i++) {
                                            if (user.drill_ticket[1] === user.tickets[i]['id']) {
                                                t = user.tickets[i]['ticket']['ticket'];
                                            }
                                        }
                                        return (
                                            <div key={'drill_ticket_label-' + user.id}><span>{t}</span></div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div key={'drill_ticket_label-' + user.id}></div>
                                        )
                                    }
                                }
                                else {
                                    return (<div key={'drill_ticket_label-' + user.id}></div>)
                                }
                            }
                            else {
                                if (localStorage.getItem('user_type') > 1) {
                                        return(
                                            <button id={'add_ticket-' + user.id} key={'add_ticket-' + user.id} onClick={this.handleNewUserTicket} className='add_ticket_btn'
                                                    data-user_id={user.id}>Новый абонемент</button>
                                        )
                                }
                                else {
                                    return (<div></div>)
                                }
                            }
                        })()}
                    </div>
                </div>
            )
        })
    }

    renderTable() {
        return (
            <div
                className='clients_tickets_table_wrapper'
            >
                <div
                    className='clients_tickets_table_head_wrapper'
                >
                    <div
                        className='clients_tickets_table_head_item client_ticket_col_0'
                    >
                    </div>
                    <div
                        className='clients_tickets_table_head_item client_ticket_col_1'
                    >
                        Клиент
                    </div>
                    <div
                        className='clients_tickets_table_head_item client_ticket_col_2'
                    >
                    </div>
                    <div
                        className='clients_tickets_table_head_item client_ticket_col_3'
                    >
                        Абонемент
                    </div>
                </div>
                {this.renderUsersList()}
            </div>
        )
    }

    handleModalClick = (e) => {
        console.log();
    }

    handleCorrectArriveList = (e) => {
        var selectors = document.getElementsByName('drill-tickets-selector');
        for (let i = 0; i < selectors.length; i++) {
            selectors[i].disabled = false;
        }
        this.setState({correct: true});
    }

    handleUpdateTraffic = (e) => {
        document.getElementById('lock_modal_content_id').style.display = 'block';
        var data = {clients: [], remove_clients: [], drill: this.props.drill}
        var id = '';
        var cl_st = false;
        if (e.target.tagName === "DIV") {
            id = e.target.dataset.client_id;
            cl_st = !document.getElementById("client_arrive-" + id).checked;
        }
        else if (e.target.tagName === "INPUT") {
            id = e.target.id;
            cl_st = document.getElementById(id).checked;
            id = id.split('-')[id.split('-').length - 1];
        }
        if (e.target.nodeName === 'SELECT' && e.target.value !== 'Выбери абонемент') {
            id = e.target.id.split('-')[e.target.id.split('-').length - 1];
            cl_st = true;
        }
        else if (e.target.nodeName === 'SELECT' && e.target.value === 'Выбери абонемент') {
            id = e.target.id.split('-')[e.target.id.split('-').length - 1];
            cl_st = false;
        }
        if (cl_st === true) {
            var drill_selector = document.getElementById('drill_ticket_select-' + id);
            let drill_id = null;
            if (drill_selector && drill_selector.options[drill_selector.selectedIndex].id !== '') {
                drill_id = drill_selector.options[drill_selector.selectedIndex].id.split('-')[drill_selector.options[drill_selector.selectedIndex].id.split('-').length - 1];
            }
            let client_body = {};
            client_body['id'] = id;
            client_body['ticket'] = drill_id;
            data['clients'].push(client_body);
        }
        else if (cl_st === false) {
            data['remove_clients'].push(id);
        }
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/traffic/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(data)
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            else if (res.status === 406 || res.status === 500 || res.status === 400) {
                document.getElementById('lock_modal_content_id').style.display = 'none';
                if (res.status === 406) {
                    res.json().then((json) => {
                        this.props.updateCurrentShift(json);
                    });
                return
            }
                return null;
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (!json) {
                return
            }
            if (this.props.logged_in && json) {
                var users = this.state.users;
                var clients_ids = this.state.clients_ids;
                if (cl_st === true) {
                    clients_ids.push(parseInt(id));
                    this.props.handleUpdateDrillClients({drill: this.props.drill, counter: 1});
                }
                else if (cl_st === false) {
                    clients_ids = clients_ids.filter(x => x !== parseInt(id));
                    this.props.handleUpdateDrillClients({drill: this.props.drill, counter: -1});
                }
                for (let i = 0; i < json.clients.length; i++) {
                    for (let j = 0; j < users.length; j++) {
                        if (json.clients[i]['id'] === users[j]['id']) {
                            users[j] = json.clients[i];
                            break
                        }
                    }
                }
                this.setState(
                    {
                        users: users,
                        clients_ids: clients_ids
                    }, () => {
                        document.getElementById('lock_modal_content_id').style.display = 'none';
                        document.getElementById('client_arrive-' + id).checked = cl_st;
                    }
                );
            }
        })
        if (this.props.logged_in) {
            this.setState({correct: false});
        }
    }

    handleDeclineCorrectArriveList = (e) => {
        var selectors = document.getElementsByName('drill-tickets-selector');
        for (let i = 0; i < selectors.length; i++) {
            selectors[i].disabled = false;
        }
        this.setState({correct: false, addTicket: false, form: ''});
    }

    handleSetUserTicketData = (e) => {
        var ticket_data = this.state.new_ticket_data;
        if (e.constructor === Object && e["type"] === "ticket") {
            ticket_data["ticket"] = e["value"];
        }
        else if (e.constructor === Array && e[0] && e[0]["type"] === "discipline") {
            ticket_data["discipline"] = Array.from(e, (val) => val["value"]);
        }
        else if (e.constructor === Array && e[0] && e[0]["type"] === "coach") {
            ticket_data["coaches"] = Array.from(e, (val) => val["value"]);
        }
        this.setState({new_ticket_data: ticket_data});
    }

    handleAddUserTicket(data) {
        data["user"] = this.state.addTicketUserId;
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/user_tickets/', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(data)
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            else if (res.status === 406) {
                res.json().then((json) => {
                    this.props.updateCurrentShift(json);
                });
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (!json) {
                return
            }
            if (this.props.logged_in) {
                if (json.active) {
                    var users = this.state.users;
                    for (let i = 0; i < users.length; i++) {
                        if (parseInt(users[i]['id']) === parseInt(this.state.addTicketUserId)) {
                            users[i]['tickets'].push(json);
                        }
                    }
                    this.setState(
                        {
                            addTicket: false, correct: false, users: users, addTicketUserId: ''
                        }, () => {
                            localStorage.removeItem("user_ticket_club_units");
                        }
                    );
                }
                else {
                    this.setState(
                        {
                            addTicket: false, correct: false, addTicketUserId: ''
                        }, () => {
                            localStorage.removeItem("user_ticket_club_units");
                        }
                    );
                }
            }
        })
    }

    handleDeclineUserTicket = (e) => {
        this.setState(
            {
                addTicket: false,
                update_ticket: false,
            }
        );
    }

    handleShowFilters = (e) => {
        var filters_list = document.getElementById(this.props.list_type + '_filters_list_id');
        if (filters_list.style.display === 'flex' || filters_list.style.display === 'block') {
            filters_list.style.display = 'none';
        }
        else {
            if (this.state.disciplines.length === 0 || this.state.coaches.length === 0 || this.state.club_tickets.length === 0 ||
                this.state.clubs.length === 0 || this.state.club_units.length === 0) {
                fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/filters_info/', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                .then(res => res.json())
                .then(json => {
                    var clubs = []
                    var club_units = []
                    for (let i = 0; i < json.clubs.length; i++) {
                        clubs.push({ id: json.clubs[i]['id'], name: json.clubs[i]['name'] });
                        for (let j = 0; j < json.clubs[i]['units'].length; j++) {
                            club_units.push({ id: json.clubs[i]['units'][j]['id'], name: json.clubs[i]['units'][j]['name'] });
                        }
                    }
                    this.setState({disciplines: json.disciplines, coaches: json.coaches, club_tickets: json.tickets, clubs: clubs, club_units: club_units},
                        () => {
                            if (this.props.list_type === 'full' && this.props.device === "desktop") {
                                filters_list.style.display = 'flex';
                            }
                            else if (this.props.list_type === 'full' && this.props.device === "mobile") {
                                filters_list.style.display = 'block';
                            }
                            else if (this.props.list_type === 'tickets') {
                                filters_list.style.display = 'block';
                            }
                        });
                });
            }
            else {
                if (this.props.list_type === 'full') {
                    filters_list.style.display = 'flex';
                }
                else if (this.props.list_type === 'tickets') {
                    filters_list.style.display = 'block';
                }
            }
        }
    }

    handleApplyFilters = (e) => {
        if (typeof(e.target) === 'object') {
          console.log(e.target.value)
          this.setState({ticket_expire_date: e.target.value}, () => { this.searchUser() })
        }
        else {
          if (e["type"] === "club") {
            this.setState({club: parseInt(e["value"])}, () => { this.searchUser(); });
          }
          else if (e["type"] === "club_unit") {
            this.setState({club_unit: parseInt(e["value"])}, () => { this.searchUser(); });
          }
          else if (e["type"] === "discipline") {
            this.setState({discipline_id: parseInt(e["value"])}, () => { this.searchUser(); });
          }
          else if (e["type"] === "coach") {
            this.setState({coach_id: parseInt(e["value"])}, () => { this.searchUser(); });
          }
          else if (e["type"] === "ticket") {
            this.setState({club_ticket_id: parseInt(e["value"])}, () => { this.searchUser(); });
          }
          else if (e["type"] === "client_type") {
            this.setState({client_type: e["value"]}, () => { this.searchUser(); });
          }
          else if (e["type"] === "client_employee") {
              console.log(e)
            this.setState({employee: e["value"]}, () => { this.searchUser(); });
          }
        }
    }

    handleSelectSorting = (e) => {
        var sort_key = e.target.options[e.target.selectedIndex].dataset.key;
        var uri = (this.state.uri) ? new URL(this.state.uri) : new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_clients/');
        var uri = new URL(uri);
        uri.searchParams.set("limit", 20);
        uri.searchParams.set("offset", 0);
        uri.searchParams.set("sort", sort_key);
        this.setState({users: [], sort_key: sort_key, uri: uri.href},
            () => {this.getData();}
        );
    }

    renderUsersFiltersList() {
        if (parseInt(localStorage.getItem("user_type")) > 1) {
            var employees = []
            for (let department of this.state.club_departments) {
                for (let emp of department.employees) {
                    employees.push(
                        new Object(
                            {
                                'value': emp.employee.id,
                                'label': emp.employee.first_name + ' ' + emp.employee.last_name,
                                'type': 'client_employee',
                            }
                        )
                    )
                }
            }
        }
        return (
            <div className='users_filters_wrapper'>
                <div className='show_filters_btn_wrapper'>
                    <input type='button' className='add_ticket_btn' id='show_filters_btn_id' onClick={this.handleShowFilters} value={locales.filters.filters} />
                </div>
                <div className='filters_list_wrapper' id={this.props.list_type + '_filters_list_id'}>
                    <div className='filter_btn_wrapper'>
                        <Select
                            options={
                                Array.from(this.state.clubs, (val) => new Object(
                                    {
                                        "value": val.id,
                                        "label": val.name,
                                        "type": "club",
                                    }
                                ))
                            }
                            onChange={this.handleApplyFilters}
                            styles={select_styles}
                            placeholder={locales.filters.club}
                        />
                    </div>
                    <div className='filter_btn_wrapper'>
                        <Select
                            options={
                                Array.from(this.state.club_units, (val) => new Object(
                                    {
                                        "value": val.id,
                                        "label": val.name,
                                        "type": "club_unit",
                                    }
                                ))
                            }
                            onChange={this.handleApplyFilters}
                            styles={select_styles}
                            placeholder={locales.filters.club_unit}
                        />
                    </div>
                    <div className='filter_btn_wrapper'>
                        <Select
                            options={
                                Array.from(this.state.disciplines, (val) => new Object(
                                    {
                                        "value": val.id,
                                        "label": val.name,
                                        "type": "discipline",
                                    }
                                ))
                            }
                            onChange={this.handleApplyFilters}
                            styles={select_styles}
                            placeholder={locales.filters.discipline}
                        />
                    </div>
                    {parseInt(localStorage.getItem("user_type")) > 1
                        ? <div className='filter_btn_wrapper'>
                              <Select
                                  options={
                                      Array.from(this.state.coaches, (val) => new Object(
                                          {
                                              "value": val.id,
                                              "label": val.first_name + ' ' + val.last_name,
                                              "type": "coach",
                                          }
                                      ))
                                  }
                                  onChange={this.handleApplyFilters}
                                  styles={select_styles}
                                  placeholder={locales.filters.coach}
                              />
                          </div>
                        : ''
                    }
                    <div className='filter_btn_wrapper'>
                        <Select
                            options={
                                Array.from(this.state.club_tickets, (val) => new Object(
                                    {
                                        "value": val.id,
                                        "label": val.ticket + ' (' + val.club_units.toString() + ')',
                                        "type": "ticket",
                                    }
                                ))
                            }
                            onChange={this.handleApplyFilters}
                            styles={select_styles}
                            placeholder={locales.filters.ticket}
                        />
                    </div>
                    <div className='filter_btn_wrapper'>
                        <Select
                            options={
                                [
                                    {"value": "hooky", "label": locales.filters.client_type_values.hooky, "type": "client_type"},
                                    {"value": "non-prolongation", "label": locales.filters.client_type_values.non_prolongation, "type": "client_type"},
                                    {"value": "prolongation", "label": locales.filters.client_type_values.prolongation, "type": "client_type"},
                                    {"value": "no_ticket", "label": locales.filters.client_type_values.no_ticket, "type": "client_type"},
                                    {"value": "frozen", "label": locales.filters.client_type_values.frozen, "type": "client_type"},
                                    {"value": "debtor", "label": locales.filters.client_type_values.debtor, "type": "client_type"},
                                ]
                            }
                            onChange={this.handleApplyFilters}
                            styles={select_styles}
                            placeholder={locales.filters.client_type}
                        />
                    </div>
                    {parseInt(localStorage.getItem("user_type")) > 1
                        ? <div className='filter_btn_wrapper'>
                              <Select
                                  options={employees}
                                  onChange={this.handleApplyFilters}
                                  styles={select_styles}
                                  placeholder={locales.filters.employee_clients}
                              />
                          </div>
                        : <div className='filter_btn_wrapper'>
                              <Select
                                  options={
                                      [
                                          {"value": 0, "label": locales.filters.employee_clients_values.all, "type": "client_employee"},
                                          {"value": 1, "label": locales.filters.employee_clients_values.mine, "type": "client_employee"},
                                      ]
                                  }
                                  onChange={this.handleApplyFilters}
                                  styles={select_styles}
                                  placeholder={locales.filters.employee_clients}
                              />
                          </div>
                    }
                    <div className='filter_btn_wrapper'>
                        <input
                          key='filter_ticket_date_start'
                          id='filter_ticket_date_start'
                          type='date'
                          placeholder='Дата начала'
                          className='add_ticket_btn'
                          style={
                            {
                              paddingTop: '0.4rem',
                              paddingBottom: '0.4rem',
                            }
                          }
                          onChange={this.handleApplyFilters}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderSortContainer() {
        return (
            <div className='users_filters_wrapper'>
                <div className='filter_btn_wrapper'>
                    <select key='club_selector'
                        id={this.props.list_type + '_sort_selector'}
                        className='tickets_select_tag'
                        onChange={this.handleSelectSorting}
                    >
                        <option key='user_tickets__expired-asc' data-key='user_tickets__expired_asc'>{locales.sorting.ticket_exist_with}</option>
                        <option key='user_tickets__expired-desc' data-key='user_tickets__expired_desc'>{locales.sorting.ticket_exist_without}</option>
                        <option key='created_at-asc' data-key='created_at_asc'>{locales.sorting.created_date_asc}</option>
                        <option key='created_at-desc' data-key='created_at_desc'>{locales.sorting.created_date_desc}</option>
                    </select>
                </div>
            </div>
        )
    }

    render() {
        var add_user_style = {};
        if (parseInt(window.innerHeight) < 660) {
            add_user_style['max-height'] = (parseInt(window.innerHeight) - 100).toString() + 'px';
            add_user_style['overflow'] = 'auto';
        }
        if (this.props.list_type === 'full') {
            var additional_table_class = '';
            if (parseInt(localStorage.getItem('user_type')) === 1) {
                additional_table_class = 'small_table';
            }
            return (
                <div>
                    <div className='fts_input_wrapper'>
                        <input placeholder={`${locales.tables.body.name} ${locales.tables.body.surname}`} className='fts_input' id='fts_input_id' onChange={_.debounce(this.searchUser, 300)} />
                    </div>
                    {this.renderUsersFiltersList()}
                    {this.renderSortContainer()}
                    <div className='table_container'>
                        <FullUsersTable
                            logged_in={this.props.logged_in}
                            handleNewUserTicket={this.handleNewUserTicket.bind(this)}
                            handleShowTicketInfo={this.props.handleShowTicketInfo}
                            handleCloseTicket={this.handleCloseTicket.bind(this)}
                            handleCorrectTicket={this.handleCorrectTicket.bind(this)}
                            renderDisciplineList={this.props.renderDisciplineList}
                            renderCoachList={this.props.renderCoachList}
                            update_data={this.props.update_data}
                            users={this.state.users}
                            getData={this.getData.bind(this)}
                            handleRemoveUser={this.handleRemoveUser.bind(this)}
                            handleChangeUserInfo={this.handleChangeUserInfo.bind(this)}
                            handleAddNotify={this.props.handleAddNotify}
                            getExpiredTickets={this.getExpiredTickets.bind(this)}
                            history={this.props.history}
                            handleUpdateSketches={this.props.handleUpdateSketches}
                            renderTicketsList={this.props.renderTicketsList}
                            renderNewTicketBtn={this.renderNewTicketBtn.bind(this)}
                            renderCompletedTicketDrills={this.renderCompletedTicketDrills.bind(this)}
                            additional_table_class={additional_table_class}
                            show_drills={this.state.show_drills}
                            setProps={this.setProps.bind(this)}
                            club_departments={this.state.club_departments}
                            clients_ids={this.state.clients_ids}
                            processing={this.state.processing}
                            uri={this.state.uri}
                        />
                    </div>
                    <div className='add_drill_wrapper'>
                        <div type='button' className='add_drill_btn plus_btn' id='add_drill_btn_id' onClick={this.props.handleAddUser}>
                            &#10010;
                        </div>
                    </div>
                    {this.props.add_client_form
                        ? <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_ref}>
                            <div
                                className='add_new_user_form_wrapper'
                                style={add_user_style}
                            >
                                <div className='hide_drill_clients_list_wrapper'>
                                    <input type='button' className=' hide_drill_clients_list' id='hide_drill_clients_list_btn_id' onClick={this.props.handleBackgroundClick} value='&#10008;' />
                                </div>
                                {this.props.displayed_form}
                            </div>
                          </div>
                        : ''
                    }
                    {this.state.addTicket
                        ? <div>
                            <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_ref} data-test='test1'>
                              <div className='add_new_user_form_wrapper'>
                                  <AddTicket
                                      ticket={this.state.editing_ticket}
                                      clubUnitId={this.props.clubUnitId}
                                      handleAddUserTicket={this.handleAddUserTicket.bind(this)}
                                      club_units={this.props.club_units}
                                      handleDeclineUserTicket={this.handleDeclineUserTicket.bind(this)}
                                      logged_in={this.props.logged_in}
                                      handleSetUserTicketData={this.handleSetUserTicketData.bind(this)}
                                      ticket_active={true}
                                  />
                              </div>
                            </div>
                          </div>
                        : ''
                    }
                    {this.props.add_notify
                        ? <CreateNotify
                            client_id={this.props.notify_client_id}
                            handleCreateNotification={this.props.handleCreateNotification}
                            handleDeclineNewNotify={this.props.handleDeclineNewNotify}
                            modal_ref={this.props.modal_ref}
                            client={this.state.users.filter(c => parseInt(c.id) === parseInt(this.props.notify_client_id))}
                            logged_in={this.props.logged_in}
                            setNotifyType={this.props.setNotifyType}
                        />
                        : ''
                    }
                    {this.state.remove_user
                        ? <AcceptRemoveUser
                            client_id={this.state.remove_client_id}
                            handleDeclineRemoveUser={this.handleDeclineRemoveUser.bind(this)}
                            modal_ref={this.modal_ref}
                            removeUser={this.removeUser.bind(this)}
                        />
                        : ''
                    }
                    {this.state.show_expired_tickets
                        ? <ExpiredClientTickets
                            modal_ref={this.modal_ref}
                            client_expire_tickets={this.state.client_expire_tickets}
                            expired_tickets={this.state.expired_tickets}
                            updateTicket={this.updateTicket.bind(this)}
                            removeTicketFromExpiredList={this.removeTicketFromExpiredList.bind(this)}
                            non_active_type={this.state.non_active_type}
                            replaceNonActiveType={this.replaceNonActiveType.bind(this)}
                          />
                        : ''
                    }
                    {this.state.show_client_debts
                        ? <div
                            className='background_lock_wrapper'
                            id='background_lock_id'
                            ref={this.modal_ref}
                          >
                            <div className='modal_window_wrapper'>
                              <UserDebts
                                client_id={this.state.debt_client_id}
                                catchError={this.props.catchError}
                                logged_in={this.props.logged_in}
                              />
                            </div>
                          </div>
                        : ''
                    }
                    {this.state.lock_spinner
                        ? <Spinner />
                        : ''
                    }
                {this.state.update_ticket
                    ? <div>
                        <div className='background_lock_wrapper' id='background_lock_id' ref={this.modal_ref} data-test='test'>
                            <div className='add_new_user_form_wrapper'>
                                <AddTicket
                                    ticket={this.state.editing_ticket}
                                    clubUnitId={this.state.editing_ticket.ticket.club_unit[0]}
                                    handleAddUserTicket={(!this.state.remove_ticket) ? this.handleUpdateUserTicket.bind(this) : this.removeTicket.bind(this)}
                                    club_units={this.props.club_units}
                                    handleDeclineUserTicket={this.handleDeclineUserTicket.bind(this)}
                                    logged_in={this.props.logged_in}
                                    correct_ticket={this.state.correct_ticket}
                                    disciplines={this.state.ticketDisciplines}
                                    coaches={this.state.ticketCoaches}
                                    accept={this.state.accept}
                                    handleSetUserTicketData={this.handleSetUserTicketData.bind(this)}
                                    remove_ticket={this.state.remove_ticket}
                                />
                            </div>
                        </div>
                      </div>
                    : ''
                }
                <div id="toast-wrapper-id">
                    <div id="toast-body-id">
                    </div>
                </div>
                </div>
            )
        }
        else if (this.props.list_type === 'tickets') {
            return (
                <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_users_ref}>
                    <div className='drill_clients_wrapper' id='drill_clients_wrapper_id' onClick={this.handleModalClick}>
                        <div className='lock_modal_content_wrapper' id='lock_modal_content_id'>
                             <div className="loader"></div>
                        </div>
                        <div className='drill_clients_list_wrapper' onClick={this.handleModalClick}>
                            <div className='hide_drill_clients_list_wrapper'>
                                <input type='button' className=' hide_drill_clients_list' id='hide_drill_clients_list_btn_id' onClick={this.props.handleBackgroundClick} value='&#10008;' />
                            </div>
                            <h4>Список клиентов</h4>
                            <div className='clients_table_wrapper'>
                                <div className='fts_input_wrapper'>
                                    <input placeholder='Имя Фамилия' className='fts_input' id='fts_input_modal_id' onChange={_.debounce(this.searchUser, 300)} />
                                </div>
                                {this.renderUsersFiltersList()}
                                {this.renderTable()}
                            </div>
                                <div className='drill_traffic_btn_wrapper' data-test='correct'>
                                    <div id='add_client'
                                         key='add_client'
                                         onClick={this.props.handleAddUser}
                                         className='drill_traffic_btn'>
                                        <span>&#10010;</span>
                                    </div>
                                    <div id='close-modal-clients-btn-id'
                                            key='close-modal-clients-btn'
                                            className='drill_traffic_btn'
                                            onClick={this.props.handleBackgroundClick}>
                                        <span>OK</span>
                                    </div>
                                </div>
                        </div>
                        {this.props.add_client_form
                            ? <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_ref}>
                                <div className='add_new_user_form_wrapper'>
                                    <div className='hide_drill_clients_list_wrapper'>
                                        <input type='button' className=' hide_drill_clients_list' id='hide_drill_clients_list_btn_id' onClick={this.props.handleBackgroundClick} value='&#10008;' />
                                    </div>
                                    {this.props.displayed_form}
                                </div>
                              </div>
                            : ''
                        }
                        {this.state.addTicket
                            ? <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_ref} data-test='test2'>
                                <div className='add_new_user_form_wrapper'>
                                    <div className='hide_drill_clients_list_wrapper'>
                                        <input type='button' className=' hide_drill_clients_list' id='hide_drill_clients_list_btn_id' onClick={this.props.handleBackgroundClick} value='&#10008;' />
                                    </div>
                                    <AddTicket
                                        ticket={this.state.editing_ticket}
                                        clubUnitId={this.props.clubUnitId}
                                        handleAddUserTicket={this.handleAddUserTicket.bind(this)}
                                        club_units={this.props.club_units}
                                        handleDeclineUserTicket={this.handleDeclineUserTicket.bind(this)}
                                        logged_in={this.props.logged_in}
                                        handleSetUserTicketData={this.handleSetUserTicketData.bind(this)}
                                        ticket_active={true}
                                    />
                                </div>
                              </div>
                            : ''
                        }
                        <div className='add_client_form_wrapper'>
                            {this.state.form}
                        </div>
                    </div>
                    {this.state.lock_spinner
                        ? <Spinner />
                        : ''
                    }
                </div>
            )
        }
    }
}

class AcceptRemoveUser extends(React.Component) {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_ref}>
                <div className='new_drill_form_wrapper'>
                    <h3>Удалить пользователя?</h3>
                    <button id='remove-client-accept' key='remove-client-accept' className='drill_traffic_btn' data-client_id={this.props.client_id} onClick={this.props.removeUser}>&#10004;</button>
                    <button id='remove-client-decline' key='remove-client-decline' className='drill_traffic_btn' onClick={this.props.handleDeclineRemoveUser}>&#10008;</button>
                </div>
            </div>
        )
    }
}

class ExpiredClientTickets extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            show_drills: {},
        };
    }

    renderExpiredTicketDrills(drills) {
        return drills.map((drill, index) => {
            return (
                <div
                    className='clients_tickets_table_body_wrapper'
                    key={'ticket-drills-' + index}
                >
                    <div
                        className='expired_ticket_drill_col_1'
                    >
                        {index + 1}
                    </div>
                    <div
                        className='expired_ticket_drill_col_2'
                    >
                        {drill.date}
                    </div>
                    <div
                        className='expired_ticket_drill_col_3'
                    >
                        {drill.time}
                    </div>
                </div>
            )
        })
    }

    renderExpiredTickets() {
        return this.props.expired_tickets.map((ticket) => {
            return (
                <div
                    className='expired_ticket_wrapper'
                    key={'ticket-' + ticket.id}
                >
                    <div className='expired_ticket_name'>
                        {ticket.ticket.ticket}
                    </div>
                    <div className='expired_ticket_dates_wrapper'>
                        <div className='expired_ticket_date'>
                            <span>Дата начала</span>
                            <input
                              key={'ticket_date_start-' + ticket.id}
                              id={'ticket_date_start-' + ticket.id}
                              data-ticket_id={ticket.id}
                              type='date'
                              placeholder='Дата окончания'
                              className='add_ticket_btn'
                              defaultValue={ticket.start_date}
                              onChange={
                                (e) => {
                                    this.props.updateTicket(
                                        e.target.dataset.ticket_id,
                                        {"start_date": e.target.value},
                                        false,
                                    );
                                    e.target.value = e.target.value;
                                }
                              }
                            />
                        </div>
                        <div className='expired_ticket_date'>
                            <span>Дата окончания</span>
                            <input
                              key={'ticket_date_stop-' + ticket.id}
                              id={'ticket_date_stop-' + ticket.id}
                              data-ticket_id={ticket.id}
                              type='date'
                              placeholder='Дата окончания'
                              className='add_ticket_btn'
                              defaultValue={ticket.expire_date}
                              onChange={
                                (e) => {
                                    this.props.updateTicket(
                                        e.target.dataset.ticket_id,
                                        {"expire_date": e.target.value},
                                        false,
                                    );
                                    e.target.value = e.target.value;
                                }
                              }
                            />
                        </div>
                    </div>
                        <div className='expired_ticket_completed_drills'
                        data-ticket_id={ticket.id}
                        onMouseEnter={
                            (e) => {
                                var show_drills = this.state.show_drills;
                                show_drills[parseInt(e.target.dataset.ticket_id)] = true;
                                this.setState(
                                    {
                                        show_drills: show_drills,
                                        show_drills_position: (parseInt(e.target.offsetTop) + 30).toString() + 'px',
                                    }
                                )
                            }
                        }
                        onMouseLeave={
                            (e) => {
                                var show_drills = this.state.show_drills;
                                show_drills[parseInt(e.target.dataset.ticket_id)] = false;
                                this.setState(
                                    {
                                        show_drills: show_drills,
                                    }
                                )
                            }
                        }
                    >
                        Тренировок: {ticket.completed_drills.count}
                    </div>
                    {this.state.show_drills[ticket.id]
                        ? <div
                            className='shared_info_ticket orange_border fat_border'
//                            style={{top: this.state.show_drills_position}}
                            style={
                                {
                                    top: '10vh',
                                    right: '2vw',
                                }
                            }
                          >
                            <div
                                className='clients_tickets_table_head_wrapper'
                            >
                                <div
                                    className='expired_ticket_drill_col_1'
                                >
                                    № п/п
                                </div>
                                <div
                                    className='expired_ticket_drill_col_2'
                                >
                                    Дата
                                </div>
                                <div
                                className='expired_ticket_drill_col_3'
                                >
                                    Время
                                </div>
                            </div>
                            {this.renderExpiredTicketDrills(ticket.completed_drills.schedule)}
                          </div>
                        : ''
                    }
                    <div className='expired_ticket_recover'>
                        <div className='client_arrive_chbox_wrapper'>
                            <input
                                type='checkbox'
                                id={'ticket_recovery-' + ticket.id}
                                key={'ticket_recovery-' + ticket.id}
                                name='user-arrive'
                                className='arrive_chbox'
                                data-ticket_id={ticket.id}
                                onChange={
                                    (e) => {
                                        if (this.props.non_active_type === 'expired') {
                                            this.props.updateTicket(
                                                e.target.dataset.ticket_id,
                                                {"expired": false},
                                                false,
                                            );
                                        }
                                        else if (this.props.non_active_type === 'non-active') {
                                            this.props.updateTicket(
                                                e.target.dataset.ticket_id,
                                                {"active": true},
                                                false,
                                            );
                                        }
                                        else if (this.props.non_active_type === 'frozen') {
                                            this.props.updateTicket(
                                                e.target.dataset.ticket_id,
                                                {"frozen": false},
                                                false,
                                            );
                                        }
                                        this.props.removeTicketFromExpiredList(e.target.dataset.ticket_id);
                                    }
                                }
                            />
                            <span
                                className='client_arrive_chbox_span'
                                data-ticket_id={ticket.id}
                                onChange={
                                    (e) => {
                                        if (this.props.non_active_type === 'expired') {
                                            this.props.updateTicket(
                                                e.target.dataset.ticket_id,
                                                {"expired": false},
                                                false,
                                            );
                                        }
                                        else if (this.props.non_active_type === 'non-active') {
                                            this.props.updateTicket(
                                                e.target.dataset.ticket_id,
                                                {"active": true},
                                                false,
                                            );
                                        }
                                        else if (this.props.non_active_type === 'frozen') {
                                            this.props.updateTicket(
                                                e.target.dataset.ticket_id,
                                                {"frozen": false},
                                                false,
                                            );
                                        }
                                        this.props.removeTicketFromExpiredList(e.target.dataset.ticket_id);
                                    }
                                }
                            >
                            </span>
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        return (
            <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_ref}>
                <div className='new_drill_form_wrapper'>
                    <div
                        className='slider_switch_wrapper'
                    >
                        <div
                            className='slider_item'
                            style={
                                (this.props.non_active_type === 'expired') ? {borderBottom: '2px solid', borderColor: '#f7941e'} : {}
                            }
                            onClick={
                              () => {
                                  this.props.replaceNonActiveType("expired");
                              }
                            }
                        >
                            {locales.tickets.expired}
                        </div>
                        <div
                            className='slider_item'
                            style={
                                (this.props.non_active_type === 'non-active') ? {borderBottom: '2px solid', borderColor: '#f7941e'} : {}
                            }
                            onClick={
                              () => {
                                  this.props.replaceNonActiveType("non-active");
                              }
                            }
                        >
                            {locales.tickets.non_active}
                        </div>
                        <div
                            className='slider_item'
                            style={
                                (this.props.non_active_type === 'frozen') ? {borderBottom: '2px solid', borderColor: '#f7941e'} : {}
                            }
                            onClick={
                              () => {
                                  this.props.replaceNonActiveType("frozen");
                              }
                            }
                        >
                            {locales.tickets.frozen}
                        </div>
                    </div>
                    <h3>{locales.tickets.expired_header}</h3>
                    {this.renderExpiredTickets()}
                </div>
            </div>
        )
    }
}

class ClientAttendance extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            id: window.location.pathname.split('/')[3],
            lock_spinner: false,
            client_info: {},
            client_traffic: [],
            date_start: new Date().toISOString().split('T')[0],
            date_stop: new Date().toISOString().split('T')[0],
            show_drills: {},
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
              var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/attendance/' + this.state.id + '/');
              uri.searchParams.set("date_start", this.state.date_start);
              uri.searchParams.set("date_stop", this.state.date_stop);
              fetch(uri.href, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('token')}`
                }
              })
              .then(res => {
                  if (res.status === 401) {
                      this.props.catchError(res.status);
                      return
                  }
                  const response = res.json();
                  return response;
              })
              .then(json => {
                  if (this.props.logged_in) {
                      var show_drills = this.state.show_drills;
                      for (let i in json.traffic) {
                        show_drills[i.id] = false;
                      }
                      this.setState(
                          {
                            client_info: json.client,
                            client_traffic: json.traffic,
                            lock_spinner: false,
                            show_drills: show_drills,
                          }
                      );
                  }
              })
            }
        )
    }

    renderCoachesList(coaches) {
        return coaches.map((coach) => {
            return (
                <div>
                    {coach.first_name} {coach.last_name}
                </div>
            )
        })
    }

    renderCompletedTicketDrills(drills) {
        return drills.map((drill, index) => {
            return (
                <div
                    className='clients_tickets_table_body_wrapper'
                    style={
                      {
                        backgroundColor: (drill.deleted) ? '#8d95fd8f' : 'transparent',
                      }
                    }
                >
                    <div
                        className='expired_ticket_drill_col_1'
                    >
                        {index + 1}
                    </div>
                    <div
                        className='expired_ticket_drill_col_2'
                    >
                        {drill.drill_date}
                    </div>
                    <div
                        className='expired_ticket_drill_col_3'
                    >
                        {drill.drill_time}
                    </div>
                </div>
            )
        })
    }

    renderTableBody() {
        return this.state.client_traffic.map((cts) => {
            return (
                <div
                    key={'change_request-' + cts.id}
                    className='client_row_wrapper'
                >
                    <div
                        className='client_col_name_wrapper'
                        style={
                            {
                                width: '17%',
                            }
                        }
                    >
                        <div
                            className='client_row_name'
                            style={
                                {
                                    marginLeft: '0.5rem',
                                }
                            }
                        >
                            {cts.drill.club.name}
                        </div>
                    </div>
                    <div
                        className='client_col_name_wrapper'
                        style={
                            {
                                width: '17%',
                            }
                        }
                    >
                        <div
                            className='client_row_name'
                            style={
                                {
                                    marginLeft: '0.5rem',
                                }
                            }
                        >
                            {cts.drill.discipline.name}
                        </div>
                        <div
                            className='client_row_name'
                            style={
                                {
                                    marginLeft: '0.5rem',
                                }
                            }
                        >
                            {cts.drill.drill_type.name}
                        </div>
                    </div>
                    <div
                        className='client_col_name_wrapper'
                        style={
                            {
                                width: '17%',
                            }
                        }
                    >
                        {cts.drill_date} {cts.drill.drill_time_start}
                    </div>
                    {cts.ticket
                        ? <div
                              className='client_col_name_wrapper'
                              style={
                                  {
                                      width: '25%',
                                  }
                              }
                              data-cts_id={cts.id}
                              onMouseEnter={
                                  (e) => {
                                      var show_drills = this.state.show_drills;
                                      show_drills[parseInt(e.target.dataset.cts_id)] = true;
                                      this.setState(
                                          {
                                              show_drills: show_drills,
                                          }
                                      )
                                  }
                              }
                              onMouseLeave={
                                  (e) => {
                                      var show_drills = this.state.show_drills;
                                      show_drills[parseInt(e.target.dataset.cts_id)] = false;
                                      this.setState(
                                          {
                                              show_drills: show_drills,
                                          }
                                      )
                                  }
                              }
                          >
                              <div
                                  style={
                                      {
                                          color: (!cts.ticket.payed) ? '#FF0000' : '#000',
                                      }
                                  }
                                  data-cts_id={cts.id}
                              >
                                  {cts.ticket.ticket.name}
                              </div>
                              <div data-cts_id={cts.id}>
                                  {cts.ticket.start_date} - {cts.ticket.expire_date}
                              </div>
                              <div data-cts_id={cts.id}>
                                  Осталось тренировок: {cts.ticket.lost_drills}
                              </div>
                              <div data-cts_id={cts.id}>
                                  Завершено тренировок: {cts.ticket.completed_drills.count}
                              </div>
                          </div>
                        : <div
                              className='client_col_name_wrapper'
                              style={
                                  {
                                      width: '25%',
                                  }
                              }
                          >
                          </div>
                    }
                    <div
                        className='client_col_comment_wrapper'
                        style={
                            {
                                width: '24%',
                            }
                        }
                    >
                        {this.renderCoachesList(cts.coaches)}
                    </div>
                    {this.state.show_drills[cts.id]
                        ? <div
                            className='shared_info_ticket orange_border fat_border'
                            style={
                                {
                                    top: '10vh',
                                    right: '2vw',
                                }
                            }
                          >
                            <div
                                className='clients_tickets_table_head_wrapper'
                            >
                                <div
                                    className='expired_ticket_drill_col_1'
                                >
                                    № п/п
                                </div>
                                <div
                                    className='expired_ticket_drill_col_2'
                                >
                                    Дата
                                </div>
                                <div
                                className='expired_ticket_drill_col_3'
                                >
                                    Время
                                </div>
                            </div>
                            {this.renderCompletedTicketDrills(cts.ticket.completed_drills.schedule)}
                          </div>
                        : ''
                    }
                </div>
            )
        })
    }

    render() {
        return (
            <div>
              <div
                style={
                  {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }
                }
              >
                <div
                  style={
                    {
                      transform: 'rotate(180deg)',
                      fontSize: '2rem',
                      cursor: 'pointer',
                      display: 'block',
                    }
                  }
                  onClick={
                    () => {
                      this.props.history.back()
                    }
                  }
                >
                  &#10132;
                </div>
                <div
                    style={
                        {
                            marginBottom: '1rem',
                            marginTop: '1rem',
                            fontSize: '1.25rem',
                            width: '90%',
                        }
                    }
                >
                    <div>
                        {this.state.client_info.first_name} {this.state.client_info.last_name}
                    </div>
                    {this.props.device === 'mobile'
                        ? <div>
                              <a href={'tel:' + this.state.client_info.phone}>{this.state.client_info.phone}</a>
                              <a href={'mailto:' + this.state.client_info.email}>{this.state.client_info.email}</a>
                          </div>
                        : <div>
                              <div>{this.state.client_info.phone}</div>
                              <div>{this.state.client_info.email}</div>
                          </div>
                    }
                </div>
              </div>
                <div
                    className='filters_list_wrapper noty_filters_list_wrapper'
                    style={
                        {
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            maxWidth: '100%',
                            marginBottom: '1rem',
                        }
                    }
                >
                    <div className='filter_btn_wrapper notify_filter_wrapper'>
                        <div
                            style={
                                {
                                    display: (this.props.device === 'mobile') ? 'block' : 'block',
                                    alignItems: 'center',
                                }
                            }
                        >
                            <label
                                style={
                                    {
                                        marginRight: (this.props.device === 'mobile') ? 'none' : '0.5rem',
                                        marginBottom: (this.props.device === 'mobile') ? '0.5rem' : 'none',
                                    }
                                }
                            >
                                Период посещаемости
                            </label>
                            <input
                              key='attendance_date_start'
                              id='attendance_date_start'
                              type='date'
                              placeholder='Дата начала'
                              className='add_ticket_btn'
                              style={
                                  {
                                      marginRight: (this.props.device === 'mobile') ? 'none' : '0.5rem',
                                      marginBottom: (this.props.device === 'mobile') ? '0.5rem' : 'none',
                                  }
                              }
                              onChange={
                                compose(
                                    _.debounce(
                                        (e) => {
                                            this.setState(
                                                {
                                                    date_start: e.target.value,
                                                },
                                                () => {
                                                    this.getData();
                                                }
                                            );
                                        }, 1000
                                    ),
                                    _.clone
                                )
                              }
                            />
                            <input
                              key='attendance_date_stop'
                              id='attendance_date_stop'
                              type='date'
                              placeholder='Дата окончания'
                              className='add_ticket_btn'
                              onChange={
                                compose(
                                    _.debounce(
                                        (e) => {
                                            this.setState(
                                                {
                                                    date_stop: e.target.value,
                                                },
                                                () => {
                                                    this.getData();
                                                }
                                            );
                                        }, 1000
                                    ),
                                    _.clone
                                )
                              }
                            />
                        </div>
                    </div>
                </div>
                <div className='table_container'>
                    <div className='table_wrapper'>
                        <div className="table_head_container_wrapper">
                            <div
                                key='req-0'
                                className='clients_table_container_border clients_table_container_head'
                                style={
                                    {
                                        width: '17%',
                                    }
                                }
                            >
                                Филиал
                            </div>
                            <div
                                key='req-1'
                                className='clients_table_container_border clients_table_container_head'
                                style={
                                    {
                                        width: '17%',
                                    }
                                }
                            >
                                Тренировка
                            </div>
                            <div
                                key='req-2'
                                className='clients_table_container_border clients_table_container_head'
                                style={
                                    {
                                        width: '17%',
                                    }
                                }
                            >
                                Дата/Время
                            </div>
                            <div
                                key='req-3'
                                className='clients_table_container_border clients_table_container_head'
                                style={
                                    {
                                        width: '25%',
                                    }
                                }
                            >
                                Абонемент
                            </div>
                            <div
                                key='req-4'
                                className='clients_table_container_border clients_table_container_head'
                                style={
                                    {
                                        width: '24%',
                                    }
                                }
                            >
                                Тренеры
                            </div>
                        </div>
                        <div className="table_rows_container_wrapper">
                            {this.renderTableBody()}
                        </div>
                    </div>
                </div>
                {this.state.lock_spinner
                    ? <Spinner />
                    : ''
                }
            </div>
        )
    }
}

export { UsersList, ClientAttendance };
