import React from 'react';
import PropTypes from 'prop-types';

class SignupForm extends React.Component {
  state = {
    password: '',
    child: false,
  };

  handle_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });
  };

  handleChangeAge = (e) => {
    this.setState({child: e.target.checked});
  }

  render() {
    return (
      <form onSubmit={e => this.props.handle_signup(e, this.state)} className={'login_form ' + this.props.addClass}>
        <div className='login_fields_wrapper'>
            <h4>Регистрация</h4>
            <div className='check_age_wrapper'>
            <input type='checkbox' id='child_reg_chkbox_id' key='child_reg_chkbox_id' name='check_age' className='check_age_chkbox' checked={this.state.child} onChange={this.handleChangeAge} />
            <label htmlFor="check_age">Несовершеннолетний</label>
            </div>
            <label htmlFor="password">Пароль</label>
            <input
              className="login-input"
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.handle_change}
            />
            {this.state.child
                ? <div>
                    <label htmlFor="trustee_phone">Телефон представителя</label>
                    <input
                      className="login-input"
                      type="tel"
                      name="trustee_phone"
                      pattern="+{0,1}[7,8]{1}[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      value={this.state.trustee_phone}
                      onChange={this.handle_change}
                    />
                    <label htmlFor="trustee_name">Имя представителя</label>
                    <input
                      className="login-input"
                      type="text"
                      name="trustee_name"
                      value={this.state.trustee_name}
                      onChange={this.handle_change}
                    />
                </div>
                : <div>
                    <label htmlFor="phone">Телефон</label>
                    <input
                      className="login-input"
                      type="tel"
                      name="phone"
                      pattern="+{0,1}[7,8]{1}[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      value={this.state.phone}
                      onChange={this.handle_change}
                    />
                </div>
            }
            <label htmlFor="first_name">Имя</label>
            <input
              className="login-input"
              type="text"
              name="first_name"
              value={this.state.first_name}
              onChange={this.handle_change}
            />
            <label htmlFor="last_name">Фамилия</label>
            <input
              className="login-input"
              type="text"
              name="last_name"
              value={this.state.last_name}
              onChange={this.handle_change}
            />
            <label htmlFor="patronymic">Отчество</label>
            <input
              className="login-input"
              type="text"
              name="patronymic"
              value={this.state.patronymic}
              onChange={this.handle_change}
            />
            <label htmlFor="email">Email</label>
            <input
              className="login-input"
              type="text"
              name="email"
              value={this.state.email}
              onChange={this.handle_change}
            />
            <label htmlFor="type">Тип пользователя</label>
            <select
              className="user_type_selector"
              name="type"
              value={this.state.type}
              onChange={this.handle_change}
              >
              <option>Выбери тип пользовталея</option>
              <option value="0">Клиент</option>
              <option value="1">Тренер</option>
              <option value="2">Менеджер</option>
              <option value="3">Директор</option>
            </select>
            <input type="submit" value='Зарегистрировать' className='submit_button' />
        </div>
      </form>
    );
  }
}

export default SignupForm;

SignupForm.propTypes = {
  handle_signup: PropTypes.func.isRequired
};
