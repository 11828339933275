import React from 'react';
import PropTypes from 'prop-types';

class RestoreForm extends React.Component {
    state = {
        email: '',
        restore_mail_sent: false,
    };

    handle_change = e => {
        const email = e.target.value;
        this.setState(prevstate => {
            const newState = { ...prevstate };
            newState['email'] = email;
            return newState;
        });
    };

    render() {
        if (!this.state.restore_mail_sent) {
            return (
                <form
                    onSubmit={e => {
                        this.props.handle_password_restore(e, this.state);
                        this.setState({ restore_mail_sent: true });
                    }}
                    className='login_form'
                >
                    <div className='login_fields_wrapper'>
                        <h4>Восстановление пароля</h4>
                        <label htmlFor="email">Email (на указанный email будет отправлена ссылка для восстановления/получения пароля)</label>
                        <input
                          className="login-input"
                          type="text"
                          name="email"
                          value={this.state.email}
                          onChange={this.handle_change}
                        />
                        <input type="submit" value='Отправить ссылку' className='submit_button' />
                    </div>
                </form>
            );
        }
        else {
            return (
                <form onSubmit={e => this.props.handle_password_restore(e, this.state)} className='login_form'>
                    <div className='login_fields_wrapper'>
                        <h4>Восстановление пароля</h4>
                        <p>Email: {this.state.email}</p>
                        <input type="submit" value='Отправить ссылку повторно' className='submit_button' />
                    </div>
                </form>
            );
        }
    }
}

export default RestoreForm;

RestoreForm.propTypes = {
  handle_password_restore: PropTypes.func.isRequired
};
