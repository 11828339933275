import React from 'react'
import BaseComponent from './Common' 
import {
  get_request,
  post_request,
} from '../api/api_call'

class BaseUsersList extends(BaseComponent) {
    constructor(props) {
        super(props);
        this.remove_user_modal_ref = React.createRef();
        this.expired_tickets_modal_ref = React.createRef();
        this.state = {
            users: [],
            clients: [],
            clients_ids: [],
            page: 0,
            limit: 10,
            offset: 0,
            processing: false,
            correct: false,
            discipline: [],
            addTicket: false,
            addTicketUserId: '',
            form: '',
            fts: (this.props.clients_fts) ? this.props.clients_fts : '',
            sort_key: 'user_tickets__expired-asc',
            uri: process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_clients/?limit=' + 20
                + '&offset=' + 0 + '&sort=user_tickets__expired-asc',
            disciplines: [],
            coaches: [],
            club_tickets: [],
            clubs: [],
            club_units: [],
            discipline_id: '',
            coach_id: '',
            club_ticket_id: '',
            club: '',
            club_unit: '',
            ticket_expire_date: '',
            remove_user: false,
            remove_client_id: null,
            new_ticket_data: {
                user: null,
                ticket: null,
                discipline: null,
                coaches: null,
                start_date: null,
                expire_date: null,
                expired: null,
                discount: 0.0,
            },
            show_expired_tickets: false,
            client_expire_tickets: null,
            expired_tickets: [],
            lock_spinner: false,
            non_active_type: 'expired',
            replace_expired_tickets: false,

            accept: false,
            remove_ticket: false,
            ticketClubUnitId: '',
            ticketStartDate: '',
            ticketExpireDate: '',
            ticketId: null,
            ticketDisciplines: [],
            ticketCoaches: [],
            correct_ticket: {},
            userTicketId: null,
            client_id: '',
            ticket_discount: 0,
            ticket_payed: false,
            ticket_active: true,
            update_ticket: false,
            promo_code: null,
            client_type: '',
            show_drills: {},
            name: '',
            club_departments: [],
            employee: 0,
            payed_amount: null,
            payment_type: null,
            show_client_debts: false,
            debt_client_id: null,
            editing_ticket: null,
        };
    }

    setProps(data, func=null) {
        if (func !== null) {
            this.setState(data, () => {func()})
        }
        else {
            this.setState(data)
        }
    }

    handleNewUserTicket = (e) => {
        this.setState({addTicketUserId: e.target.dataset.user_id, addTicket: true, form: ''});
    }

    handleCloseTicket = (ticket, action_type) => {
        if (action_type === 'close') {
            var disciplines = Array.from(
                ticket.discipline, (discipline) => new Object(
                    {
                        value: discipline.id,
                        label: discipline.name,
                    }
                )
            )
            var coaches = Array.from(
                ticket.coaches, (coach) => new Object(
                    {
                        value: coach.id,
                        label: `${coach.first_name} ${coach.last_name}`
                    }
                )
            )
            this.setState(
                {
                    editing_ticket: ticket,
                    update_ticket: true,
                    ticketClubUnitId: ticket.ticket.club_unit[0],
                    correct_ticket: {
                        id: ticket.id,
                        name: ticket.ticket_name
                    },
                    ticketDisciplines: disciplines,
                    ticketCoaches: coaches,
                    accept: true,
                }
            );
        }
        else if (action_type === 'remove') {
            this.setState(
                {
                    editing_ticket: ticket,
                    update_ticket: true,
                    accept: true,
                    remove_ticket: true,
                }
            );
        }
    }

    getData(users_pks=[]) {
      if (!this.state.uri) {
        return
      }
      this.setState(
        {
            lock_spinner: true,
        }, () => {
          var uri = new URL(this.state.uri);
          uri.searchParams.set("discipline", this.state.discipline_id);
          uri.searchParams.set("coach", this.state.coach_id);
          uri.searchParams.set("club", this.state.club);
          uri.searchParams.set("ticket", this.state.club_ticket_id);
          uri.searchParams.set("club_unit", this.state.club_unit);
          uri.searchParams.set("sort", this.state.sort_key);
          if (this.props.drill) {
            uri.searchParams.set("drill", this.props.drill);
          }
            if (users_pks.length > 0) {
                uri.searchParams.set("pks", encodeURIComponent(users_pks.toString()))
            }
          fetch(uri.href, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
            .then(res => {
                if (res.status === 401) {
                    this.props.catchError(res.status);
                    return
                }
                const response = res.json();
                return response;
            })
            .then(json => {
                if (this.props.logged_in) {
                    if (!json) {
                        this.setState({lock_spinner: false})
                        return
                    }
                    var uri = null;
                    if (json.next) {
                        uri = new URL(json.next);
                        uri = process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_clients/' + uri.search;
                    }
                    var users = json.results;
                    var clients_ids = [];
                    if (this.props.drill) {
                        for (let i = 0; i < users.length; i++) {
                            if (users[i]['drill_ticket']) {
                                clients_ids.push(users[i]['id']);
                            }
                        }
                    }
                    this.setState(
                        {
                            users: this.state.users.concat(users),
                            clients_ids: this.state.clients_ids.concat(clients_ids),
                            processing: true,
                            uri: uri,
                            lock_spinner: false,
                        }, () => {
                            if (this.props.replace) {
                                this.props.history.replace(
                                  '/clients',
                                  {users: []},
                                )
                            }
                        }
                    );
                }
            })
        }
      )
    }

    handleRemoveUser = (e) => {
        this.setState(
            {
                remove_user: true,
                remove_client_id: e.target.dataset.client_id,
            }
        );
    }

    handleChangeUserInfo = (e) => {
        console.log('dfhfghd')
      this.setState(
        {lock_spinner: true},
        () => {
          let data = new Object()
          var client_id = '';
          if (e.constructor == Object) {
            client_id = e['client_id'];
            data.type = parseInt(e["value"])
          }
          else {
            client_id = e.target.dataset.client_id;
            if (e.target.id.includes('user_name')) {
              var user_name = e.target.value.trim()
              var first_name = (user_name.split(' ')[0]) ? user_name.split(' ')[0] : ''
              var last_name = (user_name.split(' ')[1]) ? user_name.split(' ')[1] : ''
              data.first_name = first_name
              data.last_name = last_name
            }
            if (e.target.id.includes('user_phone')) {
              data.phone = e.target.value
            }
            if (e.target.id.includes('user_email')) {
              data.email = e.target.value
            }
            if (e.target.id.includes('user_trustee_phone')) {
              data.trustee_phone = e.target.value
            }
            if (e.target.id.includes('user_trustee_name')) {
              data.trustee_name = e.target.value
            }
            if (e.target.id.includes('user_birth_date')) {
              data.birth_date = e.target.value
            }
            if (e.target.id.includes('user_telegram')) {
              data.telegram = e.target.value
            }
            if (e.target.id.includes('user_whatsapp')) {
              data.whatsapp = e.target.value
            }
            if (e.target.id.includes('user_instagram')) {
              data.instagram = e.target.value
            }
            if (e.target.id.includes('user_vk')) {
              data.vk = e.target.value
            }
            if (e.target.id.includes('user_facebook')) {
              data.facebook = e.target.value
            }
            if (e.target.dataset.confirmation) {
              data.confirmed = true
            }
          }
          var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/account/' + client_id + '/')
          post_request(
            uri.href,
            data,
            this.props.catchError,
            'POST',
            'multipart/form-data',
          ).then(
            json => {
              if (!json) {
                this.setState({lock_spinner: false})
                return
              }
              var users = this.state.users.map(
                (user) => {
                  if (parseInt(user.id) === parseInt(json.id)) {
                    return json
                  }
                  else {
                    return user
                  }
                }
              )
                this.setState({users: users, lock_spinner: false})
            }
          )
        }
      )
    }

    getExpiredTickets() {
        if (
            !this.state.replace_expired_tickets &&
            this.state.expired_tickets.length > 0 &&
            parseInt(this.state.expired_tickets[0]["user"]["id"]) === parseInt(this.state.client_expire_tickets)
        ) {
            this.setState(
              {
                  show_expired_tickets: true,
                  lock_spinner: false,
              }
            );
        }
        else {
            var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/user_tickets/');
            if (this.state.non_active_type === 'expired') {
                uri.searchParams.set("expired", '1');
            }
            else if (this.state.non_active_type === 'non-active') {
                uri.searchParams.set("active", 'False');
            }
            else if (this.state.non_active_type === 'frozen') {
                uri.searchParams.set("frozen", 'True');
            }
            uri.searchParams.set("user", this.state.client_expire_tickets);
            fetch(uri.href, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            })
              .then(res => {
                  if (res.status === 401) {
                      this.props.catchError(res.status);
                      return
                  }
                  const response = res.json();
                  return response;
              })
              .then(json => {
                  if (this.props.logged_in) {
                      this.setState(
                        {
                            expired_tickets: json,
                            show_expired_tickets: true,
                            lock_spinner: false,
                            replace_expired_tickets: false,
                        }
                      );
                  }
              })
        }
    }

    renderCompletedTicketDrills(drills) {
        return drills.map((drill, index) => {
            return (
                <div
                    className='clients_tickets_table_body_wrapper'
                >
                    <div
                        className='expired_ticket_drill_col_1'
                    >
                        {index + 1}
                    </div>
                    <div
                        className='expired_ticket_drill_col_2'
                    >
                        {drill.drill_date}
                    </div>
                    <div
                        className='expired_ticket_drill_col_3'
                    >
                        {drill.drill_time}
                    </div>
                </div>
            )
        })
    }

    renderNewTicketBtn(id) {
        if (parseInt(localStorage.getItem('user_type')) > 1) {
            return (
                <div className='ticket_btn_info_wrapper'>
                    <div
                        id={'add_client_ticket-' + id}
                        key={'add_client_ticket-' + id}
                        onClick={this.handleNewUserTicket}
                        className='add_drill_btn add_client_ticket_btn'
                        data-user_id={id}
                    >
                        <span data-user_id={id}>&#10010;</span>
                    </div>
                </div>
            )
        }
    }
}

export default BaseUsersList

