import React from 'react';
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
import Select from 'react-select';
import Spinner from './Spinner';
import {
  Link
} from "react-router-dom";
import compose from 'lodash/fp/compose';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {
  get_request
} from './api/api_call'

var _ = require('lodash');

const select_styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        padding: 20,
        'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        color: '#f7941e',
        border: '1px solid #f7941e',
        'background-color': '#c5c6c8',
        'outline-color': '#f7941e',
    }),
    container: (provided, state) => ({
        ...provided,
        width: '100%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'margin-bottom': '0.5rem',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'background-color': '#c5c6c8',
    }),
}


class Market extends(React.Component) {
  constructor(props) {
    super(props);
    this.modal_ref = React.createRef();
    this.state = {
      categories: [],
      brands: [],
      products: [],
      limit: 20,
      offset: 0,
      sex: [
        {
          "value": 1,
          "label": "Муж."
        },
        {
          "value": 2,
          "label": "Жен."
        },
        {
          "value": 3,
          "label": "Унисекс"
        },
      ],
      age: [
        {
          "value": 1,
          "label": "Взрослые"
        },
        {
          "value": 2,
          "label": "Дети"
        },
        {
          "value": 3,
          "label": "Неважно"
        },
      ],
      products_category: '',
      products_brand: '',
      products_sex: '',
      products_age: '',
      products_name: '',
      show_product_card: false,
      buying_product: null,
      lock_spinner: false,
      barcode: '',
      stop_stream: true,
      payment_types: [],
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOut);
    document.addEventListener('scroll', this.scrollListener);
    this.getSlugItems('categories')
    this.getSlugItems('brands')
    this.getProducts()
    this.getPaymentTypes()
    document.getElementById('product_article_number_id').focus()
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', function(e) {});
    document.removeEventListener('mousedown', this.handleClickOut);
  }

  handleClickOut = (e) => {
    if (this.modal_ref &&
        this.modal_ref.current &&
        (!this.modal_ref.current.contains(e.target) || e.target.id === 'background_lock_id')
    ) {
      this.setState({
        show_product_card: false,
        buying_product: null,
      });
    }
  }

  getPaymentTypes() {
    this.setState(
      {lock_spinner: true},
      () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/payment_type/')
        get_request(uri.href, this.props.catchError).then(json => {
          if (json === undefined) {
            this.props.catchError(401);
            return
          }
          if (this.props.logged_in) {
            this.setState({
              payment_types: json.results,
              lock_spinner: false,
            });
          }
        })
      }
    )
  }

  getSlugItems(obj) {
    this.setState(
      {
          lock_spinner: true,
      }, () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/market/' + obj + '/');
        uri.searchParams.set('limit', 1000);
        fetch(uri.toString(), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
          if (res.status === 401) {
              this.props.catchError(res.status);
              return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
          if (this.props.logged_in) {
            this.setState({[obj]: json.items})
          }
        })
      }
    )
  }

  getProducts(replace_list = false) {
    if (!this.state.offset && !this.state.limit && !replace_list) {
      return
    }
    this.setState(
      {
          lock_spinner: true,
      }, () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/market/products/');
        uri.searchParams.set('limit', (!replace_list) ? this.state.limit : 20)
        uri.searchParams.set('offset', (!replace_list) ? this.state.offset: 0)
        uri.searchParams.set('brand_name', this.state.products_brand)
        uri.searchParams.set('category', this.state.products_category)
        uri.searchParams.set('sex', this.state.products_sex)
        uri.searchParams.set('age', this.state.products_age)
        uri.searchParams.set('name', this.state.products_name)
        fetch(uri.toString(), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
          if (res.status === 401) {
              this.props.catchError(res.status);
              return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
          if (this.props.logged_in) {
            var limit = this.state.limit
            var offset = this.state.offset
            if (parseInt(json.count) <= parseInt(offset) + parseInt(limit)) {
              limit = null
              offset = null
            }
            else if (json.items.length === 0) {
              limit = null
              offset = null
            }
            else {
              offset += limit
            }
            this.setState(
              {
                products: (!replace_list) ? this.state.products.concat(json.items) : json.items,
                limit: limit,
                offset: offset,
                processing: true,
                lock_spinner: false,
              }
            )
          }
        })
      }
    )
  }

  getProduct(article_number) {
    var uri = new URL(
      process.env.REACT_APP_BACKEND_URI + '/api/v1/market/products/'
    )
    uri.searchParams.set('article_number', article_number)
    this.setState(
      {lock_spinner: true, stop_stream: true},
      () => {
        get_request(uri.href, this.props.catchError).then(
          json => {
            if (this.props.logged_in && json && json.count > 0) {
              this.setState({
                show_product_card: true,
                buying_product: json.items[0],
                lock_spinner: false,
              });
            }
            else {
              this.setState({lock_spinner: false})
              document.getElementById('toast-body-id').innerHTML = "Товар не найден";
              var x = document.getElementById("toast-wrapper-id");
              x.className = 'show';
              setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
            }
          }
        )
      }
    )
  }

  buyProduct(data) {
    var uri = new URL(
        process.env.REACT_APP_BACKEND_URI +
        '/api/v1/market/product/items/'
    );
    fetch(uri.toString(), {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    })
    .then(res => res.json())
    .then(json => {
      this.setState(
        {
          show_product_card: false,
          buying_product: null,
        }
      );
    })
  }

  scrollListener = (e) => {
    if (this.state.processing &&
       document.documentElement.scrollTop >= (document.documentElement.scrollHeight - window.innerHeight) * 0.9) {
      let p = this.state.page + 1;
      this.setState(
        {processing: false},
        () => {this.getProducts()}
      )
    }
  }

  getProductByArticle = (e) => {
    if (e.target.value !== '') {
      this.getProduct(e.target.value)
    }
    e.target.value = ''
  }

  renderProductsLine(index) {
    return this.state.products.slice(0 + 3 * index, 3 * (index + 1)).map((product) => {
      return (
        <div
          key={'product-' + product.id}
          className='product_card_wrapper'
          onClick={
            () => {
              this.setState(
                {
                  show_product_card: true,
                  buying_product: product,
                }
              )
            }
          }
        >
          <div
            className='product_img_wrapper'
          >
            {product.gallery.length > 0
              ? <img
                  src={product.gallery[0]}
                  height='100'
                  className='product_img'
                />
              : <img
                  src='logo_1.png'
                  height='100'
                  className='product_img'
                />
            }
          </div>
          <div
            style={
              {
                marginLeft: '1rem',
                marginRight: '1rem',
              }
            }
          >
            <div>
              Название: {product.name}
            </div>
            <div>
              Бренд: {product.brand_name.name}
            </div>
            <div>
              Категории: {Array.from(product.category, (c) => c.name).toString()}
            </div>
            <div>
              Цена: {product.price}
            </div>
            <div>
              Скидка: {product.discount}
            </div>
          </div>
        </div>
      )
    })
  }

  renderProducts() {
    return Array.from(Array(parseInt(this.state.products.length / 3) + 1).keys()).map(
      (index) => {
        return (
          <div
            key={'product_line-' + index}
            style={
              {
                display: (this.props.device === 'desktop') ? 'flex' : 'block',
                width: 'fit-content',
                marginLeft: 'auto',
                marginRight: 'auto',
              }
            }
          >
            {this.renderProductsLine(index)}
          </div>
        )
      }
    )
  }


  handleScan(data){
    this.setState({
      barcode: data,
      stop_stream: true,
    })
  }
  handleError(err){
    this.setState({err: err.toString()})
  }

  render() {
    return (
      <div>
        <div
          style={
            {
              marginBottom: '2rem',
            }
          }
        >
          <div
            className='btn_border orange_border'
            style={
              {
                margin: '1rem',
                cursor: 'pointer',
                fontSize: '1.25rem',
                width: 'fit-content',
                marginRight: 'auto',
                marginLeft: 'auto',
                display: 'none',
              }
            }
            onClick={
              () => {
                this.setState({stop_stream: !this.state.stop_stream})
              }
            }
          >
            Сканировать штрихкод
          </div>
          {!this.state.stop_stream
            ? <BarcodeScannerComponent
                width={'100%'}
                height={'50%'}
                onUpdate={(err, result) => {
                  if (result) {
                    this.buyProduct({article_number: result.text})
                    document.getElementById('toast-body-id').innerHTML = "Товар куплен";
                    var x = document.getElementById("toast-wrapper-id");
                    x.className = 'show';
                    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                  }
                  else {
                    document.getElementById('toast-body-id').innerHTML = "Штрихкод не читается";
                    var x = document.getElementById("toast-wrapper-id");
                    x.className = 'show';
                    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                  }
                }}
              />
            : ''
          }
        </div>
        <div
          style={
            {
              marginBottom: '2rem',
            }
          }
        >
          <div
            className='btn_border orange_border'
            style={
              {
                margin: '1rem',
                cursor: 'pointer',
                fontSize: '1.25rem',
                width: 'fit-content',
                marginRight: 'auto',
                marginLeft: 'auto',
              }
            }
          >
            <input
              key='filter_ticket_date_start'
              id='product_article_number_id'
              type='text'
              placeholder='Артикул'
              className='add_ticket_btn'
              style={
                {
                  height: '2.45rem',
                  boxSizing: 'border-box',
                  width: '100%',
                }
              }
              onChange={
                compose(
                  _.debounce(this.getProductByArticle, 300),
                  _.clone
                )
              }
            />
          </div>
        </div>
        <div
          style={
            {
              display: (this.props.device === 'desktop') ? 'flex' : 'block',
            }
          }
          className='filters_list_wrapper'
        >
          <div className='filter_btn_wrapper products_filters'>
            <Select
              options={
                Array.from(this.state.categories, (val) => new Object(
                  {
                    "value": val.id,
                    "label": val.name,
                  }
                ))
              }
              onChange={
                (e) => {
                  this.setState(
                    {
                      products_category: (e) ? e.value : '',
                    }
                  )
                }
              }
              styles={select_styles}
              placeholder={"Категория"}
              isClearable={true}
            />
          </div>
          <div className='filter_btn_wrapper products_filters'>
            <Select
              options={
                Array.from(this.state.brands, (val) => new Object(
                  {
                    "value": val.id,
                    "label": val.name,
                  }
                ))
              }
              onChange={
                (e) => {
                  this.setState(
                    {
                      products_brand: (e) ? e.value : '',
                    }
                  )
                }
              }
              styles={select_styles}
              placeholder={"Бренд"}
              isClearable={true}
            />
          </div>
          <div className='filter_btn_wrapper products_filters'>
            <Select
              options={this.state.sex}
              onChange={
                (e) => {
                  this.setState(
                    {
                      products_sex: (e) ? e.value : '',
                    }
                  )
                }
              }
              styles={select_styles}
              placeholder={"Пол"}
              isClearable={true}
            />
          </div>
          <div className='filter_btn_wrapper products_filters'>
            <Select
              options={this.state.age}
              onChange={
                (e) => {
                  this.setState(
                    {
                      products_age: (e) ? e.value : '',
                    }
                  )
                }
              }
              styles={select_styles}
              placeholder={"Возраст"}
              isClearable={true}
            />
          </div>
          <div
            className='filter_btn_wrapper products_filters'
          >
            <input
              key='filter_ticket_date_start'
              id='filter_ticket_date_start'
              type='text'
              placeholder='Название'
              className='add_ticket_btn'
              style={
                {
                  height: '2.45rem',
                  boxSizing: 'border-box',
                  width: '100%',
                }
              }
              onChange={
                (e) => {
                  this.setState({products_name: e.target.value})
                }
              }
            />
          </div>
          <div
            className='filter_btn_wrapper products_filters'
            style={
              {
                position: 'relative',
                textAlign: 'center',
                border: '2px solid #f7941e',
                height: '2.25rem',
                cursor: 'pointer',
              }
            }
            onClick={
              () => {
                this.getProducts(true)
              }
            }
          >
            <div
              style={
                {
                  fontSize: '16px',
                  width: '100%',
                  position: 'absolute',
                  top: '0',
                  bottom: '0',
                  left: '0',
                  right: '0',
                  margin: 'auto',
                  display: 'table',
                }
              }
            >
              Применить
            </div>
          </div>
        </div>
        {this.state.products.length > 0
          ? <div
              style={
                {
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  overflowX: 'auto',
                  cursor: 'pointer',
                }
              }
            >
              {this.renderProducts()}
            </div>
          : ''
        }
        {this.state.lock_spinner
            ? <Spinner />
            : <div></div>
        }
        {this.state.show_product_card
          ? <div className='background_lock_wrapper' id='background_lock_id' ref={this.modal_ref}>
              <div className='new_drill_form_wrapper'>
                <MarketProduct
                  logged_in={this.props.logged_in}
                  catchError={this.props.catchError}
                  product={this.state.buying_product}
                  buyProduct={this.buyProduct.bind(this)}
                  payment_types={this.state.payment_types}
                />
              </div>
            </div>
          : ''
        }
      </div>
    )
  }
}

class MarketProduct extends(React.Component) {
  constructor(props) {
    super(props);
    this.modal_ref = React.createRef();
    this.state = {
      product_items: {},
      item_pk: null,
      users: [],
      selected_user: null,
      rent_time_field_start: "00:00",
      rent_time_field_stop: "00:00",
      rent_time_start: null,
      rent_time_stop: null,
      payed_amount: null,
      payment_type: null,
    }
  }

  searchUser = (e) => {
      this.setState(
          {
              lock_spinner: true,
          }, () => {
              var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_clients/');
              uri.searchParams.set("limit", 20);
              uri.searchParams.set("offset", 0);
              uri.searchParams.set("search", encodeURIComponent(e.target.value));
            fetch(uri.href, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            })
              .then(res => {
                  if (res.status === 401) {
                      this.props.catchError(res.status);
                      return
                  }
                  const response = res.json();
                  return response;
              })
              .then(json => {
                  if (this.props.logged_in) {
                      this.setState(
                          {
                              users: json.results,
                              lock_spinner: false,
                          }
                      );
                  }
              })
          }
      )
  }

  renderSelectedUsers() {
    return this.state.selected_users.map((user) => {
      return (
        <div
          key={'choosen-user-' + user.id}
          style={
            {
              display: 'flex',
              border: '1px solid #f7941e',
              borderRadius: '5px',
              width: 'fit-content',
              paddingLeft: '0.25rem',
              paddingRight: '0.25rem',
              paddingTop: '0.1rem',
              paddingBottom: '0.1rem',
            }
          }
        >
          <div>
            {user.last_name} {user.first_name[0]}.
          </div>
          <div
            style={
              {
                borderLeft: '1px solid #f7941e',
                marginLeft: '0.35rem',
                cursor: 'pointer',
              }
            }
            onClick={
              () => {
                console.log(user);
                var selected_users = this.state.selected_users
                for (var u in selected_users) {
                  if (parseInt(selected_users[u].id) === parseInt(user.id)) {
                    selected_users.splice(u, 1)
                    break
                  }
                }
                this.setState({selected_users: selected_users})
              }
            }
          >
            &#10008;
          </div>
        </div>
      )
    })
  }

  renderFoundUsers() {
    return this.state.users.map((user) => {
      return (
        <div
          key={'custom-select-' + user.id}
          className='custom_select'
          onClick={
            () => {
              this.setState(
                {selected_user: user},
                () => {
                  document.getElementById('conf_fts_input_id').value = ''
                  this.setState({users: []})
                }
              )
            }
          }
        >
          {user.last_name} {user.first_name}
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        <div className='ticket_creation_wrapper'>
          <div
            style={
              {
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: 'fit-content',
              }
            }
          >
            {this.props.product.rent
              ? <div>
                  <input
                    className='text_edit_input client_contact'
                    type='date'
                    size='12'
                    onChange={
                      (e) => {
                        if (!this.state.rent_time_field_start) {
                          return
                        }
                        this.setState({rent_time_start: e.target.value + 'T' + this.state.rent_time_field_start})
                      }
                    }
                  />
                </div>
              : ''
            }
            {this.props.product.rent
              ? <div>
                  <input
                    className='text_edit_input client_contact'
                    type='time'
                    size='12'
                    onChange={
                      (e) => {
                        this.setState({rent_time_field_start: e.target.value})
                      }
                    }
                    defaultValue={this.state.rent_time_field_start}
                  />
                </div>
              : ''
            }
          </div>
          <div
            style={
              {
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: 'fit-content',
              }
            }
          >
            {this.props.product.rent
              ? <div>
                  <input
                    className='text_edit_input client_contact'
                    type='date'
                    size='12'
                    onChange={
                      (e) => {
                        if (!this.state.rent_time_field_stop) {
                          return
                        }
                        this.setState({rent_time_stop: e.target.value + 'T' + this.state.rent_time_field_stop})
                      }
                    }
                  />
                </div>
              : ''
            }
            {this.props.product.rent
              ? <div>
                  <input
                    className='text_edit_input client_contact'
                    type='time'
                    size='12'
                    onChange={
                      (e) => {
                        this.setState({rent_time_field_stop: e.target.value})
                      }
                    }
                    defaultValue={this.state.rent_time_field_stop}
                  />
                </div>
              : ''
            }
          </div>
          <div>
            <div className='fts_input_wrapper'>
                <input
                  placeholder='Имя Фамилия'
                  className='fts_input'
                  id='conf_fts_input_id'
                  onChange={
                    compose(
                      _.debounce(this.searchUser, 300),
                      _.clone
                    )
                  }
                />
                {this.state.selected_user
                  ? <div
                      style={
                        {
                          width: '80%',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }
                      }
                    >
                      <div
                        style={
                          {
                            display: 'flex',
                            border: '1px solid #f7941e',
                            borderRadius: '5px',
                            width: 'fit-content',
                            paddingLeft: '0.25rem',
                            paddingRight: '0.25rem',
                            paddingTop: '0.1rem',
                            paddingBottom: '0.1rem',
                          }
                        }
                      >
                        <div>
                          {this.state.selected_user.last_name} {this.state.selected_user.first_name}.
                        </div>
                        <div
                          style={
                            {
                              borderLeft: '1px solid #f7941e',
                              marginLeft: '0.35rem',
                              cursor: 'pointer',
                            }
                          }
                          onClick={
                            () => {
                              this.setState({selected_user: null})
                            }
                          }
                        >
                          &#10008;
                        </div>
                      </div>
                    </div>
                  : ''
                }
                {this.state.users.length > 0
                  ? <div
                      style={
                        {
                          border: '1px solid #000',
                          position: 'relative',
                          backgroundColor: '#fff',
                          width: '80%',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }
                      }
                    >
                      {this.renderFoundUsers()}
                    </div>
                  : ''
                }
            </div>
          </div>
          {parseInt(localStorage.getItem("user_type")) > 1
            ? <div className='add_ticket_promos_wrapper'>
                <label htmlFor='ticket_discount'>Оплаченная сумма: </label>
                <input
                  key='ticket_payed_amount'
                  id='ticket_payed_amount'
                  type='number'
                  placeholder='Оплаченная сумма'
                  className='add_ticket_btn'
                  onChange={
                    (e) => {
                      this.setState({payed_amount: e.target.value})
                    }
                  }
                />
              </div>
            : ''
          }
          {this.props.payment_types.length > 0 && parseInt(localStorage.getItem("user_type")) > 1
            ? <div
              style={
                {
                  width: '60%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }
              }
              >
                <div className='ticket_selector_wrapper'>
                  <Select
                    options={
                      Array.from(this.props.payment_types, (val) => new Object(
                        {
                          "value": val.id,
                          "label": val.shortcut,
                        }
                      ))
                    }
                    onChange={
                      (e) => {
                        if (e) {
                          this.setState({payment_type: e["value"]})
                        }
                      }
                    }
                    styles={select_styles}
                    placeholder={"Тип оплаты"}
                    menuPosition={'fixed'}
                  />
                </div>
              </div>
            : ''
          }
          <div
            style={
              {
                width: 'fit-content',
                marginRight: 'auto',
                marginLeft: 'auto',
                marginBottom: '1rem',
              }
            }
          >
            <div
              className='filter_btn_wrapper products_filters'
              style={
                {
                  border: '2px solid #f7941e',
                  height: '2.25rem',
                  cursor: 'pointer',
                }
              }
              onClick={
                () => {
                  this.props.buyProduct(
                    {
                      product_id: this.props.product.id,
                      buyer: (this.state.selected_user) ? this.state.selected_user.id : null,
                      rent_time_start: this.state.rent_time_start,
                      rent_time_stop: this.state.rent_time_stop,
                      payed_amount: this.state.payed_amount,
                      payment_type: this.state.payment_type,
                    }
                  )
                }
              }
            >
              <div
                style={
                  {
                    fontSize: '16px',
                    width: '100%',
                    position: 'relative',
                    top: '25%',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    margin: 'auto',
                    display: 'table',
                  }
                }
              >
                Купить
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Market;
