// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add_new_user_form_wrapper {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--grey);
    padding: 1rem;
    padding-bottom: 2rem;
    margin-top: 2rem;
    overflow-y: auto;
    max-height: 90%;
}

.modal_window_wrapper {
  background-color: #c5c6c8;
  margin-top: 5vh;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  max-height: 80%;
  box-sizing: border-box;
  overflow: auto;
}

@media screen and (max-width: 700px) {
    .add_new_user_form_wrapper {
        max-height: 80vh;
        overflow: auto;
    }
}
`, "",{"version":3,"sources":["webpack://./src/modal.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,6BAA6B;IAC7B,aAAa;IACb,oBAAoB;IACpB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,UAAU;EACV,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,cAAc;AAChB;;AAEA;IACI;QACI,gBAAgB;QAChB,cAAc;IAClB;AACJ","sourcesContent":[".add_new_user_form_wrapper {\n    max-width: 80%;\n    margin-left: auto;\n    margin-right: auto;\n    background-color: var(--grey);\n    padding: 1rem;\n    padding-bottom: 2rem;\n    margin-top: 2rem;\n    overflow-y: auto;\n    max-height: 90%;\n}\n\n.modal_window_wrapper {\n  background-color: #c5c6c8;\n  margin-top: 5vh;\n  width: 80%;\n  margin-left: auto;\n  margin-right: auto;\n  padding: 1rem;\n  max-height: 80%;\n  box-sizing: border-box;\n  overflow: auto;\n}\n\n@media screen and (max-width: 700px) {\n    .add_new_user_form_wrapper {\n        max-height: 80vh;\n        overflow: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
