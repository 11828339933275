import React from 'react';
import Spinner from './Spinner';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import compose from 'lodash/fp/compose';
import {
  get_request,
  post_request,
} from './api/api_call'
import Select from 'react-select';

const select_styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        padding: 20,
        'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#f7941e',
        border: '1px solid #f7941e',
        'background-color': '#c5c6c8',
        'outline-color': '#f7941e',
    }),
    container: (provided, state) => ({
        ...provided,
        width: '100%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'margin-bottom': '0.5rem',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'background-color': '#c5c6c8',
    }),
}

var _ = require('lodash');


class ClubManage extends(React.Component) {
  constructor(props) {
    super(props);
    this.managers_ref = React.createRef();
    this.state = {
      lock_spinner: false,
      clubs: {},
      club_units: {},
      show_club_info: {},
      show_club_unit_info: {},
      edit_club_unit: null,
      show_dialog: false,
      exist_managers: [],
      coaches_salary: {},
      edit_coach_salary: false,
      edit_salary_skill: {},
      edit_salary_type: {},
      edit_salary_ticket: {},
      edit_salary_obj: null,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOut);
    this.getData()
  }

    handleClickOut = (e) => {
        if (this.managers_ref &&
            this.managers_ref.current &&
            (!this.managers_ref.current.contains(e.target) || e.target.id === 'background_lock_id')
        ) {
          this.setState(
            {
              show_dialog: false,
              edit_coach_salary: false,
            }
          );
        }
    }

  getData() {
    this.setState(
      {
          lock_spinner: true,
      }, () => {
        var uri = new URL(
          process.env.REACT_APP_BACKEND_URI + '/api/v1/management/club_units/'
        )
        get_request(uri.href, this.props.catchError).then(json => {
          if (this.props.logged_in) {
            var clubs = {}
            var show_club_info = {}
            var club_units = {}
            var show_club_unit_info = {}
            var coaches_salary = this.state.coaches_salary
            for (let cu in json) {
              if (!Object.keys(clubs).includes(json[cu].club.id)) {
                clubs[json[cu].club.id] = json[cu].club
                show_club_info[json[cu].club.id] = true
              }
              if (!Object.keys(club_units).includes(json[cu].club.id.toString())) {
                club_units[json[cu].club.id] = [json[cu]]
              }
              else {
                club_units[json[cu].club.id].push(json[cu])
              }
              show_club_unit_info[json[cu].id] = false
              if (!coaches_salary[json[cu].id]) {
                coaches_salary[json[cu].id] = {}
              }
            }
            this.setState(
              {
                clubs: clubs,
                show_club_info: show_club_info,
                club_units: club_units,
                show_club_unit_info: show_club_unit_info,
                lock_spinner: false,
                coaches_salary: coaches_salary,
              }
            );
          }
        })
      }
    )
  }

  getClubUnitCoachSalary(unit_pk) {
    this.setState(
      {
          lock_spinner: true,
      }, () => {
        var uri = new URL(
          process.env.REACT_APP_BACKEND_URI + '/api/v1/management/coach/skill/salary/'
        )
        uri.searchParams.set('club_unit', unit_pk)
        get_request(uri.href, this.props.catchError).then(json => {
          if (this.props.logged_in) {
            var coaches_salary = this.state.coaches_salary
            coaches_salary[unit_pk] = json[unit_pk]
            this.setState(
              {
                lock_spinner: false,
                coaches_salary: coaches_salary,
              }
            );
          }
        })
      }
    )
  }

  handleChangeClubUnit = (data) => {
    var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/club_units/' + this.state.edit_club_unit + '/')
    fetch(uri.href, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    })
    .then(res => {
      if (res.status === 401) {
          this.props.catchError(res.status);
          return
      }
      const response = res.json();
      return response;
    })
    .then(json => {
      if (!json) {
          return
      }
      var club_units = this.state.club_units
      for (let cu in club_units) {
        if (parseInt(cu) === json.club.id) {
          for (let j in club_units[cu]) {
            if (parseInt(club_units[cu][j].id) === json.id) {
              club_units[cu][j] = json
              break
            }
          }
          break
        }
      }
      this.setState(
        {
          club_units: club_units,
          edit_club_unit: null,
          exist_managers: [],
          show_dialog: false,
        }
      );
    })
  }

  _handleChangeClubUnit = (e) => {
    this.setState(
      {edit_club_unit: e.target.dataset.club_unit}, () => {
        this.handleChangeClubUnit({location: e.target.value})
      }
    )
  }

  renderEmployees(employees, unit_pk, removable) {
    return employees.map((employee) => {
      return (
        <div
          key={'employee-' + employee.id}
          style={
            {
              border: '1px solid #f7941e',
              borderRadius: '15px',
              width: 'fit-content',
              paddingLeft: '0.2rem',
              paddingRight: '0.2rem',
              paddingTop: '0.1rem',
              paddingBottom: '0.1rem',
              boxSizing: 'content-box',
            }
          }
        >
          {employee.last_name} {employee.first_name} ({employee.phone})
          {removable
            ? <span
                style={
                  {
                    borderLeft: '1px solid #f7941e',
                    marginLeft: '0.15rem',
                    cursor: 'pointer',
                  }
                }
                data-pk={employee.id}
                onClick={
                  (e) => {
                    this.setState(
                      {edit_club_unit: unit_pk}, () => {
                        this.handleChangeClubUnit(
                          {manager: Array.from(employees.filter(emp => emp.id != employee.id), emp => emp.id)}
                        )
                      }
                    )
                  }
                }
              >
                &#10008;
              </span>
            : ''
          }
        </div>
      )
    })
  }

  handleManageCoachSalary = (data, salary_pk) => {
    this.setState(
      {
          lock_spinner: true,
      }, () => {
        if (salary_pk) {
          var uri = new URL(
            process.env.REACT_APP_BACKEND_URI + '/api/v1/management/coach/skill/salary/' + salary_pk + '/'
          )
        }
        else {
          var uri = new URL(
            process.env.REACT_APP_BACKEND_URI + '/api/v1/management/coach/skill/salary/'
          )
        }
        post_request(
          uri.href,
          data,
          this.props.catchError, 
          {salary_pk} ? 'POST' : 'PUT',
        ).then(json => {
          if (this.props.logged_in) {
            var coaches_salary = this.state.coaches_salary
            console.log(json, coaches_salary)
            if (coaches_salary[json.club_unit]) {
              if (coaches_salary[json.club_unit][json.drill_type.id]) {
                coaches_salary[json.club_unit][json.drill_type.id]['salaries'].push(json)
              }
              else {
                coaches_salary[json.club_unit][json.drill_type.id] = { salaries: [json] }
              }
            }
            else {
              coaches_salary[json.club_unit] = {}
              coaches_salary[json.club_unit][json.drill_type.id] = { salaries: [json] }
            }
            this.setState(
              {
                lock_spinner: false,
                edit_coach_salary: false,
                coaches_salary: coaches_salary,
              }
            )
          }
        })
      }
    )
  }

  renderCoachSalary(salaries) {
    return salaries.map((salary) => {
      console.log("Salary: ", salary)
      return (
        <div
          key={'salary_' + salary.id}
          style={
            {
              display: 'flex',
            }
          }
        >
          <div
            style={
              {
                padding: '1rem',
                boxSizing: 'content-box',
                cursor: 'pointer',
              }
            }
            onClick={
              (e) => {
                var edit_salary_obj = new Object(
                  {
                    clients_from: salary.clients_from,
                    clients_to: salary.clients_to,
                    club_unit: salary.club_unit,
                    created_at: salary.created_at,
                    drill_type: salary.drill_type.id,
                    fraction: salary.fraction,
                    id: salary.id,
                    salary: salary.salary,
                    skill: salary.skill[0],
                    ticket: salary.ticket,
                    updated_at: salary.updated_at,
                  }
                )
                this.setState(
                  {
                    edit_salary_obj: edit_salary_obj,
                    edit_salary_skill: {'value': salary.skill[0], 'label': salary.skill[1]},
                    edit_salary_type: {'value': salary.drill_type.id, 'label': salary.drill_type.name},
                    edit_salary_ticket: (salary.ticket) ? {'value': salary.ticket.id, 'label': salary.ticket.ticket.name} : null,
                    edit_coach_salary: true,
                    edit_club_unit: salary.club_unit,
                  }
                )
              }
            }
          >
            &#9998;
          </div>
          <div>
            <div>
              {salary.skill[1]}
            </div>
            {salary.ticket
              ? <div>
                  <div>
                    {salary.ticket.ticket.name} ({salary.ticket.ticket.club_units[0].name})
                  </div>
                  {salary.ticket.ticket.price
                    ? <div>
                        {parseFloat(salary.ticket.ticket.price) * parseFloat(salary.fraction)} руб.
                      </div>
                    : <div>
                        Доля от цены: {parseFloat(salary.fraction)}
                      </div>
                  }
                </div>
              : <div>
                  <div>
                    {salary.salary} руб.
                  </div>
                  {salary.clients_from !== null && salary.clients_to !== null
                    ? <div>
                        Кол-во клиентов: {salary.clients_from} - {salary.clients_to}
                      </div>
                    : ''
                  }
                </div>
            }
            <hr />
          </div>
        </div>
      )
    })
  }

  renderCoachSalaryDrillType(pk) {
    return Object.values(
      this.state.coaches_salary[parseInt(pk)]
    ).map((salary) => {
      return (
        <div
          key={'salary_drill_type-' + salary.id}
        >
          <div
            style={
              {
                fontSize: '1.15rem',
                fontWeight: '500',
                width: 'fit-content',
                textDecoration: 'underline',
              }
            }
          >
            {salary.name}:
          </div>
          {salary.salaries
            ? <div
                style={
                  {
                    width: 'fit-content',
                    marginLeft: '2rem',
                  }
                }
              >
                {this.renderCoachSalary(salary.salaries)}
              </div>
            : ''
          }
        </div>
      )
    })
  }

  renderClubUnits(pk) {
    return this.state.club_units[parseInt(pk)].map((unit) => {
      return (
        <div
          key={'unit-' + unit.id}
          style={
            {
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
            }
          }
        >
          <hr />
          <div
            style={
              {
                marginTop: '1rem',
                marginBottom: '0.5rem',
                fontSize: '1.5rem',
                fontWeight: '600',
                cursor: 'pointer',
              }
            }
            onClick={
              (e) => {
                var show_club_unit_info = this.state.show_club_unit_info
                show_club_unit_info[unit.id] = !show_club_unit_info[unit.id]
                this.setState(
                  {show_club_unit_info: show_club_unit_info}, () => {
                    this.getClubUnitCoachSalary(unit.id)
                  }
                )
              }
            }
          >
            {unit.name}
            (
              <input
                className='text_edit_input client_contact'
                data-club_unit={unit.id}
                size={(unit.location) ? unit.location.length - 5 : 1}
                onChange={
                  compose(
                      _.debounce(this._handleChangeClubUnit, 500),
                      _.clone
                  )
                }
                defaultValue={unit.location}
              />
            )
          </div>
          {this.state.show_club_unit_info[unit.id]
            ? <div
                style={
                  {
                    marginLeft: '1rem',
                  }
                }
              >
                <div
                  style={
                    {
                      fontSize: '1.35rem',
                      fontWeight: '500',
                      width: 'fit-content',
                    }
                  }
                >
                  Сотрудники:
                </div>
                <div>
                  <div
                    style={
                      {
                        fontSize: '1.15rem',
                        fontWeight: '500',
                        width: 'fit-content',
                        marginLeft: '1rem',
                      }
                    }
                  >
                    Администраторы:
                  </div>
                  <div
                    style={
                      {
                        width: 'fit-content',
                        marginLeft: '2rem',
                      }
                    }
                  >
                    {this.renderEmployees(unit.manager, unit.id, true)}
                  </div>
                  <div
                    style={
                      {
                        border: '1px solid #f7941e',
                        borderRadius: '15px',
                        width: 'fit-content',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingLeft: '0.2rem',
                        paddingRight: '0.2rem',
                        paddingTop: '0.1rem',
                        paddingBottom: '0.1rem',
                        boxSizing: 'content-box',
                        fontSize: '1.25rem',
                        fontWeight: '500',
                        cursor: 'pointer',
                      }
                    }
                    onClick={
                      () => {
                        this.setState(
                          {
                            edit_club_unit: unit.id,
                            exist_managers: unit.manager.map((user) => user.id),
                            show_dialog: true,
                          }
                        )
                      }
                    }
                  >
                    &#10010;
                  </div>
                </div>
                <div>
                  <div
                    style={
                      {
                        fontSize: '1.15rem',
                        fontWeight: '500',
                        width: 'fit-content',
                        marginLeft: '1rem',
                      }
                    }
                  >
                    Тренеры:
                  </div>
                  <div
                    style={
                      {
                        width: 'fit-content',
                        marginLeft: '2rem',
                      }
                    }
                  >
                    {this.renderEmployees(unit.coaches, unit.id, false)}
                  </div>
                </div>
                <div
                  style={
                    {
                      marginTop: '0.5rem',
                      fontSize: '1.35rem',
                      fontWeight: '500',
                      width: 'fit-content',
                    }
                  }
                >
                  Оплата:
                </div>
                <div>
                  <div
                    style={
                      {
                        fontSize: '1.15rem',
                        fontWeight: '500',
                        width: 'fit-content',
                        marginLeft: '1rem',
                      }
                    }
                  >
                    Оплата тренеров:
                  </div>
                  <div
                    style={
                      {
                        width: 'fit-content',
                        marginLeft: '2rem',
                      }
                    }
                  >
                    {this.renderCoachSalaryDrillType(unit.id)}
                  </div>
                  <div
                    style={
                      {
                        border: '1px solid #f7941e',
                        borderRadius: '15px',
                        width: 'fit-content',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingLeft: '0.2rem',
                        paddingRight: '0.2rem',
                        paddingTop: '0.1rem',
                        paddingBottom: '0.1rem',
                        boxSizing: 'content-box',
                        fontSize: '1.25rem',
                        fontWeight: '500',
                        cursor: 'pointer',
                      }
                    }
                    onClick={
                      () => {
                        this.setState(
                          {
                            edit_club_unit: unit.id,
                            edit_coach_salary: true,
                          }
                        )
                      }
                    }
                  >
                    &#10010;
                  </div>
                </div>
              </div>
            : ''
          }
        </div>
      )
    })
  }

  renderClubs() {
    return Object.keys(this.state.clubs).map((pk) => {
      return (
        <div
          key={'club-' + pk}
        >
          <div
            style={
              {
                width: 'fit-content',
                fontSize: '1.5rem',
                fontWeight: '800',
                border: '2px solid #f7941e',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: '0.15rem',
                cursor: 'pointer',
              }
            }
            onClick={
              () => {
                var show_club_info = this.state.show_club_info
                show_club_info[pk] = !show_club_info[pk]
                this.setState({show_club_info: show_club_info})
              }
            }
          >
            {this.state.clubs[pk].name}
          </div>
          {this.state.show_club_info[pk]
            ? <div>
                <div>
                  Руководитель: {this.state.clubs[pk].president.last_name} {this.state.clubs[pk].president.first_name}
                </div>
                <div>
                  Филиалы:
                  <div>
                    {this.renderClubUnits(pk)}
                  </div>
                </div>
              </div>
            : ''
          }
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        <div>
          {this.renderClubs()}
        </div>
        {this.state.show_dialog
          ? <AddManager
              logged_in={this.props.logged_in}
              handleAddManagers={this.handleChangeClubUnit.bind(this)}
              exist_managers={this.state.exist_managers}
              managers_ref={this.managers_ref}
            />
          : ''
        }
        {this.state.edit_coach_salary
          ? <ManageCoachSalary
              salary_skill={this.state.edit_salary_skill}
              salary_ticket={this.state.edit_salary_ticket}
              salary_drill_type={this.state.edit_salary_type}
              logged_in={this.props.logged_in}
              managers_ref={this.managers_ref}
              edit_club_unit={this.state.edit_club_unit}
              edit_salary_obj={this.state.edit_salary_obj}
              handleManageCoachSalary={this.handleManageCoachSalary.bind(this)}
            />
          : ''
        }
        {this.state.lock_spinner
            ? <Spinner />
            : ''
        }
      </div>
    )
  }
}

class AddManager extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
          users: [],
          selected_users: [],
          lock_spinner: false,
        }
    }

    searchUser = (e) => {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_clients/');
                uri.searchParams.set("limit", 20);
                uri.searchParams.set("offset", 0);
                uri.searchParams.set("search", encodeURIComponent(e.target.value));
              fetch(uri.href, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('token')}`
                }
              })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (this.props.logged_in) {
                        this.setState(
                            {
                                users: json.results,
                                lock_spinner: false,
                            }
                        );
                    }
                })
            }
        )
    }

    renderSelectedUsers() {
      return this.state.selected_users.map((user) => {
        return (
          <div
            key={'choosen-user-' + user.id}
            style={
              {
                display: 'flex',
                border: '1px solid #f7941e',
                borderRadius: '5px',
                width: 'fit-content',
                paddingLeft: '0.25rem',
                paddingRight: '0.25rem',
                paddingTop: '0.1rem',
                paddingBottom: '0.1rem',
              }
            }
          >
            <div>
              {user.last_name} {user.first_name[0]}.
            </div>
            <div
              style={
                {
                  borderLeft: '1px solid #f7941e',
                  marginLeft: '0.35rem',
                  cursor: 'pointer',
                }
              }
              onClick={
                () => {
                  var selected_users = this.state.selected_users
                  for (var u in selected_users) {
                    if (parseInt(selected_users[u].id) === parseInt(user.id)) {
                      selected_users.splice(u, 1)
                      break
                    }
                  }
                  this.setState({selected_users: selected_users})
                }
              }
            >
              &#10008;
            </div>
          </div>
        )
      })
    }

    renderFoundUsers() {
      return this.state.users.map((user) => {
        return (
          <div
            key={'custom-select-' + user.id}
            className='custom_select'
            onClick={
              () => {
                var selected_users = this.state.selected_users
                var exist = false
                for (var u in selected_users) {
                  if (parseInt(selected_users[u].id) === user.id) {
                    exist = true
                    break
                  }
                }
                if (!exist) {
                  selected_users.push(user)
                  this.setState(
                    {selected_users: selected_users},
                    () => {
                      document.getElementById('conf_fts_input_id').value = ''
                      this.setState({users: []})
                    }
                  )
                }
              }
            }
          >
            {user.last_name} {user.first_name}
          </div>
        )
      })
    }

    render() {
        return (
            <div
              className='background_lock_wrapper'
              id='background_lock_id'
              ref={this.props.managers_ref}
            >
                <div className='new_drill_form_wrapper' ref={this.props.managers_ref}>
                  <h3 ref={this.props.modal_setting_ref}>
                      Добавить сотрудников
                  </h3>
                  <div>
                    <div className='fts_input_wrapper'>
                        <input
                          placeholder='Имя Фамилия'
                          className='fts_input'
                          id='conf_fts_input_id'
                          onChange={
                            compose(
                              _.debounce(this.searchUser, 300),
                              _.clone
                            )
                          }
                        />
                        {this.state.selected_users.length > 0
                          ? <div
                              style={
                                {
                                  width: '80%',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                }
                              }
                            >
                              {this.renderSelectedUsers()}
                            </div>
                          : ''
                        }
                        {this.state.users.length > 0
                          ? <div
                              style={
                                {
                                  border: '1px solid #000',
                                  position: 'relative',
                                  backgroundColor: '#fff',
                                  width: '80%',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                }
                              }
                            >
                              {this.renderFoundUsers()}
                            </div>
                          : ''
                        }
                    </div>
                  </div>
                  <button
                      id='add-user-ticket'
                      key='user-traffic-accept'
                      className='drill_traffic_btn'
                      data-club_unit={this.state.clubUnitId}
                      onClick={
                        (e) => {
                          this.props.handleAddManagers(
                              {
                                manager: this.props.exist_managers.concat(this.state.selected_users.map((user) => user.id)),
                              }
                          )
                        }
                      }
                  >
                      &#10004;
                  </button>
                </div>
            </div>
        )
    }
}

class ManageCoachSalary extends(React.Component) {
  constructor(props) {
    super(props);
    this.state = {
      tickets: [],
      skills: [],
      drill_types: [],
      lock_spinner: false,
      fixed_price: (this.props.edit_salary_obj && this.props.edit_salary_obj.fraction) ? false : true,
      coach_salary_obj: (this.props.edit_salary_obj) ? this.props.edit_salary_obj : {'salary': 0, 'clients_from': 0, 'clients_to': 0, 'club_unit': this.props.edit_club_unit},
    }
  }

  componentDidMount() {
    this.getSkills()
    this.getDrillTypes()
    this.getTickets()
  }

  getSkills() {
    this.setState(
      {
          lock_spinner: true,
      }, () => {
        var uri = new URL(
          process.env.REACT_APP_BACKEND_URI + '/api/v1/management/coach_skill_choices/'
        )
        get_request(uri.href, this.props.catchError).then(json => {
          if (this.props.logged_in) {
            this.setState(
              {
                skills: json,
                lock_spinner: false,
              }
            );
          }
        })
      }
    )
  }

  getDrillTypes() {
    this.setState(
      {
          lock_spinner: true,
      }, () => {
        var uri = new URL(
          process.env.REACT_APP_BACKEND_URI + '/api/v1/management/drills_types/'
        )
        get_request(uri.href, this.props.catchError).then(json => {
          if (this.props.logged_in) {
            this.setState(
              {
                drill_types: json.results,
                lock_spinner: false,
              }
            );
          }
        })
      }
    )
  }

  getTickets() {
    this.setState(
      {
          lock_spinner: true,
      }, () => {
        var uri = new URL(
          process.env.REACT_APP_BACKEND_URI + '/api/v1/management/tickets/'
        )
        uri.searchParams.set('club_unit', this.props.edit_club_unit)
        get_request(uri.href, this.props.catchError).then(json => {
          if (this.props.logged_in) {
            this.setState(
              {
                tickets: json,
                lock_spinner: false,
              }
            );
          }
        })
      }
    )
  }

  render() {
      return (
          <div
            className='background_lock_wrapper'
            id='background_lock_id'
            ref={this.props.managers_ref}
          >
              <div className='new_drill_form_wrapper' ref={this.props.managers_ref}>
                <h3 ref={this.props.modal_setting_ref}>
                    Оплата тренировки
                </h3>
                <div>
                  <div
                    style={
                      {
                        display: 'flex',
                        width: '70%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }
                    }
                  >
                      <Select
                        options={
                          Array.from(this.state.skills, (val) => new Object(
                            {
                              "value": val.id,
                              "label": val.name,
                            }
                          ))
                        }
                        onChange={
                          (e) => {
                            var coach_salary_obj = this.state.coach_salary_obj
                            coach_salary_obj['skill'] = e['value']
                            this.setState({coach_salary_obj: coach_salary_obj})
                          }
                        }
                        styles={select_styles}
                        placeholder={"Уровень тренера"}
                        defaultValue={
                          (this.props.edit_salary_obj) ? (this.props.salary_skill) : null
                        }
                        menuPosition={'fixed'}
                      />
                  </div>
                  <div
                    style={
                      {
                        display: 'flex',
                        width: '70%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }
                    }
                  >
                      <Select
                        options={
                          Array.from(this.state.drill_types, (val) => new Object(
                            {
                              "value": val.id,
                              "label": val.name,
                            }
                          ))
                        }
                        onChange={
                          (e) => {
                            var coach_salary_obj = this.state.coach_salary_obj
                            coach_salary_obj['drill_type'] = e['value']
                            this.setState({coach_salary_obj: coach_salary_obj})
                          }
                        }
                        styles={select_styles}
                        placeholder={"Тип тренировки"}
                        defaultValue={
                          (this.props.edit_salary_obj) ? (this.props.salary_drill_type) : null
                        }
                        menuPosition={'fixed'}
                      />
                  </div>
                  <div className='new_bid_child_check_wrapper'>
                    <div className='client_arrive_chbox_wrapper'>
                      <input
                        type='checkbox'
                        id='frozen_ticket'
                        key='frozen_ticket'
                        name='user-arrive'
                        className='arrive_chbox'
                        onChange={
                          () => {
                            var coach_salary_obj = this.state.coach_salary_obj
                            if (this.state.fixed_price) {
                              delete coach_salary_obj['ticket']
                              delete coach_salary_obj['fraction']
                            }
                            else {
                              delete coach_salary_obj['salary']
                              delete coach_salary_obj['clients_from']
                              delete coach_salary_obj['clients_to']
                            }
                            this.setState(
                              {
                                fixed_price: !this.state.fixed_price,
                                coach_salary_obj: coach_salary_obj,
                              }
                            )
                          }
                        }
                        checked={this.state.fixed_price}
                      />
                      <span
                        className='client_arrive_chbox_span'
                      >
                      </span>
                    </div>
                    <div
                      className='chbox_label'
                    >
                      Фиксированная плата
                    </div>
                  </div>
                  <div>
                    {this.state.fixed_price
                      ? <div>
                          <div className='add_ticket_promos_wrapper'>
                            <label htmlFor='ticket_discount'>Оплата тренера: </label>
                            <input
                              key='ticket_discount'
                              type='number'
                              step='1'
                              placeholder='Оплата тренера'
                              className='add_ticket_btn'
                              defaultValue={(this.props.edit_salary_obj) ? this.props.edit_salary_obj.salary : 0.0}
                              onChange={
                                (e) => {
                                  var coach_salary_obj = this.state.coach_salary_obj
                                  coach_salary_obj['salary'] = e.target.value
                                  this.setState({coach_salary_obj: coach_salary_obj})
                                }
                              }
                            />
                          </div>
                          <div className='add_ticket_promos_wrapper'>
                            <label htmlFor='ticket_discount'>Клиентов от: </label>
                            <input
                              key='ticket_discount'
                              type='number'
                              step='1'
                              placeholder='Клиентов от'
                              className='add_ticket_btn'
                              defaultValue={(this.props.edit_salary_obj) ? this.props.edit_salary_obj.clients_from : 0.0}
                              onChange={
                                (e) => {
                                  var coach_salary_obj = this.state.coach_salary_obj
                                  coach_salary_obj['clients_from'] = e.target.value
                                  this.setState({coach_salary_obj: coach_salary_obj})
                                }
                              }
                            />
                          </div>
                          <div className='add_ticket_promos_wrapper'>
                            <label htmlFor='ticket_discount'>Клиентов до: </label>
                            <input
                              key='ticket_discount'
                              type='number'
                              step='1'
                              placeholder='Клиентов до'
                              className='add_ticket_btn'
                              defaultValue={(this.props.edit_salary_obj) ? this.props.edit_salary_obj.clients_to : 0.0}
                              onChange={
                                (e) => {
                                  var coach_salary_obj = this.state.coach_salary_obj
                                  coach_salary_obj['clients_to'] = e.target.value
                                  this.setState({coach_salary_obj: coach_salary_obj})
                                }
                              }
                            />
                          </div>
                        </div>
                      : <div>
                          <div
                            style={
                              {
                                display: 'flex',
                                width: '70%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }
                            }
                          >
                            <Select
                              options={
                                Array.from(this.state.tickets, (val) => new Object(
                                  {
                                    "value": val.id,
                                    "label": (!val.illusive_promo) ? val.ticket : val.illusive_promo,
                                    "illusive": val.illusive,
                                    "type": "ticket",
                                  }
                                ))
                              }
                              onChange={
                                (e) => {
                                  var coach_salary_obj = this.state.coach_salary_obj
                                  coach_salary_obj['ticket'] = e['value']
                                  this.setState({coach_salary_obj: coach_salary_obj})
                                }
                              }
                              styles={select_styles}
                              placeholder={"Абонемент"}
                              defaultValue={
                                (this.props.edit_salary_obj) ? (this.props.salary_ticket) : null
                              }
                              menuPosition={'fixed'}
                            />
                          </div>
                          <div className='add_ticket_promos_wrapper'>
                            <label htmlFor='ticket_discount'>Доля от стоимости абонемента: </label>
                            <input
                              key='ticket_discount'
                              type='number'
                              step='0.1'
                              max='1'
                              placeholder='Доля от стоимости абонемента'
                              className='add_ticket_btn'
                              defaultValue={(this.props.edit_salary_obj) ? this.props.edit_salary_obj.fraction : 0.0}
                              onChange={
                                (e) => {
                                  if (parseInt(e.target.value) > 1) {
                                    e.target.value = 1
                                  }
                                  var coach_salary_obj = this.state.coach_salary_obj
                                  coach_salary_obj['fraction'] = e.target.value
                                  this.setState({coach_salary_obj: coach_salary_obj})
                                }
                              }
                            />
                          </div>
                        </div>
                    }
                  </div>
                </div>
                <button
                  id='add-user-ticket'
                  key='user-traffic-accept'
                  className='drill_traffic_btn'
                  data-club_unit={this.state.clubUnitId}
                  onClick={
                    (e) => {
                      this.props.handleManageCoachSalary(
                        this.state.coach_salary_obj,
                        (this.props.edit_salary_obj) ? this.props.edit_salary_obj.id : null,
                      )
                    }
                  }
                >
                  &#10004;
                </button>
              </div>
          </div>
      )
  }
}

export default ClubManage;
