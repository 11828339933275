import React from 'react';
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
import Select from 'react-select';
import Spinner from './Spinner';
import {
  Link
} from "react-router-dom";
import compose from 'lodash/fp/compose';

var _ = require('lodash');

const select_styles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#000',
    padding: 20,
    'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999,
    color: '#f7941e',
    border: '1px solid #f7941e',
    'background-color': '#c5c6c8',
    'outline-color': '#f7941e',
  }),
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    'margin-left': 'auto',
    'margin-right': 'auto',
    border: '1px solid #f7941e',
    'outline-color': '#f7941e',
    'margin-bottom': '0.5rem',
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #f7941e',
    'outline-color': '#f7941e',
    'background-color': '#c5c6c8',
  }),
}


class RentControl extends(React.Component) {
  constructor(props) {
    super(props);
    this.modal_ref = React.createRef();
    var today = new Date()
    this.state = {
      limit: 20,
      offset: 0,
      services: [],
      rent_items: {},
      service_id: null,
      date_start: new Date(today.getFullYear(), today.getMonth(), 1, 3).toISOString(),
      date_stop: new Date(today.getFullYear(), today.getMonth() + 1, 1, 2, 59).toISOString(),
    }
  }

  componentDidMount() {
    this.getServices()
  }

  getServices() {
    this.setState(
      {
          lock_spinner: true,
      }, () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/market/products/');
        uri.searchParams.set('service', 1);
        uri.searchParams.set('rent', 1);
        uri.searchParams.set('limit', 1000)
        uri.searchParams.set('offset', 0)
        fetch(uri.toString(), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
          if (res.status === 401) {
              this.props.catchError(res.status);
              return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
          if (this.props.logged_in) {
            this.setState(
              {
                services: json.items,
              }
            )
          }
        })
      }
    )
  }

  getItems() {
    this.setState(
      {
          lock_spinner: true,
      }, () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/market/product/items/');
        if (this.state.service_id) {
          uri.searchParams.set('product', this.state.service_id)
        }
        uri.searchParams.set('sold', true)
        uri.searchParams.set('rent_time_start', this.state.date_start)
        uri.searchParams.set('rent_time_stop', this.state.date_stop)
        uri.searchParams.set('limit', 100)
        uri.searchParams.set('offset', 0)
        uri.searchParams.set('participants_info', true)
        fetch(uri.toString(), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
          if (res.status === 401) {
              this.props.catchError(res.status);
              return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
          if (this.props.logged_in) {
            var items = new Object()
            for (let item of json.items) {
              if (!(item.product in items)) {
                items[item.product] = new Array()
              }
              items[item.product].push(item)
            }
            this.setState(
              {
                rent_items: items,
              }
            )
          }
        })
      }
    )
  }

  renderSizeItems(size) {
    return this.state.rent_items[size].map((item) => {
      var color = 'none'
      if (item.rent_time_stop) {
        if (new Date(item.rent_time_stop) > new Date()) {
          color = '#1ea51e'
        }
        else {
          color = '#e94141'
        }
      }
      return (
        <div
          key={'size_item-' + item.id}
          style={
            {
              borderBottom: '1px solid #f7941e',
              marginBottom: '0.25rem',
              backgroundColor: color,
            }
          }
        >
          <div>
            {item.buyer.last_name} {item.buyer.first_name}
          </div>
          {item.rent_time_start && item.rent_time_stop
            ? <div
                style={
                  {
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '0.75rem',
                  }
                }
              >
                <div
                  style={
                    {
                      marginRight: '0.25rem',
                      marginLeft: '0.25rem',
                    }
                  }
                >
                  <div>
                    {item.rent_time_start.split('T')[0]}
                  </div>
                  <div>
                    {item.rent_time_start.split('T')[1].split('+')[0]}
                  </div>
                </div>
                <div
                  style={
                    {
                      height: '1rem',
                      marginRight: '0.25rem',
                      marginLeft: '0.25rem',
                    }
                  }
                >
                  -
                </div>
                <div
                  style={
                    {
                      marginRight: '0.25rem',
                      marginLeft: '0.25rem',
                    }
                  }
                >
                  <div>
                    {item.rent_time_stop.split('T')[0]}
                  </div>
                  <div>
                    {item.rent_time_stop.split('T')[1].split('+')[0]}
                  </div>
                </div>
              </div>
            : ''
          }
        </div>
      )
    })
  }

  renderServices() {
    return Object.keys(this.state.rent_items).map((size) => {
      return (
        <div
          key={'size-' + size}
          style={
            {
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
            }
          }
        >
          <div
            style={
              {
                border: '1px solid #f7941e',
              }
            }
          >
            <div
              style={
                {
                  borderBottom: '1px solid #f7941e',
                  marginBottom: '0.25rem',
                }
              }
            >
              {size}
            </div>
            <div>
              {this.renderSizeItems(size)}
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        <div
          className='filters_list_wrapper'
          style={
            {
              display: 'flex',
            }
          }
        >
          <div className='filter_btn_wrapper'>
            <Select
              options={
                Array.from(this.state.services, (val) => new Object(
                  {
                    "value": val.id,
                    "label": val.name,
                  }
                ))
              }
              onChange={
                (e) => {
                  this.setState(
                    {service_id: (e) ? e['value'] : null},
                    () => {
                      if (!this.state.service_id) {
                        this.setState({rent_times: {}})
                        return
                      }
                      this.getItems()
                    }
                  )
                }
              }
              styles={select_styles}
              placeholder={"Услуга"}
              isClearable
            />
          </div>
          <div className='filter_btn_wrapper'>
            <input
              key='filter_ticket_date_start'
              id='filter_ticket_date_start'
              type='date'
              className='add_ticket_btn'
              style={
                {
                  paddingTop: '0.4rem',
                  paddingBottom: '0.4rem',
                }
              }
              defaultValue={this.state.date_start.split('T')[0]}
              onChange={
                (e) => {
                  this.setState(
                    {
                      date_start: new Date(e.target.value + 'T03:00:00').toISOString()
                    }, () => {
                      this.getItems()
                    }
                  )
                }
              }
            />
          </div>
          <div className='filter_btn_wrapper'>
            <input
              key='filter_ticket_date_start'
              id='filter_ticket_date_start'
              type='date'
              className='add_ticket_btn'
              style={
                {
                  paddingTop: '0.4rem',
                  paddingBottom: '0.4rem',
                }
              }
              defaultValue={this.state.date_stop.split('T')[0]}
              onChange={
                (e) => {
                  this.setState(
                    {
                      date_stop: new Date(e.target.value + 'T02:59:00').toISOString()
                    }, () => {
                      this.getItems()
                    }
                  )
                }
              }
            />
          </div>
        </div>
        {this.state.service_id
          ? <div
              style={
                {
                  display: 'flex',
                  overflowX: 'auto',
                }
              }
            >
              {this.renderServices()}
            </div>
          : ''
        }
      </div>
    )
  }
}

export default RentControl
