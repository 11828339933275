import React from 'react';

import {
    get_request
} from '../api/api_call'

class CashBoxReport extends(React.Component) {
  constructor(props) {
    super(props)
    this.state = {
      lock_spinner: false,
      market_sails_report: {data: [], payment_types_income: []},
      tickets_sails_report: [],
    }
  }

  componentDidMount() {
    this.getSailsReport()
  }

  getSailsReport() {
    this.setState(
      {lock_spinner: true},
      () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/daily/sails/statistic/');
        uri.searchParams.set('date_start', this.props.date_start);
        uri.searchParams.set('date_stop', this.props.date_stop);
        get_request(uri.href, this.props.catchError).then(
          json => {
            if (this.props.logged_in && json) {
              this.setState({
                market_sails_report: json.market,
                tickets_sails_report: json.tickets,
                lock_spinner: false,
              })
            }
          }
        )
      }
    )
  }

  countAvgReceiptByCategory(sails_report) {
    var avg_receipt = new Object()
    for (let sail of sails_report) {
      for (let cat of sail.categories) {
        if (avg_receipt[cat] === undefined) {
          avg_receipt[cat] = {
            payed_amount: parseFloat(sail.payed_amount) / sail.categories.length,
            price_amount: parseFloat(sail.price) / sail.categories.length,
            count: 1,
          }
        }
        else {
          avg_receipt[cat].payed_amount += parseFloat(sail.payed_amount) / sail.categories.length
          avg_receipt[cat].price_amount += parseFloat(sail.price) / sail.categories.length
          avg_receipt[cat].count += 1
        }
      }
    }
    return avg_receipt
  }

  renderReportConclusion(sails_report) {
    var avg_receipt = this.countAvgReceiptByCategory(sails_report)
    return Object.keys(avg_receipt).map((cat) => {
      return (
        <div
            key={'avg_receipt_' + cat}
        >
          <div>
            {`Доход от '${cat}': ${avg_receipt[cat].payed_amount.toFixed(2)}руб.`}
          </div>
          <div>
            {`Средний чек '${cat}': ${(avg_receipt[cat].payed_amount / avg_receipt[cat].count).toFixed(2)}руб.`}
          </div>
        </div>
      )
    })
  }

    renderTotalIncomings(sails_report) {
      var plan_income = 0.0
      var real_income = 0.0
      for (let sail of sails_report) {
        plan_income += parseFloat(sail.price)
        real_income += parseFloat(sail.payed_amount)
      }
      return (
        <div
          key='total_incomes'
        >
          <div>
            {`Ожидаемая прибыль: ${plan_income}руб.`}
          </div>
          <div>
            {`Реальная прибыль: ${real_income}руб.`}
          </div>
        </div>
      )
    }

  renderSoldItems(items) {
    var res = []
    var res_item = ''
    for (let item of items) {
      res_item = item.item
      if (item.coaches.length > 0) {
        res_item += item.coaches.map(coach => ` (${coach.first_name} ${coach.last_name})`).join()
      }
      res.push(res_item)
    }
    return res.join()
  }

  renderReportPartData(sails_report) {
    return sails_report.map((sail, index) => {
      var row_background = ''
      if (parseFloat(sail.price) > parseFloat(sail.payed_amount)) {
        row_background = 'danger_row_background'
      }
      else if (parseFloat(sail.price) < parseFloat(sail.payed_amount)) {
        row_background = 'success_row_background'
      }
      return (
        <div
          className={`daily_stat_report_row daily_report_row ${row_background}`}
          key={'daily_report_' + index}
        >
          <div className='daily_report_col_1'>
            {(sail.client) ? sail.client.first_name + ' ' + sail.client.last_name : '-'}
          </div>
          <div className='daily_report_col_2'>
            {`${sail.price}(${sail.payed_amount})`}
          </div>
          <div className='daily_report_col_3'>
              {(sail.payment_type) ? this.props.payment_types.filter(pt => pt.id === parseInt(sail.payment_type))[0].shortcut : ''}
          </div>
          <div className='daily_report_col_4'>
            {this.renderSoldItems(sail.items)}
          </div>
          <div className='daily_report_col_5'>
            {sail.categories.join()}
          </div>
        </div>
      )
    })
  }

  renderTicketsReportData() {
    return this.state.tickets_sails_report.map((sail, index) => {
      return (
        <div
          key={'tickets_daily_' + index}
        >
          <div className='report_club_unit_head'>
            {sail.club_unit.name}
          </div>
          <div>
            <div
              style={
                {
                  marginBottom: '1.5rem',
                  marginTop: '1.5rem',
                }
              }
            >
              <div className='report_part_head'>
                Выручка по способу оплаты:
              </div>
              <div
                style={
                  {
                    width: 'fit-content',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }
                }
              >
                {this.renderPaymentTypesIncome(sail.payment_types_income)}
              </div>
            </div>
            <div className='report_head_info'>
              <div>
                Посещаемость: {sail.attendance}чел.
              </div>
              <div>
                Новых клиентов: {sail.new_clients}чел.
              </div>
            </div>
            <div className='report_head_info'>
              {this.renderTotalIncomings(sail.data)}
            </div>
            <div className='report_head_info'>
              {this.renderReportConclusion(sail.data)}
            </div>
          </div>
          <div className='daily_stat_report_row daily_report_header'>
            <div className='daily_report_col_1'>
              ФИО
            </div>
            <div className='daily_report_col_2'>
              Цена
            </div>
            <div className='daily_report_col_3'>
              Оплата
            </div>
            <div className='daily_report_col_4'>
              Наименование
            </div>
            <div className='daily_report_col_5'>
              Вид
            </div>
          </div>
          <div>
            {this.renderReportPartData(sail.data)}
          </div>
        </div>
      )
    })
  }

  renderPaymentTypesIncome(payment_types_income) {
    return payment_types_income.map((pt_income) => {
      return (
        <div className='payment_types_income_wrapper'>
          <div className='payment_type_income'>
            {(pt_income.payment_type) ? this.props.payment_types.filter(pt => pt.id === parseInt(pt_income.payment_type))[0].shortcut : ''}:
          </div>
          <div className='payment_type_income'>
            {pt_income.income}руб.
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        <div>
          <div className='report_head'>
            {`Отчет ${this.props.date_start}`}
          </div>
        </div>
        <div>
          <div className='report_part_head'>
            Абонементы:
          </div>
          <div>
            {this.renderTicketsReportData()}
          </div>
        </div>
        <div>
          <div
            className='report_part_head'
            style={
              {
                marginTop: '1rem',
              }
            }
          >
            Магазин:
          </div>
          <div
            style={
              {
                marginBottom: '1.5rem',
                marginTop: '1.5rem',
              }
            }
          >
            <div className='report_part_head'>
              Выручка по способу оплаты:
            </div>
            <div
              style={
                {
                  width: 'fit-content',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }
              }
            >
              {this.renderPaymentTypesIncome(this.state.market_sails_report.payment_types_income)}
            </div>
          </div>
          <div>
            <div className='report_head_info'>
              {this.renderTotalIncomings(this.state.market_sails_report.data)}
            </div>
            <div className='report_head_info'>
              {this.renderReportConclusion(this.state.market_sails_report.data)}
            </div>
          </div>
          <div className='daily_stat_report_row daily_report_header'>
            <div className='daily_report_col_1'>
              ФИО
            </div>
            <div className='daily_report_col_2'>
              Цена
            </div>
            <div className='daily_report_col_3'>
              Оплата
            </div>
            <div className='daily_report_col_4'>
              Наименование
            </div>
            <div className='daily_report_col_5'>
              Вид
            </div>
          </div>
          <div>
            {this.renderReportPartData(this.state.market_sails_report.data)}
          </div>
        </div>
      </div>
    )
  }
}

export { CashBoxReport }
