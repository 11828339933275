// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar_filters_wrapper {
  display: flex;
}
.calendar_filters_list_wrapper {
}
.calendar_filters_btn_wrapper {
}

@media screen and (max-width: 700px) {
  .calendar_filters_wrapper {
    display: block;
  }
  .calendar_filters_list_wrapper {
    margin-top: 4rem;
  }
  .calendar_filters_btn_wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/calendar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;AACA;AACA;AACA;;AAEA;EACE;IACE,cAAc;EAChB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;AACF","sourcesContent":[".calendar_filters_wrapper {\n  display: flex;\n}\n.calendar_filters_list_wrapper {\n}\n.calendar_filters_btn_wrapper {\n}\n\n@media screen and (max-width: 700px) {\n  .calendar_filters_wrapper {\n    display: block;\n  }\n  .calendar_filters_list_wrapper {\n    margin-top: 4rem;\n  }\n  .calendar_filters_btn_wrapper {\n    margin-left: auto;\n    margin-right: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
