// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup_container {
  background-color: #fff;
}

.popup_wrapper {
  position: absolute;
  width: 30vw;
  z-index: 100;
  text-align: center;
  bottom: 5%;
  right: 5%;
  padding: 5px;
  box-sizing: border-box;
}
.popup_event_wrapper {
  border: 3px solid #f7941e;
  border-radius: 5px;
  opacity: 75%;
  background-color: #c5c6c8;
}
.popup_close {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: 5px;
  cursor: pointer;
  font-size: 1.25rem;
}
.popup_title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.popup_message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.5rem;
  white-space: nowrap;
}

.popup .popup_wrapper::after {
  animation: fadeIn 1s
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}
`, "",{"version":3,"sources":["webpack://./src/popup.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,YAAY;EACZ,sBAAsB;AACxB;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EAEE;AACF;;AAOA;EACE,MAAM,UAAU,CAAC;EACjB,IAAI,UAAU,CAAC;AACjB","sourcesContent":[".popup_container {\n  background-color: #fff;\n}\n\n.popup_wrapper {\n  position: absolute;\n  width: 30vw;\n  z-index: 100;\n  text-align: center;\n  bottom: 5%;\n  right: 5%;\n  padding: 5px;\n  box-sizing: border-box;\n}\n.popup_event_wrapper {\n  border: 3px solid #f7941e;\n  border-radius: 5px;\n  opacity: 75%;\n  background-color: #c5c6c8;\n}\n.popup_close {\n  width: fit-content;\n  margin-left: auto;\n  margin-right: 5px;\n  cursor: pointer;\n  font-size: 1.25rem;\n}\n.popup_title {\n  font-size: 1.5rem;\n  font-weight: 600;\n  margin-bottom: 0.5rem;\n}\n.popup_message {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  margin-bottom: 0.5rem;\n  white-space: nowrap;\n}\n\n.popup .popup_wrapper::after {\n  -webkit-animation: fadeIn 1s;\n  animation: fadeIn 1s\n}\n\n@-webkit-keyframes fadeIn {\n  from {opacity: 0;}\n  to {opacity: 1;}\n}\n\n@keyframes fadeIn {\n  from {opacity: 0;}\n  to {opacity:1 ;}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
