import React from 'react'
import {
  get_request
} from '../api/api_call'

class BaseComponent extends(React.Component) {
    getClubDepartments() {
      this.setState(
        {
            lock_spinner: true,
        }, () => {
          var uri = new URL(
            process.env.REACT_APP_BACKEND_URI + '/api/v1/management/club/department/'
          )
          get_request(uri.href, this.props.catchError).then(
            json => {
              if (this.props.logged_in && json) {
                this.setState({
                  club_departments: json,
                  lock_spinner: false,
                });
              }
            }
          )
        }
      )
    }
}

export default BaseComponent
