import React from 'react';
import Select from 'react-select'
import compose from 'lodash/fp/compose'
import {
  get_request,
  post_request,
} from './api/api_call'
import { locales } from '../helpers'

var _ = require('lodash');

const select_styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        padding: 20,
        'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#f7941e',
        border: '1px solid #f7941e',
        'background-color': '#c5c6c8',
        'outline-color': '#f7941e',
    }),
    container: (provided, state) => ({
        ...provided,
        width: '100%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'margin-bottom': '0.5rem',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'background-color': '#c5c6c8',
    }),
}

class Calendar extends(React.Component) {
    constructor(props) {
        super(props);
        this.drill_control_ref = React.createRef();
        var first_day = '';
        var last_day = '';
        var device = 'desktop';
        if (window.screen.width > 700 && window.screen.width < 1024) {
            first_day = new Date();
            last_day = new Date();
            last_day.setDate(last_day.getDate() + 2);
            first_day = first_day.toISOString().split('T')[0];
            last_day = last_day.toISOString().split('T')[0];
            device = 'mobile';
        }
        else if (window.screen.width < 700) {
            first_day = new Date();
            first_day = first_day.toISOString().split('T')[0];
            last_day = first_day;
            device = 'mobile';
        }
        var clubs = [];
        if (this.props.clubs) {
            clubs = this.props.clubs;
        }
        var club_units = [];
        if (this.props.club_units) {
            club_units = this.props.club_units;
        }
        this.state = {
            drills: [],
            club_id: '',
            club_unit_id: '',
            // weekday: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
            weekday: [
                'monday_shortcut',
                'tuesday_shortcut',
                'wednesday_shortcut',
                'thursday_shortcut',
                'friday_shortcut',
                'saturday_shortcut',
                'sunday_shortcut',
            ],
            days: [],
            update_data_request: true,
            date_start: first_day,
            date_stop: last_day,
            clubs: clubs,
            club_units: club_units,
            club: '',
            club_unit: '',
            device: device,
            replace_drill: false,
            replace_drill_form: '',
            coaches: [],
            drill_types: [],
            drills_control: {},
            substitute_coach: null,
            change_drill: '',
            change_drill_data: {},
        };
        this.props.updateData();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOut);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOut);
    }

    handleClickOut = (e) => {
      if (this.drill_control_ref &&
        this.drill_control_ref.current &&
            (!this.drill_control_ref.current.contains(e.target) || e.target.id === 'background_lock_id')
        // !['drill_control_btns_wrapper', 'control_drill_btn'].includes(e.target.className)
      ) {
        this.setState(
          {
            drills_control: Object.fromEntries(
              Object.entries(this.state.drills_control).map(
                (dc) => {return [dc[0], false]}
              )
            ),
            change_drill: '',
            replace_drill: false,
            replace_drill_form: '',
          }
        )
      }
    }

    handleShowDrillControl = (drill) => {
      var drills_control = Object.fromEntries(
        Object.entries(this.state.drills_control).map(
          (dc) => {return [dc[0], false]}
        )
      )
      drills_control[parseInt(drill.id)] = true
      this.setState(
        {
          drills_control: drills_control
        }
      )
    }

    handleRemoveSchedule = (schedule_id, drill_date) => {
      this.props.removeDrillSchedule(schedule_id, drill_date)
      this.setState(
        {
          change_drill: '',
          replace_drill: false,
          replace_drill_form: '',
          drills_control: Object.fromEntries(
            Object.entries(this.state.drills_control).map(
              (dc) => {return [dc[0], false]}
            )
          ),
        }
      )
    }

    handleDeclineChangeDrill = (e) => {
        this.setState({replace_drill: false, replace_drill_form: ''});
    }

    handleUpdateChangeDrill(data) {
      data["drill"] = this.state.change_drill
      this.setState({change_drill_data: data})
    }

    handleUpdateDrill = (data) => {
        data["drill"] = this.state.change_drill
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/traffic/')
        post_request(
            uri.href,
            data,
            this.props.catchError,
            'POST',
        ).then(json => {
            if (!json) {
                return
            }
            if (this.props.logged_in) {
                var drills = this.props.drills;
                for (let i in drills) {
                    if (parseInt(drills[i]["id"]) === json.id ) {
                        drills[i] = json;
                        break
                    }
                }
                this.setState(
                  {
                    drills_control: Object.fromEntries(
                      Object.entries(this.state.drills_control).map(
                        (dc) => {return [dc[0], false]}
                      )
                    ),
                    replace_drill: false,
                    replace_drill_form: '',
                  }, () => {
                    this.props.updateDrills(drills);
                  }
                )
            }
        })
    }

    renderDrillCoaches(coaches) {
        return coaches.map((coach) => {
            return (
                <li key={'drill_coach-' + coach.id}>{coach.first_name} {coach.last_name}</li>
            )
        })
    }

    renderDaySchedule(date) {
        return this.props.drills.map((drill) => {
            var drill_info_print = 'Записан';
            var arrive_print_container = 'arrive_print_container';
            var arrive_print_span = 'arrive_print_span';
            if (drill.completed) {
                drill_info_print = 'Завершена';
                arrive_print_container = 'arrive_print_container drill_complete_print_container';
                arrive_print_span = 'arrive_print_span drill_complete_print_span';
            }
            if (drill.drill_date === date && !drill.transfer_date && !drill.deleted) {
                return (
                    <div
                        key={'drill-' + drill.id}
                        id={'drill-' + drill.id + '-' + drill.drill.club.id}
                        className='drill_wrapper'
                        title="Г - Групповое занятие, П - Персональная тренировка, М - Мини-группа, С - Сплит тренировка"
                    >
                        {drill.client_arrive
                            ? <div
                                className='arrive_print_wrapper'
                                id={'arrive-' + drill.id + '-' + drill.drill.club.id}
                                onClick={this.props.handleDrillInfo}
                              >
                                  <div className={arrive_print_container}
                                       id={'arrive_container-' + drill.id + '-' + drill.drill.club.id}
                                  >
                                      <span className={arrive_print_span}
                                            id={'arrive_span-' + drill.id + '-' + drill.drill.club.id}
                                      >
                                        {drill_info_print}
                                      </span>
                                  </div>
                              </div>
                            : ''
                        }
                        {parseInt(localStorage.getItem('user_type')) > 2
                            ? <div
                                style={
                                    {
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        padding: '2px 4px',
                                        width: 'fit-content',
                                        border: '1px #f7941e solid',
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                    }
                                }
                                onClick={
                                    (e) => {
                                        this.props.handleChangeDrill(drill)   
                                    }
                                }
                              >
                                  &#9998;
                              </div>
                            : ''
                        }
                        <div
                          key={'drill-' + drill.id}
                          id={'drill-' + drill.id + '-' + drill.drill.club.id}
                          className='input_drill_wrapper'
                          onClick={this.props.handleDrillInfo}
                        >
                            {drill.substitute
                              ? <div
                                  key={'drill-substitute-' + drill.id}
                                  id={'drill-substitute-' + drill.id + '-' + drill.drill.club.id}
                                  className='decline_print_wrapper'
                                  onClick={
                                    (e) => {
                                      if (parseInt(localStorage.getItem('user_type')) > 2 ||
                                          parseInt(localStorage.getItem('pk')) === drill.substitute.id
                                      ) {
                                        this.props.handleDrillInfo(e)
                                      }
                                    }
                                  }
                                >
                                  <div
                                    key={'drill-substitute-label-' + drill.id}
                                    id={'drill-substitute-label-' + drill.id + '-' + drill.drill.club.id}
                                    className='decline_print_container'
                                    style={
                                      {
                                        borderColor: (parseInt(drill.substitute.id) === parseInt(localStorage.getItem('pk'))) ? '#01b1014f' : '#ff00004f',
                                      }
                                    }
                                    onClick={
                                      (e) => {
                                        if (parseInt(localStorage.getItem('user_type')) > 2 ||
                                            parseInt(localStorage.getItem('pk')) === drill.substitute.id
                                        ) {
                                          this.props.handleDrillInfo(e)
                                        }
                                      }
                                    }
                                  >
                                    <span
                                      className='print_span'
                                      style={
                                        {
                                          color: (parseInt(drill.substitute.id) === parseInt(localStorage.getItem('pk'))) ? '#01b1014f' : '#ff00004f',
                                        }
                                      }
                                    >
                                      Замена
                                    </span>
                                  </div>
                                </div>
                              : ''
                            }
                            <div key={'drill_club-' + drill.id} id={'drill_club-' + drill.id + '-' + drill.drill.club.id} className='club_name_wrapper'>
                                {drill.drill.club.name}
                            </div>
                            <div key={'drill_date-' + drill.id} id={'drill_date-' + drill.id + '-' + drill.drill.club.id} className='drill_time_wrapper'>
                                {drill.drill.drill_time_start} - {drill.drill.drill_time_stop}
                            </div>
                            <div key={'drill_discipline-' + drill.id} id={'drill_discipline-' + drill.id + '-' + drill.drill.club.id} className='discipline_wrapper'>
                                {drill.drill.discipline.name}
                            </div>
                            {!drill.substitute
                                ? <div key={'drill_coach-' + drill.id} id={'drill_coach-' + drill.id + '-' + drill.drill.club.id} className='coach_wrapper'>
                                      <ul className='drill_coaches_list'>{this.renderDrillCoaches(drill.coaches)}</ul>
                                  </div>
                                : <div key={'drill_coach-' + drill.id} id={'drill_coach-' + drill.id + '-' + drill.drill.club.id} className='coach_wrapper'>
                                      <ul className='drill_coaches_list'>
                                          <li key={'drill_coach-' + drill.substitute.id}>{drill.substitute.first_name} {drill.substitute.last_name}</li>
                                      </ul>
                                  </div>
                            }
                            <div key={'drill_clients-' + drill.id} id={'drill_clients-' + drill.id + '-' + drill.drill.club.id} className='discipline_wrapper'>
                                Клиентов: {drill.clients_number} <span>({drill.drill_type.name[0]})</span>
                            </div>
                        </div>
                        {parseInt(localStorage.getItem('user_type')) > 1
                            ? <div className='drill_correct_wrapper'>
                                  <button
                                    id={'add_client_ticket-' + drill.id}
                                    key={'add_client_ticket-' + drill.id}
                                    className='add_drill_btn correct_drill_info_btn'
                                    onClick={
                                        (e) => {this.handleShowDrillControl(drill)}
                                    }
                                  >
                                    >
                                  </button>
                              </div>
                            : ''
                        }
                        {parseInt(localStorage.getItem('user_type')) > 1 && this.state.drills_control[parseInt(drill.id)]
                            ? <div
                                className='drill_control_btns_wrapper'
                                id={'control_drill_btns-' + drill.id}
                                ref={this.drill_control_ref}
                              >
                                <input
                                  type='button'
                                  id={'replace_drill_btn-' + drill.id}
                                  data-drill_id={drill.id}
                                  value='Перенести'
                                  className='control_drill_btn'
                                  onClick={
                                    (e) => {
                                      this.setState(
                                        {
                                          change_drill: parseInt(drill.id),
                                          replace_drill: true,
                                          replace_drill_form: <CorrectDrill
                                            drill_id={drill.id}
                                            handleDeclineNewDrill={this.handleDeclineChangeDrill.bind(this)}
                                            updateDrills={this.props.updateDrills}
                                            handleReplaceDrill={this.handleUpdateDrill.bind(this)}
                                            handleUpdateChangeDrill={this.handleUpdateChangeDrill.bind(this)}
                                            drills={this.props.drills}
                                            type='replace'
                                            logged_in={this.props.logged_in}
                                            drill_control_ref={this.drill_control_ref}
                                          />
                                        }
                                      )
                                    }
                                  }
                                />
                                <input
                                  type='button'
                                  id={'remove_drill_btn-' + drill.id}
                                  value='Отменить'
                                  className='control_drill_btn'
                                  onClick={
                                    (e) => {
                                      this.setState(
                                        {
                                          change_drill: parseInt(drill.id),
                                          replace_drill: true,
                                          replace_drill_form: <CorrectDrill
                                            drill_id={drill.id}
                                            handleDeclineNewDrill={this.handleDeclineChangeDrill.bind(this)}
                                            updateDrills={this.props.updateDrills}
                                            handleReplaceDrill={this.handleUpdateDrill.bind(this)}
                                            handleUpdateChangeDrill={this.handleUpdateChangeDrill.bind(this)}
                                            drills={this.props.drills}
                                            type='delete'
                                            logged_in={this.props.logged_in}
                                            drill_control_ref={this.drill_control_ref}
                                          />
                                        }
                                      )
                                    }
                                  }
                                />
                                <input
                                  type='button'
                                  id={'replace_drill_btn-' + drill.id}
                                  value='Замена'
                                  className='control_drill_btn'
                                  onClick={
                                    (e) => {
                                      this.setState(
                                        {
                                          change_drill: parseInt(drill.id),
                                          replace_drill: true,
                                          replace_drill_form: <CorrectDrill
                                            drill_id={drill.id}
                                            handleDeclineNewDrill={this.handleDeclineChangeDrill.bind(this)}
                                            handleSubstituteDrill={this.handleUpdateDrill.bind(this)}
                                            updateDrills={this.props.updateDrills}
                                            handleUpdateChangeDrill={this.handleUpdateChangeDrill.bind(this)}
                                            drills={this.props.drills}
                                            type='substitute'
                                            logged_in={this.props.logged_in}
                                            catchError={this.props.catchError}
                                            drill_control_ref={this.drill_control_ref}
                                          />
                                        }
                                      )
                                    }
                                  }
                                />
                                {parseInt(localStorage.getItem('user_type')) > 2
                                  ? <input
                                      type='button'
                                      id={'replace_drill_btn-' + drill.id}
                                      data-drill_id={drill.id}
                                      data-schedule_id={drill.drill.id}
                                      data-drill_date={drill.drill_date}
                                      value='Удалить'
                                      className='control_drill_btn'
                                      onClick={
                                        (e) => {
                                          this.setState(
                                            {
                                              change_drill: parseInt(drill.id),
                                              replace_drill: true,
                                              replace_drill_form: <CorrectDrill
                                                drill_id={drill.id}
                                                schedule_id={drill.drill.id}
                                                drill_date={drill.drill_date}
                                                handleDeclineNewDrill={this.handleDeclineChangeDrill.bind(this)}
                                                handleRemoveDrill={this.handleRemoveSchedule.bind(this)}
                                                updateDrills={this.props.updateDrills}
                                                handleUpdateChangeDrill={this.handleUpdateChangeDrill.bind(this)}
                                                drills={this.props.drills}
                                                type='remove'
                                                logged_in={this.props.logged_in}
                                                drill_control_ref={this.drill_control_ref}
                                              />
                                            }
                                          )
                                        }
                                      }
                                    />
                                  : ''
                                }
                              </div>
                            : ''
                        }
                    </div>
                )
            }
            else if ((drill.drill_date === date && drill.transfer_date && drill.transfer_date !== date) ||
                    (drill.drill_date === date && !drill.transfer_date && drill.deleted) ||
                    (drill.drill_date === date && drill.transfer_date && drill.transfer_date !== date && drill.deleted)) {
                return (
                    <div
                        key={'transfer_drill-' + drill.id}
                        id={'transfer_drill-' + drill.id + '-' + drill.drill.club.id}
                        className='drill_wrapper'
                        title="Г - Групповое занятие, П - Персональная тренировка, М - Мини-группа, С - Сплит тренировка"
                    >
                        {drill.client_arrive
                            ? <div className='arrive_print_wrapper'
                                   id={'arrive-' + drill.id + '-' + drill.drill.club.id}
                                   onClick={this.props.handleDrillInfo}
                              >
                                  <div className={arrive_print_container}
                                       id={'arrive_container-' + drill.id + '-' + drill.drill.club.id}
                                  >
                                      <span className={arrive_print_span}
                                            id={'arrive_span-' + drill.id + '-' + drill.drill.club.id}
                                      >
                                        {drill_info_print}
                                      </span>
                                  </div>
                              </div>
                            : ''
                        }
                        <div className='input_drill_wrapper'>
                            <div className='decline_print_wrapper'>
                                {drill.transfer_date
                                    ? <div className='decline_print_container'>
                                          <span className='print_span'>Перенесена</span>
                                          <span className='print_span'>на</span>
                                          <span className='print_span'>{drill.transfer_date}</span>
                                      </div>
                                    : <div className='decline_print_container'>
                                          <span className='print_span'>Отменена</span>
                                      </div>
                                }
                            </div>
                            <div key={'transfer_drill_club-' + drill.id} id={'transfer_drill_club-' + drill.id + '-' + drill.drill.club.id} className='club_name_wrapper'>
                                {drill.drill.club.name}
                            </div>
                            <div key={'transfer_drill_date-' + drill.id} id={'transfer_drill_date-' + drill.id + '-' + drill.drill.club.id} className='drill_time_wrapper'>
                                {drill.drill.drill_time_start} - {drill.drill.drill_time_stop}
                            </div>
                            <div key={'transfer_drill_discipline-' + drill.id} id={'transfer_drill_discipline-' + drill.id + '-' + drill.drill.club.id} className='discipline_wrapper'>
                                {drill.drill.discipline.name}
                            </div>
                            <div key={'transfer_drill_coach-' + drill.id} id={'transfer_drill_coach-' + drill.id + '-' + drill.drill.club.id} className='coach_wrapper'>
                                <ul className='drill_coaches_list'>{this.renderDrillCoaches(drill.coaches)}</ul>
                            </div>
                            <div key={'transfer_drill_clients-' + drill.id} id={'transfer_drill_clients-' + drill.id + '-' + drill.drill.club.id} className='discipline_wrapper'>
                                Клиентов: {drill.clients_number} <span>({drill.drill_type.name[0]})</span>
                            </div>
                        </div>
                    </div>
                )
            }
            else if ((drill.drill_date !== date && drill.transfer_date && drill.transfer_date === date && !drill.deleted) ||
                    (drill.drill_date !== date && drill.transfer_date && drill.transfer_date === date && drill.deleted)) {
                return (
                    <div
                        title="Г - Групповое занятие, П - Персональная тренировка, М - Мини-группа, С - Сплит тренировка"
                    >
                        <div key={'drill-' + drill.id} id={'drill-' + drill.id + '-' + drill.drill.club.id} className='drill_wrapper'>
                            {drill.client_arrive
                                ? <div className='arrive_print_wrapper'
                                       id={'arrive-' + drill.id + '-' + drill.drill.club.id}
                                       onClick={this.props.handleDrillInfo}
                                  >
                                      <div className='arrive_print_container'
                                           id={'arrive_container-' + drill.id + '-' + drill.drill.club.id}
                                      >
                                          <span className='arrive_print_span'
                                                id={'arrive_span-' + drill.id + '-' + drill.drill.club.id}
                                          >
                                            Записан
                                          </span>
                                      </div>
                                  </div>
                                : ''
                            }
                            <div className='input_drill_wrapper' onClick={this.props.handleDrillInfo}>
                                <div className='decline_print_wrapper'>
                                    {!drill.deleted
                                        ? <div className='decline_print_container'>
                                              <span className='print_span'>Перенесен-</span>
                                              <span className='print_span'>ная</span>
                                          </div>
                                        : <div className='decline_print_container'>
                                              <span className='print_span'>Отменена</span>
                                          </div>
                                    }
                                </div>
                                <div key={'drill_club-' + drill.id} id={'drill_club-' + drill.id + '-' + drill.drill.club.id} className='club_name_wrapper'>
                                    {drill.drill.club.name}
                                </div>
                                <div key={'drill_date-' + drill.id} id={'drill_date-' + drill.id + '-' + drill.drill.club.id} className='drill_time_wrapper'>
                                    {drill.transfer_time.drill_time_start} - {drill.transfer_time.drill_time_stop}
                                </div>
                                <div key={'drill_discipline-' + drill.id} id={'drill_discipline-' + drill.id + '-' + drill.drill.club.id} className='discipline_wrapper'>
                                    {drill.drill.discipline.name}
                                </div>
                                <div key={'drill_coach-' + drill.id} id={'drill_coach-' + drill.id + '-' + drill.drill.club.id} className='coach_wrapper'>
                                    <ul className='drill_coaches_list'>{this.renderDrillCoaches(drill.coaches)}</ul>
                                </div>
                                <div key={'drill_clients-' + drill.id} id={'drill_clients-' + drill.id + '-' + drill.drill.club.id} className='discipline_wrapper'>
                                    Клиентов: {drill.clients_number} <span>({drill.drill_type.name[0]})</span>
                                </div>
                            </div>
                            {parseInt(localStorage.getItem('user_type')) > 1
                                ? <div className='drill_correct_wrapper'>
                                    <button id={'add_client_ticket-' + drill.id} key={'add_client_ticket-' + drill.id} className='add_drill_btn correct_drill_info_btn'
                                        data-drill_id={drill.id} onClick={this.handleShowDrillControl} >></button>
                                </div>
                                : ''
                            }
                            {parseInt(localStorage.getItem('user_type')) > 1
                                ? <div className='drill_control_btns_wrapper' id={'control_drill_btns-' + drill.id} ref={this.drill_control_ref}>
                                      <input
                                        type='button'
                                        id={'replace_drill_btn-' + drill.id}
                                        data-drill_id={drill.id}
                                        value='Перенести'
                                        className='control_drill_btn'
                                        onClick={
                                          (e) => {
                                            this.setState(
                                              {
                                                change_drill: parseInt(drill.id),
                                                replace_drill: true,
                                                replace_drill_form: <CorrectDrill
                                                  drill_id={drill.id}
                                                  handleDeclineNewDrill={this.handleDeclineChangeDrill.bind(this)}
                                                  updateDrills={this.props.updateDrills}
                                                  handleReplaceDrill={this.handleUpdateDrill.bind(this)}
                                                  handleUpdateChangeDrill={this.handleUpdateChangeDrill.bind(this)}
                                                  drills={this.props.drills}
                                                  type='replace'
                                                  logged_in={this.props.logged_in}
                                                  drill_control_ref={this.drill_control_ref}
                                                />
                                              }
                                            )
                                          }
                                        }
                                      />
                                      <input
                                        type='button'
                                        id={'remove_drill_btn-' + drill.id}
                                        data-drill_id={drill.id}
                                        value='Отменить'
                                        className='control_drill_btn'
                                        onClick={
                                          (e) => {
                                            this.setState(
                                              {
                                                change_drill: parseInt(drill.id),
                                                replace_drill: true,
                                                replace_drill_form: <CorrectDrill
                                                  drill_id={drill.id}
                                                  handleDeclineNewDrill={this.handleDeclineChangeDrill.bind(this)}
                                                  updateDrills={this.props.updateDrills}
                                                  handleReplaceDrill={this.handleUpdateDrill.bind(this)}
                                                  handleUpdateChangeDrill={this.handleUpdateChangeDrill.bind(this)}
                                                  drills={this.props.drills}
                                                  type='delete'
                                                  logged_in={this.props.logged_in}
                                                  drill_control_ref={this.drill_control_ref}
                                                />
                                              }
                                            )
                                          }
                                        }
                                      />
                                      <input
                                        type='button'
                                        id={'replace_drill_btn-' + drill.id}
                                        data-drill_id={drill.id}
                                        value='Замена'
                                        className='control_drill_btn'
                                        onClick={
                                          (e) => {
                                            this.setState(
                                              {
                                                change_drill: parseInt(drill.id),
                                                replace_drill: true,
                                                replace_drill_form: <CorrectDrill
                                                  drill_id={drill.id}
                                                  handleDeclineNewDrill={this.handleDeclineChangeDrill.bind(this)}
                                                  handleSubstituteDrill={this.handleUpdateDrill.bind(this)}
                                                  updateDrills={this.props.updateDrills}
                                                  handleUpdateChangeDrill={this.handleUpdateChangeDrill.bind(this)}
                                                  drills={this.props.drills}
                                                  type='substitute'
                                                  logged_in={this.props.logged_in}
                                                  catchError={this.props.catchError}
                                                  drill_control_ref={this.drill_control_ref}
                                                />
                                              }
                                            )
                                          }
                                        }
                                      />
                                      {parseInt(localStorage.getItem('user_type')) > 2
                                        ? <input
                                            type='button'
                                            id={'replace_drill_btn-' + drill.id}
                                            data-drill_id={drill.id}
                                            data-schedule_id={drill.drill.id}
                                            data-drill_date={drill.drill_date}
                                            value='Удалить'
                                            className='control_drill_btn'
                                            onClick={
                                              (e) => {
                                                this.setState(
                                                  {
                                                    change_drill: parseInt(drill.id),
                                                    replace_drill: true,
                                                    replace_drill_form: <CorrectDrill
                                                      drill_id={drill.id}
                                                      schedule_id={drill.drill.id}
                                                      drill_date={drill.drill_date}
                                                      handleDeclineNewDrill={this.handleDeclineChangeDrill.bind(this)}
                                                      handleRemoveDrill={this.handleRemoveSchedule.bind(this)}
                                                      updateDrills={this.props.updateDrills}
                                                      handleUpdateChangeDrill={this.handleUpdateChangeDrill.bind(this)}
                                                      drills={this.props.drills}
                                                      type='remove'
                                                      logged_in={this.props.logged_in}
                                                      drill_control_ref={this.drill_control_ref}
                                                    />
                                                  }
                                                )
                                              }
                                            }
                                          />
                                        : ''
                                      }
                                  </div>
                                : ''
                            }
                        </div>
                    </div>
                )
            }
            else if (drill.drill_date === date && drill.transfer_date && drill.transfer_date === date) {
                return (
                    <div
                        title="Г - Групповое занятие, П - Персональная тренировка, М - Мини-группа, С - Сплит тренировка"
                    >
                    <div key={'transfer_drill-' + drill.id} id={'transfer_drill-' + drill.id + '-' + drill.drill.club.id} className='drill_wrapper'>
                        <div className='input_drill_wrapper'>
                            <div className='decline_print_wrapper'>
                                <div className='decline_print_container'>
                                    <span className='print_span'>Перенесена</span>
                                    <span className='print_span'>на</span>
                                    <span className='print_span'>{drill.transfer_time.drill_time_start}</span>
                                </div>
                            </div>
                            <div key={'transfer_drill_club-' + drill.id} id={'transfer_drill_club-' + drill.id + '-' + drill.drill.club.id} className='club_name_wrapper'>
                                {drill.drill.club.name}
                            </div>
                            <div key={'transfer_drill_date-' + drill.id} id={'transfer_drill_date-' + drill.id + '-' + drill.drill.club.id} className='drill_time_wrapper'>
                                {drill.drill.drill_time_start} - {drill.drill.drill_time_stop}
                            </div>
                            <div key={'transfer_drill_discipline-' + drill.id} id={'transfer_drill_discipline-' + drill.id + '-' + drill.drill.club.id} className='discipline_wrapper'>
                                {drill.drill.discipline.name}
                            </div>
                            <div key={'transfer_drill_coach-' + drill.id} id={'transfer_drill_coach-' + drill.id + '-' + drill.drill.club.id} className='coach_wrapper'>
                                <ul className='drill_coaches_list'>{this.renderDrillCoaches(drill.coaches)}</ul>
                            </div>
                            <div key={'transfer_drill_clients-' + drill.id} id={'transfer_drill_clients-' + drill.id + '-' + drill.drill.club.id} className='discipline_wrapper'>
                                Клиентов: {drill.clients_number} <span>({drill.drill_type.name[0]})</span>
                            </div>
                        </div>
                    </div>
                    <div key={'drill-' + drill.id} id={'drill-' + drill.id + '-' + drill.drill.club.id} className='drill_wrapper'>
                        <div className='input_drill_wrapper'>
                            <div className='decline_print_wrapper'>
                                <div className='decline_print_container'>
                                    <span className='print_span'>Отменена</span>
                                </div>
                            </div>
                            <div key={'drill_club-' + drill.id} id={'drill_club-' + drill.id + '-' + drill.drill.club.id} className='club_name_wrapper'>
                                {drill.drill.club.name}
                            </div>
                            <div key={'drill_date-' + drill.id} id={'drill_date-' + drill.id + '-' + drill.drill.club.id} className='drill_time_wrapper'>
                                {drill.transfer_time.drill_time_start} - {drill.transfer_time.drill_time_stop}
                            </div>
                            <div key={'drill_discipline-' + drill.id} id={'drill_discipline-' + drill.id + '-' + drill.drill.club.id} className='discipline_wrapper'>
                                {drill.drill.discipline.name}
                            </div>
                            <div key={'drill_coach-' + drill.id} id={'drill_coach-' + drill.id + '-' + drill.drill.club.id} className='coach_wrapper'>
                                <ul className='drill_coaches_list'>{this.renderDrillCoaches(drill.coaches)}</ul>
                            </div>
                            <div key={'drill_clients-' + drill.id} id={'drill_clients-' + drill.id + '-' + drill.drill.club.id} className='discipline_wrapper'>
                                Клиентов: {drill.clients_number} <span>({drill.drill_type.name[0]})</span>
                            </div>
                        </div>
                    </div>
                    </div>
                )
            }
            return (
                ''
            )
        })
    }

    renderDrillsArray() {
        var days = [];
        if (this.props.drills && this.props.drills[0] !== undefined) {
            if (this.props.date_start === '') {
                days.push({weekday: this.props.drills[0]['drill']['weekday'], date: this.props.drills[0]['drill_date']});
            }
            else {
                var d = new Date(this.props.date_start);
                var day = d.getDay();
                if (day < 1) {
                    day = 7;
                }
                days.push({weekday: day, date: d.toISOString().split('T')[0]});
            }
            var day_num = 6;
            if (this.state.device === "mobile") {
                day_num = 0;
            }
            for (let i = 0; i < day_num; i++) {
                var next_day = days[i]['weekday'] + 1;
                if (next_day > 7) {
                    next_day = 1;
                }
                var next_date = new Date(days[i]['date']);
                next_date.setDate(next_date.getDate() + 1);
                next_date = next_date.toISOString().split('T')[0];
                days.push({weekday: next_day, date: next_date});
            }
        }
        return days.map((day, index) => {
            return (
                <div className='calendar_cell' key={'calendar-' + day + '-' + index}>
                    <div className='weekday_wrapper'>
                        <span className='weekday_text'>{locales.week[this.state.weekday[day.weekday - 1]]}</span> <span className='date_text'>{day.date}</span>
                    </div>
                    {this.renderDaySchedule(day.date)}
                </div>
            )
        })
    }

    renderClubUnitsList() {
        return this.state.club_units.map((club_unit) => {
            return (
                <option key={'club_units_list-' + club_unit.id} id={'club_units_list-' + club_unit.id}
                        data-ticket={club_unit.id}>
                    {club_unit.name}
                </option>
            )
        })
    }

    renderClubsList() {
        return this.state.clubs.map((club) => {
            return (
                <option key={'clubs_list-' + club.id} id={'clubs_list-' + club.id}
                        data-ticket={club.id}>
                    {club.name}
                </option>
            )
        })
    }

    renderFiltersList() {
        if (parseInt(localStorage.getItem('user_type')) > 0) {
            return (
                <div className='users_filters_wrapper'>
                    <div
                        className='show_filters_btn_wrapper calendar_filters_wrapper'
                    >
                        <div
                            style={
                                {
                                    width: '50%',
                                }
                            }
                        >
                            <input
                                key='calendar_week_start_key'
                                id='calendar_week_start_id'
                                type='date'
                                placeholder='Дата начала'
                                className='add_ticket_btn'
                                style={
                                  {
                                      float: 'left',
                                      marginLeft: '10vw',
                                  }
                                }
                                defaultValue={this.props.date_start}
                                onChange={
                                    compose(
                                        _.debounce(this.props.handleChangeStartStop, 1000),
                                        _.clone
                                    )
                                }
                            />
                        </div>
                        <div
                            className='calendar_filters_btn_wrapper'
                            style={
                                {
                                    width: '50%',
                                }
                            }
                        >
                            <input
                                type='button'
                                className='add_ticket_btn'
                                id='show_filters_btn_id'
                                onClick={this.handleShowFilters}
                                value={locales.filters.filters}
                                style={
                                  {
                                      float: 'right',
                                      marginRight: '10vw',
                                  }
                                }
                            />
                        </div>
                    </div>
                    <div className='filters_list_wrapper calendar_filters_list_wrapper' id={this.state.device + '_filters_list_id'}>
                        <div className='filter_btn_wrapper'>
                            <Select
                                options={
                                    Array.from(this.state.clubs, (val) => new Object(
                                        {
                                            "value": val.id,
                                            "label": val.name,
                                            "type": "club",
                                        }
                                    ))
                                }
                                onChange={this.props.handleApplyFilters}
                                styles={select_styles}
                                placeholder={locales.filters.club}
                            />
                        </div>
                        <div className='filter_btn_wrapper'>
                            <Select
                                options={
                                    Array.from(this.state.club_units, (val) => new Object(
                                        {
                                            "value": val.id,
                                            "label": val.name,
                                            "type": "club_unit",
                                        }
                                    ))
                                }
                                onChange={this.props.handleApplyFilters}
                                styles={select_styles}
                                placeholder={locales.filters.club_unit}
                            />
                        </div>
                        <div className='filter_btn_wrapper'>
                            <Select
                                options={
                                    Array.from(this.state.drill_types, (val) => new Object(
                                        {
                                            "value": val.id,
                                            "label": val.name,
                                            "type": "drill_type",
                                        }
                                    ))
                                }
                                onChange={this.props.handleApplyFilters}
                                styles={select_styles}
                                placeholder={locales.filters.drill_type}
                            />
                        </div>
                        {parseInt(localStorage.getItem("user_type")) !== 1
                            ? <div className='filter_btn_wrapper'>
                                  <Select
                                      options={
                                          Array.from(this.state.coaches, (val) => new Object(
                                              {
                                                  "value": val.id,
                                                  "label": val.first_name + ' ' + val.last_name,
                                                  "type": "coach",
                                              }
                                          ))
                                      }
                                      onChange={this.props.handleApplyFilters}
                                      styles={select_styles}
                                      placeholder={locales.filters.coach}
                                  />
                              </div>
                            : <div></div>
                        }
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='users_filters_wrapper'>
                    <div className='show_filters_btn_wrapper'>
                        {this.props.client_relevant_drills
                            ? <input type='button' className='add_ticket_btn' id='full_schedule_btn_id' onClick={this.props.handleClientFullDrillList} value={locales.filters.all_drills} />
                            : <input type='button' className='add_ticket_btn' id='short_schedule_btn_id' onClick={this.props.handleClientFullDrillList} value={locales.filters.ticket_drills} />
                        }
                    </div>
                </div>
            )
        }
    }

    handleShowFilters = (e) => {
        var filters_list = document.getElementById(this.state.device + '_filters_list_id');
        if (filters_list.style.display === 'flex' || filters_list.style.display === 'block') {
            filters_list.style.display = 'none';
        }
        else {
            if (this.state.clubs.length === 0 || this.state.club_units.length === 0) {
                var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/filters_info/')
                get_request(uri.href, this.props.catchError).then(json => {
                    var clubs = []
                    var club_units = []
                    for (let i = 0; i < json.clubs.length; i++) {
                        clubs.push({ id: json.clubs[i]['id'], name: json.clubs[i]['name'] });
                        for (let j = 0; j < json.clubs[i]['units'].length; j++) {
                            club_units.push({ id: json.clubs[i]['units'][j]['id'], name: json.clubs[i]['units'][j]['name'] });
                        }
                    }
                    this.setState(
                        {
                            disciplines: json.disciplines,
                            coaches: json.coaches,
                            club_tickets: json.tickets,
                            clubs: clubs,
                            club_units: club_units,
                            drill_types: (json.drill_types) ? json.drill_types : [],
                        },
                        () => {
                            if (this.state.device === 'desktop') {
                                filters_list.style.display = 'flex';
                            }
                            else if (this.state.device === 'mobile') {
                                filters_list.style.display = 'block';
                            }
                    });
                });
            }
            else {
                if (this.state.device === 'desktop') {
                    filters_list.style.display = 'flex';
                }
                else if (this.state.device === 'mobile') {
                    filters_list.style.display = 'block';
                }
            }
        }
    }

    handleChangeStartStop = (e) => {
        var first_day = new Date(this.state.date_start);
        var last_day = new Date(this.state.date_stop);
        if (e.target.id === 'day-back-arrow') {
            first_day.setDate(first_day.getDate() - 1);
            last_day.setDate(last_day.getDate() - 1);
        }
        else if (e.target.id === 'day-forward-arrow') {
            first_day.setDate(first_day.getDate() + 1);
            last_day.setDate(last_day.getDate() + 1);
        }
        this.setState({date_start: first_day.toISOString(), date_stop: last_day.toISOString()}, () => { this.props.updateData(); });
    }

    render() {
        return (
        <div>
            {this.renderFiltersList()}
            <div className='calendar_wrapper'>
                <div className='calendar_arrow_wrapper left_arrow_wrapper'>
                  <div id="day-back-arrow" className='calendar_arrow' onClick={this.props.handleChangeStartStop}>
                      &#10094;
                  </div>
                </div>
                <div className='calendar_cells_wrapper'>
                    {this.renderDrillsArray()}
                </div>
                <div className='calendar_arrow_wrapper right_arrow_wrapper'>
                  <div id="day-forward-arrow" className='calendar_arrow' onClick={this.props.handleChangeStartStop}>
                      &#10095;
                  </div>
                </div>
            </div>
            {parseInt(localStorage.getItem('user_type')) > 0
                ? <div className='add_drill_wrapper'>
                      <div className='add_drill_btn plus_btn' id='add_drill_btn_id' onClick={this.props.handleAddDrill}>
                          &#10010;
                      </div>
                  </div>
                : ''
            }
            {this.state.replace_drill
                ? <div className='new_drill_modal_wrapper'>
                    {this.state.replace_drill_form}
                  </div>
                : <div></div>
            }
            {this.props.remove_schedule_accept
                ? <AcceptRemoveScheduleDrill
                    drill_id={this.props.remove_drill_id}
                    handleDeclineRemoveDrillSchedule={this.props.handleDeclineRemoveDrillSchedule}
                    remove_drill_modal_ref={this.props.modal_ref}
                    removeDrillSchedule={this.props.removeDrillSchedule}
                />
                : ''
            }
        </div>
        )
    }
}

class CorrectDrill extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            coaches: [],
            change_data: {},
        };
        if (this.props.type === 'substitute') {
            this.getCoaches();
        }
    }

    getCoaches() {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_coaches/')
        uri.searchParams.set('drill', this.props.drill_id)
        get_request(uri.href, this.props.catchError).then(json => {
            if (json === undefined) {
                this.props.catchError(401);
                return
            }
            if (this.props.logged_in) {
                this.setState({coaches: json});
            }
        })
    }

    handleCheckReasonExist = (e) => {
        var elem = e.target;
        if (!elem.value) {
            elem.classList.remove('success_border');
            elem.classList.add('warning_border');
        }
        else {
            elem.classList.remove('warning_border');
            elem.classList.add('success_border');
        }
    }

    render() {
        if (this.props.type === 'replace') {
            return (
                <div
                    className='background_lock_wrapper'
                    id='background_lock_id'
                    ref={this.props.drill_control_ref}
                >
                    <div className='new_drill_form_wrapper'>
                        <div className='drill_time_wrapper'>
                            <label className='modal_label'>Выбери новую дату тренировки</label>
                            <input
                              key='single_drill_date'
                              id='new_single_drill_date'
                              type='datetime-local'
                              placeholder='Дата тренировки'
                              className='add_ticket_btn'
                            />
                        </div>
                        <button
                          id='add-drill'
                          key='drill-accept'
                          className='drill_traffic_btn'
                          onClick={
                            (e) => {
                              this.props.handleReplaceDrill(
                                {transfer_date: document.getElementById('new_single_drill_date').value}
                              )
                            }
                          }
                        >
                          &#10004;
                        </button>
                        <button id='decline-drill' key='drill-decline' className='drill_traffic_btn' onClick={this.props.handleDeclineNewDrill}>&#10008;</button>
                    </div>
                </div>
            )
        }
        else if (this.props.type === 'delete') {
            return (
                <div
                    className='background_lock_wrapper'
                    id='background_lock_id'
                    ref={this.props.drill_control_ref}
                >
                    <div className='new_drill_form_wrapper'>
                        <div className='drill_time_wrapper'>
                            Отменить тренировку?
                        </div>
                        <button
                          id='add-drill'
                          key='drill-accept'
                          className='drill_traffic_btn'
                          onClick={
                            (e) => {
                              this.props.handleReplaceDrill(
                                {deleted: true}
                              )
                            }
                          }
                        >
                          &#10004;
                        </button>
                        <button id='decline-drill' key='drill-decline' className='drill_traffic_btn' onClick={this.props.handleDeclineNewDrill}>&#10008;</button>
                    </div>
                </div>
            )
        }
        else if (this.props.type === 'remove') {
            return (
                <div
                    className='background_lock_wrapper'
                    id='background_lock_id'
                    ref={this.props.drill_control_ref}
                >
                    <div className='new_drill_form_wrapper'>
                        <div className='drill_time_wrapper'>
                            Удалить все последующие тренировки из расписания?
                        </div>
                        <button
                          id='add-drill'
                          key='drill-accept'
                          className='drill_traffic_btn'
                          onClick={
                            (e) => {
                              this.props.handleRemoveDrill(
                                  this.props.schedule_id,
                                  this.props.drill_date,
                              )
                            }
                          }
                        >
                          &#10004;
                        </button>
                        <button id='decline-drill' key='drill-decline' className='drill_traffic_btn' onClick={this.props.handleDeclineNewDrill}>&#10008;</button>
                    </div>
                </div>
            )
        }
        else if (this.props.type === 'substitute') {
            return (
                <div
                    className='background_lock_wrapper'
                    id='background_lock_id'
                    ref={this.props.drill_control_ref}
                >
                    <div className='new_drill_form_wrapper'>
                        <div className='drill_time_wrapper'>
                        <div
                          className='character_selector_drill_wrapper'
                          style={
                            {
                              width: '80%',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }
                          }
                        >
                            <Select
                                options={
                                    Array.from(
                                      this.state.coaches, (val) => new Object(
                                        {
                                          "value": val.id,
                                          "label": val.first_name + " " + val.last_name,
                                        }
                                      )
                                    )
                                }
                                onChange={
                                    (e) => {
                                        this.setState(
                                            {
                                                change_data: {substitute: (e) ? e["value"] : null},
                                            }
                                        )
                                    }
                                }
                                styles={select_styles}
                                isClearable={true}
                                menuPosition={'fixed'}
                                placeholder={"Выбери тренера на замену"}
                            />
                        </div>
                        </div>
                        <button
                          id='add-drill'
                          key='drill-accept'
                          className='drill_traffic_btn'
                          onClick={
                            (e) => {
                              var data = this.state.change_data
                              if (Object.keys(data).length === 0) {
                                data["substitute"] = null
                              }
                              this.props.handleSubstituteDrill(data)
                            }
                          }
                        >
                          &#10004;
                        </button>
                        <button id='decline-drill' key='drill-decline' className='drill_traffic_btn' onClick={this.props.handleDeclineNewDrill}>&#10008;</button>
                    </div>
                </div>
            )
        }
    }
}

class AcceptRemoveScheduleDrill extends(React.Component) {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_ref}>
                <div className='new_drill_form_wrapper'>
                    <h3>Удалить тренировку из расписания?</h3>
                    <button id='remove-client-accept' key='remove-client-accept' className='drill_traffic_btn' data-drill_id={this.props.drill_id} onClick={this.props.removeDrillSchedule}>&#10004;</button>
                    <button id='remove-client-decline' key='remove-client-decline' className='drill_traffic_btn' onClick={this.props.handleDeclineRemoveDrillSchedule}>&#10008;</button>
                </div>
            </div>
        )
    }
}

export default Calendar
