import React from 'react';
import Spinner from './Spinner';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

class TicketChangeRequest extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            requests: [],
            lock_spinner: false,
            uri: process.env.REACT_APP_BACKEND_URI + '/api/v1/user/ticket_changes/',
            sort: '-created_at',
            limit: 20,
            offset: 0,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        if (!this.state.uri) {
            return
        }
        this.setState(
            {
                lock_spinner: true,
            }, () => {
              var uri = new URL(this.state.uri);
              uri.searchParams.set("limit", this.state.limit);
              uri.searchParams.set("offset", this.state.offset);
              fetch(uri.href, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('token')}`
                }
              })
              .then(res => {
                  if (res.status === 401) {
                      this.props.catchError(res.status);
                      return
                  }
                  const response = res.json();
                  return response;
              })
              .then(json => {
                  if (this.props.logged_in) {
                      var uri = null;
                      var limit = 0;
                      var offset = 0;
                      if (json.next) {
                          uri = new URL(json.next);
                          limit = uri.limit;
                          offset = uri.offset;
                      }
                      var requests = json.results;
                      this.setState(
                          {
                              requests: this.state.requests.concat(requests),
                              uri: uri,
                              lock_spinner: false,
                              limit: limit,
                              offset: offset,
                          }
                      );
                  }
              })
            }
        )
    }

    handleChangeStatus(status, id) {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/ticket_changes/' + id + '/');
                var data = {status: status};
                fetch(uri.href, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                  },
                  body: JSON.stringify(data)
                })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    else if (res.status === 400 || res.status === 409) {
                        this.setState(
                            {
                                lock_spinner: false,
                            }, () => {
                                document.getElementById('toast-body-id').innerHTML = "Есть другие запросы на изменение этого абонемента";
                                var x = document.getElementById("toast-wrapper-id");
                                x.className = 'show';
                                setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                            }
                        )
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (!json) {
                        return false
                    }
                    if (this.props.logged_in) {
                        var requests = this.state.requests;
                        for (let i = 0; i < requests.length; i++) {
                            if (parseInt(requests[i]['id']) === parseInt(json.id)) {
                                requests.splice(i, 1);
                                break
                            }
                        }
                        this.setState(
                            {
                                requests: requests,
                                lock_spinner: false,
                            }
                        )
                    }
                })
            }
        )
    }

    renderTableBody() {
        return this.state.requests.map((request) => {
            var creator_name = '';
            if (request.created_by) {
                creator_name = request.created_by.first_name + ' ' + request.created_by.last_name;
            }
            var client_name = request.user.first_name + ' ' + request.user.last_name;
            var date_obj = new Date(request.created_at);
            var request_date = date_obj.toISOString().split('T')[0] + ' ' + date_obj.toISOString().split('T')[1].split('.')[0];
            return (
                <div
                    key={'change_request-' + request.id}
                    className='client_row_wrapper'
                >
                    <div
                        className='client_col_name_wrapper'
                        style={
                            {
                                width: '17%',
                            }
                        }
                    >
                        <div
                            className='clients_row_name_wrapper'
                        >
                            <div
                                key={'reject_request-' + request.id}
                                id={'reject_request-' + request.id}
                                data-request_id={request.id}
                                className='notify_client_btn'
                                style={
                                    {
                                        display: 'flex',
                                        alignItems: 'center',
                                    }
                                }
                            >
                                <img
                                    src='./icons/decline_color.png'
                                    alt='notify'
                                    width='15px'
                                    data-request_id={request.id}
                                    style={
                                        {
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }
                                    }
                                    onClick={
                                        (e) => {
                                            this.handleChangeStatus(3, parseInt(e.target.dataset.request_id));
                                        }
                                    }
                                />
                            </div>
                            <div
                                key={'apply_request-' + request.id}
                                id={'apply_request-' + request.id}
                                data-request_id={request.id}
                                className='notify_client_btn'
                                style={
                                    {
                                        display: 'flex',
                                        alignItems: 'center',
                                    }
                                }
                            >
                                <img
                                    src='./icons/apply_color.png'
                                    alt='notify'
                                    width='15px'
                                    data-request_id={request.id}
                                    style={
                                        {
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }
                                    }
                                    onClick={
                                        (e) => {
                                            this.handleChangeStatus(2, parseInt(e.target.dataset.request_id));
                                        }
                                    }
                                />
                            </div>
                            <div
                                className='client_row_name'
                                style={
                                    {
                                        marginLeft: '0.5rem',
                                    }
                                }
                            >
                                {creator_name}
                            </div>
                        </div>
                    </div>
                    <div
                        className='client_col_name_wrapper'
                        style={
                            {
                                width: '17%',
                            }
                        }
                    >
                        {client_name}
                    </div>
                    <div
                        className='client_col_name_wrapper'
                        style={
                            {
                                width: '17%',
                            }
                        }
                    >
                        {request.ticket}
                    </div>
                    <div
                        className='client_col_comment_wrapper'
                        style={
                            {
                                width: '33%',
                            }
                        }
                    >
                        {request.reason}
                    </div>
                    <div
                        className='client_col_comment_wrapper'
                        style={
                            {
                                width: '8%',
                            }
                        }
                    >
                        {request_date}
                    </div>
                    <div
                        className='client_col_comment_wrapper'
                        style={
                            {
                                width: '8%',
                            }
                        }
                    >
                        <div
                            style={
                                {
                                    padding: '2px',
                                    border: '1px solid #f7941e',
                                    cursor: 'pointer',
                                }
                            }
                        >
                            <a href={'/ticket_changing/' + request.id}>Подробнее</a>
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        return (
            <div>
                <div className='table_container'>
                    <div className='table_wrapper'>
                        <div className="table_head_container_wrapper">
                            <div
                                key='req-0'
                                className='clients_table_container_border clients_table_container_head'
                                style={
                                    {
                                        width: '17%',
                                    }
                                }
                            >
                                Автор
                            </div>
                            <div
                                key='req-1'
                                className='clients_table_container_border clients_table_container_head'
                                style={
                                    {
                                        width: '17%',
                                    }
                                }
                            >
                                Клиент
                            </div>
                            <div
                                key='req-2'
                                className='clients_table_container_border clients_table_container_head'
                                style={
                                    {
                                        width: '17%',
                                    }
                                }
                            >
                                Абонемент
                            </div>
                            <div
                                key='req-3'
                                className='clients_table_container_border clients_table_container_head'
                                style={
                                    {
                                        width: '33%',
                                    }
                                }
                            >
                                Описание
                            </div>
                            <div
                                key='req-4'
                                className='clients_table_container_border clients_table_container_head'
                                style={
                                    {
                                        width: '8%',
                                    }
                                }
                            >
                                Дата запроса
                            </div>
                            <div
                                key='req-5'
                                className='clients_table_container_border clients_table_container_head'
                                style={
                                    {
                                        width: '8%',
                                    }
                                }
                            >
                                <div>
                                    Подробнее
                                </div>
                            </div>
                        </div>
                        <div className="table_rows_container_wrapper">
                            {this.renderTableBody()}
                        </div>
                    </div>
                </div>
                {this.state.lock_spinner
                    ? <Spinner />
                    : ''
                }
            </div>
        )
    }
}


class FullTicketChangeRequest extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            id: window.location.pathname.split('/')[2],
            lock_spinner: false,
            changes: {},
            user_ticket: {},
            keys: {
                'ticket': 'Абонемент',
                'discount': 'Скидка',
                'discipline': 'Дисциплины',
                'coaches': 'Тренеры',
                'start_date': 'Дата начала',
                'expire_date': 'Дата окончания',
                'active': 'Активен',
                'payed': 'Оплачен',
                'frozen': 'Заморожен',
            }
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
              var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/ticket_changes/' + this.state.id + '/');
              fetch(uri.href, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('token')}`
                }
              })
              .then(res => {
                  if (res.status === 401) {
                      this.props.catchError(res.status);
                      return
                  }
                  const response = res.json();
                  return response;
              })
              .then(json => {
                  if (this.props.logged_in) {
                      var changes = json;
                      var user_ticket = json.user_ticket;
                      delete changes['user_ticket'];
                      this.setState(
                          {
                            changes: changes,
                            user_ticket: user_ticket,
                            lock_spinner: false,
                          }
                      );
                  }
              })
            }
        )
    }

    handleChangeStatus(status) {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/ticket_changes/' + this.state.id + '/');
                var data = {status: status};
                fetch(uri.href, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                  },
                  body: JSON.stringify(data)
                })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    else if (res.status === 400 || res.status === 409) {
                        this.setState(
                            {
                                lock_spinner: false,
                            }, () => {
                                document.getElementById('toast-body-id').innerHTML = "Есть другие запросы на изменение этого абонемента4";
                                var x = document.getElementById("toast-wrapper-id");
                                x.className = 'show';
                                setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                            }
                        )
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (!json) {
                        return false
                    }
                    if (this.props.logged_in) {
                        window.location.replace('/ticket_changing');
                    }
                })
            }
        )
    }

    renderTicketDifference() {
        if (Object.keys(this.state.changes).length === 0) {
            return
        }
        return Object.keys(this.state.keys).map((key) => {
            var change_value = this.state.changes[key];
            var current_value = this.state.user_ticket[key];
            if (key == 'discipline') {
                change_value = this.state.changes[key]['name'];
                current_value = this.state.user_ticket[key]['name'];
            }
            if (key == 'coaches') {
                change_value = Array.from(this.state.changes[key], (coach) => coach.first_name + ' ' + coach.last_name).toString();
                current_value = Array.from(this.state.user_ticket[key], (coach) => coach.first_name + ' ' + coach.last_name).toString();
            }
            if (typeof(this.state.changes[key]) == 'boolean') {
                change_value = (this.state.changes[key]) ? '✔' : '✘';
                current_value = (this.state.user_ticket[key]) ? '✔' : '✘';
            }
            var style = {};
            if (change_value !== current_value) {
                style = {backgroundColor: '#ff0000'};
            }
            return (
                <div
                    key={'change_key-' + key}
                    className='client_row_wrapper'
                    style={style}
                >
                    <div
                        className='client_col_name_wrapper'
                        style={
                            {
                                width: '14%',
                            }
                        }
                    >
                        {this.state.keys[key]}
                    </div>
                    <div
                        className='client_col_name_wrapper'
                        style={
                            {
                                width: '43%',
                            }
                        }
                    >
                        {change_value}
                    </div>
                    <div
                        className='client_col_name_wrapper'
                        style={
                            {
                                width: '43%',
                            }
                        }
                    >
                        {current_value}
                    </div>
                </div>
            )
        })
    }

    render() {
        return (
            <div>
                <div
                    style={
                        {
                            display: 'flex',
                            width: 'fit-content',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            marginTop: '1rem',
                            marginBottom: '1.5rem',
                        }
                    }
                >
                    <div
                        style={
                            {
                                border: '1px solid #f7941e',
                                padding: '0.25rem',
                                marginRight: '0.5rem',
                                marginLeft: '0.5rem',
                                cursor: 'pointer',
                                fontSize: '1.25rem',
                            }
                        }
                        onClick={
                            () => {
                                this.handleChangeStatus(2);
                            }
                        }
                    >
                        Принять
                    </div>
                    <div
                        style={
                            {
                                border: '1px solid #f7941e',
                                padding: '0.25rem',
                                marginRight: '0.5rem',
                                marginLeft: '0.5rem',
                                cursor: 'pointer',
                                fontSize: '1.25rem',
                            }
                        }
                        onClick={
                            () => {
                                this.handleChangeStatus(3);
                            }
                        }
                    >
                        Отклонить
                    </div>
                </div>
                {this.state.changes['user']
                    ? <div
                        style={
                            {
                                marginBottom: '1rem',
                                fontSize: '1.5rem',
                            }
                        }
                      >
                          Клиент: {this.state.changes['user']['first_name'] + ' ' + this.state.changes['user']['last_name']}
                      </div>
                    : <div></div>
                }
                <div className='table_container'>
                    <div className='table_wrapper'>
                        <div className="table_head_container_wrapper">
                            <div
                                key='req-0'
                                className='clients_table_container_border clients_table_container_head'
                                style={
                                    {
                                        width: '14%',
                                    }
                                }
                            >
                                Поле
                            </div>
                            <div
                                key='req-1'
                                className='clients_table_container_border clients_table_container_head'
                                style={
                                    {
                                        width: '43%',
                                    }
                                }
                            >
                                Изменения
                            </div>
                            <div
                                key='req-2'
                                className='clients_table_container_border clients_table_container_head'
                                style={
                                    {
                                        width: '43%',
                                    }
                                }
                            >
                                Текущий абонемент
                            </div>
                        </div>
                        <div className="table_rows_container_wrapper">
                            {this.renderTicketDifference()}
                        </div>
                    </div>
                </div>
                {this.state.lock_spinner
                    ? <Spinner />
                    : ''
                }
            </div>
        )
    }
}

export {FullTicketChangeRequest, TicketChangeRequest };
