function get_request(uri, catchError) {
  return fetch(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      lang: localStorage.getItem("lang") || "ru",
    }
  })
  .then(res => {
    if (res.status === 401) {
        catchError(res.status);
        return
    }
    const response = res.json();
    return response;
  })
}

function delete_request(uri, catchError) {
  return fetch(uri, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  })
  .then(res => {
    if (res.status === 401) {
        catchError(res.status);
        return
    }
    const response = res.json();
    return response;
  })
}

function post_request(
  uri, data, catchError, http_method='POST', content_type='application/json'
) {
  var headers = {Authorization: `Bearer ${localStorage.getItem('token')}`}
  if (content_type === 'application/json') {
    var body = JSON.stringify(data)
    headers['Content-Type'] = content_type
  }
  else {
    var body = new FormData()
    for(const name in data) {
      if (data[name].constructor === Array) {
        for (let v of data[name]) {
          body.append(name, v)
        }
      }
      else {
        body.append(name, data[name])
      }
    }
    for (let k of body.entries()) {
      console.log(k)
    }
  }
  return fetch(uri, {
    method: http_method,
    body: body,
    headers: headers,
  })
  .then(res => {
    if (res.status === 401) {
        catchError(res.status);
        return
    }
    else if (res.status !== 200) {
        return
    }
    const response = res.json();
    return response;
  })
}

export {
  get_request,
  post_request,
  delete_request,
}
