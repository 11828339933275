import LocalizedStrings from 'react-localization'

let locales = new LocalizedStrings({
  en:{
    week: {
      monday_shortcut:"Mon",
      tuesday_shortcut:"Tue",
      wednesday_shortcut:"Wed",
      thursday_shortcut:"Thu",
      friday_shortcut:"Fri",
      saturday_shortcut:"Sat",
      sunday_shortcut:"Sun",
    },
    menu: {
      profile: "Profile",
      club: "Club",
      statistic: "Statistic",
      clients: "Clients",
      coaches: "Coaches",
      calendar: "Calendar",
      bids: "Bids",
      time_shifting: "Time shifting",
      service_items: "Services and items",
      notifications: "Notifications",
      ticket_edit_request: "Ticket edit requests",
      market: "Market",
      rent: "Rent",
    },
    filters: {
      filters: "Filters",
      club: "Club",
      club_unit: "Club unit",
      discipline: "Discipline",
      coach: "Coach",
      ticket: "Ticket",
      client_type: "Client type",
      employee_clients: "Employee's clients",
      drill_type: "Drill type",
      all_drills: "All drills",
      ticket_drills: "Drills for exist tickets",
      time_period: "Period of time",
      bid_status: "Bid's status",
      bid_source: "Bid's source",
      phone: "Phone",
      employee_clients_values: {
        all: "All",
        mine: "My",
      },
      client_type_values: {
        hooky: "Hooky players",
        non_prolongation: "Non-prolongation ticket",
        prolongation: "Prolongation ticket",
        no_ticket: "Without ticket",
        frozen: "Frozen ticket",
        debtor: "Debtors",
      },
    },
    sorting: {
      ticket_exist_with: "By ticket existance (with ticket first)",
      ticket_exist_without: "By ticket existance (without ticket first)",
      created_date_asc: "By creation date (from oldest)",
      created_at_desc: "By creation date (from newest)",
    },
    search_placeholder: "Name Surname",
    tables: {
      heads: {
        full_name: "FULL NAME",
        ticket: "TICKET",
        contacts: "CONTACTS",
        representative: "REPRESENTATIVE",
        birthday: "BIRTHDAY",
        socials: "SOCIALS",
        employees: "EMPLOYEES",
        notes: "NOTES",
        club_unit: "CLUB/CLUB UNIT",
        traffic_source: "TRAFFIC SOURCE",
        bid_date: "BID DATE",
        bid_status: "STATUS",
      },
      body: {
        phone_shortcut: "Tel",
        name: "Name",
        surname: "Surname",
        birthday_shortcut: "Birth",
        accept: "Accept",
        decline: "Decline",
        non_active_tickets: "Not active tickets",
        attendance: "Attendance",
        status: {

        },
      },
    },
    tickets: {
      expired: "Expired",
      non_active: "Non-active",
      frozen: "Frozen",
      expired_header: "Expired tickets",
    },
    user_type: {
      client: "Client",
      coach: "Coach",
      admin: "Administrator",
      director: "Director",
      owner: "Owner",
    },
  },
  ru: {
    week: {
      monday_shortcut:"Пн",
      tuesday_shortcut:"Вт",
      wednesday_shortcut:"Ср",
      thursday_shortcut:"Чт",
      friday_shortcut:"Пт",
      saturday_shortcut:"Сб",
      sunday_shortcut:"Вс",
    },
    menu: {
      profile: "Профиль",
      club: "Клуб",
      statistic: "Статистика",
      clients: "Клиенты",
      coaches: "Тренеры",
      calendar: "Календарь",
      bids: "Заявки",
      time_shifting: "Учет времени",
      service_items: "Товары и услуги",
      notifications: "Уведомления",
      ticket_edit_request: "Запрос на редактирование",
      market: "Магазин",
      rent: "Аренда",
    },
    filters: {
      filters: "Фильтры",
      club: "Клуб",
      club_unit: "Филиал клуба",
      discipline: "Дисциплина",
      coach: "Тренер",
      ticket: "Абонемент",
      client_type: "Тип клиента",
      employee_clients: "Клиенты сотрудника",
      drill_type: "Тип тренировки",
      all_drills: "Все тренировки",
      ticket_drills: "Тренировки по абонементу",
      time_period: "Период создания заявки",
      bid_status: "Статус заявки",
      bid_source: "Источник заявки",
      phone: "Телефон",
      employee_clients_values: {
        all: "Все",
        mine: "Мои",
      },
      client_type_values: {
        hooky: "Прогульщики",
        non_prolongation: "Непродленный абонемент",
        prolongation: "Продленный абонемент",
        no_ticket: "Без абонемента",
        frozen: "Замороженный абонемент",
        debtor: "Должники",
      },
    },
    sorting: {
      ticket_exist_with: "По наличию абонементов (сначала с абонементами)",
      ticket_exist_without: "По наличию абонементов (сначала без абонементов)",
      created_date_asc: "По дате создания по увеличению",
      created_at_desc: "По дате создания по убыванию",
    },
    search_placeholder: "Имя Фамилия",
    tables: {
      heads: {
        full_name: "ФИО",
        ticket: "АБОНЕМЕНТ",
        contacts: "КОНТАКТЫ",
        representative: "ПРЕДСТАВИТЕЛЬ",
        birthday: "ДАТА РОЖДЕНИЯ",
        socials: "СОЦСЕТИ",
        employees: "СОТРУДНИКИ",
        notes: "ЗАМЕТКИ",
        club_unit: "КЛУБ/ФИЛИАЛ",
        traffic_source: "ИСТОЧНИК ТРАФИКА",
        bid_date: "ДАТА ЗАЯВКИ",
        bid_status: "СТАТУС",
      },
      body: {
        phone_shortcut: "Тел",
        name: "Имя",
        surname: "Фамилия",
        birthday_shortcut: "ДР",
        accept: "Принять",
        decline: "Отклонить",
        non_active_tickets: "Неактивные абонементы",
        attendance: "Посещаемость",
        status: {

        },
      },
    },
    tickets: {
      expired: "Истекшие",
      non_active: "Неактивные",
      frozen: "Замороженные",
      expired_header: "Истекшие абонементы",
    },
    user_type: {
      client: "Клиент",
      coach: "Тренер",
      admin: "Администратор",
      director: "Директор",
      owner: "Владелец",
    },
  }
});

export { locales }
