import React from 'react';
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
import Select from 'react-select';
import Spinner from './Spinner';
import {
  Link
} from "react-router-dom";
import compose from 'lodash/fp/compose';
import {
    delete_request
} from './api/api_call'
import BarcodeScannerComponent from "react-qr-barcode-scanner";

var _ = require('lodash');

const select_styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        padding: 20,
        'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        color: '#f7941e',
        border: '1px solid #f7941e',
        'background-color': '#c5c6c8',
        'outline-color': '#f7941e',
    }),
    container: (provided, state) => ({
        ...provided,
        width: '50%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'margin-bottom': '0.5rem',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'background-color': '#c5c6c8',
    }),
}


class ServicesItems extends(React.Component) {
    constructor(props) {
        super(props);
        this.modal_ref = React.createRef();
        this.state = {
            promos: {},
            all_promos: 0,
            promos_club_unit: '',
            add_promo: false,
            promo: null,
            clubUnitId: null,
            add_new_service: false,
            tickets: {},
            ticket: null,
            add_ticket: false,
            lock_spinner: false,
            promo_tickets: [],
            products: [],
            products_limit: 20,
            products_offset: 0,
            services: [],
            services_limit: 20,
            services_offset: 0,
            show_promos: (this.props.device === 'desktop') ? true : false,
            show_tickets: (this.props.device === 'desktop') ? true : false,
            show_products: (this.props.device === 'desktop') ? true : false,
            show_services: (this.props.device === 'desktop') ? true : false,
            edit_product: null,
            edit_service: null,
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOut);
        this.getPromos();
        this.getProductsServices(0)
        this.getProductsServices(1)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOut);
    }

    handleClickOut = (e) => {
        if (this.modal_ref &&
            this.modal_ref.current &&
            (!this.modal_ref.current.contains(e.target) || e.target.id === 'background_lock_id') &&
            (e.target.parentElement && !e.target.parentElement.classList.contains('css-1gtu0rj-indicatorContainer')) &&
            (e.target.parentElement.parentElement && !e.target.parentElement.parentElement.classList.contains('css-1gtu0rj-indicatorContainer'))
        ) {
            this.setState({
                add_promo: false,
                accept: false,
                add_new_service: false,
                add_ticket: false,
                ticket: null,
                edit_product: false,
                edit_service: false,
            });
        }
    }

    getPromos() {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/clubs/services/');
                uri.searchParams.set('all', this.state.all_promos);
                uri.searchParams.set('club_unit', this.state.promos_club_unit);
                fetch(uri.toString(), {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (this.props.logged_in) {
                        this.setState(
                            {
                                promos: json.promos,
                                tickets: json.tickets,
                                lock_spinner: false,
                            }
                        );
                    }
                })
            }
        )
    }

    handleSetPromoData = (e) => {
        var promo_tickets = this.state.promo_tickets;
        if (e.constructor === Array && e[0] && e[0]["type"] === "ticket") {
            promo_tickets = Array.from(e, (val) => val["value"]);
        }
        this.setState({promo_tickets: promo_tickets});
    }

    handleUpdatePromo = (e) => {
        if (!e.target.dataset.close_promo) {
            if (parseFloat(document.getElementById('promo_discount').value) > 100) {
                return
            }
        }
        var closed = parseInt(e.target.dataset.closed) === 1;
        var tickets = Array.from(
            this.state.promo_tickets, (val) => new Object(
                {
                    "ticket": val,
                    "discount": (document.getElementById('promo_discount')) ? parseFloat(document.getElementById('promo_discount').value) : 0,
                }
            )
        )
        var data = {
            id: (e.target.dataset.promo_id) ? e.target.dataset.promo_id : null,
            club_units: [e.target.dataset.club_unit],
            name: ((document.getElementById('promo_name')) ? document.getElementById('promo_name').value : this.state.promo.name),
            description: ((document.getElementById('promo_description')) ? document.getElementById('promo_description').value : this.state.promo.description),
            tickets: tickets,
            closed: closed,
        };
        var promo_id = e.target.dataset.promo_id;
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/promo/');
        this.setState({lock_spinner: true}, () => {
            fetch(uri.toString(), {
              method: (promo_id) ? 'PUT' : 'POST',
              body: JSON.stringify(data),
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
              }
            })
            .then(res => {
                if (res.status === 401) {
                    this.props.catchError(res.status);
                    return
                }
                else if (res.status === 406) {
                    res.json().then((json) => {
                        this.props.updateCurrentShift(json);
                    });
                    return
                }
                else if (res.status === 409) {
                    res.json().then((json) => {
                        this.setState(
                            {
                                add_promo: false,
                                accept: false,
                                promo: null,
                                add_new_service: false,
                                lock_spinner: false,
                            },
                            () => {
                              document.getElementById('toast-body-id').innerHTML = 'У призрачного абонемента может быть только одна акция. Акция ' + json.promo_name + ' существует';
                              var x = document.getElementById("toast-wrapper-id");
                              x.className = 'show';
                              setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                            }
                        );
                    });
                    return
                }
                const response = res.json();
                return response;
            })
            .then(json => {
                if (!json) {
                    return
                }
                var promos = this.state.promos;
                if (data["id"]) {
                    for (let i = 0; i < promos[data['club_units'][0]].length; i++) {
                        if (promos[data['club_units'][0]][i].id === json.id) {
                            if (closed) {
                                promos[data['club_units'][0]].splice(i, 1);
                            }
                            else {
                                promos[data['club_units'][0]][i] = json;
                                break;
                            }
                        }
                    }
                }
                else {
                    for (let i = 0; i < json.length; i++) {
                        if (promos && promos[data['club_units'][0]]) {
                            promos[data['club_units'][0]].push(json[i]);
                        }
                        else {
                            promos[data['club_units'][0]] = [json[i]];
                        }
                    }
                }
                this.setState(
                    {
                        promos: promos,
                        add_promo: false,
                        accept: false,
                        promo: null,
                        add_new_service: false,
                        lock_spinner: false,
                    }
                );
            })
        })
    }

    handleAddTicket(data, method, ticket_id) {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                if (ticket_id !== "") {
                    ticket_id = ticket_id + "/"
                }
                var uri = new URL(
                    process.env.REACT_APP_BACKEND_URI +
                    '/api/v1/management/tickets/' +
                    ticket_id
                );
                fetch(uri.toString(), {
                  method: method,
                  body: JSON.stringify(data),
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                  }
                })
                .then(res => res.json())
                .then(json => {
                    var tickets = this.state.tickets;
                    if (ticket_id !== "") {
                        for (let i = 0; i < tickets[data['club_unit'][0]].length; i++) {
                            if (tickets[data['club_unit'][0]][i].id === json.id) {
                                if (data["deleted"]) {
                                    tickets[data['club_unit'][0]].splice(i, 1);
                                }
                                else {
                                    tickets[data['club_unit'][0]][i] = json;
                                    break;
                                }
                            }
                        }
                    }
                    else {
                        if (!tickets[data['club_unit'][0]]) {
                            tickets[data['club_unit'][0]] = [json];
                        }
                        else {
                            tickets[data['club_unit'][0]].push(json);
                        }
                    }
                    this.setState(
                        {
                            tickets: tickets,
                            add_ticket: false,
                            accept: false,
                            ticket: null,
                            add_new_service: false,
                            lock_spinner: false,
                        }
                    );
                })
        })
    }

    getProductsServices(service) {
      this.setState(
        {
            lock_spinner: true,
        }, () => {
          var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/market/products/');
          uri.searchParams.set('service', service);
          if (service) {
            if (this.state.services_offset === null) {
              return
            }
            uri.searchParams.set('limit', this.state.services_limit)
            uri.searchParams.set('offset', this.state.services_offset)
          }
          else {
            if (this.state.products_offset === null) {
              return
            }
            uri.searchParams.set('limit', this.state.products_limit)
            uri.searchParams.set('offset', this.state.products_offset)
          }
          fetch(uri.toString(), {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
          })
          .then(json => {
            if (this.props.logged_in) {
              if (service) {
                this.setState(
                  {
                    services: this.state.services.concat(json.items),
                    services_offset: (this.state.services_offset + this.state.services_limit >= json.count) ? null : this.state.services_offset + this.state.services_limit,
                    lock_spinner: false,
                  }
                )
              }
              else {
                this.setState(
                  {
                    products: this.state.products.concat(json.items),
                    products_offset: (this.state.products_offset + this.state.products_limit >= json.count) ? null : this.state.products_offset + this.state.products_limit,
                    lock_spinner: false,
                  }
                )
              }
            }
          })
        }
      )
    }

    handleAddProduct(data, method) {
        var uri = new URL(
            process.env.REACT_APP_BACKEND_URI +
            '/api/v1/market/products/'
        );
        var categories = data.category
        delete data.category
        for (let i = 0; i < categories.length; i++) {
          data[`category[${i}]`] = categories[i]
        }
        var form_data = new FormData()
        for (let k in data) {
          if (k == 'gallery') {
            continue
          }
          if (data['gallery'].includes(k)) {
            form_data.append(k, data[k], data[k].name)
          }
          else if (data[k]) {
            form_data.append(k, data[k])
          }
        }
        fetch(uri.toString(), {
          method: method,
          body: form_data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        })
        .then(res => res.json())
        .then(json => {
            var products = this.state.products
            var services = this.state.services
            if (data["id"]) {
              if (data["service"]) {
                services = services.map(
                  (prod) => {
                    if (parseInt(prod.id) === parseInt(json.id)) {
                      return json
                    }
                    else {
                      return prod
                    }
                  }
                )
              }
              else {
                products = products.map(
                  (prod) => {
                    if (parseInt(prod.id) === parseInt(json.id)) {
                      return json
                    }
                    else {
                      return prod
                    }
                  }
                )
              }
            }
            else {
              if (data["service"]) {
                services.push(json)
              }
              else {
                products.push(json)
              }
            }
            this.setState(
                {
                    products: products,
                    services: services,
                    add_product: false,
                    accept: false,
                    edit_product: null,
                    add_new_service: false,
                }
            );
        })
    }

    handleRemoveProduct(product_pk) {
        var uri = new URL(
            process.env.REACT_APP_BACKEND_URI +
            '/api/v1/market/products/'
        );
        uri.searchParams.set('product_pk', product_pk)
        delete_request(uri, this.props.catchError).then(
            json => {
                if (this.props.logged_in) {
                    this.setState(
                        {
                            products: this.state.products.filter(p => parseInt(p.id) !== parseInt(product_pk)),
                            services: this.state.services.filter(s => parseInt(s.id) !== parseInt(product_pk)),
                            add_product: false,
                            accept: false,
                            edit_product: null,
                            add_new_service: false,
                            add_service: false,
                        }
                    )
                }
            }
        )
    }

    renderTicketInfo(ticket) {
        return ticket.discipline.map((discipline) => {
            return (
                <li key={'ticket_info-' + discipline.id}>
                    <span className='promo_ticket_name'>
                        {discipline.name}
                    </span>
                </li>
            )
        })
    }

    renderPromoTickets(tickets) {
        return tickets.map((ticket) => {
            return (
                <li key={'promo_ticket-' + ticket.id}>
                    <span className='promo_ticket_name'>
                        {ticket.ticket.ticket}
                    </span>
                    , скидка: {parseFloat(ticket.discount * 100)}%
                </li>
            )
        })
    }

    renderClubTickets(club_unit) {
        if (this.state.tickets[club_unit]) {
            return this.state.tickets[club_unit].map((ticket, index) => {
                var up_info_style = '';
                if (this.state.tickets[club_unit].length <= 7 ||
                    this.state.tickets[club_unit].length - index < 7
                ) {
                    up_info_style = 'promo_container_last';
                }
                return (
                    <div
                      key={'promo_container-' + ticket.id}
                      className={'promo_container ' + up_info_style}
                    >
                        <div className='promo_head_wrapper'>
                            <div className='promo_editor_wrapper'>
                                <input
                                    type='button'
                                    className='add_sketch_btn'
                                    id={'update_ticket-' + ticket.id}
                                    data-ticket_id={ticket.id}
                                    data-club_unit={ticket.club_unit}
                                    value='&#9998;'
                                    onClick={this.handleCorrectTicket}
                                />
                                <input
                                    type='button'
                                    className='add_sketch_btn'
                                    id={'close_ticket-' + ticket.id}
                                    data-ticket_id={ticket.id}
                                    data-club_unit={ticket.club_unit}
                                    value='&#10008;'
                                    onClick={this.handleCloseTicket}
                                />
                            </div>
                            <div className='promo_name_wrapper'>
                                {ticket.ticket}
                            </div>
                        </div>
                        <div className='promo_tickets_wrapper'>
                            <div>
                                Число тренировок:
                                {ticket.drill_number > 0
                                    ? ' ' + ticket.drill_number
                                    : ' Неограниченно'
                                }
                            </div>
                            <div>
                                Категория:
                                {ticket.category
                                    ? ' ' + ticket.category.name
                                    : ' Не указана'
                                }
                            </div>
                            <div>
                                Список дисциплин:
                            </div>
                            <div className='ticket_disciplines_list'>
                                <ul>
                                    {this.renderTicketInfo(ticket)}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        else {
            return (
                <div></div>
            )
        }
    }

    renderClubPromos(club_unit) {
        if (this.state.promos[club_unit]) {
            return this.state.promos[club_unit].map((promo) => {
                return (
                    <div
                      key={'club_promo-' + promo.id}
                      className='promo_container'
                    >
                        <div className='promo_head_wrapper'>
                            <div className='promo_editor_wrapper'>
                                <input
                                    type='button'
                                    className='add_sketch_btn'
                                    id={'update_promo-' + promo.id}
                                    data-promo_id={promo.id}
                                    data-club_unit={promo.club_unit.id}
                                    value='&#9998;'
                                    onClick={this.handleCorrectPromo}
                                />
                                <input
                                    type='button'
                                    className='add_sketch_btn'
                                    id={'close_promo-' + promo.id}
                                    data-promo_id={promo.id}
                                    data-club_unit={promo.club_unit.id}
                                    data-close_promo='true'
                                    value='&#10008;'
                                    onClick={this.handleClosePromo}
                                />
                            </div>
                            <div className='promo_name_wrapper'>
                                {promo.name}
                            </div>
                        </div>
                        <div className='promo_tickets_wrapper'>
                            <ul>
                                {this.renderPromoTickets(promo.tickets)}
                            </ul>
                        </div>
                    </div>
                )
            })
        }
        else {
            return (
                <div></div>
            )
        }
    }

    renderClubUnits(content_type) {
        return this.props.club_units.map((unit, index) => {
            return (
                <div
                  key={'club_unit-' + unit.id}
                  className='full_club_tickets_info_wrapper'
                >
                    <div className='club_tickets_info_wrapper'
                         onClick={() => {
                            var g = document.getElementById('unit_promos-' + unit.id);
                            if (g.style.display === 'block') {
                                g.style.display = 'none';
                            }
                            else {
                                g.style.display = 'block';
                            }
                         }}
                    >
                        <div className='club_name_tickets_info'>
                            {unit.name}
                        </div>
                    </div>
                    <div
                        className={(this.props.club_units.length - 1 === index) ? 'promo_container_head_wrapper' : ''}
                        id={'unit_promos-' + unit.id}
                    >
                        {
                            (
                                () => {
                                    if (content_type === "promo") {
                                        return this.renderClubPromos(unit.id);
                                    }
                                    else if (content_type === "tickets") {
                                        return this.renderClubTickets(unit.id);
                                    }
                                }
                            )()
                        }
                    </div>
                </div>
            )
        })
    }

    renderProducts() {
      return this.state.products.map((product) => {
        return (
          <div
            key={'product_container-' + product.id}
            className={'promo_container'}
          >
            <div className='promo_head_wrapper'>
              <div className='promo_editor_wrapper'>
                <input
                  type='button'
                  className='add_sketch_btn'
                  id={'update_product-' + product.id}
                  data-product={product.id}
                  value='&#9998;'
                  onClick={this.handleCorrectProduct}
                />
                <input
                    type='button'
                    className='add_sketch_btn'
                    id={'remove_product-' + product.id}
                    data-product={product.id}
                    value='&#10008;'
                    onClick={this.handleCorrectProduct}
                />
              </div>
              <Link
                to={'/product/' + product.id}
                style={
                    {
                        textDecoration: 'none',
                        color: '#000',
                    }
                }
              >
                <div
                  className='promo_name_wrapper'
                  onClick={
                    (e) => {
                      this.props.history.push(
                        '/service_and_items',
                        {
                          products: this.state.products,
                          products_limit: this.state.products_limit,
                          products_offset: this.state.products_offset,
                          services: this.state.services,
                          services_limit: this.state.services_limit,
                          services_offset: this.state.services_offset,
                        },
                      )
                    }
                  }
                >
                  {product.name}
                </div>
              </Link>
            </div>
          </div>
        )
      })
    }

    renderServices() {
      return this.state.services.map((service) => {
        return (
          <div
            key={'service_container-' + service.id}
            className={'promo_container'}
          >
            <div className='promo_head_wrapper'>
              <div className='promo_editor_wrapper'>
                <input
                  type='button'
                  className='add_sketch_btn'
                  id={'update_service-' + service.id}
                  data-service={service.id}
                  value='&#9998;'
                  onClick={this.handleCorrectService}
                />
                <input
                    type='button'
                    className='add_sketch_btn'
                    id={'remove_service-' + service.id}
                    data-service={service.id}
                    value='&#10008;'
                    onClick={this.handleCorrectService}
                />
              </div>
              <Link
                to={'/service/' + service.id}
                style={
                    {
                        textDecoration: 'none',
                        color: '#000',
                    }
                }
              >
                <div
                  className='promo_name_wrapper'
                  onClick={
                    (e) => {
                      this.props.history.push(
                        '/service_and_items',
                        {
                          products: this.state.products,
                          products_limit: this.state.products_limit,
                          products_offset: this.state.products_offset,
                          services: this.state.services,
                          services_limit: this.state.services_limit,
                          services_offset: this.state.services_offset,
                        },
                      )
                    }
                  }
                >
                  {service.name}
                </div>
              </Link>
            </div>
          </div>
        )
      })
    }

    handleDeclineService = (e) => {
        this.setState(
          {
            add_promo: false,
            accept: false,
            promo: null,
            add_ticket: false,
            ticket: null,
            add_product: false,
            accept: false,
            edit_product: null,
            add_service: false,
            edit_service: null,
          }
        );
    }

    handleClosePromo = (e) => {
        var promo = this.state.promos[e.target.dataset.club_unit].filter(p => p.id === parseInt(e.target.dataset.promo_id))[0];
        this.setState({ add_promo: true, accept: true, promo: promo, clubUnitId: e.target.dataset.club_unit });
    }

    handleCorrectPromo = (e) => {
        var promo = this.state.promos[e.target.dataset.club_unit].filter(p => p.id === parseInt(e.target.dataset.promo_id))[0];
        this.setState(
          {
            add_promo: true,
            promo: promo,
            clubUnitId: e.target.dataset.club_unit,
            promo_tickets: Array.from(promo.tickets, (t) => t["ticket"]["id"]),
          }
        );
    }

    handleCloseTicket = (e) => {
        var ticket = this.state.tickets[e.target.dataset.club_unit].filter(p => p.id === parseInt(e.target.dataset.ticket_id))[0];
        this.setState({ add_ticket: true, accept: true, ticket: ticket, clubUnitId: e.target.dataset.club_unit });
    }

    handleCorrectTicket = (e) => {
        var ticket = this.state.tickets[e.target.dataset.club_unit].filter(p => p.id === parseInt(e.target.dataset.ticket_id))[0];
        this.setState({ add_ticket: true, ticket: ticket, clubUnitId: e.target.dataset.club_unit });
    }

    handleCorrectProduct = (e) => {
      this.setState(
        {
          add_product: true,
          accept: (e.target.id.includes('remove')) ? true : false,
          edit_product: this.state.products.filter(p => p.id === parseInt(e.target.dataset.product))[0],
        }
      )
    }

    handleCorrectService = (e) => {
      this.setState(
        {
          add_service: true,
          accept: (e.target.id.includes('remove')) ? true : false,
          edit_service: this.state.services.filter(s => s.id === parseInt(e.target.dataset.service))[0]
        }
      )
    }

    handleAddServiceOrItem = (e) => {
        this.setState({ add_new_service: true })
    }

    renderNewServiceChoose() {
        return (
            <div className='background_lock_wrapper' id='background_lock_id' ref={this.modal_ref} data-test='test'>
                <AddServicesItems
                    handleAddPromo={this.handleUpdatePromo.bind(this)}
                    handleAddTicket={this.handleAddTicket.bind(this)}
                    club_units={this.props.club_units}
                    handleDeclineService={this.handleDeclineService}
                    logged_in={this.props.logged_in}
                    promo={this.state.promo}
                    accept={this.state.accept}
                    clubUnitId={this.state.clubUnitId}
                    handleSetPromoData={this.handleSetPromoData.bind(this)}
                    handleAddProduct={this.handleAddProduct.bind(this)}
                    handleRemoveProduct={this.handleRemoveProduct.bind(this)}
                />
            </div>
        )
    }

    renderNewService(service_type) {
        if (service_type === "promo") {
            return (
                <div className='background_lock_wrapper' id='background_lock_id' ref={this.modal_ref}>
                    <div className='new_drill_form_wrapper'>
                        <PromoControl
                            handleAddPromo={this.handleUpdatePromo.bind(this)}
                            club_units={this.props.club_units}
                            handleDeclineService={this.handleDeclineService}
                            logged_in={this.props.logged_in}
                            promo={this.state.promo}
                            accept={this.state.accept}
                            clubUnitId={this.state.clubUnitId}
                            handleSetPromoData={this.handleSetPromoData.bind(this)}
                        />
                    </div>
                </div>
            )
        }
        else if (service_type === "ticket") {
            return (
                <div className='background_lock_wrapper' id='background_lock_id' ref={this.modal_ref}>
                    <div className='new_drill_form_wrapper'>
                        <TicketsControl
                            handleAddTicket={this.handleAddTicket.bind(this)}
                            club_units={this.props.club_units}
                            handleDeclineService={this.handleDeclineService}
                            logged_in={this.props.logged_in}
                            ticket={this.state.ticket}
                            accept={this.state.accept}
                            clubUnitId={this.state.clubUnitId}
                        />
                    </div>
                </div>
            )
        }
        else if (service_type === 'product') {
            return (
                <div className='background_lock_wrapper' id='background_lock_id' ref={this.modal_ref}>
                    <div className='new_drill_form_wrapper'>
                        <ProductsControl
                            handleAddProduct={this.handleAddProduct.bind(this)}
                            handleRemoveProduct={this.handleRemoveProduct.bind(this)}
                            handleDeclineService={this.handleDeclineService}
                            logged_in={this.props.logged_in}
                            product={this.state.edit_product}
                            accept={this.state.accept}
                            product_type='product'
                        />
                    </div>
                </div>
            )
        }
        else if (service_type === 'service') {
            return (
                <div className='background_lock_wrapper' id='background_lock_id' ref={this.modal_ref}>
                    <div className='new_drill_form_wrapper'>
                        <ProductsControl
                            handleAddProduct={this.handleAddProduct.bind(this)}
                            handleDeclineService={this.handleDeclineService}
                            logged_in={this.props.logged_in}
                            product={this.state.edit_service}
                            product_type='service'
                            accept={this.state.accept}
                            handleRemoveProduct={this.handleRemoveProduct.bind(this)}
                        />
                    </div>
                </div>
            )
        }
    }

    render() {
        return(
            <div>
                <div className='service_and_items_wrapper'>
                    <div className='tickets_statistic_container service_and_items_container'>
                        <div className='head_ticket_info'
                             onClick={
                               () => {
                                 this.setState({show_promos: !this.state.show_promos})
                               }
                             }
                        >
                            Акции
                        </div>
                        {this.state.show_promos
                          ? <div className='tickets_stat_wrapper' id='promo_stat_container_id'>
                                {this.renderClubUnits("promo")}
                            </div>
                          : ''
                        }
                    </div>
                    <div className='tickets_statistic_container service_and_items_container'>
                        <div className='head_ticket_info'
                             onClick={
                               () => {
                                 this.setState({show_tickets: !this.state.show_tickets})
                               }
                             }
                        >
                            Абонементы
                        </div>
                        {this.state.show_tickets
                          ? <div className='tickets_stat_wrapper' id='tickets_stat_container_id'>
                                {this.renderClubUnits("tickets")}
                            </div>
                          : ''
                        }
                    </div>
                    <div className='tickets_statistic_container service_and_items_container'>
                        <div className='head_ticket_info'
                             onClick={
                               () => {
                                 this.setState({show_products: !this.state.show_products})
                               }
                             }
                        >
                            Товары
                        </div>
                        {this.state.show_products
                          ? <div className='tickets_stat_wrapper' id='products_stat_container_id'>
                                {this.renderProducts()}
                                {this.state.products_offset !== null
                                  ? <div
                                      className='promo_head_wrapper'
                                      style={
                                        {
                                          display: 'block',
                                        }
                                      }
                                      onClick={
                                        () => {
                                          this.getProductsServices(0)
                                        }
                                      }
                                    >
                                      <div className='promo_name_wrapper'>
                                        Еще
                                      </div>
                                    </div>
                                  : ''
                                }
                            </div>
                          : ''
                        }
                    </div>
                    <div className='tickets_statistic_container service_and_items_container'>
                        <div className='head_ticket_info'
                             onClick={
                               () => {
                                 this.setState({show_services: !this.state.show_services})
                               }
                             }
                        >
                            Услуги
                        </div>
                        {this.state.show_services
                          ? <div className='tickets_stat_wrapper' id='services_stat_container_id'>
                                {this.renderServices()}
                                {this.state.services_offset !== null
                                  ? <div
                                      className='promo_head_wrapper'
                                      style={
                                        {
                                          display: 'block',
                                        }
                                      }
                                      onClick={
                                        () => {
                                          this.getProductsServices(1)
                                        }
                                      }
                                    >
                                      <div className='promo_name_wrapper'>
                                        Еще
                                      </div>
                                    </div>
                                  : ''
                                }
                            </div>
                          : ''
                        }
                    </div>
                </div>
                <div className='add_drill_wrapper'>
                    <div
                        className='add_drill_btn plus_btn'
                        id='add_drill_btn_id'
                        onClick={this.handleAddServiceOrItem}
                    >
                        &#10010;
                    </div>
                </div>
                <div id="toast-wrapper-id">
                    <div id="toast-body-id">
                    </div>
                </div>
                {this.state.add_promo
                    ? <div>{this.renderNewService("promo")}</div>
                    : ''
                }
                {this.state.add_ticket
                    ? <div>{this.renderNewService("ticket")}</div>
                    : ''
                }
                {this.state.add_product
                    ? <div>{this.renderNewService("product")}</div>
                    : ''
                }
                {this.state.add_service
                    ? <div>{this.renderNewService("service")}</div>
                    : ''
                }
                {this.state.add_new_service
                    ? <div>{this.renderNewServiceChoose()}</div>
                    : ''
                }
                {this.state.lock_spinner
                    ? <div className='background_lock_wrapper' id='background_lock_id'>
                          <div className='drill_clients_wrapper' id='drill_clients_wrapper_id'>
                              <div className='lock_modal_content_wrapper  lock_modal_continuous_content_wrapper' id='lock_modal_content_id'>
                                   <div className="loader"></div>
                              </div>
                          </div>
                      </div>
                    : <div></div>
                }
                {this.state.lock_spinner
                    ? <Spinner />
                    : <div></div>
                }
            </div>
        )
    }
}

class AddServicesItems extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            clubUnitId: this.props.clubUnitId,
            tickets: [],
            services: [
                {"id": 1, "value": "Акции"},
                {"id": 2, "value": "Абонементы"},
                {"id": 3, "value": "Товары"},
                {"id": 4, "value": "Услуги"},
            ],
            service_class: {
                1: <PromoControl
                       handleAddPromo={this.props.handleAddPromo}
                       club_units={this.props.club_units}
                       handleDeclineService={() => {this.setState({current_service_class: null})}}
                       logged_in={this.props.logged_in}
                       promo={this.props.promo}
                       accept={this.props.accept}
                       clubUnitId={this.props.clubUnitId}
                       new_promo={true}
                       handleSetPromoData={this.props.handleSetPromoData}
                   />,
                2: <TicketsControl
                       handleAddTicket={this.props.handleAddTicket}
                       club_units={this.props.club_units}
                       handleDeclineService={() => {this.setState({current_service_class: null})}}
                       logged_in={this.props.logged_in}
                       ticket={this.props.ticket}
                       accept={this.props.accept}
                       clubUnitId={this.props.clubUnitId}
                       new_ticket={true}
                   />,
                3: <ProductsControl
                       handleAddProduct={this.props.handleAddProduct}
                       handleDeclineService={() => {this.setState({current_service_class: null})}}
                       logged_in={this.props.logged_in}
                       product={null}
                       product_type='product'
                       accept={this.props.accept}
                       handleRemoveProduct={this.props.handleRemoveProduct}
                   />,
                4: <ProductsControl
                       handleAddProduct={this.props.handleAddProduct}
                       handleDeclineService={() => {this.setState({current_service_class: null})}}
                       logged_in={this.props.logged_in}
                       product={null}
                       product_type='service'
                       accept={this.props.accept}
                       handleRemoveProduct={this.props.handleRemoveProduct}
                   />,
            },
            current_service_class: null,
        }
    }

    renderServiceFilter() {
        return this.state.services.map((service) => {
            return (
                <p>
                    <input
                        type='radio'
                        id={'service-' + service.id}
                        key={'checkkey-' + service.id}
                        data-service_id={service.id}
                        onClick={
                            (e) => {
                                this.setState({
                                    current_service_class: this.state.service_class[e.target.dataset.service_id]
                                });
                            }
                        }
                    />
                    {service.value}
                </p>
            )
        })
    }

    render() {
        if (!this.state.current_service_class) {
            return (
                <div className='background_lock_wrapper' id='background_lock_id'>
                    <div className='new_drill_form_wrapper'>
                        {this.renderServiceFilter()}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='background_lock_wrapper' id='background_lock_id'>
                    <div className='new_drill_form_wrapper'>
                        {this.state.current_service_class}
                    </div>
                </div>
            )
        }
    }
}

class PromoControl extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            clubUnitId: this.props.clubUnitId,
            tickets: [],
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/tickets/');
        uri.searchParams.set('club_unit', this.state.clubUnitId);
        fetch(uri.toString(), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (json === undefined) {
                this.props.catchError(401);
                return
            }
            if (this.props.logged_in) {
                this.setState({tickets: json});
            }
        })
    }

    renderFilter() {
        return this.props.club_units.map((unit) => {
            return (
                <p>
                    <input
                        type='radio'
                        id={'unit-' + unit.id}
                        key={'checkkey-' + unit.id}
                        data-unit_id={unit.id}
                        onClick={
                            (e) => {
                                this.setState({
                                    clubUnitId: e.target.dataset.unit_id
                                }, () => {this.getData();});
                            }
                        }
                    />
                    {unit.name}
                </p>
            )
        })
    }

    renderTicketList() {
        return this.state.tickets.map((ticket) => {
            if (this.props.promo && this.props.promo.tickets.filter(t => t.id === ticket.id).length > 0) {
                return (
                    <option key={'ticket_list-' + ticket.id} id={'ticket_list-' + ticket.id} value={ticket.id}
                            data-ticket={ticket.id} selected>
                        {ticket.ticket}
                    </option>
                )
            }
            else {
                return (
                    <option key={'ticket_list-' + ticket.id} id={'ticket_list-' + ticket.id} value={ticket.id}
                            data-ticket={ticket.id}>
                        {ticket.ticket}
                    </option>
                )
            }
        })
    }

    render() {
        if (this.props.accept) {
            return (
                <div className='ticket_creation_wrapper'>
                    <h3>Закрыть акцию?</h3>
                    <button
                        id='add-promo'
                        key='promo-accept'
                        className='drill_traffic_btn'
                        data-promo_id={this.props.promo.id}
                        data-club_unit={this.state.clubUnitId}
                        data-close_promo='true'
                        onClick={this.props.handleAddPromo}
                        data-closed='1'
                    >
                        &#10004;
                    </button>
                    <button
                        id='decline-promo'
                        key='promo-decline'
                        className='drill_traffic_btn'
                        onClick={this.props.handleDeclineService}
                    >
                        &#10008;
                    </button>
                </div>
            )
        }
        if (!this.state.clubUnitId && this.props.new_promo) {
            return (
                <div className='background_lock_wrapper' id='background_lock_id'>
                    <div className='new_drill_form_wrapper'>
                        {this.renderFilter()}
                    </div>
                </div>
            )
        }
        return (
            <div className='ticket_creation_wrapper'>
                <h3>Акция</h3>
                <div className='promo_text_wrapper'>
                    <input
                        key='promo_name'
                        id='promo_name'
                        type='text'
                        placeholder='Название акции'
                        className='promo_text'
                        defaultValue={(this.props.promo) ? this.props.promo.name : ''}
                    />
                </div>
                <div className='promo_text_wrapper'>
                    <textarea
                        id='promo_description'
                        className='promo_text'
                        placeholder='Описание акции'
                        defaultValue={(this.props.promo) ? this.props.promo.description : ''}
                    >
                    </textarea>
                </div>
                <div className='ticket_selector_wrapper'>
                    <Select
                        isMulti
                        options={
                            Array.from(this.state.tickets, (val) => new Object(
                                {
                                    "value": val.id,
                                    "label": val.ticket,
                                    "type": "ticket",
                                }
                            ))
                        }
                        onChange={this.props.handleSetPromoData}
                        styles={select_styles}
                        placeholder={"Абонементы"}
                        defaultValue={
                            (this.props.promo)
                            ? Array.from(this.props.promo.tickets, (val) => new Object(
                                {
                                    "value": val.id,
                                    "label": val.ticket.ticket,
                                    "type": "ticket",
                                }
                            ))
                            : []
                        }
                        menuPosition={'fixed'}
                    />
                </div>
                <div className='promo_text_wrapper'>
                    <input
                        key='promo_discount'
                        id='promo_discount'
                        type='number'
                        placeholder='Размер скидки'
                        className='promo_text'
                        defaultValue={(this.props.promo && this.props.promo.tickets.length > 0) ? parseFloat(this.props.promo.tickets[0].discount * 100) : ''}
                    />
                </div>
                <button
                    id='add-promo'
                    key='promo-accept'
                    className='drill_traffic_btn'
                    data-promo_id={(this.props.promo) ? this.props.promo.id : ''}
                    data-club_unit={this.state.clubUnitId}
                    data-close_promo='true'
                    onClick={this.props.handleAddPromo}
                >
                    &#10004;
                </button>
                <button
                    id='decline-promo'
                    key='promo-decline'
                    className='drill_traffic_btn'
                    onClick={this.props.handleDeclineService}
                >
                    &#10008;
                </button>
            </div>
        )
    }
}

class TicketsControl extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            clubUnitId: this.props.clubUnitId,
            categories: [],
            ticket_category: {},
            disciplines: [],
            ticket_disciplines: (this.props.ticket && this.props.ticket.discipline) ? Array.from(this.props.ticket.discipline, d => d.id) : [],
            drills_types: [],
            ticket_drill_type: (this.props.ticket && this.props.ticket.drill_type) ? this.props.ticket.drill_type.id : null,
            ghost_ticket: false,
            freezable_ticket: (this.props.ticket) ? this.props.ticket.freeze : true,
            coach_skills: [],
            coach_tickets: (this.props.ticket) ? this.props.ticket.coach_drill_cost : [],
            different_price: (this.props.ticket && this.props.ticket.coach_drill_cost.length > 0) ? true : false,
            ticket_price: (this.props.ticket) ? this.props.ticket.price : null,
            ticket_profit: (this.props.ticket) ? this.props.ticket.profit : null,
        }
    }

    componentDidMount() {
        this.getCategories();
        this.getDisciplines();
        this.getDrillsTypes();
        this.getSkills()
        if (this.props.ticket && this.props.ticket.category) {
            this.setState({ticket_category: this.props.ticket.category});
        }
    }

    getCategories() {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/ticket_categories/');
        fetch(uri.toString(), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (json === undefined) {
                this.props.catchError(401);
                return
            }
            if (this.props.logged_in) {
                this.setState({categories: json.results});
            }
        })
    }

    getDisciplines() {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/disciplines/');
        fetch(uri.toString(), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (json === undefined) {
                this.props.catchError(401);
                return
            }
            if (this.props.logged_in) {
                this.setState({disciplines: json});
            }
        })
    }

    getDrillsTypes() {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/drills_types/');
        fetch(uri.toString(), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (json === undefined) {
                this.props.catchError(401);
                return
            }
            if (this.props.logged_in) {
                this.setState({drills_types: json.results});
            }
        })
    }

    getSkills() {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/coach_skill_choices/');
        fetch(uri.toString(), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (this.props.logged_in) {
                this.setState({coach_skills: json});
            }
        })
    }

    renderFilter() {
        return this.props.club_units.map((unit) => {
            return (
                <p>
                    <input
                        type='radio'
                        id={'unit-' + unit.id}
                        key={'checkkey-' + unit.id}
                        data-unit_id={unit.id}
                        onClick={
                            (e) => {
                                this.setState({
                                    clubUnitId: e.target.dataset.unit_id
                                }, () => {
                                    this.getCategories();
                                    this.getDisciplines();
                                });
                            }
                        }
                    />
                    {unit.name}
                </p>
            )
        })
    }

    renderCategoriesList() {
        return this.state.categories.map((category) => {
            if (this.props.ticket && this.props.ticket.category && this.props.ticket.category.id === category.id) {
                return (
                    <option key={'category_list-' + category.id} id={'category_list-' + category.id} value={category.id}
                            data-category={category.id} selected>
                        {category.name}
                    </option>
                )
            }
            else {
                return (
                    <option key={'category_list-' + category.id} id={'category_list-' + category.id} value={category.id}
                            data-category={category.id}>
                        {category.name}
                    </option>
                )
            }
        })
    }

    renderDisciplinesList() {
        return this.state.disciplines.map((discipline) => {
            if (this.props.ticket && this.props.ticket.discipline.filter(d => d.id === discipline.id).length > 0) {
                return (
                    <option
                        key={'discipline-' + discipline.id}
                        id={'discipline-' + discipline.id}
                        data-discipline_id={discipline.id}
                        selected
                    >
                        {discipline.name}
                    </option>
                )
            }
            else {
                return (
                    <option
                        key={'discipline-' + discipline.id}
                        id={'discipline-' + discipline.id}
                        data-discipline_id={discipline.id}
                    >
                        {discipline.name}
                    </option>
                )
            }
        })
    }

    handleAddTicket = (e) => {
        var data = {
            club_unit: [this.state.clubUnitId],
            ticket: (!e.target.dataset.close_ticket) ? document.getElementById("ticket_name").value : this.props.ticket.ticket,
            drill_number: (!e.target.dataset.close_ticket) ? document.getElementById("ticket_drill_number").value : this.props.ticket.drill_number,
            category: this.state.ticket_category,
            discipline: this.state.ticket_disciplines,
            category_coefficient: (!e.target.dataset.close_ticket) ? document.getElementById("ticket_category_weight").value : this.props.ticket.category_coefficient,
            deleted: (e.target.dataset.close_ticket) ? true : false,
            drill_type: this.state.ticket_drill_type,
            illusive: this.state.ghost_ticket,
            days: (!e.target.dataset.close_ticket) ? document.getElementById("ticket_working_days").value : this.props.ticket.days,
            price: this.state.ticket_price,
            profit: this.state.ticket_profit,
            freeze: this.state.freezable_ticket,
            bonus: (!e.target.dataset.close_ticket && !e.target.dataset.bonus) ? document.getElementById("ticket_bonus").value : this.props.ticket.bonus,
            coach_tickets: this.state.coach_tickets,
        }
        if (this.props.ticket) {
            this.props.handleAddTicket(data, "POST", this.props.ticket.id);
        }
        else {
            this.props.handleAddTicket(data, "PUT", '');
        }
    }

    renderCoachSkillTickets() {
      return this.state.coach_skills.map((coach_skill) => {
        return(
          <div
            style={
              {
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: 'fit-content',
              }
            }
            key={'coach_tickets-' + coach_skill.id}
          >
            <div
              data-skill_id={coach_skill.id}
            >
              {coach_skill.name}
            </div>
            {this.props.ticket && this.props.ticket.coach_drill_cost.filter(ct => ct.skill == coach_skill.id).length > 0
              ? <div
                  style={
                    {
                      display: 'flex',
                    }
                  }
                >
                  <div
                    className='promo_text_wrapper'
                    style={
                      {
                        marginTop: '0.5rem',
                      }
                    }
                  >
                      <input
                          id={'ticket_price-' + coach_skill.id}
                          type='number'
                          placeholder='Цена'
                          className='promo_text'
                          defaultValue={this.props.ticket.coach_drill_cost.filter(ct => ct.skill == coach_skill.id)[0].price.toString()}
                          onChange={
                            (e) => {
                              if (
                                this.state.coach_tickets.length > 0 &&
                                this.state.coach_tickets.filter(ct => ct.skill === coach_skill.id).length > 0
                              ) {
                                var current_coach_ticket = this.state.coach_tickets.filter(ct => ct.skill == coach_skill.id)[0]
                                current_coach_ticket['price'] = e.target.value
                                var coach_tickets = this.state.coach_tickets.filter(ct => ct.skill != coach_skill.id)
                                coach_tickets.push(current_coach_ticket)
                              }
                              else {
                                current_coach_ticket = {
                                  skill: coach_skill.id,
                                  price: e.target.value,
                                  profit: 0.0,
                                }
                                var coach_tickets = this.state.coach_tickets
                                coach_tickets.push(current_coach_ticket)
                              }
                              this.setState({coach_tickets: coach_tickets})
                            }
                          }
                      />
                  </div>
                  <div className='promo_text_wrapper'>
                      <input
                          id={'ticket_profit-' + coach_skill.id}
                          type='number'
                          placeholder='Выгода'
                          className='promo_text'
                          defaultValue={this.props.ticket.coach_drill_cost.filter(ct => ct.skill == coach_skill.id)[0].profit.toString()}
                          onChange={
                            (e) => {
                              if (
                                this.state.coach_tickets.length > 0 &&
                                this.state.coach_tickets.filter(ct => ct.skill === coach_skill.id).length > 0
                              ) {
                                var current_coach_ticket = this.state.coach_tickets.filter(ct => ct.skill == coach_skill.id)[0]
                                current_coach_ticket['profit'] = e.target.value
                                var coach_tickets = this.state.coach_tickets.filter(ct => ct.skill != coach_skill.id)
                                coach_tickets.push(current_coach_ticket)
                              }
                              else {
                                current_coach_ticket = {
                                  skill: coach_skill.id,
                                  price: 0.0,
                                  profit: e.target.value,
                                }
                                var coach_tickets = this.state.coach_tickets
                                coach_tickets.push(current_coach_ticket)
                              }
                              this.setState({coach_tickets: coach_tickets})
                            }
                          }
                      />
                  </div>
                </div>
              : <div
                  style={
                    {
                      display: 'flex',
                    }
                  }
                >
                  <div
                    className='promo_text_wrapper'
                    style={
                      {
                        marginTop: '0.5rem',
                      }
                    }
                  >
                      <input
                          id={'ticket_price-' + coach_skill.id}
                          type='number'
                          placeholder='Цена'
                          className='promo_text'
                          onChange={
                            (e) => {
                              if (
                                this.state.coach_tickets.length > 0 &&
                                this.state.coach_tickets.filter(ct => ct.skill === coach_skill.id).length > 0
                              ) {
                                var current_coach_ticket = this.state.coach_tickets.filter(ct => ct.skill == coach_skill.id)[0]
                                current_coach_ticket['price'] = e.target.value
                                var coach_tickets = this.state.coach_tickets.filter(ct => ct.skill != coach_skill.id)
                                coach_tickets.push(current_coach_ticket)
                              }
                              else {
                                current_coach_ticket = {
                                  skill: coach_skill.id,
                                  price: e.target.value,
                                  profit: 0.0,
                                }
                                var coach_tickets = this.state.coach_tickets
                                coach_tickets.push(current_coach_ticket)
                              }
                              this.setState({coach_tickets: coach_tickets})
                            }
                          }
                      />
                  </div>
                  <div className='promo_text_wrapper'>
                      <input
                          id={'ticket_profit-' + coach_skill.id}
                          type='number'
                          placeholder='Выгода'
                          className='promo_text'
                          onChange={
                            (e) => {
                              if (
                                this.state.coach_tickets.length > 0 &&
                                this.state.coach_tickets.filter(ct => ct.skill === coach_skill.id).length > 0
                              ) {
                                var current_coach_ticket = this.state.coach_tickets.filter(ct => ct.skill == coach_skill.id)[0]
                                current_coach_ticket['profit'] = e.target.value
                                var coach_tickets = this.state.coach_tickets.filter(ct => ct.skill != coach_skill.id)
                                coach_tickets.push(current_coach_ticket)
                              }
                              else {
                                current_coach_ticket = {
                                  skill: coach_skill.id,
                                  price: 0.0,
                                  profit: e.target.value,
                                }
                                var coach_tickets = this.state.coach_tickets
                                coach_tickets.push(current_coach_ticket)
                              }
                              this.setState({coach_tickets: coach_tickets})
                            }
                          }
                      />
                  </div>
                </div>
            }
          </div>
        )
      })
    }

    render() {
        if (this.props.accept) {
            return (
                <div className='ticket_creation_wrapper'>
                    <h3>Закрыть абонемент?</h3>
                    <button
                        id='add-ticket'
                        key='ticket-accept'
                        className='drill_traffic_btn'
                        data-ticket_id={this.props.ticket.id}
                        data-club_unit={this.state.clubUnitId}
                        data-close_ticket='true'
                        onClick={this.handleAddTicket}
                        data-closed='1'
                    >
                        &#10004;
                    </button>
                    <button
                        id='decline-ticket'
                        key='ticket-decline'
                        className='drill_traffic_btn'
                        onClick={this.props.handleDeclineService}
                    >
                        &#10008;
                    </button>
                </div>
            )
        }
        if (!this.state.clubUnitId && this.props.new_ticket) {
            return (
                <div className='background_lock_wrapper' id='background_lock_id'>
                    <div className='new_drill_form_wrapper'>
                        {this.renderFilter()}
                    </div>
                </div>
            )
        }
        return (
            <div className='ticket_creation_wrapper' id='ticket_creation_form_id'>
                <h3>Абонемент</h3>
                <div className='promo_text_wrapper'>
                    <input
                        key='ticket_name'
                        id='ticket_name'
                        type='text'
                        placeholder='Название абонемента'
                        className='promo_text'
                        defaultValue={(this.props.ticket) ? this.props.ticket.ticket : ''}
                    />
                </div>
                <div className='promo_text_wrapper'>
                    <input
                        key='ticket_drill_number'
                        id='ticket_drill_number'
                        type='number'
                        placeholder='Количество тренировок'
                        className='promo_text'
                        defaultValue={(this.props.ticket) ? parseInt(this.props.ticket.drill_number) : ''}
                    />
                </div>
                <div className='ticket_selector_wrapper ticket_category_wrapper'>
                    {localStorage.getItem('user_type') > 2
                        ? <Creatable
                              isClearable
                              onChange={
                                  (value: any, label: any) => {
                                      if (value) {
                                          this.setState(
                                              {
                                                  ticket_category: {
                                                      id: (parseInt(value["value"])) ? value["value"] : null,
                                                      name: value["label"],
                                                  }
                                              }
                                          )
                                      }
                                      else {
                                        this.setState(
                                            {
                                                ticket_category: {},
                                            }
                                        )
                                      }
                                  }
                              }
                              onInputChange={(e) => {}}
                              options={
                                  Array.from(this.state.categories, (val) => new Object({"value": val.id, "label": val.name}))
                              }
                              defaultValue={
                                  (this.props.ticket && this.props.ticket.category)
                                      ? {label: this.props.ticket.category.name, value: this.props.ticket.category.id}
                                      : ''
                              }
                              styles={select_styles}
                              placeholder={"Категория абонемента"}
                          />
                        : <Select
                              onChange={
                                  (value: any, label: any) => {
                                      if (value) {
                                          this.setState(
                                              {
                                                  ticket_category: {
                                                      id: (parseInt(value["value"])) ? value["value"] : null,
                                                      name: value["label"],
                                                  }
                                              }
                                          )
                                      }
                                  }
                              }
                              onInputChange={(e) => {}}
                              options={
                                  Array.from(this.state.categories, (val) => new Object({"value": val.id, "label": val.name}))
                              }
                              defaultValue={
                                  (this.props.ticket && this.props.ticket.category)
                                      ? {label: this.props.ticket.category.name, value: this.props.ticket.category.id}
                                      : ''
                              }
                              styles={select_styles}
                              placeholder={"Категория абонемента"}
                          />
                    }

                    <div>Количество дней действия</div>
                    <div className='promo_text_wrapper'>
                        <input
                            key='ticket_working_days'
                            id='ticket_working_days'
                            type='number'
                            placeholder='Количество дней действия'
                            className='promo_text'
                            defaultValue={(this.props.ticket) ? parseInt(this.props.ticket.days) : ''}
                        />
                    </div>

                    <div>Вес абонемента в категории</div>
                    <div className='promo_text_wrapper'>
                        <input
                            key='ticket_category_weight'
                            id='ticket_category_weight'
                            type='number'
                            placeholder='Вес абонемента в категории'
                            className='promo_text'
                            defaultValue={(this.props.ticket) ? parseInt(this.props.ticket.category_coefficient) : ''}
                        />
                    </div>
                </div>
                <div className='character_selector_drill_wrapper'>
                    <Select
                        onChange={
                            (e) => {
                                var ticket_disciplines = this.state.ticket_disciplines;
                                ticket_disciplines = Array.from(e, (val) => val["value"]);
                                this.setState(
                                    {
                                        ticket_disciplines: ticket_disciplines,
                                    }
                                )
                            }
                        }
                        onInputChange={(e) => {}}
                        options={
                            Array.from(this.state.disciplines, (val) => new Object({"value": val.id, "label": val.name}))
                        }
                        defaultValue={
                            (this.props.ticket && this.props.ticket.discipline)
                                ? Array.from(this.props.ticket.discipline, d => new Object({"value": d.id, "label": d.name}))
                                : ''
                        }
                        styles={select_styles}
                        isMulti={true}
                        menuPosition={'fixed'}
                        placeholder={"Дисциплины"}
                    />
                </div>
                <div className='character_selector_drill_wrapper'>
                    <Select
                        onChange={
                            (value: any, label: any) => {
                                if (value) {
                                    this.setState(
                                        {
                                            ticket_drill_type: (parseInt(value["value"])) ? value["value"] : null,
                                        }
                                    )
                                }
                            }
                        }
                        onInputChange={(e) => {}}
                        options={
                            Array.from(this.state.drills_types, (val) => new Object({"value": val.id, "label": val.name}))
                        }
                        defaultValue={
                            (this.props.ticket && this.props.ticket.drill_type)
                                ? {
                                    "value": this.props.ticket.drill_type.id,
                                    "label": this.props.ticket.drill_type.name,
                                  }
                                : ''
                        }
                        styles={select_styles}
                        menuPosition={'fixed'}
                        placeholder={"Тип тренировки"}
                    />
                </div>
                <div className='checkbox_label_wrapper'>
                    <div className='filter_input_container'>
                    <div className='close_notify'>
                        <div className='client_arrive_chbox_wrapper'>
                            {this.props.ticket && this.props.ticket.illusive
                                ? <input
                                      type='checkbox'
                                      id='ghost-ticket-id'
                                      key='ghost-ticket-key'
                                      name='user-arrive'
                                      className='arrive_chbox'
                                      defaultChecked={true}
                                      onChange={
                                          (e) => {
                                              this.setState(
                                                  {
                                                      ghost_ticket: (e.target.checked) ? true : false,
                                                  }
                                              )
                                          }
                                      }
                                  />
                                : <input
                                      type='checkbox'
                                      id='ghost-ticket-id'
                                      key='ghost-ticket-key'
                                      name='user-arrive'
                                      className='arrive_chbox'
                                      onChange={
                                          (e) => {
                                              this.setState(
                                                  {
                                                      ghost_ticket: (e.target.checked) ? true : false,
                                                  }
                                              )
                                          }
                                      }
                                  />
                            }
                            <span
                                className='client_arrive_chbox_span'
                                onChange={
                                    (e) => {
                                        var ghost_ticket = document.getElementById('ghost-ticket-id');
                                        this.setState(
                                            {
                                                ghost_ticket: (ghost_ticket.checked) ? true : false,
                                            }
                                        )
                                    }
                                }
                            >
                            </span>
                        </div>
                    </div>
                    <label className="checkbox_label">Призрачный абонемент</label>
                    </div>
                </div>
                <div className='checkbox_label_wrapper'>
                    <div className='filter_input_container'>
                    <div className='close_notify'>
                        <div className='client_arrive_chbox_wrapper'>
                            {this.props.ticket && this.props.ticket.coach_drill_cost.length > 0
                                ? <input
                                      type='checkbox'
                                      id='ghost-ticket-id'
                                      key='ghost-ticket-key'
                                      name='user-arrive'
                                      className='arrive_chbox'
                                      defaultChecked={true}
                                      onChange={
                                          (e) => {
                                              this.setState(
                                                  {
                                                      different_price: !this.state.different_price,
                                                  }
                                              )
                                          }
                                      }
                                  />
                                : <input
                                      type='checkbox'
                                      id='ghost-ticket-id'
                                      key='ghost-ticket-key'
                                      name='user-arrive'
                                      className='arrive_chbox'
                                      onChange={
                                          (e) => {
                                              this.setState(
                                                  {
                                                      different_price: !this.state.different_price,
                                                  }
                                              )
                                          }
                                      }
                                  />
                            }
                            <span
                                className='client_arrive_chbox_span'
                                onChange={
                                    (e) => {
                                        var ghost_ticket = document.getElementById('ghost-ticket-id');
                                        this.setState(
                                            {
                                                different_price: !this.state.different_price,
                                            }
                                        )
                                    }
                                }
                            >
                            </span>
                        </div>
                    </div>
                    <label className="checkbox_label">Цена зависит от уровня тренера</label>
                    </div>
                </div>
                {!this.state.different_price
                  ? <div>
                      <div
                        className='promo_text_wrapper'
                        style={
                          {
                            marginTop: '0.5rem',
                          }
                        }
                      >
                          <input
                              key='ticket_price'
                              id='ticket_price'
                              type='number'
                              placeholder='Цена'
                              className='promo_text'
                              defaultValue={(this.props.ticket) ? parseFloat(this.props.ticket.price) : ''}
                              onChange={
                                (e) => {
                                  this.setState({ticket_price: e.target.value})
                                }
                              }
                          />
                      </div>
                      <div className='promo_text_wrapper'>
                          <input
                              key='ticket_profit'
                              id='ticket_profit'
                              type='number'
                              placeholder='Выгода'
                              className='promo_text'
                              defaultValue={(this.props.ticket) ? parseFloat(this.props.ticket.profit) : ''}
                              onChange={
                                (e) => {
                                  this.setState({ticket_profit: e.target.value})
                                }
                              }
                          />
                      </div>
                    </div>
                  : <div>
                      {this.renderCoachSkillTickets()}
                    </div>
                }
                <div className='checkbox_label_wrapper'>
                    <div className='filter_input_container'>
                    <div className='close_notify'>
                        <div className='client_arrive_chbox_wrapper'>
                            {this.props.ticket && this.props.ticket.freeze
                                ? <input
                                      type='checkbox'
                                      id='freezable-ticket-id'
                                      key='freezable-ticket-key'
                                      name='user-arrive'
                                      className='arrive_chbox'
                                      defaultChecked={true}
                                      onChange={
                                          (e) => {
                                              this.setState(
                                                  {
                                                      freezable_ticket: (e.target.checked) ? true : false,
                                                  }
                                              )
                                          }
                                      }
                                  />
                                : <input
                                      type='checkbox'
                                      id='freezable-ticket-id'
                                      key='freezable-ticket-key'
                                      name='user-arrive'
                                      className='arrive_chbox'
                                      onChange={
                                          (e) => {
                                              this.setState(
                                                  {
                                                      freezable_ticket: (e.target.checked) ? true : false,
                                                  }
                                              )
                                          }
                                      }
                                  />
                            }
                            <span
                                className='client_arrive_chbox_span'
                                onChange={
                                    (e) => {
                                        var freezable_ticket = document.getElementById('freezable-ticket-id');
                                        this.setState(
                                            {
                                                freezable_ticket: (freezable_ticket.checked) ? true : false,
                                            }
                                        )
                                    }
                                }
                            >
                            </span>
                        </div>
                    </div>
                    <label className="checkbox_label">Разрешена заморозка</label>
                    </div>
                </div>
                <div
                  className='promo_text_wrapper'
                  style={
                    {
                      marginTop: '0.5rem',
                    }
                  }
                >
                    <textarea
                        key='ticket_bonus'
                        id='ticket_bonus'
                        className='promo_text'
                        defaultValue={(this.props.ticket && this.props.ticket.bonus) ? this.props.ticket.bonus : ''}
                    />
                </div>
                <button
                    id='add-ticket'
                    key='ticket-accept'
                    className='drill_traffic_btn'
                    data-promo_id={(this.props.ticket) ? this.props.ticket.id : ''}
                    data-club_unit={this.state.clubUnitId}
                    onClick={this.handleAddTicket}
                >
                    &#10004;
                </button>
                <button
                    id='decline-ticket'
                    key='ticket-decline'
                    className='drill_traffic_btn'
                    onClick={this.props.handleDeclineService}
                >
                    &#10008;
                </button>
            </div>
        )
    }
}

class ProductsControl extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            brands: [],
            product_category: (this.props.product && this.props.product.category.length > 0)
              ? Array.from(this.props.product.category, (c) => c.id)
              : [],
            product_brand: (this.props.product && this.props.product.brand_name)
              ? this.props.product.brand_name.id
              : null,
            product_name: (this.props.product) ? this.props.product.name : null,
            product_description: (this.props.product) ? this.props.product.description : null,
            product_color: (this.props.product) ? this.props.product.color : null,
            product_sex: (this.props.product) ? this.props.product.sex : null,
            product_age: (this.props.product) ? this.props.product.age : null,
            product_article_number: (this.props.product) ? this.props.product.article_number : null,
            product_price: (this.props.product) ? this.props.product.price : null,
            product_currency: (this.props.product) ? this.props.product.currency : null,
            product_discount: (this.props.product) ? this.props.product.discount : null,
            product_rent: (this.props.product) ? this.props.product.rent : false,
            product_exist_gallery: (this.props.product) ? this.props.product.gallery : [],
            product_size: (this.props.product) ? this.props.product.size : null,
            sex: [
              {
                "value": 1,
                "label": "Муж."
              },
              {
                "value": 2,
                "label": "Жен."
              },
              {
                "value": 3,
                "label": "Унисекс"
              },
            ],
            age: [
              {
                "value": 1,
                "label": "Взрослые"
              },
              {
                "value": 2,
                "label": "Дети"
              },
              {
                "value": 3,
                "label": "Неважно"
              },
            ],
            stop_stream: true,
        }
    }

    componentDidMount() {
      this.getSlugItems('categories')
      this.getSlugItems('brands')
    }

    getSlugItems(obj) {
      this.setState(
        {
            lock_spinner: true,
        }, () => {
          var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/market/' + obj + '/');
          uri.searchParams.set('limit', 1000);
          fetch(uri.toString(), {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
          })
          .then(json => {
            if (this.props.logged_in) {
              this.setState({[obj]: json.items})
            }
          })
        }
      )
    }

    renderGallery() {
      return this.state.product_exist_gallery.map((gal, index) => {
        return (
          <div
            key={'gallery_wrapper-' + index}
            style={
              {
                position: 'relative',
                marginLeft: '0.15rem',
                marginRight: '0.15rem',
              }
            }
          >
            <div
              style={
                {
                  position: 'absolute',
                  right: '0',
                  color: '#a7a6a6',
                  fontWeight: '600',
                  size: '2rem',
                  cursor: 'pointer',
                }
              }
              onClick={
                () => {
                  var exist_gallery = this.state.product_exist_gallery
                  exist_gallery.splice(index, 1)
                  this.setState({product_exist_gallery: exist_gallery})
                }
              }
            >
              &#9746;
            </div>
            <img src={gal} width='75' />
          </div>
        )
      })
    }

    render() {
        if (this.props.accept) {
            return (
                <div className='ticket_creation_wrapper'>
                    <h3>Удалить товар?</h3>
                    <button
                        id='add-ticket'
                        key='ticket-accept'
                        className='drill_traffic_btn'
                        data-close_ticket='true'
                        onClick={
                            (e) => {
                                this.props.handleRemoveProduct(this.props.product.id)
                            }
                        }
                        data-closed='1'
                    >
                        &#10004;
                    </button>
                    <button
                        id='decline-ticket'
                        key='ticket-decline'
                        className='drill_traffic_btn'
                        onClick={this.props.handleDeclineService}
                    >
                        &#10008;
                    </button>
                </div>
            )
        }
        else {
            return (
                <div className='ticket_creation_wrapper' id='product_creation_form_id'>
                    <div className='character_selector_drill_wrapper'>
                        <Creatable
                            onChange={
                                (value: any, label: any) => {
                                    if (value.filter(v => v.__isNew__).length > 0) {
                                      var new_category = value.filter(v => v.__isNew__)[0]
                                      var uri = new URL(
                                          process.env.REACT_APP_BACKEND_URI +
                                          '/api/v1/market/categories/'
                                      );
                                      fetch(uri.toString(), {
                                        method: 'POST',
                                        body: JSON.stringify({name: new_category.value}),
                                        headers: {
                                          Authorization: `Bearer ${localStorage.getItem('token')}`,
                                          'Content-Type': 'application/json',
                                        }
                                      })
                                      .then(res => res.json())
                                      .then(json => {
                                        // var categories = this.state.categories
                                        // categories.push(json)
                                        value = value.map(
                                          (v) => {
                                            if (v.__isNew__) {
                                              return {
                                                value: json.id,
                                                label: json.name,
                                              }
                                            }
                                            else {
                                              return v
                                            }
                                          }
                                        )
                                        this.setState(
                                          {
                                            // categories: categories,
                                            product_category: Array.from(value, (v) => v.value),
                                          }
                                        )
                                      })
                                    }
                                    else if (value) {
                                        this.setState(
                                            {
                                                product_category: Array.from(value, (v) => v.value),
                                            }
                                        )
                                    }
                                    else {
                                        this.setState(
                                            {
                                                product_category: [],
                                            }
                                        )
                                    }
                                }
                            }
                            onInputChange={(e) => {}}
                            options={
                                Array.from(this.state.categories, (val) => new Object({"value": val.id, "label": val.name}))
                            }
                            defaultValue={
                                (this.props.product && this.props.product.category.length > 0)
                                    ? Array.from(
                                        this.props.product.category, (c1) => new Object({"value": c1.id, "label": c1.name})
                                      )
                                    : []
                            }
                            styles={select_styles}
                            menuPosition={'fixed'}
                            placeholder={"Категории"}
                            isMulti={true}
                        />
                    </div>
                    <div className='character_selector_drill_wrapper'>
                        <Creatable
                            onChange={
                                (value: any, label: any) => {
                                    if (value.__isNew__) {
                                      var uri = new URL(
                                          process.env.REACT_APP_BACKEND_URI +
                                          '/api/v1/market/brands/'
                                      );
                                      var form_data = new FormData()
                                      form_data.append('name', value.value)
                                      fetch(uri.toString(), {
                                        method: 'POST',
                                        body: form_data,
                                        headers: {
                                          Authorization: `Bearer ${localStorage.getItem('token')}`,
                                        }
                                      })
                                      .then(res => res.json())
                                      .then(json => {
                                        this.setState(
                                          {
                                            product_brand: json.id,
                                          }
                                        )
                                      })
                                    }
                                    if (value) {
                                        this.setState(
                                            {
                                                product_brand: value["value"],
                                            }
                                        )
                                    }
                                }
                            }
                            onInputChange={(e) => {}}
                            options={
                                Array.from(this.state.brands, (val) => new Object({"value": val.id, "label": val.name}))
                            }
                            defaultValue={
                                (this.props.product && this.props.product.brand_name)
                                    ? {
                                        "value": this.props.product.brand_name.id,
                                        "label": this.props.product.brand_name.name,
                                      }
                                    : ''
                            }
                            styles={select_styles}
                            menuPosition={'fixed'}
                            placeholder={"Бренд"}
                        />
                    </div>
                    <div
                      className='promo_text_wrapper'
                      style={
                        {
                          marginTop: '0.5rem',
                        }
                      }
                    >
                        <label
                          htmlFor='product_name'
                          className='product_desc_label'
                        >
                          Название
                        </label>
                        <input
                            key='product_name'
                            id='product_name'
                            type='text'
                            placeholder='Название'
                            className='promo_text'
                            defaultValue={(this.props.product) ? this.props.product.name : ''}
                            onChange={
                              (e) => {
                                this.setState({product_name: e.target.value})
                              }
                            }
                        />
                    </div>
                    <div
                      className='promo_text_wrapper'
                      style={
                        {
                          marginTop: '0.5rem',
                        }
                      }
                    >
                        <label
                          htmlFor='product_desc'
                          className='product_desc_label'
                        >
                          Описание
                        </label>
                        <textarea
                            key='product_desc'
                            id='product_desc'
                            className='promo_text'
                            defaultValue={(this.props.product) ? this.props.product.description : ''}
                            onChange={
                              (e) => {
                                this.setState({product_desc: e.target.value})
                              }
                            }
                        />
                    </div>
                    <div
                      className='promo_text_wrapper'
                      style={
                        {
                          marginTop: '0.5rem',
                        }
                      }
                    >
                        <label
                          htmlFor='product_color'
                          className='product_desc_label'
                        >
                          Цвет
                        </label>
                        <input
                            key='product_color'
                            id='product_color'
                            type='color'
                            className='promo_text'
                            style={
                              {
                                width: '10%',
                              }
                            }
                            defaultValue={(this.props.product) ? this.props.product.color : ''}
                            onChange={
                              (e) => {
                                this.setState({product_color: e.target.value})
                              }
                            }
                        />
                    </div>
                    <div className='character_selector_drill_wrapper'>
                        <Select
                            onChange={
                                (value: any, label: any) => {
                                    if (value) {
                                        this.setState(
                                            {
                                                product_sex: value["value"],
                                            }
                                        )
                                    }
                                }
                            }
                            onInputChange={(e) => {}}
                            options={this.state.sex}
                            defaultValue={
                                (this.props.product && this.props.product.sex)
                                    ? {
                                        "value": this.props.product.sex,
                                        "label": this.state.sex.filter(
                                          s => s.value === parseInt(this.props.product.sex)
                                        )[0]["label"],
                                      }
                                    : ''
                            }
                            styles={select_styles}
                            menuPosition={'fixed'}
                            placeholder={"Пол"}
                        />
                    </div>
                    <div className='character_selector_drill_wrapper'>
                        <Select
                            onChange={
                                (value: any, label: any) => {
                                    if (value) {
                                        this.setState(
                                            {
                                                product_age: value["value"],
                                            }
                                        )
                                    }
                                }
                            }
                            onInputChange={(e) => {}}
                            options={this.state.age}
                            defaultValue={
                                (this.props.product && this.props.product.age)
                                    ? {
                                        "value": this.props.product.age,
                                        "label": this.state.age.filter(
                                          s => s.value === parseInt(this.props.product.age)
                                        )[0]["label"],
                                      }
                                    : ''
                            }
                            styles={select_styles}
                            menuPosition={'fixed'}
                            placeholder={"Возраст"}
                        />
                    </div>
                    <div
                      className='promo_text_wrapper'
                      style={
                        {
                          marginTop: '0.5rem',
                        }
                      }
                    >
                        <label
                          htmlFor='product_size'
                          className='product_desc_label'
                        >
                          Размер
                        </label>
                        <input
                            key='product_size'
                            id='product_size'
                            type='text'
                            placeholder='Размер'
                            className='promo_text'
                            defaultValue={this.state.product_size}
                            onChange={
                              (e) => {
                                this.setState({product_size: e.target.value})
                              }
                            }
                        />
                    </div>
                    <div
                      className='promo_text_wrapper'
                      style={
                        {
                          marginTop: '0.5rem',
                        }
                      }
                    >
                        <label
                          htmlFor='product_article_number'
                          className='product_desc_label'
                        >
                          Артикул
                        </label>
                        <input
                            key='product_article_number'
                            id='product_article_number'
                            type='text'
                            placeholder='Артикул'
                            className='promo_text'
                            defaultValue={this.state.product_article_number}
                            onChange={
                              (e) => {
                                this.setState({product_article_number: e.target.value})
                              }
                            }
                        />
                        <div
                          className='btn_border orange_border'
                          style={
                            {
                              margin: '1rem',
                              cursor: 'pointer',
                              fontSize: '1.25rem',
                              width: 'fit-content',
                              marginRight: 'auto',
                              marginLeft: 'auto',
                              display: 'none',
                            }
                          }
                          onClick={
                            () => {
                              this.setState({stop_stream: !this.state.stop_stream})
                            }
                          }
                        >
                          Сканировать штрихкод
                        </div>
                        {!this.state.stop_stream
                          ? <BarcodeScannerComponent
                              width={'100%'}
                              height={'30%'}
                              onUpdate={(err, result) => {
                                  if (result) {
                                      this.setState(
                                          {
                                              article_number: result.text,
                                              stop_stream: true,
                                          }
                                      )
                                }
                                else {
                                  document.getElementById('toast-body-id').innerHTML = "Штрихкод не читается";
                                  var x = document.getElementById("toast-wrapper-id");
                                  x.className = 'show';
                                  setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                                }
                              }}
                            />
                          : ''
                        }
                    </div>
                    <div
                      className='promo_text_wrapper'
                      style={
                        {
                          marginTop: '0.5rem',
                        }
                      }
                    >
                        <label
                          htmlFor='product_price'
                          className='product_desc_label'
                        >
                          Цена
                        </label>
                        <input
                            key='product_price'
                            id='product_price'
                            type='number'
                            placeholder='Цена'
                            className='promo_text'
                            defaultValue={(this.props.product) ? parseFloat(this.props.product.price) : ''}
                            onChange={
                              (e) => {
                                this.setState({product_price: e.target.value})
                              }
                            }
                        />
                    </div>
                    <div
                      className='promo_text_wrapper'
                      style={
                        {
                          marginTop: '0.5rem',
                        }
                      }
                    >
                        <label
                          htmlFor='product_discount'
                          className='product_desc_label'
                        >
                          Скидка
                        </label>
                        <input
                            key='product_discount'
                            id='product_discount'
                            type='number'
                            placeholder='Скидка'
                            className='promo_text'
                            defaultValue={(this.props.product) ? parseFloat(this.props.product.discount) : ''}
                            onChange={
                              (e) => {
                                this.setState({product_discount: e.target.value})
                              }
                            }
                        />
                    </div>
                    <div className='checkbox_label_wrapper'>
                        <div className='filter_input_container'>
                        <div className='close_notify'>
                            <div className='client_arrive_chbox_wrapper'>
                                {this.props.product && this.props.product.rent
                                    ? <input
                                          type='checkbox'
                                          id='rent-product-id'
                                          key='rent-product-key'
                                          name='user-arrive'
                                          className='arrive_chbox'
                                          defaultChecked={true}
                                          onChange={
                                              (e) => {
                                                  this.setState(
                                                      {
                                                          product_rent: (e.target.checked) ? true : false,
                                                      }
                                                  )
                                              }
                                          }
                                      />
                                    : <input
                                          type='checkbox'
                                          id='rent-product-id'
                                          key='rent-product-key'
                                          name='user-arrive'
                                          className='arrive_chbox'
                                          onChange={
                                              (e) => {
                                                  this.setState(
                                                      {
                                                          product_rent: (e.target.checked) ? true : false,
                                                      }
                                                  )
                                              }
                                          }
                                      />
                                }
                                <span
                                    className='client_arrive_chbox_span'
                                    onChange={
                                        (e) => {
                                            var rent_product = document.getElementById('rent-product-id');
                                            this.setState(
                                                {
                                                    product_rent: (rent_product.checked) ? true : false,
                                                }
                                            )
                                        }
                                    }
                                >
                                </span>
                            </div>
                        </div>
                        <label className="checkbox_label">Аренда</label>
                        </div>
                    </div>
                    <div
                      style={
                        {
                          display: 'flex',
                          width: 'fit-content',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          marginTop: '1rem',
                          marginBottom: '1rem',
                        }
                      }
                    >
                      {this.renderGallery()}
                    </div>
                    <div
                      className='promo_text_wrapper'
                      style={
                        {
                          marginTop: '0.5rem',
                        }
                      }
                    >
                      <input
                        id='product_addition_gallery'
                        type='file'
                        multiple
                        className='multiple_upload_input'
                      />
                    </div>
                    <button
                        id='add-ticket'
                        key='ticket-accept'
                        className='drill_traffic_btn'
                        data-promo_id={(this.props.ticket) ? this.props.ticket.id : ''}
                        data-club_unit={this.state.clubUnitId}
                        onClick={
                          () => {
                            var product_data = {
                              id: (this.props.product) ? this.props.product.id : null,
                              category: this.state.product_category,
                              brand_name: this.state.product_brand,
                              name: this.state.product_name,
                              description: this.state.product_desc,
                              color: this.state.product_color,
                              sex: this.state.product_sex,
                              age: this.state.product_age,
                              article_number: this.state.product_article_number,
                              price: this.state.product_price,
                              currency: this.state.product_currency,
                              discount: this.state.product_discount,
                              rent: this.state.product_rent,
                              service: (this.props.product_type === 'product') ? false : true,
                              gallery: [],
                              size: this.state.product_size,
                            }
                            for (let i = 0; i < this.state.product_exist_gallery.length; i++) {
                              product_data[`exist_gallery[${i}]`] = this.state.product_exist_gallery[i].split('/')[this.state.product_exist_gallery[i].split('/').length - 1]
                            }
                            var gal = document.getElementById('product_addition_gallery')
                            var loaded_files = Array.from(gal.files, (g) => new Object({'key': (Math.random() + 1).toString(36).substring(3), f: g}))
                            for (let i = 0; i < loaded_files.length; i++) {
                              product_data['gallery'].push(loaded_files[i]['key'])
                              product_data[`gallery[${i}]`] = loaded_files[i]['key']
                              product_data[loaded_files[i]['key']] = loaded_files[i]['f']
                            }
                            this.props.handleAddProduct(
                              product_data,
                              'POST',
                            )
                          }
                        }
                    >
                        &#10004;
                    </button>
                    <button
                        id='decline-ticket'
                        key='ticket-decline'
                        className='drill_traffic_btn'
                        onClick={this.props.handleDeclineService}
                    >
                        &#10008;
                    </button>
                </div>
            )
        }
    }
}

class ProductManage extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
          id: window.location.pathname.split('/')[2],
          product: null,
          lock_spinner: false,
          product_items: {},
          add_product_item: false,
        }
    }

    componentDidMount() {
        this.getProductsServices()
        this.getProductItems()
    }

    getProductsServices() {
      this.setState(
        {
            lock_spinner: true,
        }, () => {
          var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/market/products/');
          uri.searchParams.set('id', this.state.id);
          fetch(uri.toString(), {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
          })
          .then(json => {
            if (this.props.logged_in) {
              this.setState(
                {
                  product: json.items[0],
                  lock_spinner: false,
                }
              )
            }
          })
        }
      )
    }

    getProductItems() {
      this.setState(
        {
            lock_spinner: true,
        }, () => {
          var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/market/product/items/');
          uri.searchParams.set('product', this.state.id);
          fetch(uri.toString(), {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
          })
          .then(json => {
            if (this.props.logged_in) {
              this.setState(
                {
                  product_items: json.items,
                  lock_spinner: false,
                }
              )
            }
          })
        }
      )
    }

    addProductItem(data) {
      this.setState(
        {
            lock_spinner: true,
        }, () => {
          var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/market/product/items/');
          fetch(uri.toString(), {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            method: 'POST',
            body: JSON.stringify(data),
          })
          .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
          })
          .then(json => {
            if (this.props.logged_in) {
              var product_items = this.state.product_items.concat(json)
              this.setState(
                {
                  product_items: product_items,
                  lock_spinner: false,
                  add_product_item: !this.state.add_product_item,
                }
              )
            }
          })
        }
      )
    }

    handleManageProductItem = (e) => {
      var key = e.target.dataset.change_key
      var value = ""
      var did = ""
      var tid = ""
      if (key.includes('start')) {
        did = 'prod_item_rent_date_start-'
        tid = 'prod_item_rent_time_start-'
      }
      else {
        did = 'prod_item_rent_date_stop-'
        tid = 'prod_item_rent_time_stop-'
      }
      var date_el = document.getElementById(did + e.target.dataset.prod_item_id)
      var time_el = document.getElementById(tid + e.target.dataset.prod_item_id)
      value = date_el.value + "T" + time_el.value
      this.addProductItem(
        {
          item_pk: e.target.dataset.prod_item_id,
          [key]: value,
        },
        'PUT',
      )
    }

    renderGallery() {
      return this.state.product.gallery.slice(1).map((gal) => {
        return (
          <div
            style={
              {
                marginLeft: '0.15rem',
                marginRight: '0.15rem',
              }
            }
          >
            <img src={gal} height='75' />
          </div>
        )
      })
    }

    render() {
      return (
        <div>
          <div>
            <div
              style={
                {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }
              }
            >
              <div
                style={
                  {
                    transform: 'rotate(180deg)',
                    fontSize: '2rem',
                    cursor: 'pointer',
                    display: 'block',
                  }
                }
                onClick={
                  () => {
                    this.props.history.back()
                  }
                }
              >
                &#10132;
              </div>
              <div
                style={
                  {
                    marginBottom: '1rem',
                    marginTop: '1rem',
                    fontSize: '1.25rem',
                    width: '90%',
                  }
                }
              >
                <div>
                  {this.state.product
                    ? <span>{this.state.product.name}</span>
                    : ''
                  }
                </div>
              </div>
            </div>
            <div
              style={
                {
                  display: 'flex',
                }
              }
            >
              <div
                style={
                  {
                    display: 'flex',
                    width: '30%',
                  }
                }
              >
                <div
                  style={
                    {
                      width: '100%',
                    }
                  }
                >
                  <div>
                    {this.state.product && this.state.product.gallery.length > 0
                      ? <img src={this.state.product.gallery[0]} />
                      : ''
                    }
                  </div>
                  {this.state.product
                    ? <div
                        style={
                          {
                            display: 'flex',
                            width: '100%',
                            overflowX: 'auto',
                          }
                        }
                      >
                        {this.renderGallery()}
                      </div>
                    : ''
                  }
                </div>
              </div>
              <div
                style={
                  {
                    width: '70%',
                  }
                }
              >
                <div
                  style={
                    {
                      width: 'fit-content',
                    }
                  }
                >
                  <span>Категории: </span>
                  {this.state.product
                    ? <span>
                        {Array.from(this.state.product.category, c => c.name).toString()}
                      </span>
                    : ''
                  }
                </div>
                <div
                  style={
                    {
                      width: 'fit-content',
                    }
                  }
                >
                  <span>Бренд: </span>
                  {this.state.product
                    ? <span>{this.state.product.brand_name.name}</span>
                    : ''
                  }
                </div>
                <div
                  style={
                    {
                      width: 'fit-content',
                    }
                  }
                >
                  <span>Артикул: </span>
                  {this.state.product
                    ? <span>{this.state.product.article_number}</span>
                    : ''
                  }
                </div>
                <div
                  style={
                    {
                      width: 'fit-content',
                    }
                  }
                >
                  <span>Пол: </span>
                  {this.state.product
                    ? <span>{this.props.sex[this.state.product.sex]}</span>
                    : ''
                  }
                </div>
                <div
                  style={
                    {
                      width: 'fit-content',
                    }
                  }
                >
                  <span>Возраст: </span>
                  {this.state.product
                    ? <span>{this.props.age[this.state.product.age]}</span>
                    : ''
                  }
                </div>
                <div
                  style={
                    {
                      width: 'fit-content',
                    }
                  }
                >
                  <span>Цена: </span>
                  {this.state.product
                    ? <span>{this.state.product.price} {this.state.product.currency}</span>
                    : ''
                  }
                </div>
                <div
                  style={
                    {
                      width: 'fit-content',
                    }
                  }
                >
                  <span>Скидка: </span>
                  {this.state.product
                    ? <span>{this.state.product.discount}</span>
                    : ''
                  }
                </div>
                <div
                  style={
                    {
                      width: 'fit-content',
                    }
                  }
                >
                  <span>Аренда: </span>
                  {this.state.product
                    ? <span>
                        {this.state.product.rent
                          ? <span>&#10004;</span>
                          : <span>&#10008;</span>
                        }
                      </span>
                    : ''
                  }
                </div>
                <div
                  style={
                    {
                      width: 'fit-content',
                      display: 'flex',
                    }
                  }
                >
                  <div>Кол-во:</div>
                  {this.state.product_items.length > 0
                    ? <div
                        style={
                          {
                            marginLeft: '0.2rem',
                          }
                        }
                      >
                        {this.state.product_items.length}
                      </div>
                    : ''
                  }
                  {this.state.product_items.length > 0
                    ? <div
                        style={
                          {
                            marginLeft: '1rem',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: 'var(--orange)',
                            borderRadius: '0.5rem',
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem',
                            cursor: 'pointer',
                          }
                        }
                        onClick={
                          () => {
                            this.setState(
                              {
                                  lock_spinner: true,
                              }, () => {
                                var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/market/product/items/');
                                var prod_item_pk = parseInt(this.state.product_items[this.state.product_items.length - 1].id)
                                uri.searchParams.set('item_pk', prod_item_pk)
                                fetch(uri.toString(), {
                                  headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${localStorage.getItem('token')}`
                                  },
                                  method: 'DELETE',
                                })
                                .then(res => {
                                  if (res.status === 401) {
                                      this.props.catchError(res.status);
                                      return
                                  }
                                  const response = res.json();
                                  return response;
                                })
                                .then(json => {
                                  if (this.props.logged_in) {
                                    var product_items = this.state.product_items.filter(p => parseInt(p.id) !== parseInt(prod_item_pk))
                                    this.setState(
                                      {
                                        product_items: product_items,
                                        lock_spinner: false,
                                      }
                                    )
                                  }
                                })
                              }
                            )
                          }
                        }
                      >
                        Уменьшить остаток
                      </div>
                    : ''
                  }
                </div>
                {this.state.add_product_item
                  ? <div>
                      <ProductItemManage
                        logged_in={this.props.logged_in}
                        catchError={this.props.catchError}
                        product={this.state.product}
                        addProductItem={this.addProductItem.bind(this)}
                      />
                    </div>
                  : <div>
                      <div
                        style={
                          {
                            cursor: 'pointer',
                            fontSize: '18px',
                            border: '2px solid',
                            borderRadius: '50%',
                            outline: 'none',
                            borderColor: '#f7941e',
                            backgroundColor: '#c5c6c8',
                            width: 'fit-content',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            padding: '0.5rem',
                            boxSizing: 'border-box',
                          }
                        }
                        onClick={
                          () => {
                            this.setState({add_product_item: !this.state.add_product_item})
                          }
                        }
                      >
                        Добавить
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
          {this.state.lock_spinner
              ? <Spinner />
              : ''
          }
        </div>
      )
    }
}

class ProductItemManage extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
          items_amount: 0,
          size: null,
          serial_number: null,
          rent_date_start: null,
          rent_date_stop: null,
          rent_time_start: null,
          rent_time_stop: null,
        }
    }

    render() {
      return (
        <div
          style={
            {
              display: 'flex',
              overflowX: 'auto',
              alignItems: 'center',
            }
          }
        >
          <div
            style={
              {
                cursor: 'pointer',
                border: '1px solid',
                borderColor: '#f7941e',
                backgroundColor: '#f7941e',
                borderRadius: '50%',
              }
            }
            onClick={
              () => {
                this.props.addProductItem(
                  {
                    product: this.props.product.id,
                    items: Array.from(
                      Array(this.state.items_amount),
                      () => new Object(
                        {
                          rent_time_start: (this.state.rent_date_start && this.state.rent_time_start) ? this.state.rent_date_start + 'T' + this.state.rent_time_start : null,
                          rent_time_stop: (this.state.rent_date_stop && this.state.rent_time_stop) ? this.state.rent_date_stop + 'T' + this.state.rent_time_stop : null,
                        }
                      )
                    ),
                  },
                )
              }
            }
          >
            <div
              style={
                {
                  width: '1.5rem',
                  height: '1.5rem',
                }
              }
            >
              &#10010;
            </div>
          </div>
          <div className='client_row_name add_item_border'>
            <div
              style={
                {
                  display: 'flex',
                  width: 'fit-content',
                }
              }
            >
              <label
                htmlFor='product_items_amount'
                style={
                  {
                    minWidth: '4rem',
                  }
                }
              >
                Кол-во:
              </label>
              <input
                id='product_items_amount'
                className='text_edit_input client_name'
                style={
                  {
                    border: '1px solid',
                    borderColor: '#f7941e',
                    width: '4rem',
                  }
                }
                type='number'
                onChange={
                  (e) => {
                    this.setState({items_amount: parseInt(e.target.value)})
                  }
                }
              />
            </div>
          </div>
          {this.props.product.rent
            ? <div>
                <input
                  className='text_edit_input client_contact'
                  type='date'
                  size='12'
                  onChange={
                    (e) => {
                      this.setState({rent_date_start: e.target.value})
                    }
                  }
                />
              </div>
            : ''
          }
          {this.props.product.rent
            ? <div className='add_item_border'>
                <input
                  className='text_edit_input client_contact'
                  type='time'
                  size='12'
                  onChange={
                    (e) => {
                      this.setState({rent_time_start: e.target.value})
                    }
                  }
                  defaultValue="00:00"
                />
              </div>
            : ''
          }
          {this.props.product.rent
            ? <div>
                <input
                  className='text_edit_input client_contact'
                  type='date'
                  size='12'
                  onChange={
                    (e) => {
                      this.setState({rent_date_stop: e.target.value})
                    }
                  }
                />
              </div>
            : ''
          }
          {this.props.product.rent
            ? <div>
                <input
                  className='text_edit_input client_contact'
                  type='time'
                  size='12'
                  onChange={
                    (e) => {
                      this.setState({rent_time_stop: e.target.value})
                    }
                  }
                  defaultValue="00:00"
                />
              </div>
            : ''
          }
        </div>
      )
    }
}

export { ServicesItems, ProductManage };
