import React from 'react';
import Spinner from './Spinner';

class Chat extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
          lock_spinner: false,
          show_part: 'contacts',
          current_chat: null,
          limit: 20,
          offset: 0,
          last_dialog_length: 0,
          load_previous: false,
        }
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.state.last_dialog_length !== this.props.dialog_messages.length && !this.state.load_previous) {
        var ch = document.getElementById('chat_messages_wrapper_id')
        if (ch) ch.scrollTop = ch.scrollHeight
        this.setState({last_dialog_length: this.props.dialog_messages.length, load_previous: false})
      }
    }

    renderSpeakersList() {
      return this.props.speakers.map((speaker) => {
        var unread_messages = false
        if (
          this.props.unread_chat_messages &&
          this.props.unread_chat_messages.filter(mes => parseInt(mes.outside_id) === parseInt(speaker.id)).length > 0
        ) unread_messages = true
        return (
          <div
            key={'contact-' + speaker.id}
            style={
              {
                cursor: 'pointer',
                width: 'fit-content',
              }
            }
            className={(unread_messages) ? 'speaker_container' : ''}
            onClick={
              () => {
                this.setState(
                  {
                    limit: 20,
                    offset: 0,
                  }, () => {
                    this.props.handleGetChat(speaker.id, this.state.limit, this.state.offset)
                    this.setState(
                        {
                            offset: this.state.offset + this.state.limit,
                            show_part: 'chat',
                            current_chat: speaker.id,
                        }, () => {
                          if (this.props.unread_chat_messages) this.props.replaceUnreadMessages(
                            this.props.unread_chat_messages.filter(mes => parseInt(mes.outside_id) !== parseInt(speaker.id))
                          )
                        }
                    );
                  }
                )
              }
            }
          >
            {speaker.first_name} {speaker.last_name}
          </div>
        )
      })
    }

    renderDialogMessages() {
      return this.props.dialog_messages.map((message) => {
        var mes_date = new Date(message.created_at).toISOString()
        return (
          <div
            key={'message-' + message.pk}
            data-message_pk={message.pk}
            className={(parseInt(message.author_id) === parseInt(this.props.account_chat_id)) ? 'author_me' : 'author_other'}
            style={
              {
                width: 'fit-content',
                marginLeft: (parseInt(message.author_id) === parseInt(this.props.account_chat_id)) ? 'auto' : '10px',
                marginRight: (parseInt(message.author_id) === parseInt(this.props.account_chat_id)) ? '10px' : 'auto',
                textAlign: (parseInt(message.author_id) === parseInt(this.props.account_chat_id)) ? 'right' : 'left',
                color: '#000',
                boxSizing: 'border-box',
                padding: '0.25rem',
                backgroundColor: '#f7941e4f',
                border: '1px solid #a51e2e63',
                marginTop: '0.25rem',
                marginBottom: '0.25rem',
              }
            }
          >
            <div
              style={
                {
                  fontSize: '0.6rem',
                }
              }
            >
              {mes_date.split('T')[0]} {mes_date.split('T')[1].split('.')[0]}
            </div>
            <div
              style={
                {
                  marginTop: '0.25rem',
                }
              }
            >
              {message.message}
            </div>
          </div>
        )
      })
    }

    render() {
        return (
          <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.chat_ref}>
            <div
              ref={this.props.chat_ref}
              className='chat_wrapper'
            >
              <div
                style={
                  {
                    display: 'flex',
                    border: '2px solid #f7941e',
                    padding: '1rem',
                    height: '100%',
                    boxSizing: 'border-box',
                  }
                }
              >
                <div
                  ref={this.props.chat_ref}
                  className='chat_contacts_wrapper'
                  style={
                    {
                      display: (this.props.device === 'mobile' && this.state.show_part !== 'contacts') ? 'none' : 'block',
                      borderRight: (this.props.device === 'desktop') ? '1px solid #f7941e' : 'none',
                    }
                  }
                >
                  <div
                    ref={this.props.chat_ref}
                    className='chat_column_header'
                  >
                    Контакты
                  </div>
                  <div
                    ref={this.props.chat_ref}
                    style={
                      {
                        textAlign: 'left',
                        overflow: 'auto',
                        height: '90%',
                      }
                    }
                  >
                    {this.renderSpeakersList()}
                  </div>
                </div>
                <div
                  ref={this.props.chat_ref}
                  className='chat_message_wrapper'
                  id='chat_container_id'
                  style={
                    {
                      display: (this.props.device === 'mobile' && this.state.show_part === 'contacts') ? 'none' : 'block',
                      borderLeft: (this.props.device === 'desktop') ? '1px solid #f7941e' : 'none',
                    }
                  }
                >
                  {this.props.device === 'mobile'
                    ? <div
                        style={
                          {
                            display: 'flex',
                          }
                        }
                      >
                        <div
                          className='chat_column_header'
                          style={
                            {
                              transform: 'rotate(180deg)',
                              width: '20%',
                            }
                          }
                          onClick={
                            () => {
                              this.setState(
                                {
                                  show_part: 'contacts',
                                  current_chat: null,
                                }
                              )
                            }
                          }
                        >
                          &#10132;
                        </div>
                        <div
                          ref={this.props.chat_ref}
                          className='chat_column_header'
                          style={
                            {
                              width: '80%',
                            }
                          }
                        >
                          Чат
                        </div>
                      </div>
                    : <div
                        ref={this.props.chat_ref}
                        className='chat_column_header'
                      >
                        Чат
                      </div>
                  }
                  {this.state.current_chat
                    ? <div
                        style={
                          {
                            height: '100%',
                          }
                        }
                      >
                        <div
                          className='chat_history_log'
                          style={
                            {
                              backgroundImage: 'url(\'/logo_1_transparent.png\')',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              height: '90%',
                              overflow: 'auto',
                            }
                          }
                          id="chat_messages_wrapper_id"
                        >
                          <div
                            style={
                              {
                                cursor: 'pointer',
                                transform: 'rotate(90deg)',
                              }
                            }
                            onClick={
                              (e) => {
                                this.setState(
                                  {load_previous: true}, () => {
                                    this.props.handleGetChat(this.state.current_chat, this.state.limit, this.state.offset)
                                    this.setState(
                                      {
                                        offset: this.state.offset + this.state.limit,
                                        show_part: 'chat',
                                      }
                                    );
                                  }
                                )
                              }
                            }
                          >
                            &#10096;
                          </div>
                          {this.renderDialogMessages()}
                        </div>
                        <div
                          style={
                            {
                              display: 'flex',
                              marginRight: 'auto',
                              marginLeft: 'auto',
                              width: 'fit-content',
                            }
                          }
                        >
                          <input
                            type='text'
                            size={(this.props.device === 'desktop') ? '40' : '20'}
                            className='text_edit_input client_contact'
                            style={
                              {
                                borderBottom: '1px solid #f7941e',
                                textAlign: 'left',
                              }
                            }
                            placeholder='Сообщение...'
                            id='message_input_id'
                          />
                          <div
                            style={
                              {
                                fontSize: '1.5rem',
                                color: '#f7941e',
                                cursor: 'pointer',
                              }
                            }
                            onClick={
                              () => {
                                this.setState(
                                  {new_mes: true}, () => {
                                    var data = {
                                      id: null,
                                      message: document.getElementById('message_input_id').value,
                                      destination_id: this.state.current_chat,
                                      message_type: 'chat',
                                    }
                                    this.props.ws.send(JSON.stringify(data))
                                    document.getElementById('message_input_id').value = ""
                                  }
                                )
                              }
                            }
                          >
                            &#10148;
                          </div>
                        </div>
                      </div>
                    : ''
                  }
                </div>
              </div>
            </div>
            {this.state.lock_spinner
                ? <Spinner />
                : <div></div>
            }
          </div>
        )
    }
}

export default Chat
