import React from 'react';
import Select from 'react-select';

const select_styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        padding: 20,
        'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        color: '#f7941e',
        border: '1px solid #f7941e',
        'background-color': '#c5c6c8',
        'outline-color': '#f7941e',
    }),
    container: (provided, state) => ({
        ...provided,
        width: '50%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'margin-bottom': '0.5rem',
        'margin-top': '1rem',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'background-color': '#c5c6c8',
    }),
}

class CreateNotify extends(React.Component) {
    constructor(props) {
        super(props);
        var client_sketches = '';
        if (this.props.client.length > 0) {
            client_sketches = this.props.client[0].sketches;
        }
        this.state = {
             client_sketches: client_sketches,
             notify_types: [],
        }
    }

    componentWillMount() {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/notify_types/');
        fetch(uri.href, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
          .then(res => {
              if (res.status === 401) {
                  this.props.catchError(res.status);
                  return
              }
              const response = res.json();
              return response;
          })
          .then(json => {
              if (this.props.logged_in) {
                  this.setState(
                      {
                          notify_types: json,
                      }
                  );
              }
          })
    }

    render() {
        return (
            <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_ref}>
                <div className='new_drill_form_wrapper'>
                    {parseInt(localStorage.getItem("user_type")) > 1
                        ? <div className='drill_time_wrapper'>
                              <Select
                                  options={
                                      Array.from(this.state.notify_types, (val) => new Object(
                                          {
                                              "value": val.id,
                                              "label": val.type,
                                          }
                                      ))
                                  }
                                  onChange={this.props.setNotifyType}
                                  styles={select_styles}
                                  placeholder={"Тип уведомления"}
                                  menuPosition={'fixed'}
                              />
                          </div>
                        : ''
                    }
                    <div className='drill_time_wrapper message_wrapper'>
                        <textarea
                            rows='3'
                            cols='20'
                            key='notify_message'
                            id='notify_message_id'
                            type='text'
                            data-client_id={this.props.client_id}
                            data-bid_id={this.props.bid_id}
                            className='message_input notify_message_input'
                        >
                            {this.state.client_sketches}
                        </textarea>
                    </div>
                    <div className='drill_time_wrapper'>
                        <label className='modal_label'>Выбери дату оповещения</label>
                        <input key='notify_date' id='notify_date_id' type='date' placeholder='Дата тренировки'  className='add_ticket_btn' />
                    </div>
                    <button
                        id='add-notify'
                        key='notify-accept'
                        className='drill_traffic_btn'
                        data-client_id={this.props.client_id}
                        data-bid_id={this.props.bid_id}
                        onClick={this.props.handleCreateNotification}
                    >
                        &#10004;
                    </button>
                    <button id='decline-notify' key='notify-decline' className='drill_traffic_btn' onClick={this.props.handleDeclineNewNotify}>&#10008;</button>
                </div>
            </div>
        )
    }
}

export default CreateNotify