import React from 'react';
import CreateNotify from './CreateNoty';
import { AcceptModal } from './AcceptModal';
import Spinner from './Spinner';
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
import compose from 'lodash/fp/compose';
import Select from 'react-select';
import {
  get_request,
  post_request,
} from './api/api_call'
import { locales } from '../helpers'

var _ = require('lodash');

const select_styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        padding: 20,
        'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        color: '#f7941e',
        border: '1px solid #f7941e',
        'background-color': '#c5c6c8',
        'outline-color': '#f7941e',
    }),
    container: (provided, state) => ({
        ...provided,
        width: '100%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'margin-bottom': '0.5rem',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'background-color': '#c5c6c8',
    }),
}

class BidsList extends(React.Component) {
    constructor(props) {
        super(props);
        this.modal_ref = React.createRef();
        var club_units = [];
        if (this.props.club_units) {
            club_units = this.props.club_units;
        }
        var date_start = new Date()
        date_start.setDate(1)
        var date_stop = new Date(date_start.getFullYear(), date_start.getMonth() + 1)
        this.state = {
            status: '',
            bids: [],
            page: 0,
            limit: 20,
            offset: 0,
            processing: false,
            new_bid: false,
            new_bid_form: '',
            club_units: club_units,
            reset_list: false,
            bid_sources: [],
            bids_source: '',
            sort_key: '-bid_date',
            lock_spinner: false,
            accept_remove: false,
            accept_remove_form: '',
            fts: null,
            statuses: {},
            colors: {},
            bids_date_start: date_start.toISOString().substr(0, 10),
            bids_date_stop: date_stop.toISOString().substr(0, 10),
            club_departments: [],
        }
    }

    handleClickOut = (e) => {
        if (this.modal_ref &&
            this.modal_ref.current &&
            (!this.modal_ref.current.contains(e.target) || e.target.id === 'background_lock_id')
        ) {
            this.setState(
                {
                    new_bid: false,
                }
            );
        }
    }

    getData() {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/bids/');
                if (this.state.fts) {
                    uri.searchParams.set('search', this.state.fts);
                }
                uri.searchParams.set('limit', this.state.limit);
                uri.searchParams.set('offset', this.state.offset);
                uri.searchParams.set('status', this.state.status);
                uri.searchParams.set('bid_source', this.state.bids_source);
                uri.searchParams.set('sort', this.state.sort_key);
                uri.searchParams.set('date_start', this.state.bids_date_start);
                uri.searchParams.set('date_stop', this.state.bids_date_stop);
                fetch(uri.toString(), {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (this.props.logged_in) {
                        var bids = json.results;
                        var limit = null;
                        var offset = null;
                        var processing = false;
                        if (json.next) {
                            var uri = new URL(json.next);
                            limit = uri.searchParams.get('limit');
                            offset = uri.searchParams.get('offset');
                            processing = true;
                        }
                        var res_bids = []
                        if (this.state.reset_list) {
                          res_bids = bids
                        }
                        else {
                          res_bids = this.state.bids.concat(bids)
                        }
                        this.setState(
                            {
                                bids: res_bids,
                                processing: processing,
                                limit: limit,
                                offset: offset,
                                reset_list: false,
                                lock_spinner: false,
                            }
                        );
                    }
                })
            }
        )
    }

    getBidSources() {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/bid_source/');
        fetch(uri.toString(), {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (this.props.logged_in) {
                this.setState({
                    bid_sources: json,
                });
            }
        })
    }

    getClubUnits() {
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/club_units/?short=1&my=1', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (this.props.logged_in) {
                var club_units = [];
                for (let i = 0; i < json.length; i++) {
                    club_units.push(
                        { id: json[i].id, name: json[i].name }
                    )
                }
                this.setState({
                    club_units: club_units,
                });
            }
        })
    }

    getBidConsts() {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/bid_consts/')
                get_request(uri.href, this.props.catchError).then(json => {
                    if (this.props.logged_in) {
                        this.setState({
                            statuses: json.statuses,
                            colors: json.colors,
                            lock_spinner: false,
                        });
                    }
                })
            }
        )
    }

    getClubDepartments() {
      this.setState(
        {
            lock_spinner: true,
        }, () => {
          var uri = new URL(
            process.env.REACT_APP_BACKEND_URI + '/api/v1/management/club/department/'
          )
          get_request(uri.href, this.props.catchError).then(
            json => {
              if (this.props.logged_in && json) {
                this.setState({
                  club_departments: json,
                  lock_spinner: false,
                });
              }
            }
          )
        }
      )
    }

    componentDidMount() {
        this.getData();
        if (this.state.club_units.length == 0) {
            this.getClubUnits();
        }
        this.getBidConsts();
        this.getClubDepartments()
        document.addEventListener('mousedown', this.handleClickOut);
        document.addEventListener('scroll', this.scrollListener);
    }

    componentWillMount() {
        this.getBidSources();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOut);
        document.removeEventListener('scroll', this.scrollListener);
    }

    scrollListener = (e) => {
        if (this.state.processing && document.documentElement.scrollTop >= (document.documentElement.scrollHeight - window.innerHeight)*0.9) {
            this.setState({processing: false}, () => {this.getData();});
        }
    }

    updateBidStatus = (e) => {
      var id = document.getElementById(e.target.id);
      var status = id.value;
        if (status === 'Новая') {
            status = 0;
        }
        else if (status === 'Ожидает ответа') {
            status = 1;
        }
        else if (status === 'Закрыта') {
            status = 2;
        }
        else {
            return
        }
      id = id.id.split('-')[id.id.split('-').length - 1];
      var data = {'bid': id, 'bid_status': status};
      fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/bids/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
      })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            else if (res.status === 406) {
                res.json().then((json) => {
                    this.props.updateCurrentShift(json);
                });
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (!json) {
                return
            }
            if (this.props.logged_in) {
                var bids = this.state.bids;
                for (let i = 0; i < bids.length; i++) {
                    if (bids[i]['id'] === json['id']) {
                        bids[i] = json;
                    }
                }
                this.setState({bids: bids});
            }
        })
    }

    handleChangeStatus = (e) => {
        var id = document.getElementById(e.target.id);
        id = id.id.split('-')[id.id.split('-').length - 1];
        var wrappers = document.getElementsByClassName('ticket_info_wrapper');
        for (let i = 0; i < wrappers.length; i++) {
            if (wrappers[i].id !== 'bid_selector_status_wrapper-' + id) {
                wrappers[i].style.display = 'none';
            }
        }
        if (document.getElementById('bid_selector_status_wrapper-' + id).style.display === 'block' ||
            getComputedStyle(document.getElementById('bid_selector_status_wrapper-' + id)).display === 'block') {
            document.getElementById('bid_selector_status_wrapper-' + id).style.display = 'none';
        }
        else {
            document.getElementById('bid_selector_status_wrapper-' + id).style.display = 'block';
        }
    }

    handleUpdateBid = (e) => {
        var data = {'bid': e.target.dataset.bid_id};
        if (e.target.dataset.field === "child") {
            data[e.target.dataset.field] = e.target.checked;
        }
        else {
            data[e.target.dataset.field] = e.target.value;
        }
        this.setState(
            {lock_spinner: true},
            () => {
                var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/bids/')
                post_request(
                    uri.href,
                    data,
                    this.props.catchError,
                    'POST',
                ).then(json => {
                    if (!json) {
                        return
                    }
                    if (this.props.logged_in) {
                        var bids = this.state.bids;
                        for (let i = 0; i < bids.length; i++) {
                            if (parseInt(bids[i]['id']) === parseInt(json['id'])) {
                                bids[i] = json;
                            }
                        }
                        this.setState(
                            {bids: bids, lock_spinner: false}
                        );
                    }
                })
            }
        )
    }

    handleBidToUser = (e) => {
        var id = document.getElementById(e.target.id);
        id = id.id.split('-')[id.id.split('-').length - 1];
        var data = {'bid': id, 'success': true};
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/bids/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(data)
        })
          .then(res => {
              if (res.status === 401) {
                  this.props.catchError(res.status);
                  return
              }
              else if (res.status === 406) {
                  res.json().then((json) => {
                      this.props.updateCurrentShift(json);
                  });
                  return
              }
              else if (res.status === 409) {
                  console.log("USER EXISTS");
                  document.getElementById('toast-body-id').innerHTML = 'Пользователь с такими данными существует';
                  var x = document.getElementById("toast-wrapper-id");
                  x.className = 'show';
                  setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                  return
              }
              const response = res.json();
              return response;
          })
          .then(json => {
              if (!json) {
                  return
              }
              if (this.props.logged_in) {
                  var bids = this.state.bids;
                  for (let i = 0; i < bids.length; i++) {
                      if (bids[i]['id'] === json['id']) {
                          bids[i] = json;
                      }
                  }
                  this.setState({bids: bids});
              }
          })
    }

    handleAddBid = (e) => {
        if (document.getElementById('background_lock_id') !== null) {
            document.getElementById('background_lock_id').style.display = 'block';
        }
        this.setState({new_bid: true});
    }

    handleCreateBid = (data) => {
        this.setState({lock_spinner: true}, () => {
            fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/bids/', {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
              },
              body: JSON.stringify(data)
            })
              .then(res => {
                  if (res.status === 401) {
                      this.props.catchError(res.status);
                      return
                  }
                  else if (res.status === 406) {
                      res.json().then((json) => {
                          this.props.updateCurrentShift(json);
                      });
                      return
                  }
                  else if (res.status === 409) {
                      document.getElementById('toast-body-id').innerHTML = 'Пользователь с такими данными существует';
                      var x = document.getElementById("toast-wrapper-id");
                      x.className = 'show';
                      setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                      return
                  }
                  const response = res.json();
                  return response;
              })
              .then(json => {
                  if (!json) {
                      return
                  }
                  if (this.props.logged_in) {
                      var bids = this.state.bids;
                      bids.splice(0, 0, json)
                      this.setState(
                        {
                          bids: bids,
                          new_bid: false,
                          lock_spinner: false,
                        }
                      );
                  }
              })
        });
    }

    handleCloseBid = (e) => {
        this.setState({
            accept_remove: true,
            accept_remove_form: <AcceptModal
                accept_question='Удалить заявку?'
                accept_id={e.target.dataset.bid_id}
                Accept={this.closeBid.bind(this)}
                Decline={this.declineCloseBid.bind(this)}
            />
        });
    }

    declineCloseBid = (e) => {
        this.setState({ accept_remove: false, accept_remove_form: '' });
    }

    closeBid = (e) => {
        var bid_id = e.target.dataset.accept_id;
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/bids/');
        uri.searchParams.set('bid', bid_id);
        fetch(uri, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            var new_bids = this.state.bids;
            for (let i = 0; i < new_bids.length; i++) {
                if (parseInt(new_bids[i]["id"]) === parseInt(bid_id)) {
                    new_bids.splice(i, 1);
                    break
                }
            }
            this.setState({ bids: new_bids, accept_remove: false, accept_remove_form: '' });
        })
    }

    handleUpdateSketches = (e) => {
      this.setState({lock_spinner: true},
        () => {
          var id = e.target.dataset.bid_id;
          var sketch = document.getElementById('client_sketch-' + id).value;
          var bid_index = -1;
          for (let i = 0; i < this.state.bids.length; i++) {
            if (this.state.bids[i]['id'] === parseInt(id)) {
                bid_index = this.state.bids[i];
            }
          }
          var data = {
            bid: id,
            comment: sketch,
          }
          var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/bids/')
          post_request(
            uri.href,
            data,
            this.props.catchError,
            'POST'
          ).then(json => {
            if (!json) {
              return
            }
            let bids = this.state.bids;
            bids[bid_index] = json;
            this.setState(
              {
                bids: bids,
                lock_spinner: false,
              }
            );
          })
      })
    }

    handleSearchBids = (e) => {
        this.setState(
            {
                fts: document.getElementById('fts_input_id').value,
                reset_list: true,
                limit: 20,
                offset: 0,
            }, () => {
                this.getData();
            }
        );
    }

    renderBidColor(bid_id) {
        return Object.keys(this.state.colors).map((key) => {
            var style = {
                backgroundColor: this.state.colors[key],
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                cursor: 'pointer',
                marginTop: '0.25rem',
                marginBottom: '0.25rem',
            };
            return (
                <div
                    key={"bid-color-" + key + "-" + bid_id}
                    style={
                        style
                    }
                    data-bid_id={bid_id}
                    data-color={key}
                    onClick={
                        (e) => {
                            var data = {'bid': e.target.dataset.bid_id, 'color': e.target.dataset.color};
                            fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/bids/', {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                              },
                              body: JSON.stringify(data)
                            })
                              .then(res => {
                                  if (res.status === 401) {
                                      this.props.catchError(res.status);
                                      return
                                  }
                                  else if (res.status === 406) {
                                      res.json().then((json) => {
                                          this.props.updateCurrentShift(json);
                                      });
                                      return
                                  }
                                  const response = res.json();
                                  return response;
                              })
                              .then(json => {
                                  if (!json) {
                                      return
                                  }
                                  if (this.props.logged_in) {
                                      var bids = this.state.bids;
                                      for (let i = 0; i < bids.length; i++) {
                                          if (bids[i]['id'] === json['id']) {
                                              bids[i] = json;
                                          }
                                      }
                                      this.setState({bids: bids});
                                  }
                              })
                        }
                    }
                >
                </div>
            )
        })
    }

    renderBidEmployees(bid) {
      return this.state.club_departments.map((department) => {
        return (
          <div
            style={
              {
                display: 'block',
              }
            }
            key={'department-' + department.id}
          >
            <div
              style={
                {
                  fontWeight: '600',
                }
              }
            >
                {department.name}:
            </div>
            {localStorage.getItem('user_type') > 1
              ? <div>
                  <Select
                    options={
                      Array.from(
                        department.employees, (emp) => new Object(
                          {
                            "value": emp.id,
                            "label": emp.employee.first_name + ' ' + emp.employee.last_name,
                            "department": department.id,
                          }
                        )
                      )
                    }
                    onChange={
                      (e) => {
                        this.setState({lock_spinner: true},
                          () => {
                            var employees = []
                            for (let depart of this.state.club_departments) {
                              if (e[0] && depart.id !== e[0]['department']) {
                                employees = employees.concat(
                                  Array.from(depart.employees, (emp) => emp.id).filter(emp => bid.employees.includes(emp))
                                )
                              }
                              else if (!e[0] && depart.id !== department.id) {
                                employees = employees.concat(
                                  Array.from(depart.employees, (emp) => emp.id).filter(emp => bid.employees.includes(emp))
                                )
                              }
                            }
                            employees = employees.concat(Array.from(e, i => i['value']))
                            post_request(
                              process.env.REACT_APP_BACKEND_URI + '/api/v1/management/bids/',
                              {
                                'bid': bid.id,
                                'employees': employees,
                              },
                              this.props.catchError,
                              'POST',
                            ).then(json => {
                              if (this.props.logged_in) {
                                var bids = this.state.bids.map(bid => bid.id === json.id ? json : bid)
                                this.setState(
                                  {
                                    bids: bids,
                                    lock_spinner: false,
                                  }
                                )
                              }
                            })
                          }
                        )
                      }
                    }
                    defaultValue={
                      Array.from(
                        department.employees, (emp) => new Object(
                          {
                            "value": emp.id,
                            "label": emp.employee.first_name + ' ' + emp.employee.last_name,
                          }
                        )
                      ).filter(emp => bid.employees.includes(emp.value))
                    }
                    styles={select_styles}
                    isClearable={true}
                    isMulti={true}
                    placeholder={"Сотрудники"}
                  />
                </div>
              : <div>
                    {
                      Array.from(
                        Array.from(
                          department.employees, (emp) => new Object(
                            {
                              "value": emp.id,
                              "label": emp.employee.first_name + ' ' + emp.employee.last_name,
                            }
                          )
                        ).filter(emp => bid.employees.includes(emp.value)),
                        (emp) => emp.label
                      ).toString()
                    }
                </div>
            }
          </div>
        )
      })
    }

    renderTableHeader() {
        let header = [];
        if (localStorage.getItem('user_type') > 1) {
            header = [
                locales.tables.heads.full_name,
                locales.tables.heads.contacts,
                locales.tables.heads.club_unit,
                locales.tables.heads.traffic_source,
                locales.tables.heads.bid_date,
                locales.tables.heads.bid_status,
                locales.tables.heads.employees,
                locales.tables.heads.notes,
            ];
        }
        else {
            if (this.props.device === "mobile") {
                header = ['ФИО', 'ДАТА ЗАЯВКИ', 'СТАТУС'];
            }
            else {
                header = ['ФИО', 'КЛУБ/ФИЛИАЛ', 'ИСТОЧНИК ТРАФИКА', 'ДАТА ЗАЯВКИ', 'СТАТУС'];
            }
        }
        return header.map((key, index) => {
            if (key === locales.tables.heads.bid_date) {
                return (
                    <div
                        key={index}
                        className={'clients_table_container_border clients_table_container_head clickable_mesh bids_column_' + index}
                        onClick={
                            () => {
                                this.setState(
                                    {
                                        sort_key: (this.state.sort_key === 'bid_date') ? '-bid_date' : 'bid_date',
                                        reset_list: true,
                                        limit: parseInt(this.state.limit) + parseInt(this.state.offset) - parseInt(this.state.limit),
                                        offset: 0,
                                    }, () => {this.getData();}
                                )
                            }
                        }
                    >
                        {key}
                        {this.state.sort_key === 'bid_date'
                            ? <span className='bid_sort_arrow'>&#8593;</span>
                            : <span className='bid_sort_arrow'>&#8595;</span>
                        }
                    </div>
                )
            }
            else {
                return (
                    <div
                        key={index}
                        className={'clients_table_container_border clients_table_container_head bids_column_' + index}
                    >
                        {key}
                    </div>
                )
            }
        })
    }

    renderTableData() {
        if (this.props.update_data === 'yes') {
            this.getData();
        }
        return this.state.bids.map((bid) => {
            if (localStorage.getItem('user_type') > 0) {
                return (
                    <div
                        key={'client-' + bid.id}
                        className='client_row_wrapper'
                        style={
                            {
                                backgroundColor: bid.color_val,
                            }
                        }
                    >
                        <div className='client_col_name_wrapper bids_column_0'>
                            <div className='clients_row_name_wrapper'>
                                <div
                                    key={'remove_bid-' + bid.id}
                                    id={'remove_bid-' + bid.id}
                                    data-bid_id={bid.id}
                                    className='notify_client_btn'
                                    onClick={this.handleCloseBid}
                                >
                                    <img src='./icons/trash.png' alt='notify' width='15px' data-bid_id={bid.id} />
                                </div>
                                <div className='client_row_name'>
                                    <input
                                        className='text_edit_input client_name'
                                        type='text'
                                        defaultValue={bid.contact_name}
                                        data-bid_id={bid.id}
                                        id={'client_name-' + bid.id}
                                        size={bid.contact_name.length}
                                        data-field='contact_name'
                                        onChange={
                                         compose(
                                             _.debounce(this.handleUpdateBid, 500),
                                             _.clone
                                         )
                                        }
                                    />
                                </div>
                                <div key={'bid_notify-' + bid.id} id={'bid_notify-' + bid.id} data-bid_id={bid.id}
                                    className='notify_client_btn' onClick={this.props.handleAddNotify}>
                                    <img src='./icons/bell.png' alt='notify' width='15px' data-bid_id={bid.id} />
                                </div>
                            </div>
                            <div className='clients_row_name_wrapper'>
                                <div className='client_arrive_chbox_wrapper'>
                                    <input
                                        type='checkbox'
                                        id={'bid_child-' + bid.id}
                                        key={'bid_child-' + bid.id}
                                        name='user-arrive'
                                        className='arrive_chbox'
                                        data-field='child'
                                        data-bid_id={bid.id}
                                        checked={bid.child}
                                        onChange={
                                            (e) => {
                                                this.handleUpdateBid(e);
                                            }
                                        }
                                    />
                                    <span
                                        className='client_arrive_chbox_span'
                                        data-field='child'
                                        data-bid_id={bid.id}
                                        onChange={
                                            (e) => {
                                                this.handleUpdateBid(e);
                                            }
                                        }
                                    >
                                    </span>
                                </div>
                                {bid.child
                                    ? <div
                                        className='chbox_label'
                                      >
                                        Ребенок:
                                      </div>
                                    : <div
                                        className='chbox_label'
                                      >
                                        Ребенок
                                      </div>
                                }
                                {bid.child
                                    ? <div className='editable_userinfo_cell_wrapper'>
                                          <input className='text_edit_input client_contact'
                                              type='text'
                                              defaultValue={bid.child_name}
                                              data-client_id={bid.id}
                                              id={'bid_child_name-' + bid.id}
                                              size={bid.child_name ? bid.child_name.length : 1}
                                              data-bid_id={bid.id}
                                              data-field='child_name'
                                              onChange={
                                               compose(
                                                   _.debounce(this.handleUpdateBid, 500),
                                                   _.clone
                                               )
                                              }
                                          />
                                      </div>
                                    : ''
                                }
                            </div>
                        </div>
                        <div className='client_col_ticket_wrapper bids_column_1'>
                            <div className='userinfo_cell_wrapper'>
                                <div>Тел: </div>
                                <div className='editable_userinfo_cell_wrapper'>
                                    <input className='text_edit_input client_contact'
                                        type='text'
                                        defaultValue={bid.contact_phone}
                                        data-client_id={bid.id}
                                        id={'bid_contact_phone-' + bid.id}
                                        size={bid.contact_phone ? bid.contact_phone.length : 1}
                                        data-bid_id={bid.id}
                                        data-field='contact_phone'
                                        onChange={
                                         compose(
                                             _.debounce(this.handleUpdateBid, 500),
                                             _.clone
                                         )
                                        }
                                    />
                                </div>
                            </div>
                            <div className='userinfo_cell_wrapper'>
                                <div>Email: </div>
                                <div className='editable_userinfo_cell_wrapper'>
                                    <input className='text_edit_input client_contact'
                                        type='text'
                                        defaultValue={bid.contact_email}
                                        data-client_id={bid.id}
                                        id={'bid_contact_email-' + bid.id}
                                        size={bid.contact_email ? bid.contact_email.length : 1}
                                        data-field='contact_email'
                                        data-bid_id={bid.id}
                                        onChange={
                                         compose(
                                             _.debounce(this.handleUpdateBid, 500),
                                             _.clone
                                         )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='client_col_ticket_wrapper bids_column_2'>
                            {bid.club_unit}
                        </div>
                        <div className='client_col_ticket_wrapper bids_column_3'>
                            {bid.source
                                ? bid.source.source
                                : ''
                            }
                        </div>
                        <div className='client_col_ticket_wrapper bids_column_4'>
                            {bid.bid_date}
                        </div>
                        <div className='client_col_ticket_wrapper bids_column_5'>
                            <div
                                style={
                                    {
                                        display: 'flex',
                                        alignItems: 'center',
                                    }
                                }
                            >
                                <div
                                    style={
                                        {
                                            height: 'fit-content',
                                        }
                                    }
                                >
                                    <input type='button' className='add_drill_btn show_ticket_info_btn' id={'change_bid_status-' + bid.id} onClick={this.handleChangeStatus} value={bid.bid_status_name} />
                                    <div className='ticket_info_wrapper' id={'bid_selector_status_wrapper-' + bid.id}>
                                        <Select
                                            options={
                                                Array.from(Object.keys(this.state.statuses), (k) => new Object({"value": k, "label": this.state.statuses[k], "pk": bid.id}))
                                            }
                                            onChange={
                                                (e) => {
                                                  var data = {'bid': e["pk"], 'bid_status': e["value"]};
                                                  fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/bids/', {
                                                    method: 'POST',
                                                    headers: {
                                                      'Content-Type': 'application/json',
                                                      Authorization: `Bearer ${localStorage.getItem('token')}`
                                                    },
                                                    body: JSON.stringify(data)
                                                  })
                                                    .then(res => {
                                                        if (res.status === 401) {
                                                            this.props.catchError(res.status);
                                                            return
                                                        }
                                                        else if (res.status === 406) {
                                                            res.json().then((json) => {
                                                                this.props.updateCurrentShift(json);
                                                            });
                                                            return
                                                        }
                                                        const response = res.json();
                                                        return response;
                                                    })
                                                    .then(json => {
                                                        if (!json) {
                                                            return
                                                        }
                                                        if (this.props.logged_in) {
                                                            var bids = this.state.bids;
                                                            for (let i = 0; i < bids.length; i++) {
                                                                if (bids[i]['id'] === json['id']) {
                                                                    bids[i] = json;
                                                                }
                                                            }
                                                            this.setState({bids: bids});
                                                        }
                                                    })
                                                }
                                            }
                                            styles={select_styles}
                                            defaultValue={{value: bid.bid_status, label: bid.bid_status_name, pk: bid.id}}
                                        />
                                    </div>
                                </div>
                                <div>
                                    {this.renderBidColor(bid.id)}
                                </div>
                            </div>
                        </div>
                        <div className='client_col_ticket_wrapper bids_column_6'>
                            {this.renderBidEmployees(bid)}
                        </div>
                        <div className='client_col_ticket_wrapper bids_column_7'>
                            <textarea
                              rows='5'
                              cols='25'
                              id={'client_sketch-' + bid.id}
                              defaultValue={bid.comment}
                            >

                            </textarea>
                            <input type='button'
                                   className='add_sketch_btn'
                                   id={'add_user_sketch-' + bid.id}
                                   onClick={this.handleUpdateSketches}
                                   value='&#10004;'
                                   data-bid_id={bid.id}
                            />
                        </div>
                        <div className='client_col_ticket_wrapper bids_column_8'>
                            <input
                                type='button'
                                className='add_drill_btn show_ticket_info_btn'
                                id={'create_bid_user-' + bid.id}
                                onClick={this.handleBidToUser}
                                value='В клиента'
                            />
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div
                        key={'client-' + bid.id}
                        className='client_row_wrapper'
                    >
                        <div className='client_col_name_wrapper bids_column_0'>
                            <span>{bid.contact_name}</span>
                        </div>
                        {this.props.device === "desktop"
                            ? <div className='client_col_ticket_wrapper bids_column_2'>
                                {bid.club_unit}
                              </div>
                            : ''
                        }
                        {this.props.device === "desktop"
                            ? <div className='client_col_ticket_wrapper bids_column_3'>
                                  {bid.source
                                      ? bid.source.source
                                      : ''
                                  }
                              </div>
                            : ''
                        }
                        <div className='client_col_ticket_wrapper bids_column_4'>
                            {bid.bid_date}
                        </div>
                        {this.props.device === "desktop"
                            ? <div className='client_col_ticket_wrapper bids_column_5'>
                                  <span className='add_drill_btn show_ticket_info_btn'>
                                      {bid.bid_status_name}
                                  </span>
                              </div>
                            : <div className='client_col_ticket_wrapper bids_column_5'>
                                  <span>
                                      {bid.bid_status_name}
                                  </span>
                              </div>
                        }
                    </div>
                )
            }
        })
    }

    render() {
        return(
            <div>
                <div className='fts_input_wrapper'>
                    <div className='fts_input_wrapper'>
                        <input
                            placeholder='Телефон'
                            className='fts_input'
                            disabled={this.state.lock_spinner}
                            id='fts_input_id'
                            onChange={_.debounce(this.handleSearchBids, 500)}
                        />
                    </div>
                    <div className='filter_btn_wrapper notify_filter_wrapper'>
                        <div
                          className='filter_input_container'
                          style={
                            {
                              marginBottom: '1rem',
                            }
                          }
                        >
                            <label
                                style={
                                  {
                                    marginRight: '0.5rem',
                                  }
                                }
                            >
                              Период создания заявки
                            </label>
                            <input
                              key='export_date_start'
                              id='export_date_start'
                              type='date'
                              placeholder='Дата начала'
                              className='add_ticket_btn'
                              defaultValue={this.state.bids_date_start}
                              onChange={
                                  (e) => {
                                      this.setState(
                                          {
                                              bids_date_start: e.target.value,
                                              limit: 20,
                                              offset: 0,
                                              reset_list: true,
                                          }, () => {this.getData()}
                                      )
                                  }
                              }
                            />
                            <input
                              key='export_date_stop'
                              id='export_date_stop'
                              type='date'
                              placeholder='Дата окончания'
                              className='add_ticket_btn'
                              defaultValue={this.state.bids_date_stop}
                              onChange={
                                  (e) => {
                                      this.setState(
                                          {
                                              bids_date_stop: e.target.value,
                                              limit: 20,
                                              offset: 0,
                                              reset_list: true,
                                          }, () => {this.getData()}
                                      )
                                  }
                              }
                            />
                        </div>
                    </div>
                    <div className='bids_filters_wrapper'>
                        <Select
                            options={
                                Array.from(Object.keys(this.state.statuses), (k) => new Object({"value": k, "label": this.state.statuses[k]}))
                            }
                            onChange={
                                (e) => {
                                    this.setState(
                                        {
                                            status: (e) ? Array.from(e, (val) => val["value"]).toString() : '',
                                            limit: 20,
                                            offset: 0,
                                            reset_list: true,
                                        }, () => {this.getData();}
                                    )
                                }
                            }
                            styles={select_styles}
                            isClearable={true}
                            isMulti={true}
                            placeholder={"Статус заявки"}
                        />
                        <Select
                            options={
                                Array.from(this.state.bid_sources, (val) => new Object({"value": val.id, "label": val.source}))
                            }
                            onChange={
                                (e) => {
                                    this.setState(
                                        {
                                            bids_source: (e) ? Array.from(e, (val) => val["value"]).toString() : '',
                                            limit: 20,
                                            offset: 0,
                                            reset_list: true,
                                        }, () => {this.getData();}
                                    )
                                }
                            }
                            styles={select_styles}
                            isClearable={true}
                            isMulti={true}
                            placeholder={"Источник заявки"}
                        />
                    </div>
                </div>
                <div className='table_container'>
                    <div
                         id='bids-table-id'
                         className='table_wrapper'
                    >
                        <div className="table_head_container_wrapper">
                            <div className="table_head_row_wrapper">
                                {this.renderTableHeader()}
                            </div>
                        </div>
                        <div className="table_rows_container_wrapper">
                            {this.renderTableData()}
                        </div>
                    </div>
                </div>
                <div className='add_drill_wrapper'>
                    <div className='add_drill_btn plus_btn' id='add_drill_btn_id' onClick={this.handleAddBid}>
                        &#10010;
                    </div>
                </div>
                <div id="toast-wrapper-id">
                    <div id="toast-body-id">
                    </div>
                </div>
                {this.state.new_bid
                    ? <NewBid
                        logged_in={this.props.logged_in}
                        modal_ref={this.modal_ref}
                        handleBackgroundClick={this.props.handleBackgroundClick}
                        club_units={this.state.club_units}
                        bid_sources={this.state.bid_sources}
                        handleCreateBid={this.handleCreateBid.bind(this)}
                      />
                    : ''
                }
                {this.state.lock_spinner
                    ? <Spinner />
                    : <div></div>
                }
                {this.props.add_notify
                    ? <CreateNotify
                        bid_id={this.props.notify_bid_id}
                        handleCreateNotification={this.props.handleCreateNotification}
                        handleDeclineNewNotify={this.props.handleDeclineNewNotify}
                        modal_ref={this.props.modal_ref}
                        client={this.state.bids.filter(b => parseInt(b.id) === parseInt(this.props.notify_bid_id))}
                        logged_in={this.props.logged_in}
                        setNotifyType={this.props.setNotifyType}
                    />
                    : ''
                }
                {this.state.accept_remove
                    ? <div
                        className='background_lock_wrapper'
                        id='background_lock_id'
                      >
                        {this.state.accept_remove_form}
                      </div>
                    : ''
                }
            </div>
        )
    }
}

class NewBid extends(React.Component) {
  constructor(props) {
    super(props);
    this.state = {
      new_bid_club_unit: null,
      new_bid_child: false,
      bid_source_choose: {},
    }
  }

  render() {
    var today = new Date().getFullYear().toString();
    if (new Date().getMonth().toString().length == 1) {
        today = today + "-0" + (parseInt(new Date().getMonth().toString()) + 1);
    }
    else {
        today = today + "-" + (parseInt(new Date().getMonth().toString()) + 1);
    }
    if (new Date().getDate().toString().length == 1) {
        today = today + "-0" + new Date().getDate().toString();
    }
    else {
        today = today + "-" + new Date().getDate().toString();
    }
    return(
      <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_ref}>
          <div className='new_drill_form_wrapper'>
              <div className='hide_drill_clients_list_wrapper'>
                  <input
                    type='button'
                    className='hide_drill_clients_list'
                    id='hide_drill_clients_list_btn_id'
                    onClick={this.props.handleBackgroundClick}
                    value='&#10008;'
                  />
              </div>
              <div className='drill_time_wrapper bid_field_wrapper bid_field_select_wrapper'>
                <Select
                    options={
                        Array.from(this.props.club_units, (val) => new Object(
                            {
                                "value": val.id,
                                "label": val.name,
                            }
                        ))
                    }
                    onChange={
                        (e) => {
                          this.setState({new_bid_club_unit: e["value"]})
                        }
                    }
                    styles={select_styles}
                    placeholder={"Филиал"}
                />
              </div>
              <div className='new_bid_child_check_wrapper'>
                  <div className='client_arrive_chbox_wrapper'>
                      <input
                          type='checkbox'
                          id='bid_child'
                          key='bid_child'
                          name='user-arrive'
                          className='arrive_chbox'
                          data-field='child'
                          onChange={
                              (e) => {
                                  this.setState(
                                      {
                                          new_bid_child: e.target.checked
                                      }, () => {
                                          if (this.state.new_bid_child) {
                                              document.getElementById('bid_child_wrapper_id').style.display = 'block';
                                          }
                                          else {
                                              document.getElementById('bid_child_wrapper_id').style.display = 'none';
                                          }
                                      }
                                  );
                              }
                          }
                      />
                      <span
                          className='client_arrive_chbox_span'
                          data-field='child'
                          onChange={
                              (e) => {
                                  this.setState(
                                      {
                                          new_bid_child: e.target.checked
                                      }, () => {
                                          if (this.state.new_bid_child) {
                                              document.getElementById('bid_child_wrapper_id').style.display = 'block';
                                          }
                                          else {
                                              document.getElementById('bid_child_wrapper_id').style.display = 'none';
                                          }
                                      }
                                  );
                              }
                          }
                      >
                      </span>
                  </div>
                  <div
                    className='chbox_label'
                  >
                    Ребенок
                  </div>
              </div>
              <div className='drill_time_wrapper bid_field_wrapper'>
                  <label className='drill_time_label'>Телефон: </label>
                  <input key='bid_phone' id='bid_phone_id' type='text' placeholder='Телефон' className='add_ticket_btn' />
              </div>
              <div className='drill_time_wrapper bid_field_wrapper'>
                  <label className='drill_time_label'>Email: </label>
                  <input key='bid_email' id='bid_email_id' type='text' placeholder='Email' className='add_ticket_btn' />
              </div>
              <div
                  className='drill_time_wrapper bid_field_wrapper'
                  style={{display: 'none'}}
                  id='bid_child_wrapper_id'
              >
                  <label className='drill_time_label'>Имя ребенка: </label>
                  <input key='bid_child_name' id='bid_child_name_id' type='text' placeholder='Имя' className='add_ticket_btn' />
              </div>
              <div className='drill_time_wrapper bid_field_wrapper'>
                  <label className='drill_time_label'>Имя: </label>
                  <input key='bid_name' id='bid_name_id' type='text' placeholder='Имя' className='add_ticket_btn' />
              </div>
              <div className='drill_time_wrapper bid_field_wrapper bid_field_select_wrapper'>
                  <label className='drill_time_label'>Источник заявки: </label>
                  <Creatable
                      isClearable
                      onChange={
                          (value: any, label: any) => {
                              if (value) {
                                  this.setState(
                                      {
                                          bid_source_choose: {
                                              id: (parseInt(value["value"])) ? value["value"] : null,
                                              source: value["label"],
                                          }
                                      }
                                  )
                              }
                          }
                      }
                      onInputChange={(e) => {}}
                      options={
                          Array.from(this.props.bid_sources, (val) => new Object({"value": val.id, "label": val.source}))
                      }
                      styles={select_styles}
                      menuPosition={'fixed'}
                  />
              </div>
              <div className='ticket_date_valid_wrapper bid_field_wrapper'>
                  <label>Дата заявки</label>
                  <input
                    key='bid_date_creation'
                    id='bid_date_creation'
                    type='date'
                    placeholder='Дата заявки'
                    className='add_ticket_btn'
                    defaultValue={today}
                  />
              </div>
              {this.state.new_bid_club_unit
                ? <div className='bid_create_btn_wrapper bid_field_wrapper'>
                      <div
                        className='bid_create_btn'
                        onClick={
                          (e) => {
                            this.props.handleCreateBid(
                              {
                                club_unit: this.state.new_bid_club_unit,
                                contact_phone: document.getElementById('bid_phone_id').value,
                                contact_email: document.getElementById('bid_email_id').value,
                                contact_name: document.getElementById('bid_name_id').value,
                                source: this.state.bid_source_choose,
                                bid_date: document.getElementById('bid_date_creation').value,
                                child: this.state.new_bid_child,
                                child_name: (this.state.new_bid_child) ? document.getElementById('bid_child_name_id').value : null,
                              }
                            )
                          }
                        }
                      >
                          Создать
                      </div>
                  </div>
                : ''
              }
          </div>
      </div>
    )
  }
}

export default BidsList
