import React from 'react';
import compose from 'lodash/fp/compose';
import { AcceptModal } from './AcceptModal';
import Spinner from './Spinner';
import Select from 'react-select';

const select_styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        padding: 20,
        'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#f7941e',
        border: '1px solid #f7941e',
        'background-color': '#c5c6c8',
        'outline-color': '#f7941e',
    }),
    container: (provided, state) => ({
        ...provided,
        width: '100%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'margin-bottom': '0.5rem',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'background-color': '#c5c6c8',
    }),
}

var _ = require('lodash');

class Notifications  extends(React.Component) {
    constructor(props) {
        super(props);
        this.show_user_info_modal_ref = React.createRef();
        this.state = {
            notifications: [],
            uri: process.env.REACT_APP_BACKEND_URI + '/api/v1/management/notifications/',
            limit: 20,
            offset: 0,
            processing: false,
            start_date: '',
            end_date: '',
            all: 0,
            noty_types: [],
            noty_type: '',
            accept_remove: false,
            accept_remove_form: '',
            show_user_info: false,
            user_info: {},
            user_info_position: '200px',
            show_user_info_spinner: false,
            lock_spinner: false,
        }
    }

    handleClickOut = (e) => {
        if (this.show_user_info_modal_ref &&
            this.show_user_info_modal_ref.current &&
            (!this.show_user_info_modal_ref.current.contains(e.target) || e.target.id === 'background_lock_id')
        ) {
            this.setState({show_user_info: false});
        }
    }

    componentDidMount() {
        if (this.props.list_type === 'full') {
            this.getData();
            document.addEventListener('scroll', this.scrollListener);
            document.addEventListener('mousedown', this.handleClickOut);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', function(e) {});
        document.removeEventListener('mousedown', this.handleClickOut);
    }

    scrollListener = (e) => {
        if (this.state.processing &&
             document.documentElement.scrollTop >= (document.documentElement.scrollHeight - window.innerHeight) * 0.9) {
            this.setState({processing: false}, () => {this.getData();})
        }
    }

    getData() {
        if (!this.state.uri) {
          return
        }
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                var uri = new URL(this.state.uri);
                uri.searchParams.set("full", 1);
                uri.searchParams.set("limit", this.state.limit);
                uri.searchParams.set("offset", this.state.offset);
                uri.searchParams.set("all", this.state.all);
                uri.searchParams.set("start_date", this.state.start_date);
                uri.searchParams.set("end_date", this.state.end_date);
                uri.searchParams.set("noty_type", this.state.noty_type);
                fetch(uri.href, {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                  }
                })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (this.props.logged_in) {
                        if (json.next) {
                            var uri = new URL(json.next);
                            var limit = uri.searchParams.get('limit');
                            var offset = uri.searchParams.get('offset');
                            var uri = this.state.uri;
                        }
                        else {
                            var uri = null;
                            var limit = '';
                            var offset = '';
                        }
                        this.setState(
                            {
                                notifications: this.state.notifications.concat(json.results),
                                uri: uri,
                                limit: limit,
                                offset: offset,
                                processing: (json.next) ? true : false,
                                lock_spinner: false,
                            }
                        );
                    }
                })
            }
        )
    }

    getUserInfo({user_id, offset = '200px'}) {
        this.setState(
            {
                show_user_info_spinner: true,
                user_info_position: offset,
            }, () => {
                fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/account/' + user_id + '/', {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                  }
                })
                  .then(res => {
                      if (res.status === 401) {
                          this.props.catchError(res.status);
                          return
                      }
                      const response = res.json();
                      return response;
                  })
                  .then(json => {
                      if (this.props.logged_in) {
                          this.setState({
                            user_info: json,
                            show_user_info: true,
                            show_user_info_spinner: false,
                          });
                      }
                  })
            }
        )
    }

    handleShowUserInfo = (e) => {
        if (!e.target.dataset.client_id) {
            return
        }
        if (e.target.dataset.client_id && this.state.user_info
            && parseInt(e.target.dataset.client_id) === parseInt(this.state.user_info['id'])) {
            this.setState({
                show_user_info: true,
                user_info_position: (parseInt(e.target.offsetTop) + 50).toString() + 'px',
            });
        }
        else {
            this.getUserInfo(
                {
                    user_id: e.target.dataset.client_id,
                    offset: (parseInt(e.target.offsetTop) + 50).toString() + 'px',
                }
            );
        }
    }

    handleChangeNotyMessage = (e) => {
        var data = {
            id: parseInt(e.target.dataset.noty_id),
            [e.target.dataset.change_key]: e.target.value,
        }
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/club_management/notifications/', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            else if (res.status === 406) {
                res.json().then((json) => {
                    this.props.updateCurrentShift(json);
                });
                return
            }
            const response = res.json();
            return response;
        })
    }

    handleApplyFilters = (e) => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/notifications/');
        var start_date = document.getElementById('export_date_start').value;
        var end_date = document.getElementById('export_date_stop').value;
        var all = 0;
        if (document.getElementById('all-noty-list').checked) {
            var all = 1;
        }
        this.setState(
            {
                uri: uri.href,
                limit: 20,
                offset: 0,
                all: all,
                start_date: start_date,
                end_date: end_date,
                notifications: [],
            }, () => {
                this.getData();
            }
        )
    }

    handleCloseNotification = (e) => {
        var closed = false;
        var deleted = false;
        if (e.target.type === "checkbox") {
            closed = e.target.checked;
        }
        if (e.target.tagName === "IMG") {
            deleted = true;
        }
        var chbox = e.target;
        var data = {id: e.target.dataset.notify_id, closed: closed, deleted: deleted};
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/notifications/', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            else if (res.status === 406) {
                res.json().then((json) => {
                    this.props.updateCurrentShift(json);
                });
                return
            }
            else if (res.status === 409) {
                res.json().then((json) => {
                    chbox.checked = false;
                    document.getElementById('toast-body-id').innerHTML = json.response;
                    var x = document.getElementById("toast-wrapper-id");
                    x.className = 'show';
                    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                });
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (!json) {
                return
            }
            this.setState({ notifications: this.state.notifications.filter(n => parseInt(n.id) !== parseInt(json.id))});
        })
    }

    renderUnitFilters() {
        return this.props.club_units.map((unit) => {
            return (
                <div
                    data-club_unit={unit.id}
                    id={'club_unit_notify_filter-' + unit.id}
                    onClick={this.props.handleChooseNotifyUnit}
                    className='notify_filter'
                >
                    {unit.name}
                </div>
            )
        })
    }

    renderNotificationsFiltersList() {
        var today = new Date().getFullYear().toString();
        if (new Date().getMonth().toString().length == 1) {
            today = today + "-0" + (parseInt(new Date().getMonth().toString()) + 1);
        }
        else {
            today = today + "-" + (parseInt(new Date().getMonth().toString()) + 1);
        }
        if (new Date().getDate().toString().length == 1) {
            today = today + "-0" + new Date().getDate().toString();
        }
        else {
            today = today + "-" + new Date().getDate().toString();
        }
        return (
            <div className='users_filters_wrapper'>
                <div className='show_filters_btn_wrapper'>
                    <input
                        type='button'
                        className='add_ticket_btn'
                        id='show_filters_btn_id'
                        onClick={
                            () => {
                                var filters_list = document.getElementById('filters_list_id');
                                if (this.state.noty_types.length === 0) {
                                    var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/notify_types/');
                                    fetch(uri.href, {
                                        method: 'GET',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            Authorization: `Bearer ${localStorage.getItem('token')}`
                                        },
                                    })
                                    .then(res => {
                                        if (res.status === 401) {
                                            this.props.catchError(res.status);
                                            return
                                        }
                                        else if (res.status === 406) {
                                            res.json().then((json) => {
                                                this.props.updateCurrentShift(json);
                                            });
                                            return
                                        }
                                        const response = res.json();
                                        return response;
                                    })
                                    .then(json => {
                                        this.setState(
                                            {
                                                noty_types: json,
                                            }, () => {
                                                if (filters_list.style.display === 'flex' || filters_list.style.display === 'block') {
                                                    filters_list.style.display = 'none';
                                                }
                                                else if (this.props.list_type === 'full') {
                                                    filters_list.style.display = 'flex';
                                                }
                                                else if (this.props.list_type === 'tickets') {
                                                    filters_list.style.display = 'block';
                                                }
                                            }
                                        );
                                    })
                                }
                                else {
                                    if (filters_list.style.display === 'flex' || filters_list.style.display === 'block') {
                                        filters_list.style.display = 'none';
                                    }
                                    else if (this.props.list_type === 'full') {
                                        filters_list.style.display = 'flex';
                                    }
                                    else if (this.props.list_type === 'tickets') {
                                        filters_list.style.display = 'block';
                                    }
                                }
                            }
                        }
                        value='Фильтры'
                    />
                </div>
                <div className='filters_list_wrapper noty_filters_list_wrapper' id='filters_list_id'>
                    <div className='filter_btn_wrapper notify_filter_wrapper'>
                        <div className='filter_input_container'>
                            <label>Период уведомлений</label>
                            <input
                              key='export_date_start'
                              id='export_date_start'
                              type='date'
                              placeholder='Дата начала'
                              className='add_ticket_btn'
                              onChange={this.handleApplyFilters}
                            />
                            <input
                              key='export_date_stop'
                              id='export_date_stop'
                              type='date'
                              placeholder='Дата окончания'
                              className='add_ticket_btn'
                              onChange={this.handleApplyFilters}
                            />
                        </div>
                    </div>
                    <div className='filter_btn_wrapper'>
                        <div className='filter_input_container'>
                            <label>Все уведомления</label>
                            <div className='close_notify'>
                                <div className='client_arrive_chbox_wrapper'>
                                    <input
                                        type='checkbox'
                                        id='all-noty-list'
                                        key='all-noty-list'
                                        name='user-arrive'
                                        className='arrive_chbox'
                                        onChange={this.handleApplyFilters}
                                    />
                                    <span
                                        className='client_arrive_chbox_span'
                                        onChange={this.handleApplyFilters}
                                    >
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='filter_btn_wrapper'>
                        <Select
                            options={
                                Array.from(this.state.noty_types, (noty) => new Object(
                                    {
                                        "value": noty.id,
                                        "label": noty.type,
                                    }
                                ))
                            }
                            onChange={
                                (e) => {
                                    this.setState(
                                        {
                                            noty_type: e.value,
                                        }, () => {
                                            this.handleApplyFilters(e);
                                        }
                                    )
                                }
                            }
                            styles={select_styles}
                            placeholder={"Тип уведомления"}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderNotifications() {
        return this.props.notifications.map((notify) => {
            var contact_name = '';
            var contact_phone = '';
            var contact_mail = '';
            var name_class = '';
            if (notify.client) {
                contact_name = notify.client.last_name + " " + notify.client.first_name;
                contact_phone = notify.client.phone;
                contact_mail = notify.client.email;
                name_class = 'notify_name_client';
            }
            else if (notify.bid) {
                contact_name = notify.bid.contact_name;
                contact_phone = notify.bid.contact_phone;
                contact_mail = notify.bid.contact_email;
                name_class = 'notify_name_bid';
            }
            return (
                <div className='notifications_list_wrapper'>
                    <div className='notification_client_wrapper'>
                        <span className={'notify_client ' + name_class}>{contact_name}</span>
                    </div>
                    <div className='close_notify'>
                        <div className='client_arrive_chbox_wrapper'>
                            <input type='checkbox' id={'notify_close-' + notify.id} key={'notify_close-' + notify.id} name='user-arrive' className='arrive_chbox'
                                data-notify_id={notify.id} onChange={this.props.handleCloseNotification} />
                            <span className='client_arrive_chbox_span' onChange={this.props.handleCloseNotification} data-notify_id={notify.id}></span>
                        </div>
                    </div>
                    <div className='notification_message_wrapper'>
                        <p className='notification_message_phone'>{notify.message}</p>
                        <p className='notification_message_phone'>{contact_phone}</p>
                        <p className='notification_message_phone'>{contact_mail}</p>
                    </div>
                </div>
            )
        })
    }

    renderNotificationsTable() {
        return this.state.notifications.map((notify, index) => {
            var contact_name = '';
            var contact_phone = '';
            var contact_mail = '';
            var name_class = '';
            var bid_id = '';
            var client_id = '';
            if (notify.client) {
                contact_name = notify.client.last_name + " " + notify.client.first_name;
                contact_phone = notify.client.phone;
                contact_mail = notify.client.email;
                client_id = notify.client.id;
                name_class = 'notify_name_client';
            }
            else if (notify.bid) {
                contact_name = notify.bid.contact_name;
                contact_phone = notify.bid.contact_phone;
                contact_mail = notify.bid.contact_email;
                bid_id = notify.bid.id;
                name_class = 'notify_name_bid';
            }
            return (
                <div
                    key={'noty-' + notify.id}
                    className='client_row_wrapper'
                >
                    <div
                        className='client_col_name_wrapper clients_column_0'
                        data_client_id={client_id}
                        data_bid_id={bid_id}
                    >
                        <div className='clients_row_name_wrapper'>
                            {parseInt(localStorage.getItem('user_type')) > 2
                              ? <div
                                    key={'remove_noty-' + notify.id}
                                    id={'remove_noty-' + notify.id}
                                    data-notify_id={notify.id}
                                    className='notify_client_btn'
                                    onClick={this.handleCloseNotification}
                                >
                                    <img src='./icons/trash.png' alt='notify' width='15px' data-notify_id={notify.id} />
                                </div>
                              : ''
                            }
                            <div className='client_row_name'>
                                <span
                                    className={'notify_client ' + name_class}
                                    onMouseEnter={this.handleShowUserInfo}
                                    data-client_id={client_id}
                                    data-bid_id={bid_id}
                                >
                                    {contact_name}
                                </span>
                            </div>
                            <div className='close_notify'>
                                <div className='client_arrive_chbox_wrapper'>
                                    <input
                                        type='checkbox'
                                        id={'notify_close-' + notify.id}
                                        key={'notify_close-' + notify.id}
                                        name='user-arrive'
                                        className='arrive_chbox'
                                        data-notify_id={notify.id}
                                        onChange={this.handleCloseNotification}
                                    />
                                    <span
                                        className='client_arrive_chbox_span'
                                        onChange={this.handleCloseNotification}
                                        data-notify_id={notify.id}
                                    >
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                      className='client_col_contact_wrapper clients_column_1'
                      style={
                        {
                          width: '13%',
                        }
                      }
                    >
                        <div className='userinfo_cell_wrapper'>
                            <div>Тел: </div>
                            <div className='editable_userinfo_cell_wrapper'>
                                {contact_phone}
                            </div>
                        </div>
                        <div className='userinfo_cell_wrapper'>
                            <div>Email: </div>
                            <div className='editable_userinfo_cell_wrapper'>
                                {contact_mail}
                            </div>
                        </div>
                    </div>
                    <div
                        className='client_col_contact_wrapper clients_column_2'
                        style={
                            {
                                width: '10%',
                            }
                        }
                    >
                        {notify.author.last_name} {notify.author.first_name}
                    </div>
                    <div className='client_col_trustee_contact_wrapper clients_column_3'>
                        {notify.noty_type_str}
                    </div>
                    <div
                        className='client_col_comment_wrapper clients_column_6'
                        style={
                            {
                                width: '20%',
                            }
                        }
                    >
                        <div
                            style={
                                {
                                    display: 'flex'
                                }
                            }
                        >
                            <div
                                style={
                                    {
                                        fontSize: '0.8rem',
                                        width: '40%',
                                    }
                                }
                            >
                                {notify.message}
                            </div>
                            <div>
                                <textarea
                                    rows='5'
                                    cols='30'
                                    id={'noty_message-' + notify.id}
                                    data-noty_id={notify.id}
                                    data-change_key='message'
                                    className='message_input'
                                    onChange={
                                        compose(
                                            _.debounce(this.handleChangeNotyMessage, 700),
                                            _.clone
                                        )
                                    }
                                    defaultValue={notify.sketches}
                                >
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <div
                        className='client_col_ticket_wrapper clients_column_5'
                        style={
                            {
                                width: '8%',
                            }
                        }
                    >
                        {notify.closed
                            ? 'Закрыто'
                            : 'Открыто'
                        }
                    </div>
                    <div
                        className='client_col_birthday_wrapper clients_column_4'
                        style={
                            {
                                width: '8%',
                            }
                        }
                    >
                        {notify.created_at.split("T")[0]}
                    </div>
                    <div
                        className='client_col_birthday_wrapper clients_column_4'
                        style={
                            {
                                width: '10%',
                            }
                        }
                    >
                        <div className='editable_userinfo_cell_wrapper'>
                            <input
                              className='text_edit_input client_contact'
                              type='date'
                              defaultValue={notify.notify_date}
                              data-noty_id={notify.id}
                              data-change_key='notify_date'
                              id={'noty_date-' + notify.id}
                              size='12'
                              style={
                                {
                                  width: '100%',
                                }
                              }
                              onChange={
                                compose(
                                  _.debounce(this.handleChangeNotyMessage, 500),
                                  _.clone
                                )
                              }
                            />
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        if (this.props.list_type === 'dropdown') {
            return (
                <div>
                    <div className='notify_filters_wrapper'>
                        {this.renderUnitFilters()}
                    </div>
                    {this.renderNotifications()}
                    <div>
                        <div key='next_notify_key' id='next_notify_key'
                            className='more_notifies' onClick={this.props.getNotifications}>
                            <span>Ещё</span>
                        </div>
                    </div>
                </div>
            )
        }
        else if (this.props.list_type === 'full') {
            return (
                <div>
                    <div className='notify_filters_wrapper'>
                        {this.renderNotificationsFiltersList()}
                    </div>
                    <div className='table_container'>
                        <div className='table_wrapper'>
                            <div className="table_head_container_wrapper">
                                <div
                                    key='noty-0'
                                    className='clients_table_container_border clients_table_container_head clients_column_0'
                                >
                                    <div
                                        className='table_header_cell_wrapper'
                                    >
                                        <span className='notify_name_client'>
                                            Клиент
                                        </span>
                                        /
                                        <span className='notify_name_bid'>
                                            Заявка
                                        </span>
                                    </div>
                                </div>
                                <div
                                    key='noty-1'
                                    className='clients_table_container_border clients_table_container_head clients_column_1'
                                    style={
                                      {
                                        width: '13%',
                                      }
                                    }
                                >
                                    Контакты
                                </div>
                                <div
                                    key='noty-2'
                                    className='clients_table_container_border clients_table_container_head clients_column_2'
                                    style={
                                        {
                                            width: '10%',
                                        }
                                    }
                                >
                                    Автор
                                </div>
                                <div
                                    key='noty-3'
                                    className='clients_table_container_border clients_table_container_head clients_column_3'
                                >
                                    Тип
                                </div>
                                <div
                                    key='noty-6'
                                    className='clients_table_container_border clients_table_container_head clients_column_6'
                                    style={
                                        {
                                            width: '20%',
                                        }
                                    }
                                >
                                    Информация
                                </div>
                                <div
                                    key='noty-5'
                                    className='clients_table_container_border clients_table_container_head clients_column_5'
                                    style={
                                        {
                                            width: '8%',
                                        }
                                    }
                                >
                                    Статус
                                </div>
                                <div
                                    key='noty-4'
                                    className='clients_table_container_border clients_table_container_head clients_column_4'
                                    style={
                                        {
                                            width: '8%',
                                        }
                                    }
                                >
                                    Дата
                                </div>
                                <div
                                    key='noty-7'
                                    className='clients_table_container_border clients_table_container_head clients_column_4'
                                    style={
                                        {
                                            width: '10%',
                                        }
                                    }
                                >
                                    Дата напоминая
                                </div>
                            </div>
                            <div className="table_rows_container_wrapper">
                                {this.renderNotificationsTable()}
                            </div>
                        </div>
                    </div>
                    <div id="toast-wrapper-id">
                        <div id="toast-body-id">
                        </div>
                    </div>
                    {this.state.show_user_info && this.state.user_info
                        ? <ClientInfo
                            user={this.state.user_info}
                            renderTicketsList={this.props.renderTicketsList}
                            user_info_position={this.state.user_info_position}
                            show_user_info_modal_ref={this.show_user_info_modal_ref}
                          />
                        : ''
                    }
                    {this.state.show_user_info_spinner
                        ? <div
                            className='table_wrapper single_user_table_wrapper'
                            style={{top: this.state.user_info_position}}
                          >
                            <div className="loader small_loader"></div>
                          </div>
                        : ''
                    }
                    {this.state.lock_spinner
                        ? <Spinner />
                        : <div></div>
                    }
                </div>
            )
        }
    }
}

class ClientInfo extends(React.Component) {
    constructor(props) {
        super(props);
        const modal_style = {
            position: this.props.user_info_position
        };
    }

    render() {
        return (
            <div
                className='table_wrapper single_user_table_wrapper'
                style={{top: this.props.user_info_position}}
                ref={this.props.show_user_info_modal_ref}
            >
                <div
                    id='traffic-table-head-id'
                    key='traffic-table-head-key'
                    className='table_head_container_wrapper modal_table_head'
                    ref={this.props.show_user_info_modal_ref}
                >
                    <div
                        key='modal_client_0'
                        className='clients_table_container_border clients_table_container_head modal_clients_column_1'
                        ref={this.props.show_user_info_modal_ref}
                    >
                        Абонемент
                    </div>
                    <div
                        key='modal_client_1'
                        className='clients_table_container_border clients_table_container_head modal_clients_column_2'
                        ref={this.props.show_user_info_modal_ref}
                    >
                        Контакты
                    </div>
                    {this.props.user.trustee_name || this.props.user.trustee_phone
                        ? <div
                              key='modal_client_2'
                              className='clients_table_container_border clients_table_container_head modal_clients_column_3'
                              ref={this.props.show_user_info_modal_ref}
                          >
                            Представитель
                          </div>
                        : ''
                    }
                    <div
                        key='modal_client_3'
                        className='clients_table_container_border clients_table_container_head modal_clients_column_4'
                        ref={this.props.show_user_info_modal_ref}
                    >
                        Дата рождения
                    </div>
                    <div
                        key='modal_client_4'
                        className='clients_table_container_border clients_table_container_head modal_clients_column_5'
                        ref={this.props.show_user_info_modal_ref}
                    >
                        Соцсети
                    </div>
                </div>
                <div
                    id='traffic-table-id'
                    key='traffic-table-key'
                    className='client_row_wrapper'
                    ref={this.props.show_user_info_modal_ref}
                >
                    <div
                        className='client_col_ticket_wrapper modal_clients_column_1'
                        ref={this.props.show_user_info_modal_ref}
                    >
                        {this.props.renderTicketsList(this.props.user.tickets, this.props.user.id)}
                    </div>
                    <div
                        className='client_col_contact_wrapper modal_clients_column_2'
                        ref={this.props.show_user_info_modal_ref}
                    >
                        <div
                            className='userinfo_cell_wrapper'
                            ref={this.props.show_user_info_modal_ref}
                        >
                            <div ref={this.props.show_user_info_modal_ref}>Тел: {this.props.user.phone}</div>
                        </div>
                        <div
                            className='userinfo_cell_wrapper'
                            ref={this.props.show_user_info_modal_ref}
                        >
                            <div ref={this.props.show_user_info_modal_ref}>Email: {this.props.user.email}</div>
                        </div>
                    </div>
                    {this.props.user.trustee_name || this.props.user.trustee_phone
                        ? <div
                              className='client_col_trustee_contact_wrapper modal_clients_column_3'
                              ref={this.props.show_user_info_modal_ref}
                          >
                              <div
                                className='userinfo_cell_wrapper'
                                ref={this.props.show_user_info_modal_ref}
                              >
                                  <div ref={this.props.show_user_info_modal_ref}>Имя: {this.props.user.trustee_name}</div>
                              </div>
                              <div
                                className='userinfo_cell_wrapper'
                                ref={this.props.show_user_info_modal_ref}
                              >
                                  <div ref={this.props.show_user_info_modal_ref}>Тел: {this.props.user.trustee_phone}</div>
                              </div>
                          </div>
                        : ''
                    }
                    <div
                        className='client_col_birthday_wrapper modal_clients_column_4'
                        ref={this.props.show_user_info_modal_ref}
                    >
                        <div
                            className='userinfo_cell_wrapper'
                            ref={this.props.show_user_info_modal_ref}
                        >
                            <div ref={this.props.show_user_info_modal_ref}>ДР: {this.props.user.birth_date}</div>
                        </div>
                    </div>
                    <div
                        className='client_col_social_wrapper modal_clients_column_5'
                        ref={this.props.show_user_info_modal_ref}
                    >
                        <ul
                            className='client_socials_list'
                            ref={this.props.show_user_info_modal_ref}
                        >
                            <div
                                className='userinfo_cell_wrapper'
                                ref={this.props.show_user_info_modal_ref}
                            >
                                <div ref={this.props.show_user_info_modal_ref}>Telegram: {this.props.user.telegram}</div>
                            </div>
                            <div
                                className='userinfo_cell_wrapper'
                                ref={this.props.show_user_info_modal_ref}
                            >
                                <div ref={this.props.show_user_info_modal_ref}>Whatsapp: {this.props.user.whatsapp}</div>
                            </div>
                            <div
                                className='userinfo_cell_wrapper'
                                ref={this.props.show_user_info_modal_ref}
                            >
                                <div ref={this.props.show_user_info_modal_ref}>Instagram: {this.props.user.instagram}</div>
                            </div>
                            <div
                                className='userinfo_cell_wrapper'
                                ref={this.props.show_user_info_modal_ref}
                            >
                                <div ref={this.props.show_user_info_modal_ref}>ВК: {this.props.user.vk}</div>
                            </div>
                            <div
                                className='userinfo_cell_wrapper'
                                ref={this.props.show_user_info_modal_ref}
                            >
                                <div ref={this.props.show_user_info_modal_ref}>Facebook: {this.props.user.facebook}</div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Notifications
