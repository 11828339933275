import React from 'react';
import Spinner from './Spinner';

class ManagerCalendar extends(React.Component) {
    constructor(props) {
        super(props);
        var first_day = '';
        var last_day = '';
        var device = 'desktop';
        if (window.screen.width > 700 && window.screen.width < 1024) {
            first_day = new Date();
            last_day = new Date();
            last_day.setDate(last_day.getDate() + 2);
            first_day = first_day.toISOString().split('T')[0];
            last_day = last_day.toISOString().split('T')[0];
            device = 'mobile';
        }
        else if (window.screen.width < 700) {
            first_day = new Date();
            first_day = first_day.toISOString().split('T')[0];
            last_day = first_day;
            device = 'mobile';
        }
        this.state = {
            weekday: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
            shifts: [],
            date_start: first_day,
            date_stop: last_day,
            device: device,
            club_units: [],
            lock_spinner: false,
        };
    }

    componentDidMount() {
        if (parseInt(localStorage.getItem('user_type')) > 1) {
            this.getShifts();
            this.getClubUnits();
        }
    }

    getClubUnits() {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                var uri = process.env.REACT_APP_BACKEND_URI + '/api/v1/management/club_units/?my=1&short=1';
                fetch(uri, {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                  }
                })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (this.props.logged_in) {
                        this.setState(
                            {
                                club_units: json,
                                lock_spinner: false,
                            }
                        );
                    }
                })
            }
        )
    }

    getShifts() {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                var uri = process.env.REACT_APP_BACKEND_URI + '/api/v1/management/manager/shift/' +
                            '?date_start=' + this.state.date_start + '&date_stop=' + this.state.date_stop;
                fetch(uri, {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                  }
                })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (this.props.logged_in) {
                        this.setState(
                            {
                                shifts: json.shifts,
                                date_start: json.date_distance['date_start'],
                                date_stop: json.date_distance['date_stop'],
                                lock_spinner: false,
                            }
                        );
                    }
                })
            }
        )
    }

    handleChangeStartStop = (e) => {
        var first_day = new Date(this.state.date_start);
        var last_day = new Date(this.state.date_stop);
        if (e.target.id === 'day-back-arrow') {
            first_day.setDate(first_day.getDate() - 1);
            last_day.setDate(last_day.getDate() - 1);
        }
        else if (e.target.id === 'day-forward-arrow') {
            first_day.setDate(first_day.getDate() + 1);
            last_day.setDate(last_day.getDate() + 1);
        }
        this.setState({date_start: first_day.toISOString(), date_stop: last_day.toISOString()}, () => { this.getShifts(); });
    }

    handleShowStatistic = (e) => {
        var filters_list = document.getElementById('manager_statistic_id');
        if (filters_list.style.display === 'flex' || filters_list.style.display === 'block') {
            filters_list.style.display = 'none';
        }
        else {
            if (Object.keys(this.props.total_shifts).length === 0) {
                this.props.getTotalShifts(
                    {
                        date_start: document.getElementById('manager_shift_date_start').value,
                        date_stop: document.getElementById('manager_shift_date_stop').value,
                    }
                );
            }
            filters_list.style.display = 'block';
        }
    }

    handleGetTotalShifts = (e) => {
        this.props.getTotalShifts(
            {
                date_start: document.getElementById('manager_shift_date_start').value,
                date_stop: document.getElementById('manager_shift_date_stop').value,
            }
        );
    }

    renderDayShifts(date, unit_id) {
        var date = new Date(date);
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        return this.state.shifts.map((shift) => {
            if (unit_id === shift.club_unit) {
                var shift_start = '';
                var shift_start_date = '';
                var shift_end = '';
                var shift_end_date = '';
                if (shift.shift_start) {
                    shift_start = new Date(shift.shift_start);
                    shift_start_date = shift_start.getFullYear() + '-' +
                      (shift_start.getMonth()<10?'0':'') + shift_start.getMonth() + '-' +
                      (shift_start.getDate()<10?'0':'') + shift_start.getDate()
                    shift_start = (shift_start.getHours()<10?'0':'') + shift_start.getHours() + ':' +
                      (shift_start.getMinutes()<10?'0':'') + shift_start.getMinutes() + ':' +
                      (shift_start.getSeconds()<10?'0':'') + shift_start.getSeconds()
                }
                if (shift.shift_end) {
                    shift_end = new Date(shift.shift_end);
                    shift_end_date = shift_end.getFullYear() + '-' +
                      (shift_end.getMonth()<10?'0':'') + shift_end.getMonth() + '-' +
                      (shift_end.getDate()<10?'0':'') + shift_end.getDate()
                    shift_end = (shift_end.getHours()<10?'0':'') + shift_end.getHours() + ':' +
                      (shift_end.getMinutes()<10?'0':'') + shift_end.getMinutes() + ':' +
                      (shift_end.getSeconds()<10?'0':'') + shift_end.getSeconds()
                }
                if (date.getFullYear() + '/' + date.getMonth() + '/' + date.getDate() ===
                    new Date(shift.shift_start).getFullYear() + '/' + new Date(shift.shift_start).getMonth() + '/' + new Date(shift.shift_start).getDate()) {
                    if (shift.shift_end) {
                        if (parseInt(localStorage.getItem("user_type")) < 3) {
                            return (
                                <div className='shift_wrapper shift_complete' key={'day_shift-' + unit_id + shift.id}>
                                    <div className='manager_name'>
                                        {shift.manager.first_name[0]}.{shift.manager.last_name}
                                    </div>
                                    <div className='shift_time'>
                                        {shift_start} - {shift_end}
                                    </div>
                                </div>
                            )
                        }
                        else if (parseInt(localStorage.getItem("user_type")) >= 3) {
                            return (
                                <div className='shift_wrapper shift_complete' key={'day_shift-' + unit_id + shift.id}>
                                    <div className='manager_name'>
                                        {shift.manager.first_name[0]}.{shift.manager.last_name}
                                    </div>
                                    <div className='shift_time'>
                                        <input className='text_edit_input client_contact'
                                               type='time'
                                               defaultValue={shift_start}
                                               data-shift_id={shift.id}
                                               data-shift_date={shift_start_date}
                                               id={'manager-shift-start-' + shift.id}
                                               size='12'
                                               onChange={
                                                (e) => {
                                                    fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/manager/shift/', {
                                                        method: 'PUT',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            Authorization: `Bearer ${localStorage.getItem('token')}`
                                                        },
                                                        body: JSON.stringify(
                                                            {
                                                                'shift': e.target.dataset.shift_id,
                                                                'shift_start': e.target.dataset.shift_date + ' ' + e.target.value,
                                                            }
                                                        )
                                                    })
                                                    .then(res => {
                                                        if (res.status === 401) {
                                                            this.props.catchError(res.status);
                                                            return
                                                        }
                                                        else if (res.status === 406) {
                                                            res.json().then((json) => {
                                                                this.props.updateCurrentShift(json);
                                                            });
                                                            return
                                                        }
                                                        const response = res.json();
                                                        return response;
                                                    })
                                                }
                                               }
                                        />
                                        <input className='text_edit_input client_contact'
                                               type='time'
                                               defaultValue={shift_end}
                                               data-shift_id={shift.id}
                                               data-shift_date={shift_end_date}
                                               id={'manager-shift-end-' + shift.id}
                                               size='12'
                                               onChange={
                                                (e) => {
                                                    fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/manager/shift/', {
                                                        method: 'PUT',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            Authorization: `Bearer ${localStorage.getItem('token')}`
                                                        },
                                                        body: JSON.stringify(
                                                            {
                                                                'shift': e.target.dataset.shift_id,
                                                                'shift_end': e.target.dataset.shift_date + ' ' + e.target.value,
                                                            }
                                                        )
                                                    })
                                                    .then(res => {
                                                        if (res.status === 401) {
                                                            this.props.catchError(res.status);
                                                            return
                                                        }
                                                        else if (res.status === 406) {
                                                            res.json().then((json) => {
                                                                this.props.updateCurrentShift(json);
                                                            });
                                                            return
                                                        }
                                                        const response = res.json();
                                                        return response;
                                                    })
                                                }
                                               }
                                        />
                                    </div>
                                </div>
                            )
                        }
                    }
                    else {
                        return (
                            <div className='shift_wrapper shift_running' key={'day_shift-' + unit_id + shift.id}>
                                <div className='manager_name'>
                                    {shift.manager.first_name[0]}.{shift.manager.last_name}
                                </div>
                                <div className='shift_time'>
                                    {shift_start}
                                </div>
                            </div>
                        )
                    }
                }
                else {
                    return (
                        <div key={'day_shift-' + unit_id + shift.id}></div>
                    )
                }
            }
            else {
                return (
                    <div key={'day_shift-' + unit_id + shift.id}></div>
                )
            }
        })
    }

    renderClubUnits(date) {
        if (parseInt(localStorage.getItem('user_type')) >= 2 ) {
            return this.state.club_units.map((unit) => {
                return (
                    <div className='shift_unit_wrapper'>
                        <div className='unit_name_wrapper'>
                            {unit.name}
                        </div>
                        {this.renderDayShifts(date, unit.id)}
                    </div>
                )
            })
        }
        else if (parseInt(localStorage.getItem('user_type')) > 2 ) {
            return this.state.club_units.map((unit) => {
                return (
                    <div>{unit.name}</div>
                )
            })
        }
    }

    renderShiftsArray() {
        var days = [];
        if (this.state.shifts && this.state.shifts[0] !== undefined) {
            if (this.state.date_start === '') {
                days.push({weekday: this.state.shifts[0]['weekday'], date: this.state.shifts[0]['shift_start']});
            }
            else {
                var d = new Date(this.state.date_start);
                var day = d.getDay();
                if (day < 1) {
                    day = 7;
                }
                days.push({weekday: day, date: d.toISOString().split('T')[0]});
            }
            var day_num = 6;
            if (this.state.date_start !== '' && this.state.date_stop !== '') {
                let first_day = new Date(this.state.date_start);
                let last_day = new Date(this.state.date_stop);
                var day_num = 0;
                if (last_day.getDate() < first_day.getDate()) {
                    day_num = last_day.getDate() - 1 + (new Date(first_day.getFullYear(), first_day.getMonth() + 1, 0).getDate() - first_day.getDate());
                }
                else {
                    day_num = last_day.getDate() - first_day.getDate();
                }
            }
            for (let i = 0; i < day_num; i++) {
                var next_day = days[i]['weekday'] + 1;
                if (next_day > 7) {
                    next_day = 1;
                }
                var next_date = new Date(days[i]['date']);
                next_date.setDate(next_date.getDate() + 1);
                next_date = next_date.toISOString().split('T')[0];
                days.push({weekday: next_day, date: next_date});
            }
        }
        else {
            if (this.state.date_start) {
                var d = new Date(this.state.date_start);
            }
            else {
                var d = new Date();
            }
            var day = d.getDay();
            if (this.state.device === 'desktop') {
                for (let i = 1; i < 8; i++) {
                    days.push({weekday: day, date: d.toISOString().split('T')[0]});
                    day++;
                    if (day < 1) {
                        day = 7;
                    }
                    else if (day > 7) {
                        day = day - 7;
                    }
                    d.setDate(d.getDate() + 1);
                }
            }
            else if (this.state.device === 'mobile') {
                days.push({weekday: day, date: d.toISOString().split('T')[0]});
            }
        }
        return days.map((day, index) => {
            return (
                <div className='calendar_cell' key={'calendar-' + day + '-' + index}>
                    <div className='weekday_wrapper'>
                        <span className='weekday_text'>{this.state.weekday[day.weekday - 1]}</span> <span className='date_text'>{day.date}</span>
                    </div>
                    {this.renderClubUnits(day.date)}
                </div>
            )
        })
    }

    renderManagerTotalShifts(manager) {
        return manager.club_units.map((unit) => {
            return (
                <div
                    className='shift_manager_time'
                    data-club_unit={unit.id}
                >
                    &#9900; {unit.name}: {unit.hours}ч. {unit.minutes}мин.
                </div>
            )
        })
    }

    renderTotalShifts() {
        return Object.keys(this.props.total_shifts).map((key) => {
            var manager = new Object(this.props.total_shifts[key]);
            return (
                <div className='manager_shift_wrapper'>
                    <div className='shift_manager_name'>
                        {manager.first_name} {manager.last_name}:
                    </div>
                    {this.renderManagerTotalShifts(manager)}
                </div>
            )
        })
    }

    render() {
        var today = new Date().getFullYear().toString();
        if (new Date().getMonth().toString().length == 1) {
            today = today + "-0" + (parseInt(new Date().getMonth().toString()) + 1);
        }
        else {
            today = today + "-" + (parseInt(new Date().getMonth().toString()) + 1);
        }
        if (new Date().getDate().toString().length == 1) {
            today = today + "-0" + new Date().getDate().toString();
        }
        else {
            today = today + "-" + new Date().getDate().toString();
        }
        if (parseInt(localStorage.getItem('user_type')) > 1) {
            return (
                <div>
                    <div className='users_filters_wrapper'>
                        <div className='show_filters_btn_wrapper'>
                            <input type='button' className='add_ticket_btn' id='show_statistic_btn_id' onClick={this.handleShowStatistic} value='Статистика' />
                        </div>
                        <div className='filters_list_wrapper' id='manager_statistic_id'>
                            <div className='ticket_date_valid_wrapper'>
                                <label>Дата начала и дата окончания</label>
                                <input
                                  key='manager_shift_date_start'
                                  id='manager_shift_date_start'
                                  type='date'
                                  placeholder='Дата начала'
                                  className='add_ticket_btn'
                                  defaultValue={today}
                                  onChange={this.handleGetTotalShifts}
                                />
                                <input
                                  key='manager_shift_date_stop'
                                  id='manager_shift_date_stop'
                                  type='date'
                                  placeholder='Дата окончания'
                                  className='add_ticket_btn'
                                  defaultValue={today}
                                  onChange={this.handleGetTotalShifts}
                                />
                            </div>
                            {this.renderTotalShifts()}
                        </div>
                    </div>
                    <div className='calendar_wrapper'>
                        <div className='calendar_arrow_wrapper left_arrow_wrapper'>
                          <div id="day-back-arrow" className='calendar_arrow' onClick={this.handleChangeStartStop}>
                              &#10094;
                          </div>
                        </div>
                        <div className='calendar_cells_wrapper'>
                            {this.renderShiftsArray()}
                        </div>
                        <div className='calendar_arrow_wrapper right_arrow_wrapper'>
                          <div id="day-forward-arrow" className='calendar_arrow' onClick={this.handleChangeStartStop}>
                              &#10095;
                          </div>
                        </div>
                    </div>
                    {this.state.lock_spinner
                        ? <Spinner />
                        : <div></div>
                    }
                </div>
            )
        }
        else {
            return (
                <div></div>
            )
        }
    }
}

export default ManagerCalendar
