import React, { Component } from 'react';
import Nav from './components/Nav';
import LoginForm from './components/LoginForm';
import SignupForm from './components/SignupForm';
import RestoreForm from './components/PasswordRestore';
import Clubs from './components/Clubs';
import Table from './components/AccountTable';
import Notifications from './components/Notifications';
import Spinner from './components/Spinner';
import Chat from './components/Chat';
import PopUp from './components/PopUp';
import { CashboxModal } from './components/AcceptModal';
import './App.css';
import './style.css';
import './chat.css';
import './profile.css';
import './products_service.css';
import './statistic.css';
import './popup.css';
import './calendar.css';
import './buttons.css';
import './modal.css';
import {
      BrowserRouter as Router,
      Switch,
      Route,
      Link,
} from "react-router-dom";
import compose from 'lodash/fp/compose';
import { createBrowserHistory } from 'history'
import {post_request} from './components/api/api_call'
import { locales } from './helpers'

var history = createBrowserHistory();

var _ = require('lodash');

class App extends Component {
    constructor(props) {
        super(props);
        locales.setLanguage('en');
        this.notify_ref = React.createRef();
        this.chat_ref = React.createRef();
        var device = 'desktop';
        if (window.screen.width < 1024) {
            device = 'mobile';
        }
        let query = new URLSearchParams(window.location.search);
        let sent_token = query.get('access_token');
        if (sent_token) {
          localStorage.setItem('token', sent_token);
        }
        this.state = {
          device: device,
          displayed_form: 'login',
          logged_in: localStorage.getItem('token') ? true : false,
          username: '',
          show_form: false,
          clubs: [],
          club_units: [],
          notifications: [],
          next_notifications: null,
          notify_form_show: false,
          notify_form: '',
          add_client_notify: false,
          add_bid_notify: false,
          notify_client_id: '',
          notify_bid_id: '',
          club_unit: null,
          current_shift: null,
          current_shift_start: null,
          shift_time: null,
          notify_club_unit: '',
          restore_mail_sent: false,
          password_restore_page: false,
          new_notify_type: null,
          permissions: {},
          lock_spinner: false,
          active: false,
          last_action: null,
          sentence: [
            "Всякая работа трудна до времени, пока ее не полюбишь, а потом — она возбуждает и становится легче. (М. Горький)",
            "Только два стимула заставляют работать людей: жажда заработной платы и боязнь её потерять. (Г. Форд)",
            "Работайте как в аду. Я имею ввиду, что вы должны работать от 80 до 100 часов в неделю каждую неделю. Это повышает шансы на успех. Если остальные будут работать по 40 часов в неделю, а вы будете работать 100 часов, то даже если вы будете делать одинаковые вещи, вы за 4 месяца достигнете того, на что у остальных уйдёт целый год. (И. Маск)",
            "Пенни не падают к нам с небес, мы вынуждены зарабатывать их здесь на земле. (М. Тэтчер)",
            "Кто не работает, тот не занимается любовью. (А. Челентано)",
            "Есть только один способ проделать большую работу — полюбить ее. Если вы к этому не пришли, подождите. Не бросайтесь за дело. Как и со всем другим, подсказать интересное дело вам поможет собственное сердце. (С. Джобс)",
            "Работа необходима для здоровья. (Гиппократ)",
            "Работа — лучшее лекарство от всех бед. (Э. Хемингуэй)",
            "Работа, которую мы делаем охотно, исцеляет боли. (У. Шекспир)",
          ][Math.floor(Math.random() * 9)],
          show_toast: false,
          toast_text: '',
          speakers: [],
          show_chat: false,
          ws: null,
          unread_chat_messages: [],
          dialog_messages: [],
          account_chat_id: null,
          current_chat: null,
          show_pop_ups: {},
          show_cashbox: false,
          user: null,
        };
        if (this.state.logged_in && localStorage.getItem('user_type') > 1) {
            this.getNotifications();
            this.getCurrentShift();
        }
    }

    componentDidMount() {
        if (this.state.logged_in) {
            document.addEventListener('mousedown', this.handleClickOut);
            this.timerID = setInterval(
                  () => this.tick(),
                  1000
            );
            this.setState({show_form: true, displayed_form: ''})
            fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/account/', {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            })
              .then(res => {
                  if (res.status === 401 || res.status === 403) {
                      this.catchError(res.status);
                      throw new Error('Something went wrong');
                  }
                  return res.json()
              })
              .then(json => {
                var clubs = []
                var club_units = []
                for (let i = 0; i < json.clubs.length; i++) {
                  clubs.push({ id: json.clubs[i]['id'], name: json.clubs[i]['name'] });
                  for (let j = 0; j < json.clubs[i]['units'].length; j++) {
                      club_units.push({ id: json.clubs[i]['units'][j]['id'], name: json.clubs[i]['units'][j]['name'] });
                  }
                }
                var club_unit = null;
                if (club_units.length === 1) {
                  club_unit = club_units[0]['id'];
                }
                localStorage.setItem("pk", json.id);
                localStorage.setItem("manage_stat_uri", JSON.stringify(json.last_report));
                localStorage.setItem('user_type', json.type);
                this.setState(
                  {
                    username: json.first_name,
                    club_units: club_units,
                    clubs: clubs,
                    club_unit: club_unit,
                    permissions: json.permissions,
                    user: json,
                  }, () => {
                    var lang = this.getLanguage()
                    if (lang !== '') {
                      locales.setLanguage(lang)
                      localStorage.setItem("lang", lang)
                    }
                    var ws = this.createSocketConnection()
                    this.setState({ws: ws})
                  }
                );
                let query = new URLSearchParams(window.location.search);
                let sent_token = query.get('access_token');
                if (sent_token) {
                  location.href = location.href.split('?')[0];
                }
              })
              .catch(error => {
                  this.setState({logged_in: false});
                  localStorage.removeItem('token');
              })
        }
        else {
            setTimeout(
                () => { this.setState({show_form: true}); },
                3000
            );
            if (new RegExp('/password/reset/[\\w\\d=]+').exec(document.location.pathname)) {
                this.getTemporaryToken();
                this.setState({
                    show_form: true,
                    password_restore_page: true,
                })
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    createSocketConnection() {
      var socket_url = new URL(process.env.REACT_APP_BACKEND_SOCKET + '/api/chat/ws')
      socket_url.searchParams.set('token', localStorage.getItem('token'))
      var ws = new WebSocket(socket_url.href)
      ws.onmessage = evt => {
        var data = JSON.parse(evt.data)
        var exist_unread_mes = this.state.unread_chat_messages
        var dialog_messages = this.state.dialog_messages
        if (data && data.constructor === Object) {
          if (data["message_type"] === "chat") {
            dialog_messages.push(data)
            if (parseInt(localStorage.getItem("pk")) != parseInt(data["outside_id"])) {
              if (exist_unread_mes && parseInt(data["outside_id"]) !== parseInt(this.state.current_chat)) {
                exist_unread_mes.push(data)
                data = exist_unread_mes
              }
              else if (parseInt(data["outside_id"]) !== parseInt(this.state.current_chat)) {
                data = [data]
              }
              else {
                data = []
              }
            }
            else {
              data = []
            }
          }
          else if (data["message_type"] === "event") {
            var show_pop_ups = this.state.show_pop_ups
            if (data["event_type"] === "shift_end") {
              this.setState({
                  current_shift: null
              });
            }
            if (Object.keys(show_pop_ups).length > 3) {
              delete show_pop_ups[parseInt(Object.keys(show_pop_ups)[0])]
            }
            show_pop_ups[parseInt(data["event_obj_id"])] = data
            this.setState({show_pop_ups: show_pop_ups})
          }
        }
        if (!data) data = []
        this.setState(
          {
            unread_chat_messages: data,
            dialog_messages: dialog_messages,
          }
        )
      }
      ws.onopen = () => {
        ws.send(
          JSON.stringify(
            {
              id: null,
              message: null,
              destination_id: null,
              message_type: 'check',
            }
          )
        )
      }
      return ws
    }

  getSetting(name, settings_arr) {
    var value = ""
    for (let s of settings_arr) {
      if (s.name === name) {
        value = s.value
        break
      }
    }
    return value
  }

  getLanguage() {
    var value = this.getSetting('language', this.state.user.user_settings)
    if (value !== '') {
      return value
    }
    for (let club of this.state.user.clubs) {
      value = this.getSetting('language', club.settings)
      if (value !== '') {
        return value
      }
    }
    return value
  }

    replaceUnreadMessages(unread_messages) {
      this.setState({unread_chat_messages: unread_messages})
    }

    handleClickOut = (e) => {
      if (this.chat_ref &&
        this.chat_ref.current &&
        (!this.chat_ref.current.contains(e.target) || e.target.id === 'background_lock_id')
      ) {
        this.setState(
          {
            show_chat: false,
            current_chat: null,
            show_cashbox: false,
          }
        );
      }
    }

    handle_login = (e, data) => {
        e.preventDefault();
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/token/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        })
        .then(res => {
          if (res.status === 401) {
              this.catchError(res.status);
              document.getElementById('toast-body-id').innerHTML = "Неверный логин или пароль";
              var x = document.getElementById("toast-wrapper-id");
              x.className = 'show';
              setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
              return
          }
          if (res.status !== 200) {
              this.catchError(res.status);
              document.getElementById('toast-body-id').innerHTML = "Серверная ошибка";
              var x = document.getElementById("toast-wrapper-id");
              x.className = 'show';
              setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
              return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
            this.setState(
                {lock_spinner: true},
                () => {
                  if (!json) {
                    this.setState({lock_spinner: false});
                    return
                  }
                  localStorage.setItem('token', json.access);
                  fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/account/', {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                  })
                  .then(res => res.json())
                  .then(json => {
                    localStorage.setItem('user_type', json.type);
                    localStorage.setItem('first_name', json.first_name)
                    localStorage.setItem('last_name', json.last_name)
                    localStorage.setItem('patronymic', json.patronymic)
                    var clubs = []
                    var club_units = []
                    if (json.clubs) {
                        for (let i = 0; i < json.clubs.length; i++) {
                          clubs.push({ id: json.clubs[i]['id'], name: json.clubs[i]['name'] });
                          for (let j = 0; j < json.clubs[i]['units'].length; j++) {
                              club_units.push({ id: json.clubs[i]['units'][j]['id'], name: json.clubs[i]['units'][j]['name'] });
                          }
                        }
                    }
                    var club_unit = null;
                    if (club_units.length === 1) {
                      club_unit = club_units[0]['id'];
                    }
                    this.setState({
                      logged_in: true,
                      displayed_form: '',
                      username: json.first_name,
                      club_units: club_units,
                      clubs: clubs,
                      club_unit: club_unit,
                    }, () => {
                      localStorage.setItem("pk", json.id)
                      var ws = this.createSocketConnection()
                      if (localStorage.getItem('user_type') > 1) {
                        this.getNotifications();
                        this.getCurrentShift();
                        this.setState(
                          {
                            lock_spinner: false,
                            ws: ws,
                          }
                        );
                      }
                      else {
                        this.setState(
                          {
                            lock_spinner: false,
                            ws: ws,
                          }
                        );
                      }
                    });
                }
            )
        });
        })
        .catch(error => {
          this.setState({logged_in: false});
          localStorage.removeItem('token');
        })
    };

    handle_password_restore = (e, data) => {
        e.preventDefault();
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/password/reset/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        })
        .then(res => {
          if (res.status === 401) {
              this.catchError(res.status);
              return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
          this.setState({
            logged_in: false,
            restore_mail_sent: true,
          });
        })
        .catch(error => {
          this.setState({
            logged_in: false,
            restore_mail_sent: false,
          });
          localStorage.removeItem('token');
        })
    };

    handle_signup = (e, data) => {
        const form_data = new FormData();
        for(const name in data) {
            if (name === 'child') {
                continue;
            }
            form_data.append(name, data[name]);
          }
        e.preventDefault();
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/account/', {
          method: 'PUT',
          body: form_data
        })
        .then(res => {
            if (res.status === 500) {
                document.getElementById('toast-body-id').innerHTML = "Серверная ошибка";
                var x = document.getElementById("toast-wrapper-id");
                x.className = 'show';
                setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                return
            }
            else if (res.status === 400) {
                document.getElementById('toast-body-id').innerHTML = "Имя и фамилия, или телефон, или email должны быть заполнены";
                var x = document.getElementById("toast-wrapper-id");
                x.className = 'show';
                setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (!json) {
                this.setState({
                  logged_in: false,
                  displayed_form: '',
                });
                return
            }
            else {
                localStorage.setItem('token', json.access_token);
                this.setState({
                  logged_in: true,
                  displayed_form: '',
                  username: json.username
                });
            }
        })
    };

    handle_logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user_type');
        if (this.state.ws) {
          this.state.ws.close()
        }
        this.setState({ logged_in: false, username: '', displayed_form: 'login' });
    };

    getNotifications() {
        if (!this.state.next_notifications) {
          var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/notifications/');
        }
        else if (this.state.next_notifications) {
          var uri = new URL(this.state.next_notifications);
        }
        uri.searchParams.set("club_unit", this.state.notify_club_unit);
        fetch(uri.href, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
          .then(res => {
              if (res.status === 401) {
                  this.catchError(res.status);
                  return
              }
              const response = res.json();
              return response;
          })
          .then(json => {
              if (this.state.logged_in) {
                  if (!json) {
                    return
                  }
                  if (json.next) {
                      var uri = new URL(json.next);
                      uri = uri.href.replace(uri.origin, process.env.REACT_APP_BACKEND_URI)
                  }
                  else {
                      var uri = null;
                  }
                  this.setState({
                    notifications: this.state.notifications.concat(json.results).filter(
                        (thing, index, self) => index === self.findIndex((t) => (
                            parseInt(t.id) === parseInt(thing.id)
                        ))),
                    next_notifications: uri},
                    () => {
                        this.setState({
                            notify_form: <Notifications
                              notifications={this.state.notifications}
                              getNotifications={this.getNotifications.bind(this)}
                              next_notify={this.state.next_notifications}
                              handleCloseNotification={this.handleCloseNotification.bind(this)}
                              handleChooseNotifyUnit={this.handleChooseNotifyUnit.bind(this)}
                              club_units={this.state.club_units}
                              list_type='dropdown'
                              user={this.state.user}
                            />});
                        if (localStorage.getItem('user_type') > 1 && this.state.notifications.length > 0) {
                            document.getElementById('bell_btn_wrapper_id').style.backgroundColor = '#c5c6c8';
                            document.getElementById('bell_btn_wrapper_id').classList.add('notifications_wrapper_message')
                        }
                    });
              }
          })
    }

    handleChooseNotifyUnit = (e) => {
        if (e.target.classList.contains("notify_filter_choosen")) {
            e.target.classList.remove("notify_filter_choosen");
            this.setState({notifications: [], notify_club_unit: ''},
                () => {this.getNotifications();}
            );
        }
        else {
            e.target.classList.add("notify_filter_choosen");
            var filter_btns = document.getElementsByClassName('notify_filter');
            for (let i = 0; i < filter_btns.length; i++) {
                if (filter_btns[i].id !== e.target.id) {
                    filter_btns[i].classList.remove("notify_filter_choosen");
                }
            }
            this.setState({notifications: [], notify_club_unit: e.target.dataset.club_unit},
                () => {this.getNotifications();}
            );
        }
    }

    handleCloseNotification = (e) => {
        var data = {id: e.target.dataset.notify_id, closed: true};
        this.setState({lock_spinner: true}, () => {
            fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/notifications/', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            })
            .then(res => {
                if (res.status === 401) {
                    this.catchError(res.status);
                    return
                }
                const response = res.json();
                return response;
            })
            .then(json => {
                this.setState(
                    {
                        notifications: this.state.notifications.filter(n => parseInt(n.id) !== parseInt(json.id)),
                        lock_spinner: false,
                    }, () => {
                        this.setState(
                            {
                                notify_form: <Notifications
                                                notifications={this.state.notifications}
                                                getNotifications={this.getNotifications.bind(this)}
                                                next_notify={this.state.next_notifications}
                                                handleCloseNotification={this.handleCloseNotification.bind(this)}
                                                handleChooseNotifyUnit={this.handleChooseNotifyUnit.bind(this)}
                                                club_units={this.state.club_units}
                                                list_type='dropdown'
                                                user={this.state.user}
                                             />
                            }
                        );
                        if (this.state.notifications.length === 0) {
                            this.setState({notify_form_show: false});
                            document.getElementById('bell_btn_wrapper_id').style.backgroundColor = '#272727';
                            document.getElementById('bell_btn_wrapper_id').classList.remove('notifications_wrapper_message')
                        }
                    });
            })
        })
    }

    getCurrentShift() {
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/manager/shift/?current=true', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        .then(res => {
            if (res.status === 401) {
                this.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (json) {
                this.setState({
                    current_shift: json.id,
                    current_shift_start: json.shift_start,
                    shift_time: this.msToTime(new Date() - new Date(json.shift_start)),
                    active: json.active,
                    last_action: json.last_action,
                });
            }
        })
    }

    updateCurrentShift(json) {
        this.setState({
            current_shift: json.id,
            current_shift_start: json.shift_start,
            shift_time: this.msToTime(new Date() - new Date(json.shift_start)),
            active: json.active,
            last_action: json.last_action,
        });
    }

    msToTime(s) {
        function pad(n, z) {
          z = z || 2;
          return ('00' + n).slice(-z);
        }

        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;

        return pad(hrs) + ':' + pad(mins) + ':' + pad(secs);
    }

    tick() {
        if (this.state.current_shift_start) {
            var previous_hour = parseInt(this.state.shift_time.split(':')[0]);
            this.setState(
                {
                    shift_time: this.msToTime(new Date() - new Date(this.state.current_shift_start))
                }, () => {
                    if (parseInt(this.state.shift_time.split(':')[0]) > previous_hour) {
                        this.setState({active: false});
                    }
                }
            );
        }
    }

    handleDeclineNewNotify = (e) => {
        document.getElementById('background_lock_id').style.display = 'none';
        this.setState({add_bid_notify: false, add_client_notify: false});
    }

    handleAddNotify = (e) => {
        if (e.target.dataset.client_id) {
            this.setState(
                {
                    add_client_notify: true,
                    notify_client_id: e.target.dataset.client_id,
                }
            )
        }
        else if (e.target.dataset.bid_id) {
            this.setState(
                {
                    add_bid_notify: true,
                    notify_bid_id: e.target.dataset.bid_id,
                }
            )
        }
    }

    setNotifyType = (e) => {
        this.setState({new_notify_type: parseInt(e["value"])});
    }

    handleCreateNotification = (e) => {
        var data = {
            client: e.target.dataset.client_id,
            bid: e.target.dataset.bid_id,
            notify_date: document.getElementById('notify_date_id').value,
            message: document.getElementById('notify_message_id').value,
            noty_type: (parseInt(localStorage.getItem("user_type")) > 1) ? this.state.new_notify_type : 5,
        };
        this.setState({lock_spinner: true}, () => {
            fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/notifications/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            })
            .then(res => {
                if (res.status === 401) {
                    this.catchError(res.status);
                    return
                }
                const response = res.json();
                return response;
            })
            .then(json => {
                var today = new Date();
                var notify_date = new Date(json.notify_date);
                if (Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) -
                    Date.UTC(notify_date.getFullYear(), notify_date.getMonth(), notify_date.getDate()) === 0
                ) {
                    this.setState(
                        {
                            notifications: this.state.notifications.concat(json),
                            add_bid_notify: false,
                            add_client_notify: false,
                            lock_spinner: false,
                        }, () => {
                            if (localStorage.getItem('user_type') > 1 &&
                                document.getElementById('bell_btn_wrapper_id').style.backgroundColor !== '#c5c6c8') {
                                document.getElementById('bell_btn_wrapper_id').style.backgroundColor = '#c5c6c8';
                                document.getElementById('bell_btn_wrapper_id').classList.add('notifications_wrapper_message')
                        }
                    });
                }
                else {
                    this.setState(
                        {
                            add_client_notify: false,
                            add_bid_notify: false,
                            new_notify_type: null,
                            lock_spinner: false,
                        }
                    );
                }
            })
        })
    }

    handleHideNotifications = (e) => {
        this.setState({notify_form_show: false, notify_form: ''});
    }

    handleShowNotifications = (e) => {
        var container = document.getElementById('notify_dropdown_id');
        if (!this.state.notify_form_show && this.state.notifications.length > 0) {
            this.setState({
                notify_form_show: true,
                notify_form: <Notifications
                    notifications={this.state.notifications}
                    getNotifications={this.getNotifications.bind(this)}
                    next_notify={this.state.next_notifications}
                    handleCloseNotification={this.handleCloseNotification.bind(this)}
                    handleChooseNotifyUnit={this.handleChooseNotifyUnit.bind(this)}
                    club_units={this.state.club_units}
                    list_type='dropdown'
                    user={this.state.user}
                />
            });
        }
        else {
            this.setState({notify_form_show: false, notify_form: ''});
        }
    }

    handleShowSpeakers = (e) => {
      if (this.state.speakers.length === 0 && !this.state.show_chat) {
        this.setState(
          {lock_spinner: true}, () => {
              var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/account');
              uri.searchParams.set("employers", 1)
              fetch(uri.toString(), {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('token')}`
                }
              })
              .then(res => {
                  if (res.status === 401) {
                      this.props.catchError(res.status);
                      return
                  }
                  else if (
                    res.status === 400 ||
                    res.status === 404 ||
                    res.status === 409 ||
                    res.status === 406
                  ) {
                    this.setState({lock_spinner: false})
                    document.getElementById('toast-body-id').innerHTML = "Чат временно не работает";
                    var x = document.getElementById("toast-wrapper-id");
                    x.className = 'show';
                    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                    return
                  }
                  const response = res.json();
                  return response;
              })
              .then(json => {
                  if (this.state.logged_in) {
                      this.setState(
                          {
                              show_chat: !this.state.show_chat,
                              lock_spinner: false,
                              speakers: json,
                          }
                      );
                  }
              })
          }
        )
      }
      else {
        this.setState({show_chat: !this.state.show_chat})
      }
    }

    handleGetChat(pk, limit, offset) {
      this.setState(
        {
          lock_spinner: true,
          dialog_messages: (parseInt(pk) != parseInt(this.state.current_chat)) ? [] : this.state.dialog_messages,
          current_chat: null,
        }, () => {

          var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/chat/messages');
          uri.searchParams.set("author", pk)
          uri.searchParams.set("limit", limit)
          uri.searchParams.set("offset", offset)
          fetch(uri.toString(), {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(res => {
            if (res.status === 401) {
              this.props.catchError(res.status);
              return
            }
            else if (
              res.status === 400 ||
              res.status === 404 ||
              res.status === 409 ||
              res.status === 406
            ) {
              this.setState({lock_spinner: false})
              document.getElementById('toast-body-id').innerHTML = "Чат временно не работает";
              var x = document.getElementById("toast-wrapper-id");
              x.className = 'show';
              setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
              return
            }
            const response = res.json();
            return response;
          })
          .then(json => {
            if (this.state.logged_in) {
              var dialog_messages = json.messages
              dialog_messages.reverse()
              dialog_messages = dialog_messages.concat(this.state.dialog_messages)
              this.setState(
                {
                  lock_spinner: false,
                  dialog_messages: dialog_messages,
                  offset: this.state.offset + this.state.limit,
                  show_part: 'chat',
                  current_chat: pk,
                  account_chat_id: json.account.pk,
                }, () => {
                  if (this.state.unread_chat_messages) this.replaceUnreadMessages(
                    this.state.unread_chat_messages.filter(mes => parseInt(mes.outside_id) !== parseInt(pk))
                  )
                }
              );
            }
          })
        }
      )
    }

    handleUpdateClubUnit = (e) => {
        var unit_id = e.target.dataset.unit_id;
        this.setState(
            {club_unit: unit_id},
            () => {
                if (!this.state.current_shift) {
                    this.startShift();
                }
                else {
                    this.stopShift();
                }
            }
        );
        document.getElementById('background_lock_id').style.display = 'none';
    }

    renderFilter() {
        return this.state.club_units.map((unit) => {
            return (
                <p>
                    <input type='radio'
                           id={'unit-' + unit.id}
                           key={'checkkey-' + unit.id}
                           data-unit_id={unit.id}
                           onClick={this.handleUpdateClubUnit}
                    />{unit.name}
                </p>
            )
        })
    }

    startShift() {
        var n = new Date();
        var data = {shift_start: n.toISOString(), club_unit: this.state.club_unit};
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/manager/shift/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        })
        .then(res => {
            if (res.status === 401) {
                this.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            this.setState({
                current_shift: json.id,
                current_shift_start: (new Date).toISOString(),
                shift_time: this.msToTime(new Date() - new Date()),
            });
        })
    }

    stopShift() {
        var n = new Date();
        var data = {shift_end: n.toISOString(), shift: this.state.current_shift};
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/manager/shift/', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        })
        .then(res => {
            if (res.status === 401) {
                this.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            this.setState({
                current_shift: null
            });
        })
    }

    handleStartStopShift = (e) => {
        if (!this.state.club_unit && this.state.club_units.length > 0 && !this.state.current_shift) {
            const choose_club_unit_form = (
                <div className='background_lock_wrapper' id='background_lock_id'>
                    <div className='new_drill_form_wrapper'>
                        {this.renderFilter()}
                    </div>
                </div>
            )
            this.setState(
                {choose_club_unit_form: choose_club_unit_form},
                () => {
                    document.getElementById('background_lock_id').style.display = 'block';
                }
            );
        }
        else if (this.state.club_unit && this.state.club_units.length > 0 && !this.state.current_shift) {
            this.startShift();
        }
        else if (this.state.current_shift) {
            this.stopShift();
        }
    }

  updateBoxOffice = (amount) => {
    var uri = new URL(
      process.env.REACT_APP_BACKEND_URI + '/api/v1/management/box_office/'
    )
    this.setState(
      {
        lock_spinner: true,
        show_cashbox: false,
      },
      () => {
        post_request(
          uri.href,
          {amount: amount},
          this.catchError,
        ).then(
          json => {
            this.setState({
              lock_spinner: false,
            });
            if (!json) {
              document.getElementById('toast-body-id').innerHTML = "Серверная ошибка, попробуйте позже!";
              var x = document.getElementById("toast-wrapper-id");
              x.className = 'show';
              setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
            }
          }
        )
      }
    )
  }

    getTemporaryToken() {
        var token = '';
        var loc_path_list = document.location.pathname.split("/");
        if (loc_path_list[loc_path_list.length - 1] === "") {
            token = loc_path_list[loc_path_list.length - 2];
        }
        else {
            token = loc_path_list[loc_path_list.length - 1];
        }
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/password/reset/' + token, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(res => {
            if (res.status !== 200) {
                this.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (json) {
                localStorage.setItem('token', json.access);
                localStorage.setItem('user_id', json.id);
            }
        })
    }

    restorePassword = (e) => {
        var password = document.getElementById('new_password_id').value;
        const form_data = new FormData();
        form_data.append('password', password);
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/account/' + localStorage.getItem("user_id") + '/', {
          method: 'POST',
          body: form_data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
          .then(res => res.json())
          .then(json => {
            let users = this.state.users;
            localStorage.removeItem('token');
            document.location.href = document.location.origin;
          })
    }

    comparePasswords = (e) => {
        if (e.target.value == document.getElementById('new_password_id').value) {
            document.getElementById('repeat_new_password_id').classList.remove("warning_border");
            document.getElementById('set_new_password_id').style.display = 'block';
        }
        else {
            document.getElementById('set_new_password_id').style.display = 'none';
            document.getElementById('repeat_new_password_id').classList.add("warning_border");
        }
    }

    display_form = form => {
        this.setState({
          displayed_form: form
        });
    };

    handleOpenForm = (e) => {
        this.setState({show_form: true});
    }

    catchError = (e) => {
        this.setState({ logged_in: false, username: '', displayed_form: 'login' });
        localStorage.removeItem('token');
    }

    handleOutsideClick = (e) => {
        if (e.target !== document.getElementById('club_list_id') || e.target !== document.getElementById('show_filters_id') ||
            !document.getElementById('club_list_id').children.includes(e.target)) {
            document.getElementById('club_list_id').style.display = 'none';
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
    }

    closePopUp = (e) => {
      var show_pop_ups = this.state.show_pop_ups
      delete show_pop_ups[parseInt(e.target.dataset.obj_id)]
      this.setState({show_pop_ups: show_pop_ups})
    }

    render() {
        let form;
        switch (this.state.displayed_form) {
          case 'login':
            form = <LoginForm handle_login={this.handle_login} />;
            break;
          case 'signup':
            form = <SignupForm handle_signup={this.handle_signup} />;
            break;
          case 'restore':
            form = <RestoreForm handle_password_restore={this.handle_password_restore} />;
            break;
          default:
            form = null;
        }

        if (this.state.show_form) {
            var cls_wrapper_name = 'head_page_wrapper';
            var head_menu = (
                <div className='user_headmenu_wrapper'>
                    <Nav
                      logged_in={this.state.logged_in}
                      display_form={this.display_form}
                      handle_logout={this.handle_logout}
                    />
                </div>
            );
            if (this.state.logged_in) {
                cls_wrapper_name = 'head_page_wrapper_logged_in';
                var notify = '';
                if (localStorage.getItem('user_type') > 1) {
                    var notify = (
                        <div className='notifications_wrapper' id='bell_btn_wrapper_id' onClick={this.handleShowNotifications}>
                            <div className='nav_btn' id='bell_btn_id'>
                                <img src='/icons/bell.png' alt='clients' width='20px' id='bell_btn_id' />
                            </div>
                        </div>
                    );
                }
                var shift = '';
                if (parseInt(localStorage.getItem('user_type')) === 2) {
                    if (this.state.current_shift) {
                        var shift = (
                            <div className='shifts_wrapper stop_shift_back' id='shift_btn_wrapper_id' onClick={this.handleStartStopShift}>
                                <div className='nav_btn' id='shift_btn_id'>
                                    <img src='/icons/sandclock.png' alt='sandclock' height='20px' />
                                </div>
                                <div className='shifts_title_wrapper'>
                                    {this.state.shift_time}
                                </div>
                            </div>
                        );
                    }
                    else {
                        var shift = (
                            <div className='shifts_wrapper start_shift_back' id='shift_btn_wrapper_id' onClick={this.handleStartStopShift}>
                                <div className='nav_btn' id='shift_btn_id'>
                                    <img src='/icons/sandclock.png' alt='sandclock' height='20px' />
                                </div>
                            </div>
                        );
                    }
                }
                head_menu = (
                    <div className='user_headmenu_wrapper'>
                        <div className='head_wrapper'>
                            <div className='logo_wrapper'>
                                <img src={`/${process.env.REACT_APP_CLUB_MENU_LOGO}`} alt='ares online' className='portal_logo' />
                            </div>
                            <div className='menu_wrapper'>
                                <div
                                  className='notifications_wrapper'
                                  id='cashbox_btn_wrapper_id'
                                  style={
                                    {
                                      backgroundColor: '#c5c6c8',
                                    }
                                  }
                                >
                                    <div className='nav_btn'>
                                      <img
                                        src='/icons/cashbox.png'
                                        alt='cashbox'
                                        width='20px'
                                        id='cashbox_btn_id'
                                        onClick={
                                          () => {this.setState({show_cashbox: true})}
                                        }
                                      />
                                    </div>
                                </div>
                                <div
                                  className={(this.state.unread_chat_messages && this.state.unread_chat_messages.length > 0) ? 'notifications_wrapper notifications_wrapper_message' : 'notifications_wrapper'}
                                  id='chat_btn_wrapper_id'
                                  onClick={this.handleShowSpeakers}
                                  style={
                                    {
                                      backgroundColor: '#c5c6c8',
                                    }
                                  }
                                >
                                    <div className='nav_btn' id='chat_btn_id'>
                                      <img
                                        src='/icons/chat.png'
                                        alt='chat'
                                        width='20px'
                                        id='chat_btn_id'
                                      />
                                    </div>
                                </div>
                                {shift}
                                {notify}
                                <div className='notifications_block_wrapper' ref={this.notify_ref}>
                                    {this.state.notify_form_show
                                        ? <div className='notify_dropdown_container' id='notify_dropdown_id'>
                                            {this.state.notify_form}
                                          </div>
                                        : ''
                                    }
                                </div>
                                <div className='username_wrapper'>
                                    <h3 className='username_head'>
                                      {this.state.logged_in
                                        ? <a href={`${process.env.REACT_APP_NEW_DESIGN_DOMAIN}/profile?access_token=${localStorage.getItem('token')}`}
                                             className='username_profile_link'>
                                             {this.state.username}
                                          </a>
                                        : ''}
                                    </h3>
                                </div>
                                <div className='logout_wrapper' onClick={this.handle_logout}>
                                    <div className='nav_btn' id='logout_btn_id' type='button'>
                                        <img src='/icons/logout.svg' alt='logout' width='20px' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            return (
              <div className="App">
                {!this.state.password_restore_page
                    ? <div className={cls_wrapper_name}>
                          {head_menu}
                          {form}
                          {this.state.logged_in
                              ? <div>
                                  <Table
                                    clubs={this.state.clubs}
                                    catchError={this.catchError.bind(this)}
                                    logged_in={this.state.logged_in}
                                    club_units={this.state.club_units}
                                    handleCreateNotification={this.handleCreateNotification.bind(this)}
                                    handleAddNotify={this.handleAddNotify}
                                    handleDeclineNewNotify={this.handleDeclineNewNotify}
                                    add_client_notify={this.state.add_client_notify}
                                    add_bid_notify={this.state.add_bid_notify}
                                    notify_client_id={this.state.notify_client_id}
                                    notify_bid_id={this.state.notify_bid_id}
                                    notify_ref={this.notify_ref}
                                    handleHideNotifications={this.handleHideNotifications.bind(this)}
                                    setNotifyType={this.setNotifyType.bind(this)}
                                    permissions={this.state.permissions}
                                    updateCurrentShift={this.updateCurrentShift.bind(this)}
                                    device={this.state.device}
                                    history={history}
                                    user={this.state.user}
                                  />
                                </div>
                              : <div data-test='no table'></div>
                          }
                          {this.state.logged_in && this.state.choose_club_unit_form
                              ? <div className='new_drill_modal_wrapper'>
                                  {this.state.choose_club_unit_form}
                                </div>
                              : <div></div>
                          }
                      </div>
                    : <div className='password_restore_wrapper'>
                        <div className='login_form'>
                            <label htmlFor="password">Введите новый пароль</label>
                            <input
                              className="login-input password_restore_input"
                              type="password"
                              name="password"
                              id="new_password_id"
                            />
                            <label htmlFor="password">Повторите пароль</label>
                            <input
                              className="login-input password_restore_input"
                              type="password"
                              name="password"
                              id="repeat_new_password_id"
                              onChange={
                                compose(
                                    _.debounce(this.comparePasswords, 500),
                                    _.clone
                                )
                              }
                            />
                            <div
                                className='password_restore_btn'
                                id='set_new_password_id'
                                onClick={this.restorePassword}
                            >
                                Отправить
                            </div>
                        </div>
                      </div>
                }
                {this.state.lock_spinner
                    ? <Spinner />
                    : <div></div>
                }
                {this.state.logged_in && !this.state.active && parseInt(localStorage.getItem('user_type')) == 2 && this.state.current_shift
                    ? <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_users_ref}>
                          <div className='drill_clients_wrapper' id='drill_clients_wrapper_id' onClick={this.handleModalClick}>
                              <div className='lock_modal_content_wrapper  lock_modal_continuous_content_wrapper' id='lock_modal_content_id'>
                                <div className='new_drill_form_wrapper'>
                                    <div className='motivation_sentence_wrapper'>
                                        <h3>{this.state.sentence}</h3>
                                    </div>
                                    <button
                                        id='remove-client-accept'
                                        key='remove-client-accept'
                                        className='drill_traffic_btn'
                                        onClick={
                                            () => {
                                                fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/manager/shift/', {
                                                    method: 'PUT',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        Authorization: `Bearer ${localStorage.getItem('token')}`
                                                    },
                                                    body: JSON.stringify({active: true, shift: this.state.current_shift})
                                                })
                                                .then(res => {
                                                    if (res.status === 401) {
                                                        this.catchError(res.status);
                                                        return
                                                    }
                                                    else if (res.status === 406) {
                                                        res.json().then((json) => {
                                                            this.updateCurrentShift(json);
                                                        });
                                                        return
                                                    }
                                                    const response = res.json();
                                                    return response;
                                                })
                                                .then(json => {
                                                    if (!json) {
                                                        return
                                                    }
                                                    this.setState({
                                                        current_shift: json.id,
                                                        current_shift_start: json.shift_start,
                                                        shift_time: this.msToTime(new Date() - new Date(json.shift_start)),
                                                        active: json.active,
                                                        last_action: json.last_action,
                                                    });
                                                })
                                            }
                                        }
                                    >
                                        &#10004;
                                    </button>
                                </div>
                              </div>
                          </div>
                      </div>
                    : ''
                }
                {this.state.show_chat
                  ? <Chat
                      catchError={this.catchError.bind(this)}
                      logged_in={this.state.logged_in}
                      speakers={this.state.speakers}
                      chat_ref={this.chat_ref}
                      device={this.state.device}
                      ws={this.state.ws}
                      unread_chat_messages={this.state.unread_chat_messages}
                      replaceUnreadMessages={this.replaceUnreadMessages.bind(this)}
                      handleGetChat={this.handleGetChat.bind(this)}
                      dialog_messages={this.state.dialog_messages}
                      account_chat_id={this.state.account_chat_id}
                      user={this.state.user}
                    />
                  : ''
                }
                {this.state.show_cashbox
                  ? <CashboxModal
                      chat_ref={this.chat_ref}
                      updateBoxOffice={this.updateBoxOffice.bind(this)}
                      user={this.state.user}
                    />
                  : ''
                }
                {Object.keys(this.state.show_pop_ups).length > 0
                  ? <div
                      className="popup_container"
                    >
                      <PopUp
                        pop_ups={Object.values(this.state.show_pop_ups)}
                        closePopUp={this.closePopUp.bind(this)}
                        user={this.state.user}
                      />
                    </div>
                  : ''
                }
                <div id="toast-wrapper-id">
                    <div id="toast-body-id">
                    </div>
                </div>
              </div>
            );
        }
        else {
            return (
            <div onClick={this.handleOpenForm}
                 className='site_cover'>
                <div className='cover_img_wrapper'>
                    <img src='/logo_1.png' alt='ARES' />
                </div>
            </div>
            )
        }
    }
}

export default App;
