import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveFunnel } from '@nivo/funnel'
import { ResponsiveLine } from '@nivo/line'
import Select from 'react-select';
import Spinner from './Spinner';
import compose from 'lodash/fp/compose';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent } from 'react-chartjs-2';
import {
  post_request,
  get_request,
} from './api/api_call'
import BaseUsersList from './base_objs/UsersList'
import BaseComponent from './base_objs/Common'
import FullUsersTable from './interface/UsersList'
import {
  SailsStatisticPlot,
  CoachesDrillsPlot,
} from './interface/Statistic'
import {
  CashBoxReport
} from './statistic/Report'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

var _ = require('lodash');

const select_styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        padding: 20,
        'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        color: '#f7941e',
        border: '1px solid #f7941e',
        'background-color': '#c5c6c8',
        'outline-color': '#f7941e',
    }),
    container: (provided, state) => ({
        ...provided,
        width: '50%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'margin-bottom': '0.5rem',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'background-color': '#c5c6c8',
    }),
}

class StatisticPage extends(BaseComponent) {
  constructor(props) {
    super(props);
    this.modal_setting_ref = React.createRef();
    this.modal_conf_ref = React.createRef();
    this.modal_user_tickets_ref = React.createRef();
    var date_start = new Date()
    date_start.setDate(1)
    var date_stop = new Date(date_start.getFullYear(), date_start.getMonth() + 1)
    this.state = {
      stat_data: {},
      stat_start_date: date_start.toISOLocaleString().substr(0, 10),
      stat_stop_date: date_stop.toISOLocaleString().substr(0, 10),
      manage_stat_uri: {
        'head-table': '',
        'coaches-table': '',
      },
      tickets_client_stat: {},
      lock_spinner: false,
      lock_spinner_stat: false,
      excluded_tickets: [],
      excluded_promos: [],
      stat_club_unit: '',
      show_settings: false,
      club_tickets: [],
      club_promos: [],
      show_ticket_stat: false,
      show_stat_export: false,
      tickets_info: [],
      show_ticket_info: false,
      choosen_ticket_show: {},
      salary_group_rate: {},
      clients_promo_code_statistic: [],
      show_coaches_stat: false,
      coaches_promo_prolongation: {},
      coaches_unlimit_attendance: {},
      coach_stat_club_unit_id: null,
      coach_stat_date_start: date_start.toISOLocaleString().substr(0, 10),
      coach_stat_date_stop: date_stop.toISOLocaleString().substr(0, 10),
      coach_stat_promo_id: '',
      coach_stat_ticket_id: '',
      show_bids_statistic: false,
      show_sails_statistic: false,
      bid_sources: [],
      coaches: [],
      coach_stat_coach_id: '',
      user_tickets_show: false,
      user_tickets: [],
      club_departments: [],
      stat_drills_coach_id: (parseInt(localStorage.getItem('user_type')) === 1) ? localStorage.getItem('pk') : '',
      stat_drills_type_id: '',
      coach_drills_stat: [],
      drill_types: [],
      coach_stat_type: 'drill_stat', // drill_stat\attendence_stat
      show_cashbox_statistic: false,
      coaches_arr: [],
      coach_stat_data: null,
    }
  }

  handleClickOut = (e) => {
    if (this.modal_setting_ref &&
        this.modal_setting_ref.current &&
        (!this.modal_setting_ref.current.contains(e.target) || e.target.id === 'background_lock_id')
    ) {
      this.setState({show_settings: false});
    }
    else if (this.modal_user_tickets_ref &&
        this.modal_user_tickets_ref.current &&
        (!this.modal_user_tickets_ref.current.contains(e.target) || e.target.id === 'background_lock_id')
    ) {
      this.setState({user_tickets_show: false});
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOut);
    this.getClubDepartments()
    this.getDrillsTypes()
    if (parseInt(localStorage.getItem('user_type')) > 1 && this.state.show_ticket_stat) {
      this.getTicketStatistic();
    }
    if (parseInt(localStorage.getItem('user_type')) > 2 && this.state.show_ticket_stat) {
      this.getClientPromoCodeTicketStatistic();
    }
    if (localStorage.getItem("manage_stat_uri")) {
      this.setState({manage_stat_uri: JSON.parse(localStorage.getItem("manage_stat_uri"))})
    }
  }

  updateState(new_state, func = null, args = []) {
    if (func) {
      this.setState(
        new_state, () => {
          func(...args)
        }
      )
    }
    else {
      this.setState(new_state)
    }
  }

  getTicketStatistic() {
    this.setState(
      {
          lock_spinner: true,
      }, () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/tickets/statistic/')
        uri.searchParams.set('date_start', this.state.stat_start_date)
        uri.searchParams.set('date_stop', this.state.stat_stop_date)
        if (this.state.coach_stat_data) {
          uri.searchParams.set('coach_id', this.state.coach_stat_data)
        }
        get_request(uri.href, this.props.catchError).then(json => {
          if (!json) {
              return false
          }
          if (this.props.logged_in) {
            var clubs_stats = this.state.stat_data;
            for (let i in json.clubs) {
              let club = {
                "name": json.clubs[i]['name'],
                "id": i,
                "all": json.clubs[i]['all'],
                "renewed": json.clubs[i]['renewed'],
                "unrenewed": json.clubs[i]['unrenewed'],
                "new": json.clubs[i]['new'],
                "stoped": json.clubs[i]['stoped'],
                "acting": json.clubs[i]['acting'],
                "tickets": [],
                "y_axe": Array.from(Array(json.clubs[i]['all']).keys()),
              };
              for (let j in json.clubs[i]['tickets']) {
                club['tickets'].push(
                  {
                    'Абонемент': json.clubs[i]['tickets'][j]['ticket_name'],
                    'Продленные': json.clubs[i]['tickets'][j]['renewed'],
                    'Непродленные': json.clubs[i]['tickets'][j]['unrenewed'],
                    'Новые': json.clubs[i]['tickets'][j]['new'],
                  }
                );
              }
              clubs_stats[i] = club;
            }
            this.setState({
              stat_data: clubs_stats,
              stat_start_date: json.date_start,
              stat_stop_date: json.date_stop,
              lock_spinner: false,
            }, () => {this.getClientsTicketStatistic();});
          }
        })
      }
    )
  }

  getClientPromoCodeTicketStatistic() {
    this.setState(
      {
        lock_spinner: true,
      }, () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/promo_code/tickets/statistic/');
        uri.searchParams.set('date_start', this.state.stat_start_date);
        uri.searchParams.set('date_stop', this.state.stat_stop_date);
        fetch(uri.href, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
          if (res.status === 401) {
              this.props.catchError(res.status);
              return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
          if (this.props.logged_in) {
            this.setState(
              {
                clients_promo_code_statistic: json,
                lock_spinner: false,
              }
            )
          }
        })
      }
    )
  }

  getClientsTicketStatistic() {
    this.setState(
      {
        lock_spinner: true,
      }, () => {
        fetch(
            process.env.REACT_APP_BACKEND_URI +
            '/api/v1/management/tickets/clients/statistic/?date_start=' + this.state.stat_start_date +
            '&date_stop=' + this.state.stat_stop_date, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
          if (res.status === 401) {
            this.props.catchError(res.status);
            return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
          if (this.props.logged_in) {
            var clubs_stats = this.state.stat_data;
            for (let i in json) {
              clubs_stats[parseInt(i)]["sales_stat"] = [
                {"id": "Такой же", "label": "Такой же", "value": json[i]["same"]},
                {"id": "Дороже", "label": "Дороже", "value": json[i]["more_expensive"]},
                {"id": "Дешевле", "label": "Дешевле", "value": json[i]["lower_expensive"]},
                {"id": "Не продленные", "label": "Не продленные", "value": json[i]["not_longer"]},
                {"id": "Продленные акции", "label": "Продленные акции", "value": json[i]["longer_promo"]},
                {"id": "Не продленные акции", "label": "Не продленные акции", "value": json[i]["not_longer_promo"]},
                {"id": "Новый", "label": "Новый", "value": json[i]["new"]},
                {"id": "Новый по акции", "label": "Новый по акции", "value": json[i]["new_promo"]},
              ];
            }
            this.setState({
              stat_data: clubs_stats,
              lock_spinner: false,
            });
          }
        })
      }
    )
  }

  getTickets(full = false, drills_amount = null) {
    this.setState(
      {
        lock_spinner: true,
      }, () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/tickets/');
        if (!full) {
          uri.searchParams.set('club_unit', this.state.stat_club_unit);
        }
        if (parseInt(localStorage.getItem('user_type')) === 1) {
          uri.searchParams.set('relevant', 1);
        }
        if (drills_amount !== null) {
          uri.searchParams.set('drills_amount', drills_amount);
        }
        fetch(uri.href, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
          if (res.status === 401) {
            this.props.catchError(res.status);
            return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
          if (this.props.logged_in) {
            if (!full) {
              this.setState(
                {
                  club_tickets: json,
                  lock_spinner: false,
                  excluded_tickets: [],
                }
              );
            }
            else {
              this.setState(
                {
                  tickets_info: json,
                  lock_spinner: false,
                  excluded_tickets: [],
                }
              )
            }
          }
        })
      }
    )
  }

  getPromos(club_unit) {
    this.setState(
      {
        lock_spinner: true,
      }, () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/promo/');
        uri.searchParams.set('club_unit', club_unit);
        fetch(uri.href, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
          if (res.status === 401) {
            this.props.catchError(res.status);
            return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
          if (this.props.logged_in) {
            this.setState({
                club_promos: json.results,
                lock_spinner: false,
                excluded_promos: [],
            });
          }
        })
      }
    )
  }

  getCoaches() {
    var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_coaches/');
    uri.searchParams.set('limit', 100);
    fetch(uri.href, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(res => {
      if (res.status === 401) {
        this.props.catchError(res.status);
        return
      }
      const response = res.json();
      return response;
    })
    .then(json => {
      if (json === undefined) {
        this.props.catchError(401);
        return
      }
      if (this.props.logged_in) {
        this.setState({coaches: json.results});
      }
    })
  }

  getDrillsTypes() {
    this.setState(
      {lock_spinner: true},
      () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/drills_types/');
        get_request(uri.href, this.props.catchError).then(
          json => {
            if (this.props.logged_in && json) {
                this.setState({
                  drill_types: json.results,
                  lock_spinner: false,
                })
            }
          }
        )
      }
    )
  }

  getCoachesStatistic(statistic_type) {
    this.setState(
      {lock_spinner: true}, () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/coaches/statistic/');
        uri.searchParams.set('statistic_type', statistic_type);
        uri.searchParams.set('date_start', this.state.coach_stat_date_start);
        uri.searchParams.set('date_stop', this.state.coach_stat_date_stop);
        if (statistic_type === 'promo_prolongation') {
          if (!this.state.coach_stat_promo_id && !this.state.coach_stat_coach_id) {
            this.setState({lock_spinner: false})
            return
          }
          uri.searchParams.set('promo_id', this.state.coach_stat_promo_id);
          uri.searchParams.set('coach_id', this.state.coach_stat_coach_id);
        }
        else if (statistic_type === 'unlimit_attendance') {
          if (!this.state.coach_stat_ticket_id) {
            this.setState({lock_spinner: false})
            return
          }
          uri.searchParams.set('ticket_id', this.state.coach_stat_ticket_id);
          uri.searchParams.set('club_unit_id', this.state.coach_stat_club_unit_id);
        }
        fetch(uri.href, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
          if (res.status === 401) {
            this.props.catchError(res.status);
            return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
          if (this.props.logged_in) {
            if (statistic_type === 'promo_prolongation') {
              this.setState(
                {
                  lock_spinner: false,
                  coach_stat_date_start: json.date_start,
                  coach_stat_date_stop: json.date_stop,
                  coaches_promo_prolongation: json.statistic,
                }
              )
            }
            else if (statistic_type === 'unlimit_attendance') {
              this.setState(
                {
                  lock_spinner: false,
                  coach_stat_date_start: json.date_start,
                  coach_stat_date_stop: json.date_stop,
                  coaches_unlimit_attendance: json.statistic,
                }
              )
            }
          }
        })
      }
    )
  }

  getCoachesDrillsStat() {
    if (!this.state.stat_drills_coach_id || !this.state.stat_drills_type_id) {
      return
    }
    this.setState(
      {lock_spinner: true},
      () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/coaches/drills/statistic/');
        uri.searchParams.set('date_start', this.state.coach_stat_date_start);
        uri.searchParams.set('date_stop', this.state.coach_stat_date_stop);
        uri.searchParams.set('coach_id', this.state.stat_drills_coach_id);
        uri.searchParams.set('drill_type_id', this.state.stat_drills_type_id);
        uri.searchParams.set('stat_type', this.state.coach_stat_type);
        get_request(uri.href, this.props.catchError).then(
          json => {
            if (this.props.logged_in && json) {
              this.setState({
                coach_drills_stat: json.drills,
                lock_spinner: false
              });
            }
          }
        )
      }
    )
  }

  getBidsSource() {
    this.setState(
      {
        lock_spinner: true,
      }, () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/bid_source/');
        fetch(uri.href, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
          if (res.status === 401) {
            this.props.catchError(res.status);
            return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
          if (this.props.logged_in) {
            this.setState({
                bid_sources: json,
                lock_spinner: false,
            });
          }
        })
      }
    )
  }

  getCoachesArr() {
    this.setState(
      {lock_spinner: true},
      () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/get_coaches/');
        uri.searchParams.set('short', 1)
        get_request(uri.href, this.props.catchError).then(
          json => {
            if (this.props.logged_in && json) {
                this.setState({
                  coaches_arr: json.results,
                  lock_spinner: false,
                })
            }
          }
        )
      }
    )
  }

  updateTicketStatistic(start_date, stop_date) {
    this.setState(
      {
        stat_start_date: start_date,
        stat_stop_date: stop_date,
      }, () => {
        this.getTicketStatistic()
        this.getClientPromoCodeTicketStatistic()
      }
    )
  }

  handleSetExcludeTickets = (e) => {
    if (e.constructor === Array && e[0] && e[0]["type"] === "ticket") {
      this.setState({excluded_tickets: Array.from(e, (val) => val["value"])});
    }
    else if (e.constructor === Array && e[0] && e[0]["type"] === "promo") {
      this.setState({excluded_promos: Array.from(e, (val) => val["value"])});
    }
  }

  handleExportStatistic = (e) => {
    var elem_id = e.target.id;
    if (elem_id === '') {
        elem_id = e.target.parentNode.id;
    }
    var stat_type = e.target.dataset.stat_id;
    if (stat_type === '' || !stat_type) {
        stat_type = e.target.parentNode.dataset.stat_id;
    }
    var path = '/api/v2/management/export_xls/'
    if (stat_type === 'market-statistic') {
      path = '/api/v1/market/export/statistic'
    }
    this.setState({lock_spinner: true}, () => {
      var uri = new URL(process.env.REACT_APP_BACKEND_URI + path);
      uri.searchParams.set("club_unit", this.state.stat_club_unit);
      uri.searchParams.set("date_start", document.getElementById('export_date_start-' + stat_type).value);
      uri.searchParams.set("date_stop", document.getElementById('export_date_stop-' + stat_type).value);
      uri.searchParams.set("stat_type", stat_type);
      var exclude_tickets = this.state.excluded_tickets.join(',');
      var exclude_promos = this.state.excluded_promos.join(',');
      uri.searchParams.set("exclude_tickets", exclude_tickets);
      uri.searchParams.set("exclude_promos", exclude_promos);
      fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          this.props.catchError(res.status);
          return
        }
        if (res.status === 403) {
          document.getElementById('export_club_units_filter_selector' + stat_type).classList.add('warning_border');
        }
        if (res.status === 400) {
          document.getElementById(elem_id).classList.add('warning_border');
          this.setState({lock_spinner: false});
        }
        const response = res.json();
        return response;
      })
      .then(json => {
        if (json === undefined) {
          this.props.catchError(401);
          return
        }
        if (this.props.logged_in) {
          let manage_stat_uri = this.state.manage_stat_uri;
          manage_stat_uri[stat_type] = json.report
          this.setState(
            {
              manage_stat_uri: manage_stat_uri,
              lock_spinner: false,
            }
          );
        }
      })
    })
  }

  getUserTicketsStat(user_ticket_ids) {
    this.setState(
      {
        user_tickets: user_ticket_ids,
        user_tickets_show: true,
      }
    )
    // this.setState(
    //   {
    //     lock_spinner: true,
    //   }, () => {
    //     var uri = new URL(
    //       process.env.REACT_APP_BACKEND_URI + '/api/v1/management/coaches/statistic/details/'
    //     )
    //     post_request(
    //       uri.href,
    //       {user_ticket_id: user_ticket_ids},
    //       this.props.catchError,
    //     ).then(
    //       json => {
    //         if (this.props.logged_in && json) {
    //           this.setState({
    //             user_tickets: json,
    //             user_tickets_show: true,
    //             lock_spinner: false,
    //           });
    //         }
    //       }
    //     )
    //   }
    // )
  }

  render() {
    return (
      <div>
        {parseInt(localStorage.getItem('user_type')) > 2
          ? <div>
              <div
                className='tab_name_wrapper'
                style={
                  {
                    display: 'flex',
                  }
                }
              >
                <div
                  style={
                    {
                      transform: (this.state.show_stat_export) ? 'rotate(90deg)' : 'rotate(0deg)'
                    }
                  }
                >
                  >
                </div>
                <div
                  onClick={
                    () => {
                      this.setState({show_stat_export: !this.state.show_stat_export})
                    }
                  }
                >
                    Экспорт статистики
                </div>
              </div>
              {this.state.show_stat_export
                ? <div className='tickets_stat_wrapper'>
                    <ExportStatTicket
                      handleExportStatistic={this.handleExportStatistic.bind(this)}
                      handleSetExcludeTickets={this.handleSetExcludeTickets.bind(this)}
                      promos={this.state.promos}
                      tickets={this.state.tickets}
                      modal_setting_ref={this.modal_setting_ref}
                      club_units={this.props.club_units}
                      stat_club_unit={this.state.stat_club_unit}
                      updateState={this.updateState.bind(this)}
                      manage_stat_uri={this.state.manage_stat_uri}
                      getTickets={this.getTickets.bind(this)}
                      getPromos={this.getPromos.bind(this)}
                      excluded_promos={this.state.excluded_promos}
                      excluded_tickets={this.state.excluded_tickets}
                    />
                  </div>
                : ''
              }
            </div>
          : ''
        }
        {parseInt(localStorage.getItem('user_type')) > 1
          ? <div>
              <div
                className='tab_name_wrapper'
                style={
                  {
                    display: 'flex',
                  }
                }
              >
                <div
                  style={
                    {
                      transform: (this.state.show_ticket_stat) ? 'rotate(90deg)' : 'rotate(0deg)'
                    }
                  }
                >
                  >
                </div>
                <div
                  onClick={
                    () => {
                      this.setState(
                        {show_ticket_stat: !this.state.show_ticket_stat},
                        () => {
                          if (this.state.show_ticket_stat) {
                            this.getCoachesArr()
                          }
                          if (
                            this.state.show_ticket_stat &&
                            Object.keys(this.state.stat_data).length === 0
                          ) {
                            this.getTicketStatistic();
                          }
                          if (
                            this.state.show_ticket_stat &&
                            this.state.clients_promo_code_statistic.length === 0
                          ) {
                            this.getClientPromoCodeTicketStatistic()
                          }
                        }
                      )
                    }
                  }
                >
                  Статистика по абонементам
                </div>
              </div>
              {this.state.show_ticket_stat
                ? <div>
                    <TicketStatisticTab
                      updateTicketStatistic={this.updateTicketStatistic.bind(this)}
                      stat_data={this.state.stat_data}
                      clients_promo_code_statistic={this.state.clients_promo_code_statistic}
                      getUserTicketsStat={this.getUserTicketsStat.bind(this)}
                      coaches_arr={this.state.coaches_arr}
                      updateState={this.updateState.bind(this)}
                      getTicketStatistic={this.getTicketStatistic.bind(this)}
                    />
                  </div>
                : ''
              }
            </div>
          : ''
        }
        {parseInt(localStorage.getItem('user_type')) > 0
          ? <div>
              <div
                className='tab_name_wrapper'
                style={
                  {
                    display: 'flex',
                  }
                }
              >
                <div
                  style={
                    {
                      transform: (this.state.show_coaches_stat) ? 'rotate(90deg)' : 'rotate(0deg)'
                    }
                  }
                >
                  >
                </div>
                <div
                  onClick={
                    () => {
                      this.setState(
                        {show_coaches_stat: !this.state.show_coaches_stat},
                        () => {
                          if (this.state.show_coaches_stat) {
                            this.getPromos('')
                            this.getTickets(false, -1)
                            this.getCoaches()
                          }
                        }
                      )
                    }
                  }
                >
                  Статистика по тренерам
                </div>
              </div>
              {this.state.show_coaches_stat
                ? <CoachesPromoStatisticTab
                    promos={this.state.club_promos}
                    coaches={this.state.coaches}
                    tickets={this.state.club_tickets}
                    updateState={this.updateState.bind(this)}
                    coach_stat_date_start={this.state.coach_stat_date_start}
                    coach_stat_date_stop={this.state.coach_stat_date_stop}
                    getCoachesStatistic={this.getCoachesStatistic.bind(this)}
                    coaches_promo_prolongation={this.state.coaches_promo_prolongation}
                    coaches_unlimit_attendance={this.state.coaches_unlimit_attendance}
                    device={this.props.device}
                    getUserTicketsStat={this.getUserTicketsStat.bind(this)}
                    getCoachesDrillsStat={this.getCoachesDrillsStat.bind(this)}
                    drill_types={this.state.drill_types}
                    coach_drills_stat={this.state.coach_drills_stat}
                    coach_stat_type={this.state.coach_stat_type}
                  />
                : ''
              }
            </div>
          : ''
        }
        {parseInt(localStorage.getItem('user_type')) > 1
          ? <div>
              <div
                className='tab_name_wrapper'
                style={
                  {
                    display: 'flex',
                  }
                }
              >
                <div
                  style={
                    {
                      transform: (this.state.show_bids_statistic) ? 'rotate(90deg)' : 'rotate(0deg)'
                    }
                  }
                >
                  >
                </div>
                <div
                  onClick={
                    () => {
                      this.setState(
                        {show_bids_statistic: !this.state.show_bids_statistic},
                        () => {
                          if (
                            this.state.show_bids_statistic &&
                            this.state.bid_sources.length === 0
                          ) {
                            this.getBidsSource()
                          }
                        }
                      )
                    }
                  }
                >
                  Статистика по заявкам
                </div>
              </div>
              {this.state.show_bids_statistic
                ? <BidsStatisticTab
                    bid_sources={this.state.bid_sources}
                    logged_in={this.props.logged_in}
                  />
                : ''
              }
            </div>
          : ''
        }
        {parseInt(localStorage.getItem('user_type')) > 1
          ? <div>
              <div
                className='tab_name_wrapper'
                style={
                  {
                    display: 'flex',
                  }
                }
              >
                <div
                  style={
                    {
                      transform: (this.state.show_sails_statistic) ? 'rotate(90deg)' : 'rotate(0deg)'
                    }
                  }
                >
                  >
                </div>
                <div
                  onClick={
                    () => {
                      this.setState(
                        {show_sails_statistic: !this.state.show_sails_statistic}
                      )
                    }
                  }
                >
                  Статистика по продажам
                </div>
              </div>
              {this.state.show_sails_statistic
                ? <SailsStatisticTab
                    updateState={this.updateState.bind(this)}
                    logged_in={this.props.logged_in}
                  />
                : ''
              }
            </div>
          : ''
        }
        {parseInt(localStorage.getItem('user_type')) > 1
          ? <div>
              <div
                className='tab_name_wrapper'
                style={
                  {
                    display: 'flex',
                  }
                }
              >
                <div
                  style={
                    {
                      transform: (this.state.show_cashbox_statistic) ? 'rotate(90deg)' : 'rotate(0deg)'
                    }
                  }
                >
                  >
                </div>
                <div
                  onClick={
                    () => {
                      this.setState(
                        {show_cashbox_statistic: !this.state.show_cashbox_statistic}
                      )
                    }
                  }
                >
                  Отчет по кассе
                </div>
              </div>
              {this.state.show_cashbox_statistic
                ? <CashboxStatisticTab
                    updateState={this.updateState.bind(this)}
                    logged_in={this.props.logged_in}
                    catchError={this.props.catchError}
                  />
                : ''
              }
            </div>
          : ''
        }
        {this.state.lock_spinner
          ? <Spinner />
          : <div></div>
        }
        {this.state.show_settings
            ? <ExportStatTicketSettings
                club_unit={this.state.stat_club_unit}
                handleSetExcludeTickets={this.handleSetExcludeTickets.bind(this)}
                modal_setting_ref={this.modal_setting_ref}
                tickets={this.state.club_tickets}
                promos={this.state.club_promos}
                excluded_promos={this.state.excluded_promos}
                excluded_tickets={this.state.excluded_tickets}
              />
            : ''
        }
        {this.state.user_tickets_show
          ? <StatisticClientInfo
              logged_in={this.props.logged_in}
              handleShowTicketInfo={this.props.handleShowTicketInfo}
              renderDisciplineList={this.props.renderDisciplineList}
              renderCoachList={this.props.renderCoachList}
              handleAddNotify={this.props.handleAddNotify}
              handleUpdateSketches={this.props.handleUpdateSketches}
              renderTicketsList={this.props.renderTicketsList}
              history={this.props.history}
              update_data={this.props.update_data}
              user_tickets={this.state.user_tickets}
              modal_user_tickets_ref={this.modal_user_tickets_ref}
              users_pks={this.state.user_tickets}
              club_departments={this.state.club_departments}
            />
          : ''
        }
      </div>
    )
  }
}

class ExportStatTicket extends(React.Component) {
  constructor(props) {
    super(props)
    this.state = {
      update_tickets_promo: false,
    }
  }

  countToday() {
    var today = new Date().getFullYear().toString();
    if (new Date().getMonth().toString().length == 1) {
      today = today + "-0" + (parseInt(new Date().getMonth().toString()) + 1);
    }
    else {
      today = today + "-" + (parseInt(new Date().getMonth().toString()) + 1);
    }
    if (new Date().getDate().toString().length == 1) {
      today = today + "-0" + new Date().getDate().toString();
    }
    else {
      today = today + "-" + new Date().getDate().toString();
    }
    return today
  }

  renderExportStatParts() {
    var today = this.countToday();
    return [
      { id: 'head-table', label: 'Управленческая таблица'},
      { id: 'coaches-table', label: 'Посещаемость тренировок'},
      { id: 'attendance-table', label: 'Посещаемость клиентов'},
      { id: 'market-statistic', label: 'Статистика продаж (маркет)'},
    ].map((val) => {
      return (
        <div
          className='statistic_export_container'
          key={val.id}
        >
          <div className='club_name_tickets_info'>
              {val.label}
          </div>
          <div className='ticket_date_valid_wrapper'>
            <label>Дата начала и дата окончания</label>
            <input
              key={'export_date_start-' + val.id}
              id={'export_date_start-' + val.id}
              type='date'
              placeholder='Дата начала'
              className='add_ticket_btn'
              defaultValue={today}
            />
            <input
              key={'export_date_stop-' + val.id}
              id={'export_date_stop-' + val.id}
              type='date'
              placeholder='Дата окончания'
              className='add_ticket_btn'
              defaultValue={today}
            />
          </div>
          <div
            className='filter_apply_btn export_btn'
            id={'apply-filters-id-' + val.id}
            onClick={this.props.handleExportStatistic}
            data-stat_id={val.id}
          >
            <span>Экспорт</span>
          </div>
          {this.props.manage_stat_uri[val.id]
            ? <a href={process.env.REACT_APP_BACKEND_URI + this.props.manage_stat_uri[val.id]}>
                Скачать
              </a>
            : ''
          }
        </div>
      )
    })
  }

  render() {
    return(
      <div>
          <div className='filter_btn_wrapper stat_export_select_wrapper'>
            <div className='filter_stat_container'>
              <div
                className='filter_unit_select_wrapper'
                id='club_unit_stat_chooser'
              >
                <Select
                  options={
                    Array.from(this.props.club_units, (val) => new Object(
                      {
                        "value": val.id,
                        "label": val.name,
                        "type": "club_unit",
                      }
                    ))
                  }
                  onChange={
                    (e) => {
                      if (parseInt(this.props.stat_club_unit) !== parseInt(e["value"])) {
                        this.setState(
                          {update_tickets_promo: true},
                          () => {
                            this.props.updateState({stat_club_unit: e["value"]});
                          }
                        )
                      }
                    }
                  }
                  styles={select_styles}
                  placeholder={"Филиал клуба"}
                  menuPosition={'fixed'}
                />
              </div>
              <div
                className='setting_wrapper'
                onClick={
                  () => {
                    if (!this.props.stat_club_unit) {
                      document.getElementById('club_unit_stat_chooser').classList.remove('danger_background');
                      document.getElementById('club_unit_stat_chooser').classList.add('danger_background');
                    }
                    else {
                      if (this.state.update_tickets_promo) {
                        this.props.getTickets();
                        this.props.getPromos(this.props.stat_club_unit);
                        this.setState({update_tickets_promo: false})
                      }
                      this.props.updateState({show_settings: true})
                    }
                  }
                }
              >
                <img src='/icons/setting.png' alt='setting' height='25px' />
              </div>
            </div>
          </div>
        {this.renderExportStatParts()}
      </div>
    )
  }
}

class ExportStatTicketSettings extends(React.Component) {
  constructor(props) {
    super(props);
    this.state = {
      tickets: [],
      promos: [],
    }
  }

  render() {
    return (
      <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_setting_ref}>
        <div className='new_drill_form_wrapper' ref={this.props.modal_setting_ref}>
          <h3 ref={this.props.modal_setting_ref}>
            Список неоплачиваемых абонементов и акций
          </h3>
          <div
            className='exclude_tickets_wrapper'
            ref={this.props.modal_setting_ref}
          >
            <Select
              isMulti
              isClearable={false}
              options={
                Array.from(this.props.tickets, (val) => new Object(
                  {
                    "value": val.id,
                    "label": val.ticket,
                    "type": "ticket",
                  }
                ))
              }
              onChange={
                (e) => {
                  this.props.handleSetExcludeTickets(e);
                }
              }
              defaultValue={
                Array.from(
                  this.props.tickets.filter(
                    t => this.props.excluded_tickets.includes(t.id)
                  ), (val) => new Object(
                    {
                      "value": val.id,
                      "label": val.ticket,
                      "ticket": "ticket",
                    }
                  )
                )
              }
              styles={select_styles}
              placeholder={"Неоплачиваемые абонементы"}
              menuPosition={'fixed'}
              ref={this.props.modal_setting_ref}
            />
          </div>
          <div
            className='exclude_tickets_wrapper'
            ref={this.props.modal_setting_ref}
          >
            <Select
              isMulti
              isClearable={false}
              options={
                Array.from(this.props.promos, (val) => new Object(
                  {
                    "value": val.id,
                    "label": val.name,
                    "type": "promo",
                  }
                ))
              }
              onChange={
                (e) => {
                    this.props.handleSetExcludeTickets(e);
                }
              }
              defaultValue={
                Array.from(
                  this.props.promos.filter(
                    p => this.props.excluded_promos.includes(p.id)
                  ), (val) => new Object(
                    {
                      "value": val.id,
                      "label": val.name,
                      "type": "promo",
                    }
                  )
                )
              }
              styles={select_styles}
              placeholder={"Неоплачиваемые акции"}
              menuPosition={'fixed'}
              ref={this.props.modal_setting_ref}
            />
          </div>
        </div>
      </div>
    )
  }
}

class TicketStatisticTab extends (React.Component) {
  constructor(props) {
    super(props);
    this.chartRef = {}
    for (var k in this.props.stat_data) {
      this.chartRef[k] = React.createRef()
    }
    this.state = {
      stat_start_date: '',
      stat_stop_date: '',
    }
  }

  renderTicketsStat() {
    return Object.keys(this.props.stat_data).map((key) => {
      if (!(key in this.chartRef)) {
        this.chartRef[key] = React.createRef()
      }
      var club = new Object(this.props.stat_data[key]);
      const stat_desc = (
`
Всего - общее число абонементов, созданных в указанный период
Продленные - число абонементов созданных в указанный период для клиентов, у которых есть истекшие абонементы
Непродленные - число абонементов, закончившихся, в указанный период у клиентов, не купивших новый абонемент в указанный период
Новые - число абонементов созданных в указанный период для клиентов, у которых нет истекших абонементов
Замороженные - общее число замороженных абонементов
Действующие - общее число действующих абонементов
`
      );
      var labels = Array.from(
        club.tickets,
        (k) => k['Абонемент'],
      )
      var datasets = [
        {
          label: 'Продленные',
          data: Array.from(
            club.tickets,
            (k) => k['Продленные'].length,
          ),
          clients: Array.from(
            club.tickets,
            (k) => k['Продленные'],
          ),
          backgroundColor: 'rgb(1, 131, 23)',
        },
        {
          label: 'Непродленные',
          data: Array.from(
            club.tickets,
            (k) => k['Непродленные'].length,
          ),
          clients: Array.from(
            club.tickets,
            (k) => k['Непродленные'],
          ),
          backgroundColor: 'rgb(189, 0, 40)',
        },
        {
          label: 'Новые',
          data: Array.from(
            club.tickets,
            (k) => k['Новые'].length,
          ),
          clients: Array.from(
            club.tickets,
            (k) => k['Новые'],
          ),
          backgroundColor: 'rgb(29, 46, 233)',
        },
      ]
      return (
        <div
          className='full_club_tickets_info_wrapper'
          key={club.id}
        >
          <div className='club_tickets_info_wrapper'
            onClick={
              () => {
                var g = document.getElementById('tickets_club-' + club.id);
                var gc = document.getElementById('tickets_stat_club-' + club.id);
                if (g.style.display === 'block') {
                  g.style.display = 'none';
                  gc.style.display = 'none';
                }
                else {
                  g.style.display = 'block';
                  gc.style.display = 'block';
                }
              }
            }
          >
            <div className='club_name_tickets_info'>
              {club.name}
            </div>
            <div
              className='club_tickets_stat_wrapper'
              title={stat_desc}
            >
              <span
                className='tickets_info'
                title={stat_desc}
              >
                Всего: {club.all},
              </span>
              <span
                className='tickets_info'
                title={stat_desc}
              >
                Продленные: {club.renewed},
              </span>
              <span
                className='tickets_info'
                title={stat_desc}
              >
                Непродленные: {club.unrenewed},
              </span>
              <span
                className='tickets_info'
                title={stat_desc}
              >
                Новые: {club.new},
              </span>
              <span
                className='tickets_info'
                title={stat_desc}
              >
                Замороженные: {club.stoped},
              </span>
              <span
                className='tickets_info'
                title={stat_desc}
              >
                Действующие: {club.acting}
              </span>
            </div>
          </div>
          {club.tickets && club.tickets.length > 0
            ? <div
                className='graphic_wrapper'
                id={'tickets_club-' + club.id}
                style={
                  {
                    height: 'fit-content',
                    marginBottom: '1rem',
                  }
                }
              >
                <Bar
                  ref={this.chartRef[key]}
                  data={
                    {
                      labels: labels,
                      datasets: datasets,
                    }
                  }
                  options={
                    {
                      plugins: {
                        title: {
                          display: true,
                          text: 'Продажи абонементов',
                        },
                      },
                      responsive: true,
                      scales: {
                        x: {
                          stacked: true,
                        },
                        y: {
                          stacked: true,
                          min: 0,
                          ticks: {
                            stepSize: 1,
                          },
                        },
                      },
                    }
                  }
                  onClick={
                    (e) => {
                      var elem = getElementAtEvent(this.chartRef[key].current, e)
                      if (elem.length === 0) {
                        return
                      }
                      elem = elem[0]
                      this.props.getUserTicketsStat(datasets[elem.datasetIndex].clients[elem.index].map((arr) => {return arr[0]}))
                      }
                  }
                />
              </div>
            : <div id={'tickets_club-' + club.id}></div>
          }
          {club.sales_stat
            ? <div className='graphic_wrapper' id={'tickets_stat_club-' + club.id} data-test="true">
                <ResponsivePie
                  data={club.sales_stat}
                  margin={{ top: 60, right: 20, bottom: 60, left: 20 }}
                  innerRadius={0.5}
                  padAngle={3}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  colors={{"scheme": "category10"}}
                  borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
                  defs={[]}
                  fill={[]}
                  legends={
                    [
                      {
                        anchor: 'top-left',
                        direction: 'column',
                        justify: false,
                        translateX: 0,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: '#000',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 20,
                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000'
                            }
                          }
                        ]
                      }
                    ]
                  }
                />
              </div>
            : <div id={'tickets_stat_club-' + club.id}></div>
          }
        </div>
      )
    })
  }

  renderClientTicketPromoCodeStatistic() {
    return this.props.clients_promo_code_statistic.map((promo_code) => {
      return (
        <div
          key={promo_code.promo_code}
          style={
            {
              marginTop: '0.25rem',
              marginBottom: '0.25rem',
              width: 'fit-content',
              marginRight: 'auto',
              marginLeft: 'auto',
            }
          }
        >
          <span
            style={
              {
                fontWeight: '500',
              }
            }
          >
            {promo_code.promo_code}:
          </span> {promo_code.amount}
        </div>
      )
    })
  }

  render() {
    return(
      <div>
        <div className='tickets_stat_wrapper'>
          <div className='tickets_stat_dates_wrapper'>
            <div className='drill_time_wrapper'>
              <input
                key='ticket_stat_date_start'
                id='ticket_stat_date_start'
                type='date'
                placeholder='Время начала'
                className='add_ticket_btn'
                onChange={
                  (e) => {
                    this.setState({stat_start_date: e.target.value},
                      () => {
                        this.props.updateTicketStatistic(
                          this.state.stat_start_date,
                          this.state.stat_stop_date,
                        )
                      }
                    );
                  }
                }
                defaultValue={this.state.stat_start_date}
              />
              <input
                key='ticket_stat_date_stop'
                id='ticket_stat_date_stop'
                type='date'
                placeholder='Время окончания'
                className='add_ticket_btn'
                onChange={
                  (e) => {
                    this.setState({stat_stop_date: e.target.value},
                      () => {
                        this.props.updateTicketStatistic(
                          this.state.stat_start_date,
                          this.state.stat_stop_date,
                        )
                      }
                    );
                  }
                }
                defaultValue={this.state.stat_stop_date}
              />
            </div>
          </div>
          <div className='ticket_selector_wrapper'>
            <Select
              options={
                Array.from(
                  this.props.coaches_arr,
                  (val) => new Object(
                    {
                      "value": val.id,
                      "label": val.first_name + " " + val.last_name,
                    }
                  )
                )
              }
              onChange={
                (e) => {
                  this.props.updateState(
                    {
                      coach_stat_data: {e} ? e["value"] : null
                    },
                    this.props.getTicketStatistic,
                  )
                }
              }
              styles={select_styles}
              placeholder={"Тренер"}
              isClearable
            />
          </div>
          {this.renderTicketsStat()}
        </div>
        {parseInt(localStorage.getItem('user_type')) > 2
          ? <div>
              <div className='club_name_tickets_info'>
                Промокоды:
              </div>
              <div>
                {this.renderClientTicketPromoCodeStatistic()}
              </div>
            </div>
          : ''
        }
      </div>
    )
  }
}

class CoachesPromoStatisticTab extends (React.Component) {
  constructor(props) {
    super(props);
    this.state = {
      attendance_client: null,
      promo_prolongation_tab: 0, // 0 - stat by promo, 1 - stat by coach
    }
  }

  render() {
    const colors = { 'Непродленные': 'red', 'Продленные': 'green' };
    const getColor = bar => colors[bar.id];
    return(
      <div>
        <div className='tickets_stat_dates_wrapper'>
          <div className='drill_time_wrapper'>
            <input
              key='ticket_stat_date_start'
              id='ticket_stat_date_start'
              type='date'
              placeholder='Время начала'
              className='add_ticket_btn'
              onChange={
                (e) => {
                  this.props.updateState({coach_stat_date_start: e.target.value})
                  this.props.getCoachesStatistic('promo_prolongation')
                  this.props.getCoachesStatistic('unlimit_attendance')
                  this.props.getCoachesDrillsStat()
                }
              }
              defaultValue={this.props.coach_stat_date_start}
            />
            <input
              key='ticket_stat_date_stop'
              id='ticket_stat_date_stop'
              type='date'
              placeholder='Время окончания'
              className='add_ticket_btn'
              onChange={
                (e) => {
                  this.props.updateState({coach_stat_date_stop: e.target.value})
                  this.props.getCoachesStatistic('promo_prolongation')
                  this.props.getCoachesStatistic('unlimit_attendance')
                  this.props.getCoachesDrillsStat()
                }
              }
              defaultValue={this.props.coach_stat_date_stop}
            />
          </div>
        </div>
        <div
            className='slider_switch_wrapper'
        >
          <div
            className='slider_item'
            style={
                (this.state.promo_prolongation_tab === 0) ? {borderBottom: '2px solid', borderColor: '#f7941e'} : {}
            }
            onClick={
              () => {
                  this.setState(
                    {promo_prolongation_tab: 0},
                    () => {
                      this.props.updateState({coaches_promo_prolongation: {}})
                    }
                  )
              }
            }
          >
            Аггрегировать по акциям
          </div>
          <div
            className='slider_item'
            style={
                (this.state.promo_prolongation_tab === 1) ? {borderBottom: '2px solid', borderColor: '#f7941e'} : {}
            }
            onClick={
              () => {
                  this.setState(
                    {promo_prolongation_tab: 1},
                    () => {
                      this.props.updateState({coaches_promo_prolongation: {}})
                    }
                  )
              }
            }
          >
            Аггрегировать по тренеру
          </div>
        </div>
        {this.state.promo_prolongation_tab === 0
          ? <CoachesPromoStatisticTabByPromo
              promos={this.props.promos}
              updateState={this.props.updateState}
              getCoachesStatistic={this.props.getCoachesStatistic}
              coaches_promo_prolongation={this.props.coaches_promo_prolongation}
              getColor={getColor}
              device={this.props.device}
              getUserTicketsStat={this.props.getUserTicketsStat}
            />
          : <CoachesPromoStatisticTabByCoach
              coaches={this.props.coaches}
              updateState={this.props.updateState}
              getCoachesStatistic={this.props.getCoachesStatistic}
              coaches_promo_prolongation={this.props.coaches_promo_prolongation}
              getColor={getColor}
              device={this.props.device}
              getUserTicketsStat={this.props.getUserTicketsStat}
            />
        }
        <div
          className='tickets_stat_dates_wrapper'
        >
          <Select
            isClearable={false}
            options={
              Array.from(this.props.tickets, (val) => new Object(
                {
                  "value": val.id,
                  "label": val.ticket + ' (' + val.club_unit[0].name + ')',
                  "type": "promo",
                }
              ))
            }
            onChange={
              (e) => {
                  this.props.updateState(
                    {coach_stat_ticket_id: e["value"]},
                    this.props.getCoachesStatistic,
                    ['unlimit_attendance'],
                  )
              }
            }
            styles={select_styles}
            placeholder={"Посещаемость по абонементу"}
            menuPosition={'fixed'}
          />
        </div>
        {Object.keys(this.props.coaches_unlimit_attendance).length > 1
          ? <div>
              <div
                className='tickets_stat_dates_wrapper'
              >
                <Select
                  isClearable={true}
                  options={
                    Array.from(
                      this.props.coaches_unlimit_attendance.clients,
                      (val) => new Object(
                        {
                          "value": val.id,
                          "label": val.last_name + ' ' + val.first_name,
                        }
                      )
                    )
                  }
                  onChange={
                    (e) => {
                        this.setState(
                          {attendance_client: (e) ? e["value"] : null}
                        )
                    }
                  }
                  styles={select_styles}
                  placeholder={"Клиент"}
                  menuPosition={'fixed'}
                />
              </div>
              <
                div className='graphic_wrapper'
                style={
                  {
                    display: 'block',
                  }
                }
              >
                <ResponsivePie
                  data={
                    Array.from(
                      Object.keys(
                        this.props.coaches_unlimit_attendance
                      ).filter(v => v !== "clients"),
                      (k) => new Object(
                        {
                          'id': this.props.coaches_unlimit_attendance[k].coach,
                          'label': this.props.coaches_unlimit_attendance[k].coach,
                          'value': (!this.state.attendance_client)
                            ? this.props.coaches_unlimit_attendance[k].attendance
                            : this.props.coaches_unlimit_attendance[k][this.state.attendance_client],
                        }
                      )
                    )
                  }
                  margin={{ top: 60, right: 20, bottom: 60, left: 20 }}
                  innerRadius={0.5}
                  padAngle={3}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  colors={{"scheme": "category10"}}
                  borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
                  defs={[]}
                  fill={[]}
                  legends={
                    [
                      {
                        anchor: 'top-left',
                        direction: 'column',
                        justify: false,
                        translateX: 0,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: '#000',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 20,
                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000'
                            }
                          }
                        ]
                      }
                    ]
                  }
                />
              </div>
            </div>
          : ''
        }
        <div
            className='slider_switch_wrapper'
        >
          <div
            className='slider_item'
            style={
                (this.props.coach_stat_type === 'drill_stat') ? {borderBottom: '2px solid', borderColor: '#f7941e'} : {}
            }
            onClick={
              () => {
                this.props.updateState(
                  {coach_stat_type: 'drill_stat'},
                  this.props.getCoachesDrillsStat,
                )
              }
            }
          >
            Проведенные тренировки
          </div>
          <div
            className='slider_item'
            style={
                (this.props.coach_stat_type === 'attendence_stat') ? {borderBottom: '2px solid', borderColor: '#f7941e'} : {}
            }
            onClick={
              () => {
                this.props.updateState(
                  {coach_stat_type: 'attendence_stat'},
                  this.props.getCoachesDrillsStat,
                )
              }
            }
          >
            Посещаемость тренировок
          </div>
        </div>
        {parseInt(localStorage.getItem('user_type')) > 1
          ? <div
              className='tickets_stat_dates_wrapper'
            >
              <Select
                isClearable={false}
                options={
                  Array.from(
                    this.props.coaches, (val) => new Object(
                      {
                        "value": val.id,
                        "label": val.first_name + " " + val.last_name,
                      }
                    )
                  )
                }
                onChange={
                  (e) => {
                    this.props.updateState(
                      {
                        stat_drills_coach_id: e["value"],
                      },
                      this.props.getCoachesDrillsStat,
                    )
                  }
                }
                styles={select_styles}
                placeholder={"Тренер"}
                menuPosition={'fixed'}
                menuPlacement={(this.props.device == 'mobile') ? 'top' : 'bottom'}
              />
            </div>
          : <div
              className='tickets_stat_dates_wrapper'
              style={
                {
                  marginBottom: '1rem',
                  fontWeight: '600',
                  fontSize: '1.25rem',
                }
              }
            >
              {localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name')}
            </div>
        }
        <div
          className='tickets_stat_dates_wrapper'
        >
          <Select
            isClearable={false}
            options={
              Array.from(
                this.props.drill_types, (val) => new Object(
                  {
                    "value": val.id,
                    "label": val.name,
                  }
                )
              )
            }
            onChange={
              (e) => {
                this.props.updateState(
                  {
                    stat_drills_type_id: e["value"],
                  },
                  this.props.getCoachesDrillsStat,
                )
              }
            }
            styles={select_styles}
            placeholder={"Тип тренировки"}
            menuPosition={'fixed'}
            menuPlacement={(this.props.device == 'mobile') ? 'top' : 'bottom'}
          />
        </div>
        <div
          style={
            {
              height: 'fit-content',
            }
          }
        >
          <CoachesDrillsPlot
            stat_data={this.props.coach_drills_stat}
            plot_text={(this.props.coach_stat_type === 'drill_stat') ? 'Проведено тренировок' : 'Общая посещаемость'}
          />
        </div>
      </div>
    )
  }
}

class CoachesPromoStatisticTabByPromo extends (React.Component) {
  constructor(props) {
    super(props)
    this.chartRef = React.createRef()
  }

  render() {
    var labels = Array.from(
      Object.keys(this.props.coaches_promo_prolongation),
      (k) => this.props.coaches_promo_prolongation[k]['coach']
    )
    var promo_prolongation = Array.from(
          Object.keys(this.props.coaches_promo_prolongation),
          (k) => this.props.coaches_promo_prolongation[k]['prolongation']
        )
    var promo_non_prolongation = Array.from(
          Object.keys(this.props.coaches_promo_prolongation),
          (k) => this.props.coaches_promo_prolongation[k]['non_prolongation']
        )
    var datasets = [
      {
        label: 'Непродленные',
        data: Array.from(promo_non_prolongation, p => p.length),
        clients: promo_non_prolongation,
        backgroundColor: 'rgb(189, 0, 40)',
      },
      {
        label: 'Продленные',
        data: Array.from(promo_prolongation, p => p.length),
        clients: promo_prolongation,
        backgroundColor: 'rgb(1, 131, 23)',
      },
    ]
    return (
      <div>
        <div
          className='tickets_stat_dates_wrapper'
        >
          <Select
            isClearable={false}
            options={
              Array.from(this.props.promos, (val) => new Object(
                {
                  "value": val.id,
                  "label": val.name + ' (' + val.club_unit.name + ')',
                  "type": "promo",
                }
              ))
            }
            onChange={
              (e) => {
                  this.props.updateState(
                    {
                      coach_stat_promo_id: e["value"],
                      coach_stat_coach_id: '',
                    },
                    this.props.getCoachesStatistic,
                    ['promo_prolongation'],
                  )
              }
            }
            styles={select_styles}
            placeholder={"Продленная акция"}
            menuPosition={'fixed'}
            menuPlacement={(this.props.device == 'mobile') ? 'top' : 'bottom'}
          />
        </div>
        {Object.keys(this.props.coaches_promo_prolongation).length > 0
          ? <div
              className='graphic_wrapper'
              style={
                {
                  display: 'block',
                  height: 'fit-content',
                  marginBottom: '1rem',
                }
              }
            >
              <Bar
                ref={this.chartRef}
                data={
                  {
                    labels: labels,
                    datasets: datasets,
                  }
                }
                options={
                  {
                    plugins: {
                      title: {
                        display: true,
                        text: 'Продление акций',
                      },
                    },
                    responsive: true,
                    scales: {
                      x: {
                        stacked: true,
                      },
                      y: {
                        stacked: true,
                        min: 0,
                        ticks: {
                          stepSize: 1,
                        },
                      },
                    },
                  }
                }
                onClick={
                  (e) => {
                    var elem = getElementAtEvent(this.chartRef.current, e)
                    if (elem.length === 0) {
                      return
                    }
                    elem = elem[0]
                    this.props.getUserTicketsStat(datasets[elem.datasetIndex].clients[elem.index].map((arr) => {return arr[0]}))
                  }
                }
              />
            </div>
          : ''
        }
      </div>
    )
  }
}

class CoachesPromoStatisticTabByCoach extends (React.Component) {
  constructor(props) {
    super(props)
    this.chartRef = React.createRef()
  }

  componentDidMount() {
    if (parseInt(localStorage.getItem('user_type')) < 2) {
      this.props.updateState(
        {
          coach_stat_promo_id: '',
          coach_stat_coach_id: parseInt(localStorage.getItem('user_type')),
        },
        this.props.getCoachesStatistic,
        ['promo_prolongation'],
      )
    }
  }

  render() {
    var labels = Array.from(
      Object.keys(this.props.coaches_promo_prolongation),
      (k) => this.props.coaches_promo_prolongation[k]['promo']
    )
    var promo_prolongation = Array.from(
          Object.keys(this.props.coaches_promo_prolongation),
          (k) => this.props.coaches_promo_prolongation[k]['prolongation']
        )
    var promo_non_prolongation = Array.from(
          Object.keys(this.props.coaches_promo_prolongation),
          (k) => this.props.coaches_promo_prolongation[k]['non_prolongation']
        )
    var datasets = [
      {
        label: 'Непродленные',
        data: Array.from(promo_non_prolongation, p => p.length),
        clients: promo_non_prolongation,
        backgroundColor: 'rgb(189, 0, 40)',
      },
      {
        label: 'Продленные',
        data: Array.from(promo_prolongation, p => p.length),
        clients: promo_prolongation,
        backgroundColor: 'rgb(1, 131, 23)',
      },
    ]
    return (
      <div>
        {parseInt(localStorage.getItem('user_type')) > 1
          ? <div
              className='tickets_stat_dates_wrapper'
            >
              <Select
                isClearable={false}
                options={
                  Array.from(
                    this.props.coaches, (val) => new Object(
                      {
                        "value": val.id,
                        "label": val.first_name + " " + val.last_name,
                      }
                    )
                  )
                }
                onChange={
                  (e) => {
                    this.props.updateState(
                      {
                        coach_stat_promo_id: '',
                        coach_stat_coach_id: e["value"],
                      },
                      this.props.getCoachesStatistic,
                      ['promo_prolongation'],
                    )
                  }
                }
                styles={select_styles}
                placeholder={"Тренер"}
                menuPosition={'fixed'}
                menuPlacement={(this.props.device == 'mobile') ? 'top' : 'bottom'}
              />
            </div>
          : ''
        }
        {Object.keys(this.props.coaches_promo_prolongation).length > 0
          ? <div
              className='graphic_wrapper'
              style={
                {
                  display: 'block',
                  height: 'fit-content',
                  marginBottom: '1rem',
                }
              }
            >
              <Bar
                ref={this.chartRef}
                data={
                  {
                    labels: labels,
                    datasets: datasets, 
                  }
                }
                options={
                  {
                    plugins: {
                      title: {
                        display: true,
                        text: 'Продление акций',
                      },
                    },
                    responsive: true,
                    scales: {
                      x: {
                        stacked: true,
                      },
                      y: {
                        stacked: true,
                        min: 0,
                        ticks: {
                          stepSize: 1,
                        },
                      },
                    },
                  }
                }
                onClick={
                  (e) => {
                    var elem = getElementAtEvent(this.chartRef.current, e)
                    if (elem.length === 0) {
                      return
                    }
                    elem = elem[0]
                    this.props.getUserTicketsStat(datasets[elem.datasetIndex].clients[elem.index].map((arr) => {return arr[0]}))
                  }
                }
              />
            </div>
          : ''
        }
      </div>
    )
  }
}

class BidsStatisticTab extends (React.Component) {
  constructor(props) {
    super(props);
    this.state = {
      date_start: '',
      date_stop: '',
      bid_source_id: null,
      bid_source_statistic: {},
      plot_labels: {
        'all': 'Всего заявок',
        'new': 'Новые',
        'waiting': 'Думающие',
        'denial': 'Отказ',
        'declined': 'Отклоненные',
        'placed': 'Запись',
        'closed': 'Закрытые',
        'bids_to_tickets': 'Купили абонемент',
        'prolongation': 'Продлили абонемент',
      }
    }
  }

  getBidsStatistic(statistic_type) {
    if (!this.state.bid_source_id) {
      return
    }
    this.setState(
      {lock_spinner: true}, () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/bid_client/statistic/');
        uri.searchParams.set('bid_source_id', this.state.bid_source_id);
        uri.searchParams.set('date_start', this.state.date_start);
        uri.searchParams.set('date_stop', this.state.date_stop);
        fetch(uri.href, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
          if (res.status === 401) {
            this.props.catchError(res.status);
            return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
          if (this.props.logged_in) {
            this.setState(
              {
                lock_spinner: false,
                date_start: json.date_start,
                date_stop: json.date_stop,
                bid_source_statistic: json.statistic,
              }
            )
          }
        })
      }
    )
  }

  render() {
    return (
      <div>
        <div
          className='tickets_stat_dates_wrapper'
        >
          <Select
            isClearable={false}
            options={
              Array.from(this.props.bid_sources, (val) => new Object(
                {
                  "value": val.id,
                  "label": val.source,
                }
              ))
            }
            onChange={
              (e) => {
                  this.setState(
                    {bid_source_id: e["value"]},
                    () => {this.getBidsStatistic()}
                  )
              }
            }
            styles={select_styles}
            placeholder={"Источник заявки"}
            menuPosition={'fixed'}
          />
        </div>
        {this.state.bid_source_id
          ? <div className='tickets_stat_dates_wrapper'>
              <div className='drill_time_wrapper'>
                <input
                  key='ticket_stat_date_start'
                  id='ticket_stat_date_start'
                  type='date'
                  placeholder='Время начала'
                  className='add_ticket_btn'
                  onChange={
                    (e) => {
                      this.setState(
                        {date_start: e.target.value},
                        () => {this.getBidsStatistic()}
                      )
                    }
                  }
                  defaultValue={this.state.date_start}
                />
                <input
                  key='ticket_stat_date_stop'
                  id='ticket_stat_date_stop'
                  type='date'
                  placeholder='Время окончания'
                  className='add_ticket_btn'
                  onChange={
                    (e) => {
                      this.setState(
                        {date_stop: e.target.value},
                        () => {this.getBidsStatistic()}
                      )
                    }
                  }
                  defaultValue={this.state.date_stop}
                />
              </div>
            </div>
          : ''
        }
        {this.state.bid_source_id && Object.keys(this.state.bid_source_statistic).length > 0
          ? <div className='tickets_stat_dates_wrapper'>
              <div>
                Конверсия:
              </div>
              {this.state.bid_source_statistic.all
                ? <div>
                    - купили промо-абонемент: {
                      parseFloat(this.state.bid_source_statistic.bids_to_tickets) /
                      parseFloat(this.state.bid_source_statistic.all)
                    }%
                  </div>
                : <div>- купили промо-абонемент: 0%</div>
              }
              {this.state.bid_source_statistic.all
                ? <div>
                    - продлили промо-абонемент: {
                      parseFloat(this.state.bid_source_statistic.prolongation) /
                      parseFloat(this.state.bid_source_statistic.all)
                    }
                  </div>
                : <div>- продлили промо-абонемент: 0%</div>
              }
            </div>
          : ''
        }
        {this.state.bid_source_id && Object.keys(this.state.bid_source_statistic).length > 0
          ? <div
              className='graphic_wrapper'
              style={
                {
                  display: 'block',
                }
              }
            >
            <ResponsiveFunnel
              data={
                Array.from(
                  Object.keys(this.state.plot_labels),
                  (k) => new Object(
                    {
                      'id': k,
                      'value': this.state.bid_source_statistic[k],
                      'label': this.state.plot_labels[k],
                    }
                  )
                )
              }
              margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              valueFormat=">-.4s"
              colors={{ scheme: 'spectral' }}
              borderWidth={0}
              labelColor={
                {
                  from: 'color',
                  modifiers: [
                    [
                      'darker',
                      3,
                    ]
                  ]
                }
              }
              beforeSeparatorLength={100}
              beforeSeparatorOffset={20}
              afterSeparatorLength={100}
              afterSeparatorOffset={20}
              currentPartSizeExtension={10}
              currentBorderWidth={40}
              motionConfig="wobbly"
              interpolation="linear"
            />
            </div>
          : ''
        }
      </div>
    )
  }
}

class SailsStatisticTab extends (React.Component) {
  constructor(props) {
    super(props);
    this.state = {
      date_start: '',
      date_stop: '',
      sails_statistic: [],
      max_sails: 0.0,
      stat_divider: 'manager',
      ticket_lock_spinner: false,
      market_salesman_sails_statistic: [],
      max_salesman_sails: 0.0,
      market_category_sails_statistic: [],
      max_category_sails: 0.0,
      market_lock_spinner: false,
    }
  }

  componentDidMount() {
    this.getSailsStatistic()
    this.getMarketSailsStatistic()
  }

  getSailsStatistic() {
    this.setState(
      {ticket_lock_spinner: true},
      () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/sails/statistic/');
        uri.searchParams.set('date_start', this.state.date_start);
        uri.searchParams.set('date_stop', this.state.date_stop);
        uri.searchParams.set('stat_divider', this.state.stat_divider);
        fetch(uri.href, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
          if (res.status === 401) {
            this.props.catchError(res.status);
            return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
          if (this.props.logged_in) {
            var sails_statistic = Object.keys(json.statistic).map(
              (k, j) => new Object(
                {
                  "id": (this.state.stat_divider === 'manager') 
                  ? json.statistic[k]["manager"]["first_name"] + " " + json.statistic[k]["manager"]["last_name"]
                  : json.statistic[k]["club_unit"]["name"],
                  "color": `hsl(${10 + j * 50}, 70%, 50%)`,
                  "data": Object.keys(json.statistic[k]["data"]).sort().map(
                    (d) => new Object(
                      {
                        "x": d,
                        "y": json.statistic[k]["data"][d],
                      }
                    )
                  )
                }
              )
            )
            var max_sails = Math.max(
              ...Array.from(
                Object.keys(json.statistic).map(
                  (k) => json.statistic[k]['total']
                )
              )
            )
            max_sails = max_sails + max_sails / 10
            this.setState(
              {
                ticket_lock_spinner: false,
                date_start: json.date_start,
                date_stop: json.date_stop,
                sails_statistic: sails_statistic,
                max_sails: max_sails,
              }
            )
          }
        })
      }
    )
  }

  getExistCateogriesSalesmanStat(statistic) {
    var exist_categories = new Object()
    var exist_salesman = new Object()
    for (let s of statistic) {
      if (s.data.length) {
        for (let d of s.data) {
          if (!Object.keys(exist_salesman).includes(d.salesman.id)) {
            exist_salesman[d.salesman.id] = new Object(
              {
                "id": (d.salesman) ? d.salesman.first_name + " " + d.salesman.last_name : "Без продавца",
                "color": `hsl(${d.salesman.id}, 70%, 50%)`,
                "data": new Array(),
              }
            )
          }
          for (let c of d.data) {
            if (!Object.keys(exist_categories).includes(c.category_id)) {
              exist_categories[c.category_id] = new Object(
                {
                  "id": c.category,
                  "color": `hsl(${10 + c.category_id * 40})`,
                  "data": new Array(),
                }
              )
            }
          }
        }
      }
    }

    return [exist_categories, exist_salesman]
  }

  getMarketSailsStatistic() {
    this.setState(
      {market_lock_spinner: true},
      () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/market/sails/statistic/');
        uri.searchParams.set('date_start', this.state.date_start);
        uri.searchParams.set('date_stop', this.state.date_stop);
        fetch(uri.href, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
          if (res.status === 401) {
            this.props.catchError(res.status);
            return
          }
          const response = res.json();
          return response;
        })
        .then(json => {
          if (this.props.logged_in) {
            var [category_sails, salesman_sails] = this.getExistCateogriesSalesmanStat(json.statistic)
            var max_category_sail = 0.0
            var max_salesman_sail = 0.0
            for (let s of json.statistic) {
              if (s.data.length > 0) {
                for (let d of s.data) {
                  if (salesman_sails[d.salesman.id]["data"].length === 0) {
                    salesman_sails[d.salesman.id]["data"].push(
                      new Object(
                        {
                          "x": s.date,
                          "y": d.data.map(st => st.amount).reduce((ps, a) => ps + a, 0),
                        }
                      )
                    )
                  }
                  else {
                    salesman_sails[d.salesman.id]["data"].push(
                      new Object(
                        {
                          "x": s.date,
                          "y": salesman_sails[d.salesman.id]["data"][salesman_sails[d.salesman.id]["data"].length - 1]["y"] + d.data.map(st => st.amount).reduce((ps, a) => ps + a, 0),
                        }
                      )
                    )
                    if (max_salesman_sail < salesman_sails[d.salesman.id]["data"][salesman_sails[d.salesman.id]["data"].length - 1]["y"] + d.data.map(st => st.amount).reduce((ps, a) => ps + a, 0)) {
                      max_salesman_sail = salesman_sails[d.salesman.id]["data"][salesman_sails[d.salesman.id]["data"].length - 1]["y"] + d.data.map(st => st.amount).reduce((ps, a) => ps + a, 0)
                    }
                  }
                  for (let c of d.data) {
                    if (category_sails[c.category_id]["data"].length === 0) {
                      category_sails[c.category_id]["data"].push(
                        new Object({"x": s.date, "y": 0.0})
                      )
                    }
                    else {
                      category_sails[c.category_id]["data"].push(
                        new Object(
                          {
                            "x": s.date,
                            "y": category_sails[c.category_id]["data"][category_sails[c.category_id]["data"].length - 1]["y"] + c.amount,
                          }
                        )
                      )
                      if (max_category_sail < category_sails[c.category_id]["data"][category_sails[c.category_id]["data"].length - 1]["y"]) {
                        max_category_sail = category_sails[c.category_id]["data"][category_sails[c.category_id]["data"].length - 1]["y"]
                      }
                    }
                  }
                }
              }
              else {
                for (let cat of Object.keys(category_sails)) {
                  if (category_sails[cat]["data"].length === 0) {
                    category_sails[cat]["data"].push(
                      new Object({"x": s.date, "y": 0.0})
                    )
                  }
                  else {
                    category_sails[cat]["data"].push(
                      new Object(
                        {
                          "x": s.date,
                          "y": category_sails[cat]["data"][category_sails[cat]["data"].length - 1]["y"],
                        }
                      )
                    )
                    if (max_category_sail < category_sails[cat]["data"][category_sails[cat]["data"].length - 1]["y"]) {
                      max_category_sail = category_sails[cat]["data"][category_sails[cat]["data"].length - 1]["y"]
                    }
                  }
                }
                for (let sl of Object.keys(salesman_sails)) {
                  if (salesman_sails[sl]["data"].length === 0) {
                    salesman_sails[sl]["data"].push(
                      new Object({"x": s.date, "y": 0.0})
                    )
                  }
                  else {
                    salesman_sails[sl]["data"].push(
                      new Object(
                        {
                          "x": s.date,
                          "y": salesman_sails[sl]["data"][salesman_sails[sl]["data"].length - 1]["y"],
                        }
                      )
                    )
                    if (max_salesman_sail < salesman_sails[sl]["data"][salesman_sails[sl]["data"].length - 1]["y"]) {
                      max_salesman_sail = salesman_sails[sl]["data"][salesman_sails[sl]["data"].length - 1]["y"]
                    }
                  }
                }
              }
              for (let k in salesman_sails) {
                if (
                  salesman_sails[k]["data"][salesman_sails[k]["data"].length - 1] &&
                  salesman_sails[k]["data"][salesman_sails[k]["data"].length - 1]["x"] !== s.date
                ) {
                  salesman_sails[k]["data"].push(
                    new Object(
                      {
                        "x": s.date,
                        "y": salesman_sails[k]["data"][salesman_sails[k]["data"].length - 1]["y"],
                      }
                    )
                  )
                }
              }
            }
            max_salesman_sail = max_salesman_sail + max_salesman_sail / 10
            max_category_sail = max_category_sail + max_category_sail / 10
            this.setState(
              {
                market_lock_spinner: false,
                market_category_sails_statistic: Object.values(category_sails),
                market_salesman_sails_statistic: Object.values(salesman_sails),
                max_salesman_sails: max_salesman_sail,
                max_category_sails: max_category_sail,
              }
            )
          }
        })
      }
    )
  }

  render() {
    return (
      <div>
        <div className='tickets_stat_dates_wrapper'>
          <div className='drill_time_wrapper'>
            <input
              key='ticket_stat_date_start'
              id='ticket_stat_date_start'
              type='date'
              placeholder='Время начала'
              className='add_ticket_btn'
              onChange={
                (e) => {
                  this.setState(
                    {date_start: e.target.value},
                    () => {
                      this.getSailsStatistic()
                      this.getMarketSailsStatistic()
                    }
                  )
                }
              }
              defaultValue={this.state.date_start}
            />
            <input
              key='ticket_stat_date_stop'
              id='ticket_stat_date_stop'
              type='date'
              placeholder='Время окончания'
              className='add_ticket_btn'
              onChange={
                (e) => {
                  this.setState(
                    {date_stop: e.target.value},
                    () => {
                      this.getSailsStatistic()
                      this.getMarketSailsStatistic()
                    }
                  )
                }
              }
              defaultValue={this.state.date_stop}
            />
          </div>
        </div>
        <div
            className='slider_switch_wrapper'
        >
          <div
            className='slider_item'
            style={
                (this.state.stat_divider === 'manager') ? {borderBottom: '2px solid', borderColor: '#f7941e'} : {}
            }
            onClick={
              () => {
                  this.setState(
                    {stat_divider: 'manager'},
                    () => {this.getSailsStatistic()}
                  )
              }
            }
          >
            Менеджеры
          </div>
          <div
            className='slider_item'
            style={
                (this.state.stat_divider === 'club_unit') ? {borderBottom: '2px solid', borderColor: '#f7941e'} : {}
            }
            onClick={
              () => {
                  this.setState(
                    {stat_divider: 'club_unit'},
                    () => {this.getSailsStatistic()}
                  )
              }
            }
          >
            Филиалы
          </div>
        </div>
        <div className='stat_plot_wrapper'>
          <SailsStatisticPlot
            data={this.state.sails_statistic}
            y_max={this.state.max_sails}
          />
          {this.state.ticket_lock_spinner
            ? <div
                className="loader spinner1"
                style={
                  {
                    position: 'absolute',
                    top: '25%',
                    left: '50%',
                  }
                }
              >
              </div>
            : ''
          }
        </div>
        <div className='stat_plot_wrapper'>
          <div
              className='slider_switch_wrapper'
          >
            <div
              className='slider_item'
              style={
                  {borderBottom: '2px solid', borderColor: '#f7941e'}
              }
            >
              Магазин
            </div>
          </div>
          <SailsStatisticPlot
            data={this.state.market_salesman_sails_statistic}
            y_max={this.state.max_salesman_sails}
          />
          <SailsStatisticPlot
            data={this.state.market_category_sails_statistic}
            y_max={this.state.max_category_sails}
          />
          {this.state.market_lock_spinner
            ? <div
                className="loader spinner2"
                style={
                  {
                    position: 'absolute',
                    top: '25%',
                    left: '50%',
                  }
                }
              >
              </div>
            : ''
          }
        </div>
      </div>
    )
  }
}

class StatisticClientInfo extends(BaseUsersList) {
    constructor(props) {
        super(props);
    }

  componentDidMount() {
    this.getData(this.props.users_pks)
  }

  render() {
    return (
      <div className='background_lock_wrapper' ref={this.props.modal_user_tickets_ref} id='background_lock_id'>
        <div
          className='drill_clients_wrapper stat_clients_wrapper'
          ref={this.props.modal_user_tickets_ref}
          id='drill_clients_wrapper_id'
          style={
            {
              width: '85%',
            }
          }
        >
          <div className='table_container' ref={this.props.modal_user_tickets_ref}>
              <FullUsersTable
                  logged_in={this.props.logged_in}
                  handleNewUserTicket={this.handleNewUserTicket.bind(this)}
                  handleShowTicketInfo={this.props.handleShowTicketInfo}
                  handleCloseTicket={this.handleCloseTicket.bind(this)}
                  renderDisciplineList={this.props.renderDisciplineList}
                  renderCoachList={this.props.renderCoachList}
                  update_data={this.props.update_data}
                  users={this.state.users}
                  getData={this.getData.bind(this)}
                  handleRemoveUser={this.handleRemoveUser.bind(this)}
                  handleChangeUserInfo={this.handleChangeUserInfo.bind(this)}
                  handleAddNotify={this.props.handleAddNotify}
                  getExpiredTickets={this.getExpiredTickets.bind(this)}
                  history={this.props.history}
                  handleUpdateSketches={this.props.handleUpdateSketches}
                  renderTicketsList={this.props.renderTicketsList}
                  renderNewTicketBtn={this.renderNewTicketBtn.bind(this)}
                  renderCompletedTicketDrills={this.renderCompletedTicketDrills.bind(this)}
                  additional_table_class={''}
                  show_drills={this.state.show_drills}
                  setProps={this.setProps.bind(this)}
                  hide_attendance={true}
                  club_departments={this.props.club_departments}
              />
          </div>
        </div>
      </div>
    )
  }
}

class CashboxStatisticTab extends (React.Component) {
  constructor(props) {
    super(props);
    this.modal_daily_stat_ref = React.createRef();
    var date_start = new Date()
    date_start.setDate(1)
    var date_stop = new Date(date_start.getFullYear(), date_start.getMonth() + 1)
    this.state = {
      date_start: date_start.toISOLocaleString().substr(0, 10),
      date_stop: date_stop.toISOLocaleString().substr(0, 10),
      box_office_lock_spinner: false,
      box_office_data: {},
      show_daily_stat: false,
      daily_stat_date: '',
      payment_types: [],
    }
  }

  handleClickOut = (e) => {
    if (this.modal_daily_stat_ref &&
        this.modal_daily_stat_ref.current &&
        (!this.modal_daily_stat_ref.current.contains(e.target) || e.target.id === 'background_lock_id')
    ) {
      this.setState({show_daily_stat: false});
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOut);
    this.getBoxOfficeData()
    this.getPaymentTypes()
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOut);
  }

  getBoxOfficeData() {
    this.setState(
      {box_office_lock_spinner: true},
      () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/box_office/')
        uri.searchParams.set('date_start', this.state.date_start)
        uri.searchParams.set('date_stop', this.state.date_stop)
        get_request(uri.href, this.props.catchError).then(
          json => {
            if (this.props.logged_in && json) {
              this.setState({
                box_office_data: this.boxOfficeArrToObject(json),
                box_office_lock_spinner: false,
              })
            }
          }
        )
      }
    )
  }

  getPaymentTypes() {
    this.setState(
      {lock_spinner: true},
      () => {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/payment_type/')
        get_request(uri.href, this.props.catchError).then(json => {
          if (json === undefined) {
            this.props.catchError(401);
            return
          }
          if (this.props.logged_in) {
            this.setState({
              payment_types: json.results,
              lock_spinner: false,
            });
          }
        })
      }
    )
  }

  boxOfficeArrToObject(arr) {
    var res = new Object()
    for (let r of arr) {
      let current_day = parseInt(
        (new Date(r.created_at.substr(0, 10)) - new Date(this.state.date_start)) / 1000 / 3600 / 24
      )
      if (res[current_day]) {
        res[current_day].push(r)
      }
      else {
        res[current_day] = [r]
      }
    }
    return res
  }

  getDaysArr(date_start, date_stop) {
    var days_amount = parseInt(
      (new Date(date_stop) - new Date(date_start)) / 1000 / 3600 / 24  + 1
    )
    if (days_amount < 0) {
      days_amount = 0
    }
    return Array.from(
      Array(days_amount).keys()
    )
  }

  getDatesArr(date_start, date_stop) {
    var days_arr = this.getDaysArr(date_start, date_stop)
    var day_date = new Date(date_start)
    var dates_arr = new Array()
    for (let day of days_arr) {
      day_date = new Date(date_start)
      dates_arr.push(new Date(day_date.setDate(new Date(date_start).getDate() + day)).toISOLocaleString().substr(0, 10))
    }
    return dates_arr
  }

  renderBoxOfficeDayAmounts(amounts) {
    return amounts.map((amount) => {
      return (
        <div
          key={'manager_amount_' + amount.id}
          style={
            {
              borderBottom: '1px solid var(--orange)',
            }
          }
        >
          {`${amount.manager.first_name[0]}. ${amount.manager.last_name}: ${amount.amount}руб.`}
        </div>
      )
    })
  }

  renderDaysRow(arr, day_index) {
    return arr.map((day, index) => {
      return (
        <div
          className='stat_calendar_day_wrapper'
          key={'day_box_office_stat_' + index + (day_index * 7)}
          onClick={
            () => {
              this.setState(
                {
                  show_daily_stat: true,
                  daily_stat_date: day,
                }
              )
            }
          }
        >
          <div className='stat_calendar_day_date'>
            {day}
          </div>
          <div className='stat_calendar_day_amount'>
            {(this.state.box_office_data[index + (day_index * 7)]) ? this.renderBoxOfficeDayAmounts(this.state.box_office_data[index + (day_index * 7)]) : '-'}
          </div>
        </div>
      )
    })
  }

  renderDaysArr() {
    var days = this.getDatesArr(this.state.date_start, this.state.date_stop)
    var days_slice = Array.from(
      Array(
        parseInt(days.length / 7) + 1
      ).keys()
    )
    return days_slice.map((index) => {
      return (
        <div
          style={
            {
              display: 'flex',
            }
          }
          key={'row_box_office_stat_' + index}
        >
          {this.renderDaysRow(days.slice(index * 7, index * 7 + 7), index)}
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        <div className='tickets_stat_dates_wrapper'>
          <div className='drill_time_wrapper'>
            <input
              key='ticket_stat_date_start'
              id='ticket_stat_date_start'
              type='date'
              placeholder='Время начала'
              className='add_ticket_btn'
              onChange={
                (e) => {
                  this.setState(
                    {date_start: e.target.value},
                    () => {
                      this.getBoxOfficeData()
                    }
                  )
                }
              }
              defaultValue={this.state.date_start}
            />
            <input
              key='ticket_stat_date_stop'
              id='ticket_stat_date_stop'
              type='date'
              placeholder='Время окончания'
              className='add_ticket_btn'
              onChange={
                (e) => {
                  this.setState(
                    {date_stop: e.target.value},
                    () => {
                      this.getBoxOfficeData()
                    }
                  )
                }
              }
              defaultValue={this.state.date_stop}
            />
          </div>
        </div>
        <div
          style={
            {
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
            }
          }
        >
          {this.state.box_office_lock_spinner
            ? <div
                className="loader spinner1"
                style={
                  {
                    position: 'absolute',
                    top: '25%',
                    left: '50%',
                  }
                }
              >
              </div>
            : <div>{this.renderDaysArr()}</div>
          }
        </div>
        {this.state.show_daily_stat
          ? <div
              className='background_lock_wrapper'
              id='background_lock_id'
              ref={this.modal_daily_stat_ref}
            >
              <div className='modal_window_wrapper'>
                <CashBoxReport
                  logged_in={this.props.logged_in}
                  catchError={this.props.catchError}
                  date_start={this.state.daily_stat_date}
                  date_stop={this.state.daily_stat_date}
                  payment_types={this.state.payment_types}
                />
              </div>
            </div>
          : ''
        }
      </div>
    )
  }
}

export default StatisticPage
