import React from 'react';

class PopUp extends(React.Component) {
  constructor(props) {
    super(props);
  }

  renderPopUps() {
    return this.props.pop_ups.map((pop_up, i) => {
      return (
        <div
          key={'pop_up-' + i + '-' + pop_up.event_obj_id}
          className='popup_event_wrapper'
        >
          <div
            onClick={this.props.closePopUp}
            data-obj_id={pop_up.event_obj_id}
            className='popup_close'
          >
            &#10008;
          </div>
          <div className='popup_title'>
            {pop_up.event_obj === 'User' && pop_up.event_type === 'registration'
              ? <a
                  href={'/client/attendance/' + pop_up.event_obj_id}
                  style={
                    {
                      textDecoration: 'none',
                      color: '#000',
                    }
                  }
                >
                  {pop_up.event_title}
                </a>
              : <span>{pop_up.event_title}</span>
            }
          </div>
          <div className='popup_message'>
            {pop_up.event_message}
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      <div
        className="popup_wrapper"
      >
        {this.renderPopUps()}
      </div>
    )
  }
}

export default PopUp
