// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn_border {
  border-style: solid;
  border-width: 2px;
}

.orange_border {
  border-color: var(--orange)!important;
}
`, "",{"version":3,"sources":["webpack://./src/buttons.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":[".btn_border {\n  border-style: solid;\n  border-width: 2px;\n}\n\n.orange_border {\n  border-color: var(--orange)!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
