import React from 'react';
import compose from 'lodash/fp/compose';
import Select from 'react-select';
import {
  Link
} from "react-router-dom";
import {
  post_request
} from '../api/api_call'
import { locales } from '../../helpers'

const select_styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        padding: 20,
        'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#f7941e',
        border: '1px solid #f7941e',
        'background-color': '#c5c6c8',
        'outline-color': '#f7941e',
    }),
    container: (provided, state) => ({
        ...provided,
        width: '100%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'margin-bottom': '0.5rem',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'background-color': '#c5c6c8',
    }),
}

var _ = require('lodash');

class FullUsersTable extends(React.Component) {
    constructor(props) {
        super(props);
    }

    renderTableHeader() {
        let header = [];
        header = [
            locales.tables.heads.full_name,
            locales.tables.heads.ticket,
            locales.tables.heads.contacts,
            locales.tables.heads.representative,
            locales.tables.heads.birthday,
            locales.tables.heads.socials,
            locales.tables.heads.employees,
            locales.tables.heads.notes,
        ];
        return header.map((key, index) => {
            return (
                <div
                    key={index}
                    className={'clients_table_container_border clients_table_container_head clients_column_' + index}
                >
                    {key}
                </div>
            )
        })
    }

    renderNewTicketBtn(id) {
        if (parseInt(localStorage.getItem('user_type')) > 1) {
            return (
                <div className='ticket_btn_info_wrapper'>
                    <div
                        id={'add_client_ticket-' + id}
                        key={'add_client_ticket-' + id}
                        onClick={this.props.handleNewUserTicket}
                        className='add_drill_btn add_client_ticket_btn'
                        data-user_id={id}
                    >
                        <span data-user_id={id}>&#10010;</span>
                    </div>
                </div>
            )
        }
    }

    renderTicketsList(tickets, user_id) {
        return tickets.map((ticket) => {
            var coaches = '';
            var disciplines = '';
            for (let i = 0; i < ticket.coaches.length; i++) {
                coaches += JSON.stringify(
                    {
                        value: ticket.coaches[i]['id'],
                        label: ticket.coaches[i]['first_name'] + ' ' + ticket.coaches[i]['last_name'],
                    }
                ) + ';'
            }
            for (let i = 0; i < ticket.discipline.length; i++) {
                disciplines += JSON.stringify(
                    {
                        value: ticket.discipline[i]['id'],
                        label: ticket.discipline[i]['name'],
                    }
                ) + ';'
            }
            var ticket_warning = (ticket.payed) ? '' : 'warning_font';
            var payed = (ticket.payed) ? 1 : 0;
            var active = (ticket.active) ? 1 : 0;
            return (
                <div
                    className='ticket_btn_info_wrapper'
                    key={'ticket_info-' + ticket.id}
                    id={'ticket_info-' + ticket.id}
                >
                    <div
                        className={'add_drill_btn show_ticket_info_btn ' + ticket_warning}
                        id={'show_ticket_info_id-' + ticket.id}
                        onClick={this.props.handleShowTicketInfo}
                    >
                        {ticket.ticket.ticket}
                    </div>
                    <div className='ticket_info_wrapper' id={'ticket_info_wrapper_id-' + ticket.id}>
                        <div className='user_ticket_info_wrapper'
                            data-ticket_id={ticket.id}
                            onMouseEnter={
                                (e) => {
                                    var show_drills = this.props.show_drills;
                                    show_drills[parseInt(e.target.dataset.ticket_id)] = true;
                                    this.props.setProps(
                                        {
                                            show_drills: show_drills,
                                        }
                                    )
                                }
                            }
                            onMouseLeave={
                                (e) => {
                                    var show_drills = this.props.show_drills;
                                    show_drills[parseInt(e.target.dataset.ticket_id)] = false;
                                    this.props.setProps(
                                        {
                                            show_drills: show_drills,
                                        }
                                    )
                                }
                            }
                        >
                            <div className='user_ticket_editor_wrapper'
                                data-ticket_id={ticket.id}
                            >
                                <input
                                    type='button'
                                    className='add_sketch_btn'
                                    id={'update_user_ticket-' + user_id + '-' + ticket.id}
                                    value='&#9998;'
                                    onClick={
                                        (e) => {this.props.handleCorrectTicket(ticket)}
                                    }
                                />
                                <input
                                    type='button'
                                    className='add_sketch_btn'
                                    id={'close_user_ticket-' + user_id + '-' + ticket.id}
                                    value='&#10008;'
                                    onClick={
                                      (e) => {this.props.handleCloseTicket(ticket, 'close')}
                                    }
                                />
                                {parseInt(localStorage.getItem('user_type')) > 2
                                    ? <input
                                          type='button'
                                          className='add_sketch_btn'
                                          id={'remove_user_ticket-' + user_id + '-' + ticket.id}
                                          value='&#9760;'
                                          onClick={
                                            (e) => {this.props.handleCloseTicket(ticket, 'remove')}
                                          }
                                      />
                                    : ''
                                }
                            </div>
                            <div>
                                <ul name="disciplines" id={"discipline-" + ticket.id} className='disciplines_list_in_ticket'>
                                    <p className='ticket_info'
                                        data-ticket_id={ticket.id}
                                    >
                                        {ticket.start_date} - {ticket.expire_date}
                                    </p>
                                    <p className='ticket_info'
                                        data-ticket_id={ticket.id}
                                    >
                                        Осталось тренировок: {ticket.lost_drills >= 0 ? ticket.lost_drills : -1}
                                    </p>
                                    <p className='ticket_info'
                                        data-ticket_id={ticket.id}
                                    >
                                        Закончено тренировок: {ticket.completed_drills.count}
                                    </p>
                                    {this.props.renderDisciplineList(ticket)}
                                    {this.props.renderCoachList(ticket)}
                                </ul>
                            </div>
                        </div>
                        {this.props.show_drills[ticket.id]
                            ? <div
                                className='shared_info_ticket orange_border fat_border'
                                style={
                                    {
                                        top: '10vh',
                                        right: '2vw',
                                    }
                                }
                              >
                                <div
                                    className='clients_tickets_table_head_wrapper'
                                >
                                    <div
                                        className='expired_ticket_drill_col_1'
                                    >
                                        № п/п
                                    </div>
                                    <div
                                        className='expired_ticket_drill_col_2'
                                    >
                                        Дата
                                    </div>
                                    <div
                                    className='expired_ticket_drill_col_3'
                                    >
                                        Время
                                    </div>
                                </div>
                                {this.props.renderCompletedTicketDrills(ticket.completed_drills.schedule)}
                              </div>
                            : ''
                        }
                    </div>
                </div>
            )
        })
    }

    renderTableData() {
        if (this.props.update_data === 'yes') {
            this.props.getData();
        }
        return this.props.users.map((client) => {
            var flname = client.first_name + ' ' + client.last_name;
            var notify_font = ''
            for (let i = 0; i < client.tickets.length; i++) {
                if (client.tickets[i].lost_drills >= 0 &&
                    (client.tickets[i].lost_drills + client.tickets[i].completed_drills.count) * 0.3 >= client.tickets[i].lost_drills) {
                    notify_font = 'warning_font';
                    break
                }
            }
            return (
                <div
                    key={'client-' + client.id}
                    id={'client-' + client.id}
                    className='client_row_wrapper'
                >
                    {parseInt(localStorage.getItem("user_type")) > 1
                      ? <div
                            className='client_col_name_wrapper clients_column_0'
                            style={
                                {
                                    overflowX: 'auto',
                                }
                            }
                        >
                            <div className='clients_row_name_wrapper'>
                                <div>
                                    <div
                                        key={'remove_client-' + client.id}
                                        id={'remove_client-' + client.id}
                                        data-client_id={client.id}
                                        className='notify_client_btn'
                                        onClick={this.props.handleRemoveUser}
                                    >
                                        <img src='/icons/trash.png' alt='notify' width='15px' data-client_id={client.id} />
                                    </div>
                                    <div
                                        key={'client_notify-' + client.id}
                                        id={'client_notify-' + client.id}
                                        data-client_id={client.id}
                                        className='notify_client_btn'
                                        onClick={this.props.handleAddNotify}
                                    >
                                        <img src='/icons/bell.png' alt='notify' width='15px' data-client_id={client.id} />
                                    </div>
                                    <div
                                        key={'client_depts-' + client.id}
                                        id={'client_depts-' + client.id}
                                        data-client_id={client.id}
                                        className='notify_client_btn'
                                        onClick={
                                            (e) => {
                                                this.props.setProps(
                                                    {
                                                        show_client_debts: true,
                                                        debt_client_id: client.id,
                                                    }
                                                )
                                            }
                                        }
                                    >
                                        <img src='/icons/thief.png' alt='thief' width='20px' data-client_id={client.id} />
                                    </div>
                                </div>
                                <div className='client_row_name'>
                                    <input className='text_edit_input client_name'
                                           type='text'
                                           defaultValue={flname}
                                           data-client_id={client.id}
                                           id={'user_name-' + client.id}
                                           size={flname.length}
                                           onChange={
                                            compose(
                                                _.debounce(this.props.handleChangeUserInfo, 500),
                                                _.clone
                                            )
                                           }
                                     />
                                </div>
                            </div>
                            <div className='user_type_select_wrapper'>
                                <Select
                                    options={
                                        [
                                            {value: 0, label: locales.user_type.client, client_id: client.id},
                                            {value: 1, label: locales.user_type.coach, client_id: client.id},
                                            {value: 2, label: locales.user_type.admin, client_id: client.id},
                                            {value: 3, label: locales.user_type.director, client_id: client.id},
                                            {value: 4, label: locales.user_type.owner, client_id: client.id},
                                        ]
                                    }
                                    defaultValue={{value: client.type, label: locales.user_type[client.text_type]}}
                                    onChange={this.props.handleChangeUserInfo}
                                />
                            </div>
                            {!client.confirmed && parseInt(localStorage.getItem('user_type')) > 1
                              ? <div
                                  style={
                                    {
                                      display: 'flex',
                                    }
                                  }
                                >
                                  <div
                                    id={'confirm_user-' + client.id}
                                    key={'confirm_user-' + client.id}
                                    onClick={this.props.handleChangeUserInfo}
                                    className='add_drill_btn show_ticket_info_btn old_tickets_btn'
                                    data-client_id={client.id}
                                    data-confirmation='1'
                                  >
                                      <span
                                        data-user_id={client.id}
                                        data-client_id={client.id}
                                        data-confirmation='1'
                                      >
                                          {locales.tables.body.accept}
                                      </span>
                                  </div>
                                  <div
                                    id={'decline_user-' + client.id}
                                    key={'decline_user-' + client.id}
                                    onClick={this.props.handleRemoveUser}
                                    className='add_drill_btn show_ticket_info_btn old_tickets_btn'
                                    data-client_id={client.id}
                                    data-confirmation='1'
                                    style={
                                      {
                                        maxWidth: '100px',
                                      }
                                    }
                                  >
                                      <span
                                        data-user_id={client.id}
                                        data-client_id={client.id}
                                        data-confirmation='1'
                                      >
                                          {locales.tables.body.decline}
                                      </span>
                                  </div>
                                </div>
                              : ''
                            }
                        </div>
                      : <div className='client_col_name_wrapper clients_column_0'>
                            <div className='clients_row_name_wrapper'>
                                <div className='client_row_name'>
                                    <span className={notify_font}>
                                        {flname}
                                    </span>
                                </div>
                                <div key={'client_notify-' + client.id} id={'client_notify-' + client.id} data-client_id={client.id}
                                    className='notify_client_btn' onClick={this.props.handleAddNotify}>
                                    <img src='/icons/bell.png' alt='notify' width='15px' data-client_id={client.id} />
                                </div>
                            </div>
                        </div>
                    }
                    <div className='client_col_ticket_wrapper clients_column_1'>
                        <div className='clients_ticket_btns_wrapper'>
                            {parseInt(localStorage.getItem("user_type")) > 1
                              ? <div className='clients_ticket_btn_column'>
                                    <div
                                        id={'show_client_old_tickets-' + client.id}
                                        key={'show_client_old_tickets-' + client.id}
                                        onClick={
                                            () => {
                                                this.props.setProps(
                                                    {
                                                        lock_spinner: true,
                                                        client_expire_tickets: client.id,
                                                        replace_expired_tickets: true,
                                                    },
                                                    this.props.getExpiredTickets
                                                )
                                            }
                                        }
                                        className='add_drill_btn show_ticket_info_btn old_tickets_btn'
                                        data-user_id={client.id}
                                        data-client_id={client.id}
                                    >
                                        <span
                                          data-user_id={client.id}
                                          data-client_id={client.id}
                                        >
                                            {locales.tables.body.non_active_tickets}
                                        </span>
                                    </div>
                                    {!this.props.hide_attendance
                                        ? <Link
                                              to={'/client/attendance/' + client.id}
                                              style={
                                                  {
                                                      textDecoration: 'none',
                                                      color: '#fff',
                                                  }
                                              }
                                            >
                                                <div
                                                    id={'show_client_traffic-' + client.id}
                                                    key={'show_client_traffic-' + client.id}
                                                    className='add_drill_btn show_ticket_info_btn old_tickets_btn'
                                                    onClick={
                                                      (e) => {
                                                        this.props.history.push(
                                                          '/clients',
                                                          {
                                                            users: this.props.users,
                                                            clients_ids: this.props.clients_ids,
                                                            processing: this.props.processing,
                                                            uri: this.props.uri,
                                                          },
                                                        )
                                                      }
                                                    }
                                                >
                                                    {locales.tables.body.attendance}
                                                </div>
                                            </Link>
                                        : ''
                                    }
                                </div>
                              : ''
                            }
                            <div className='clients_ticket_btn_column'>
                                {this.renderTicketsList(client.tickets, client.id)}
                                {this.renderNewTicketBtn(client.id)}
                            </div>
                        </div>
                    </div>
                    <div className='client_col_contact_wrapper clients_column_2'>
                        <div className='userinfo_cell_wrapper'>
                            <div>{`${locales.tables.body.phone_shortcut}:`} </div>
                            {parseInt(localStorage.getItem("user_type")) > 1
                              ? <div className='editable_userinfo_cell_wrapper'>
                                    {client.phone
                                        ? <input className='text_edit_input client_contact'
                                               type='text'
                                               defaultValue={client.phone}
                                               data-client_id={client.id}
                                               id={'user_phone-' + client.id}
                                               size={client.phone.length}
                                               onChange={
                                                compose(
                                                    _.debounce(this.props.handleChangeUserInfo, 500),
                                                    _.clone
                                                )
                                               }
                                          />
                                        : <input className='text_edit_input client_contact'
                                               type='text'
                                               defaultValue={client.phone}
                                               data-client_id={client.id}
                                               id={'user_phone-' + client.id}
                                               size='1'
                                               onChange={
                                                compose(
                                                    _.debounce(this.props.handleChangeUserInfo, 500),
                                                    _.clone
                                                )
                                               }
                                          />
                                    }
                                </div>
                                : <div>
                                    <a href={'tel:' + client.phone}>{client.phone}</a>
                                  </div>
                            }
                        </div>
                        <div className='userinfo_cell_wrapper'>
                            <div>Email: </div>
                            {parseInt(localStorage.getItem("user_type")) > 1
                              ? <div className='editable_userinfo_cell_wrapper'>
                                    {client.email
                                        ? <input className='text_edit_input client_contact'
                                                 type='text'
                                                 defaultValue={client.email}
                                                 data-client_id={client.id}
                                                 id={'user_email-' + client.id}
                                                 size={client.email ? 15 : 1}
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.props.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                        : <input className='text_edit_input client_contact'
                                                 type='text'
                                                 defaultValue={client.email}
                                                 data-client_id={client.id}
                                                 id={'user_email-' + client.id}
                                                 size='1'
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.props.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                    }
                                </div>
                              : <div>{client.email}</div>
                            }
                        </div>
                    </div>
                    <div className='client_col_trustee_contact_wrapper clients_column_3'>
                        <div className='userinfo_cell_wrapper'>
                            <div>{`${locales.tables.body.name}:`} </div>
                            <div className='editable_userinfo_cell_wrapper'>
                                {client.trustee_name
                                    ? <input className='text_edit_input client_contact'
                                             type='text'
                                             defaultValue={client.trustee_name}
                                             data-client_id={client.id}
                                             id={'user_trustee_name-' + client.id}
                                             size={client.trustee_name.length}
                                             onChange={
                                              compose(
                                                  _.debounce(this.props.handleChangeUserInfo, 500),
                                                  _.clone
                                              )
                                             }
                                      />
                                    : <input className='text_edit_input client_contact'
                                             type='text'
                                             defaultValue={client.trustee_name}
                                             data-client_id={client.id}
                                             id={'user_trustee_name-' + client.id}
                                             size='1'
                                             onChange={
                                              compose(
                                                  _.debounce(this.props.handleChangeUserInfo, 500),
                                                  _.clone
                                              )
                                             }
                                      />
                                }
                            </div>
                        </div>
                        <div className='userinfo_cell_wrapper'>
                            <div>{`${locales.tables.body.phone_shortcut}:`} </div>
                            <div className='editable_userinfo_cell_wrapper'>
                                {client.trustee_phone
                                    ? <input className='text_edit_input client_contact'
                                             type='text'
                                             defaultValue={client.trustee_phone}
                                             data-client_id={client.id}
                                             id={'user_trustee_phone-' + client.id}
                                             size={client.trustee_phone.length}
                                             onChange={
                                              compose(
                                                  _.debounce(this.props.handleChangeUserInfo, 500),
                                                  _.clone
                                              )
                                             }
                                      />
                                    : <input className='text_edit_input client_contact'
                                             type='text'
                                             defaultValue={client.trustee_phone}
                                             data-client_id={client.id}
                                             id={'user_trustee_phone-' + client.id}
                                             size='1'
                                             onChange={
                                              compose(
                                                  _.debounce(this.props.handleChangeUserInfo, 500),
                                                  _.clone
                                              )
                                             }
                                      />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='client_col_birthday_wrapper clients_column_4'>
                        <div className='userinfo_cell_wrapper'>
                            <div>{`${locales.tables.body.birthday_shortcut}:`} </div>
                            <div className='editable_userinfo_cell_wrapper'>
                                <input className='text_edit_input client_contact'
                                       type='date'
                                       defaultValue={client.birth_date}
                                       data-client_id={client.id}
                                       id={'user_birth_date-' + client.id}
                                       size='12'
                                       onChange={
                                        compose(
                                            _.debounce(this.props.handleChangeUserInfo, 500),
                                            _.clone
                                        )
                                       }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='client_col_social_wrapper clients_column_5'>
                        <ul className='client_socials_list'>
                            <div className='userinfo_cell_wrapper'>
                                <div>Telegram: </div>
                                <div className='editable_userinfo_cell_wrapper'>
                                    {client.telegram
                                        ? <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={client.telegram}
                                                 data-client_id={client.id}
                                                 id={'user_telegram-' + client.id}
                                                 size={client.telegram.length}
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.props.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                        : <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={client.telegram}
                                                 data-client_id={client.id}
                                                 id={'user_telegram-' + client.id}
                                                 size='1'
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.props.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                    }
                                </div>
                            </div>
                            <div className='userinfo_cell_wrapper'>
                                <div>Whatsapp: </div>
                                <div className='editable_userinfo_cell_wrapper'>
                                    {client.whatsapp
                                        ? <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={client.whatsapp}
                                                 data-client_id={client.id}
                                                 id={'user_whatsapp-' + client.id}
                                                 size={client.whatsapp.length}
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.props.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                        : <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={client.whatsapp}
                                                 data-client_id={client.id}
                                                 id={'user_whatsapp-' + client.id}
                                                 size='1'
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.props.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                    }
                                </div>
                            </div>
                            <div className='userinfo_cell_wrapper'>
                                <div>Instagram: </div>
                                <div className='editable_userinfo_cell_wrapper'>
                                    {client.instagram
                                        ? <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={client.instagram}
                                                 data-client_id={client.id}
                                                 id={'user_instagram-' + client.id}
                                                 size={client.instagram.length}
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.props.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                        : <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={client.instagram}
                                                 data-client_id={client.id}
                                                 id={'user_instagram-' + client.id}
                                                 size='1'
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.props.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                    }
                                </div>
                            </div>
                            <div className='userinfo_cell_wrapper'>
                                <div>VK: </div>
                                <div className='editable_userinfo_cell_wrapper'>
                                    {client.vk
                                        ? <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={client.vk}
                                                 data-client_id={client.id}
                                                 id={'user_vk-' + client.id}
                                                 size={client.vk.length}
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.props.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                        : <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={client.vk}
                                                 data-client_id={client.id}
                                                 id={'user_vk-' + client.id}
                                                 size='1'
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.props.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                    }
                                </div>
                            </div>
                            <div className='userinfo_cell_wrapper'>
                                <div>Facebook: </div>
                                <div className='editable_userinfo_cell_wrapper'>
                                    {client.facebook
                                        ? <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={client.facebook}
                                                 data-client_id={client.id}
                                                 id={'user_facebook-' + client.id}
                                                 size={client.facebook.length}
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.props.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                        : <input className='text_edit_input'
                                                 type='text'
                                                 defaultValue={client.facebook}
                                                 data-client_id={client.id}
                                                 id={'user_facebook-' + client.id}
                                                 size='1'
                                                 onChange={
                                                  compose(
                                                      _.debounce(this.props.handleChangeUserInfo, 500),
                                                      _.clone
                                                  )
                                                 }
                                          />
                                    }
                                </div>
                            </div>
                        </ul>
                    </div>
                    <div className='client_col_ticket_wrapper bids_column_6'>
                        {this.renderClientEmployees(client)}
                    </div>
                    <div className='client_col_comment_wrapper clients_column_7'>
                        <textarea
                            rows='5'
                            cols='30'
                            id={'client_sketch-' + client.id}
                            data-client_id={client.id}
                            className='message_input'
                            defaultValue={client.sketches}
                        >
                        </textarea>
                        <input type='button' className='add_sketch_btn' id={'add_user_sketch-' + client.id} onClick={this.props.handleUpdateSketches} value='&#10004;' />
                    </div>
                </div>
            )
        })
    }

    renderClientEmployees(client) {
      return this.props.club_departments.map((department) => {
        return (
          <div
            style={
              {
                display: 'block',
              }
            }
            key={'department-' + department.id}
          >
            <div
              style={
                {
                  fontWeight: '600',
                }
              }
            >
                {department.name}:
            </div>
            {localStorage.getItem('user_type') > 1
              ? <div>
                  <Select
                    options={
                      Array.from(
                        department.employees, (emp) => new Object(
                          {
                            value: emp.id,
                            label: emp.employee.first_name + ' ' + emp.employee.last_name,
                            department: department.id,
                          }
                        )
                      )
                    }
                    onChange={
                      (e) => {
                        this.props.setProps({lock_spinner: true},
                          () => {
                            var employees = []
                            for (let depart of this.props.club_departments) {
                              if (e.length > 0 && depart.id !== e[0]['department']) {
                                employees = employees.concat(
                                  Array.from(depart.employees, (emp) => emp.id).filter(emp => client.employees.includes(emp))
                                )
                              }
                              else if (e.length === 0 && depart.id !== department.id) {
                                employees = employees.concat(
                                  Array.from(depart.employees, (emp) => emp.id).filter(emp => client.employees.includes(emp))
                                )
                              }
                            }
                            employees = employees.concat(Array.from(e, i => i['value']))
                            if (employees.length === 0) {
                              employees.push(0)
                            }
                            post_request(
                              process.env.REACT_APP_BACKEND_URI + '/api/v1/user/account/' + client.id + '/',
                              {
                                'employees': employees,
                              },
                              this.props.catchError,
                              'POST',
                              'multipart/form-data',
                            ).then(json => {
                              if (this.props.logged_in) {
                                var clients = this.props.users
                                for (let i = 0; i < clients.length; i++) {
                                  if (clients[i].id === json.id) {
                                    clients[i] = json
                                    break
                                  }
                                }
                                this.props.setProps(
                                  {
                                    lock_spinner: false,
                                    users: clients,
                                  }
                                )
                              }
                            })
                          }
                        )
                      }
                    }
                    defaultValue={
                      Array.from(
                        department.employees, (emp) => new Object(
                          {
                            value: emp.id,
                            label: emp.employee.first_name + ' ' + emp.employee.last_name,
                            department: department.id,
                          }
                        )
                      ).filter(emp => client.employees.includes(emp.value))
                    }
                    styles={select_styles}
                    isClearable={true}
                    isMulti={true}
                    placeholder={"Сотрудники"}
                  />
                </div>
              : <div>
                    {
                      Array.from(
                        Array.from(
                          department.employees, (emp) => new Object(
                            {
                              "value": emp.id,
                              "label": emp.employee.first_name + ' ' + emp.employee.last_name,
                            }
                          )
                        ).filter(emp => client.employees.includes(emp.value)),
                        (emp) => emp.label
                      ).toString()
                    }
                </div>
            }
          </div>
        )
      })
    }
    
    render() {
        return (
            <div className={'table_wrapper ' + this.props.additional_table_class}>
                <div className="table_head_container_wrapper">
                    {this.renderTableHeader()}
                </div>
                <div className="table_rows_container_wrapper">
                    {this.renderTableData()}
                </div>
            </div>
        )
    }
}

export default FullUsersTable
