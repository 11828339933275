import React from 'react';
import PropTypes from 'prop-types';

function Nav(props) {
  const logged_out_nav = (
    <div className="navbar">
      <div onClick={() => props.display_form('login')} className='login_selector'><span>Вход</span></div>
      <div onClick={() => props.display_form('signup')} className='login_selector'><span>Регистрация</span></div>
      <div onClick={() => props.display_form('restore')} className='login_selector'><span>Восстановление пароля</span></div>
    </div>
  );

  const logged_in_nav = (
    <ul className="navbar">
      <li onClick={props.handle_logout} className='login_selector logout_btn'><img src='/logout.svg' alt='ares logout' width='20px' /></li>
    </ul>
  );
  var additional_class = '';
  if (props.logged_in) {
    additional_class = 'navbar_logged_wrapper';
  }
  return <div className={'navbar_wrapper ' + additional_class}>{props.logged_in ? logged_in_nav : logged_out_nav}</div>;
}

export default Nav;

Nav.propTypes = {
  logged_in: PropTypes.bool.isRequired,
  display_form: PropTypes.func.isRequired,
  handle_logout: PropTypes.func.isRequired
};