import React from 'react';
import SignupForm from './SignupForm';
import {
    UsersList,
    ClientAttendance,
} from './ClientsList';
import CoachesList from './CoachList';
import BidsList from './BidsTable';
import Calendar from './Calendar';
import AddTicket from './AddTicket';
import Profile from './Profile';
import {
  ServicesItems,
  ProductManage,
} from './ServicesItems';
import ManagerCalendar from './ManagerCalendar';
import Notifications from './Notifications';
import Spinner from './Spinner';
import {
    TicketChangeRequest,
    FullTicketChangeRequest,
} from './TicketChangeRequest';
import ClubManage from './ClubManage';
import Market from './Market';
import RentControl from './MarketRentControl';
import StatisticPage from './ClubStatistic';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Select from 'react-select';
import { delete_request } from './api/api_call'
import { locales } from '../helpers'

const select_styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        padding: 20,
        'background-color': state.isFocused ? '#f7941e' : '#c5c6c8',
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        color: '#f7941e',
        border: '1px solid #f7941e',
        'background-color': '#c5c6c8',
        'outline-color': '#f7941e',
    }),
    container: (provided, state) => ({
        ...provided,
        width: '50%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'margin-bottom': '0.5rem',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid #f7941e',
        'outline-color': '#f7941e',
        'background-color': '#c5c6c8',
    }),
}

class Table extends(React.Component) {
    constructor(props) {
        super(props);
        this.nav_menu_ref = React.createRef();
        this.modal_ref = React.createRef();
        this.modal_users_ref = React.createRef();
        var first_day = '';
        var last_day = '';
        if (window.screen.width > 700 && window.screen.width < 1024) {
            first_day = new Date();
            last_day = new Date();
            last_day.setDate(last_day.getDate() + 2);
            first_day = first_day.toISOString().split('T')[0];
            last_day = last_day.toISOString().split('T')[0];
        }
        else if (window.screen.width < 700) {
            first_day = new Date();
            first_day = first_day.toISOString().split('T')[0];
            last_day = first_day;
        }
        this.state = {
            users: [],
            clients: [],
            drills: [],
            clubUnitId: '',
            club: '',
            club_unit: '',
            users_list: false,
            weekday: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
            days: [],
            choosen_drill: '',
            update_data_request: true,
            new_drill_form: '',
            new_drill: false,
            add_client_form: false,
            displayed_form: '',
            date_start: first_day,
            date_stop: last_day,
            addTicket: false,
            addTicketUserId: '',
            ticketClubUnitId: '',
            ticketStartDate: '',
            ticketExpireDate: '',
            ticketDisciplines: [],
            ticketCoaches: [],
            client_arrive: false,
            client_relevant_drills: 1,
            accept: false,
            remove_ticket: false,
            client_id: '',
            ticket_discount: 0,
            ticket_payed: false,
            remove_schedule_accept: false,
            remove_drill_id: null,
            filter_coach: '',
            filter_drill_type: '',
            total_shifts: {},
            total_shifts_date_start: '',
            total_shifts_date_stop: '',
            new_ticket_data: {
                user: null,
                ticket: null,
                discipline: null,
                coaches: null,
                start_date: null,
                expire_date: null,
                expired: null,
                discount: null,
            },
            correct_ticket: {},
            lock_spinner: false,
            show_toast: false,
            toast_text: '',
            ticketId: null,
            clients_fts: null,
            age: {
              1: 'Муж.',
              2: 'Жен.',
              3: 'Унисекс',
            },
            sex: {
              1: 'Взрослые',
              2: 'Дети',
              3: 'Любой',
            },
            editing_ticket: null,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOut);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOut);
    }

    handleClickOut = (e) => {
        if (this.nav_menu_ref &&
            this.nav_menu_ref.current &&
            this.nav_menu_ref.current.style.display === 'block' &&
            !this.nav_menu_ref.current.contains(e.target)
        ) {
            var show_btn = document.getElementById('show_nav_menu_wrapper_id');
            this.nav_menu_ref.current.style.display = 'none';
            show_btn.style.display = 'block';
        }
        if (this.modal_ref &&
            this.modal_ref.current &&
            (!this.modal_ref.current.contains(e.target) || e.target.id === 'background_lock_id')
        ) {
            this.setState(
                {
                    addTicket: false,
                    new_drill: false,
                    new_drill_form: '',
                    add_client_form: false,
                    remove_schedule_accept: false,
                }
            );
        }
        if (this.props.notify_ref &&
            this.props.notify_ref.current &&
            !this.props.notify_ref.current.contains(e.target) &&
            e.target.id !== 'bell_btn_wrapper_id' && e.target.id !== 'bell_btn_id'
        ) {
            this.props.handleHideNotifications();
        }
        if (this.modal_users_ref &&
            this.modal_users_ref.current &&
            (!this.modal_users_ref.current.contains(e.target) || e.target.id === 'background_lock_id')
        ) {
            this.setState({clubUnitId: '', users_list: false, choosen_drill: ''});
        }
    }

    handle_signup = (e, data) => {
        const form_data = new FormData();
        for(const name in data) {
            if (name === 'child') {
                continue;
            }
            form_data.append(name, data[name]);
        }
        e.preventDefault();
        this.setState({lock_spinner: true}, () => {
            fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/account/', {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                body: form_data
            })
            .then(res => {
                if (res.status === 500) {
                    document.getElementById('toast-body-id').innerHTML = "Серверная ошибка";
                    var x = document.getElementById("toast-wrapper-id");
                    x.className = 'show';
                    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                    return
                }
                if (res.status === 401) {
                    this.props.catchError(res.status);
                    document.getElementById('toast-body-id').innerHTML = "Неавторизован";
                    var x = document.getElementById("toast-wrapper-id");
                    x.className = 'show';
                    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                    return
                }
                else if (res.status === 406) {
                    res.json().then((json) => {
                        this.props.updateCurrentShift(json);
                    });
                    document.getElementById('toast-body-id').innerHTML = "Учет времени выключен";
                    var x = document.getElementById("toast-wrapper-id");
                    x.className = 'show';
                    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                    return
                }
                else if (res.status === 400) {
                    document.getElementById('toast-body-id').innerHTML = "Имя и фамилия, или телефон, или email должны быть заполнены";
                    var x = document.getElementById("toast-wrapper-id");
                    x.className = 'show';
                    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2200);
                    return
                }
                else {
                    let response = res.json();
                    return response;
                }
            })
            .then(json => {
                this.setState({
                    displayed_form: '',
                    add_client_form: false,
                    lock_spinner: false,
                });
            })
        })
    };

    handleDrillInfo = (e) => {
        var user_type = localStorage.getItem('user_type');
        var club_id = e.target.id.split('-')[e.target.id.split('-').length - 1];
        var drill_id = e.target.id.split('-')[e.target.id.split('-').length - 2];
        if (parseInt(user_type) > 0) {
            this.setState({clubUnitId: club_id, users_list: true, choosen_drill: drill_id});
        }
        else if (parseInt(user_type) === 0) {
            this.setState({clubUnitId: club_id, choosen_drill: drill_id, client_arrive: true});
        }
    }

    unsetChoosenDrill() {
        this.setState({choosen_drill: ''});
    }

    renderDisciplineList(ticket) {
        return ticket.discipline.map((d) => {
            return (
                <li key={'discipline-' + d.id} value={d.id}>{d.name}</li>
            )
        })
    }

    renderCoachList(ticket) {
        return ticket.coaches.map((c) => {
            return (
                <li key={'coach-' + c.id} value={c.id}>{c.first_name} {c.last_name}</li>
            )
        })
    }

    handleShowTicketInfo = (e) => {
        var id = document.getElementById(e.target.id);
        id = id.id.split('-')[id.id.split('-').length - 1];
        var wrappers = document.getElementsByClassName('ticket_info_wrapper');
        for (let i = 0; i < wrappers.length; i++) {
            if (wrappers[i].id !== 'ticket_info_wrapper_id-' + id) {
                wrappers[i].style.display = 'none';
            }
        }
        if (document.getElementById('ticket_info_wrapper_id-' + id).style.display === 'block' ||
            getComputedStyle(document.getElementById('ticket_info_wrapper_id-' + id)).display === 'block') {
            document.getElementById('ticket_info_wrapper_id-' + id).style.display = 'none';
        }
        else {
            document.getElementById('ticket_info_wrapper_id-' + id).style.display = 'block';
        }
    }

    handleBackgroundClick = (e) => {
        this.setState(
            {
                users_list: false,
                add_client_form: false,
                displayed_form: '',
                client_arrive: false,
                new_drill: false,
            }
        );
    }

    handleReplaceFts(fts) {
      this.setState({clients_fts: fts})
    }

    handleUpdateSketches = (e) => {
      this.setState(
        {lock_spinner: true},
        () => {
          let id = e.target.id.split('-')[e.target.id.split('-').length - 1];
          var sketch = document.getElementById('client_sketch-' + id).value;
          const form_data = new FormData();
          form_data.append('sketches', sketch);
          var user_index = -1;
          for (let i = 0; i < this.state.users.length; i++) {
              if (this.state.users[i]['id'] === parseInt(id)) {
                  user_index = this.state.users[i];
              }
          }
          fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/account/' + id + '/', {
            method: 'POST',
            body: form_data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
            .then(res => {
              if (res.status === 401) {
                  this.props.catchError(res.status);
                  return
              }
              else if (res.status === 406) {
                  res.json().then((json) => {
                      this.props.updateCurrentShift(json);
                  });
                  return
              }
              const response = res.json();
              return response;
            })
            .then(json => {
                if (!json) {
                    this.setState({lock_spinner: false})
                  return
              }
              let users = this.state.users;
              users[user_index] = json;
                this.setState({users: users, lock_spinner: false});
            })
        }
      )
    }

    updateData({club = null, club_unit = null, coach = null, drill_type = null} = {}) {
        this.setState(
            {
                lock_spinner: true
            }, () => {
                if (!this.state.update_data_request) {
                    return
                }
                var clb = this.state.club;
                if (club) {
                    clb = club;
                }
                var clb_unit = this.state.club_unit;
                if (club_unit) {
                    clb_unit = club_unit;
                }
                var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/traffic/');
                if (club) {
                    uri.searchParams.set('club', club_unit);
                }
                else {
                    uri.searchParams.set('club', this.state.club);
                }
                if (club_unit) {
                    uri.searchParams.set('club_unit', club_unit);
                }
                else {
                    uri.searchParams.set('club_unit', this.state.club_unit);
                }
                if (coach) {
                    uri.searchParams.set('coach', coach);
                }
                else {
                    uri.searchParams.set('coach', this.state.filter_coach);
                }
                if (drill_type) {
                    uri.searchParams.set('drill_type', drill_type);
                }
                else {
                    uri.searchParams.set('drill_type', this.state.filter_drill_type);
                }
                if (parseInt(localStorage.getItem('user_type')) == 0) {
                    uri.searchParams.set('relevant', this.state.client_relevant_drills);
                }
                uri.searchParams.set('date_start', this.state.date_start);
                uri.searchParams.set('date_stop', this.state.date_stop);
                fetch(uri.toString(), {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (this.props.logged_in) {
                        this.updateDrills(json.drills);
                        this.setState(
                            {
                                drills: json.drills,
                                date_start: json.date_distance['date_start'],
                                date_stop: json.date_distance['date_stop'],
                                lock_spinner: false,
                            }
                        );
                    }
                })
            }
        );
    }

    removeDrillSchedule = (schedule_id, drill_date) => {
      this.setState(
        {lock_spinner: true},
        () => {
          var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/schedule/');
          uri.searchParams.set('schedule', schedule_id);
          uri.searchParams.set('drill_date', drill_date);
          delete_request(uri.href, this.props.catchError).then(
            json => {
              if (this.props.logged_in && json) {
                // var drills = this.state.drills;
                // var lost_drills = [];
                // for (let i = 0; i < drills.length; i++) {
                //     if (parseInt(drills[i]["drill"]["id"]) !== parseInt(json.id)) {
                //         lost_drills.push(drills[i]);
                //     }
                // }
                this.setState(
                  {
                      // drills: lost_drills,
                      remove_schedule_accept: false,
                      remove_drill_id: null,
                      lock_spinner: false,
                  }
                )
              }
            }
          )
        }
      )
    }

    handleRemoveScheduleDrill = (e) => {
        this.setState({remove_schedule_accept: true, remove_drill_id: e.target.dataset.drill_schedule_id});
    }

    handleDeclineRemoveDrillSchedule = (e) => {
        this.setState({remove_schedule_accept: false, remove_drill_id: null});
    }

    handleClientFullDrillList = (e) => {
        if (e.target.id.split('_')[0] == 'full') {
            this.setState({client_relevant_drills: 0}, () => {this.updateData();})
        }
        else if (e.target.id.split('_')[0] == 'short') {
            this.setState({client_relevant_drills: 1}, () => {this.updateData();})
        }
    }

    handleUpdateBidComment = (e) => {
        console.log("TEST");
    }

    handleSetUserTicketData = (e) => {
        var ticket_data = this.state.new_ticket_data;
        if (e.constructor === Object && e["type"] === "ticket") {
            ticket_data["ticket"] = e["value"];
        }
        else if (e.constructor === Array && e[0] && e[0]["type"] === "discipline") {
            ticket_data["discipline"] = Array.from(e, (val) => val["value"]);
        }
        else if (e.constructor === Array && e[0] && e[0]["type"] === "coach") {
            ticket_data["coaches"] = Array.from(e, (val) => val["value"]);
        }
        this.setState({new_ticket_data: ticket_data});
    }

    handleUpdateUserTicket = (e) => {
        if (this.state.accept) {
            var data = {expired: this.state.accept};
        }
        else {
            var data = this.state.new_ticket_data;
            data["user"] = this.state.client_id;
            data["start_date"] = (document.getElementById('ticket_date_start')) ? document.getElementById('ticket_date_start').value : null;
            data["expire_date"] = (document.getElementById('ticket_date_stop')) ? document.getElementById('ticket_date_stop').value : null;
        }
        if (document.getElementById('payed_ticket')) {
            data["payed"] = document.getElementById('payed_ticket').checked;
        }
        if (document.getElementById('active_ticket')) {
            data["active"] = !document.getElementById('active_ticket').checked;
        }
        this.updateTicket(this.state.userTicketId, data, true);
    }

    updateTicket(ticket_id, data, update_dom, func) {
        this.setState(
            {
                lock_spinner: true,
            }, () => {
                fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/user_tickets/' + ticket_id + '/', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                  },
                  body: JSON.stringify(data)
                })
                .then(res => {
                    if (res.status === 401) {
                        this.props.catchError(res.status);
                        return
                    }
                    else if (res.status === 406) {
                        res.json().then((json) => {
                            this.props.updateCurrentShift(json);
                        });
                        return
                    }
                    const response = res.json();
                    return response;
                })
                .then(json => {
                    if (!json) {
                        return false
                    }
                    if (this.props.logged_in) {
                        var users = this.state.users;
                        for (let i = 0; i < users.length; i++) {
                            if (parseInt(users[i]['id']) === parseInt(this.state.addTicketUserId) && !data["expired"]) {
                                users[i]['tickets'].push(json);
                            }
                        }
                        this.setState(
                            {
                                addTicket: false,
                                correct: false,
                                users: users,
                                accept: false,
                                lock_spinner: false,
                            }, () => {
                                if (json.payed && document.getElementById('show_ticket_info_id-' + json.id)) {
                                    document.getElementById('show_ticket_info_id-' + json.id).classList.remove('warning_font');
                                }
                                else if (!json.payed && document.getElementById('show_ticket_info_id-' + json.id)) {
                                    document.getElementById('show_ticket_info_id-' + json.id).classList.add('warning_font');
                                }
                                if (data['expired']) {
                                    if (document.getElementById('ticket_info-' + json.id)) {
                                        document.getElementById('ticket_info-' + json.id).style.display = 'none';
                                    }
                                }
                            }
                        );
                    }
                })
            }
        )
    }

    handleDeclineUserTicket = (e) => {
        this.setState({addTicket: false, accept: false});
    }

    handleCorrectTicket = (ticket) => {
        var disciplines = Array.from(
            ticket.discipline, (discipline) => new Object(
                {
                    value: discipline.id,
                    label: discipline.name,
                }
            )
        )
        var coaches = Array.from(
            ticket.coaches, (coach) => new Object(
                {
                    value: coach.id,
                    label: `${coach.first_name} ${coach.last_name}`
                }
            )
        )
        this.setState(
            {
                editing_ticket: ticket,
                addTicket: true,
                ticketClubUnitId: ticket.ticket.club_unit[0],
                ticketDisciplines: disciplines,
                ticketCoaches: coaches,
            }
        );
    }

    handleCloseTicket = (ticket, action_type) => {
        if (action_type === 'close') {
            var disciplines = Array.from(
                ticket.discipline, (discipline) => new Object(
                    {
                        value: discipline.id,
                        label: discipline.name,
                    }
                )
            )
            var coaches = Array.from(
                ticket.coaches, (coach) => new Object(
                    {
                        value: coach.id,
                        label: `${coach.first_name} ${coach.last_name}`
                    }
                )
            )
            this.setState(
                {
                    editing_ticket: ticket,
                    addTicket: true,
                    ticketClubUnitId: ticket.ticket.club_unit[0],
                    correct_ticket: {
                        id: ticket.id,
                        name: ticket.ticket_name
                    },
                    ticketDisciplines: disciplines,
                    ticketCoaches: coaches,
                    accept: true
                }
            );
        }
        else if (action_type === 'remove') {
            this.setState(
                {
                    editing_ticket: ticket,
                    addTicket: true,
                    accept: true,
                    remove_ticket: true,
                }
            );
        }
    }

    updateDrills(drills) {
        this.setState({drills: drills});
    }

    handleUpdateDrillClients({drill, counter, client_arrive=null}={}) {
        var drills = this.state.drills;
        for (let i = 0; i < drills.length; i++) {
            if (parseInt(drill) === parseInt(drills[i]['id'])) {
                drills[i]['clients_number'] += counter;
                if (client_arrive !== null) {
                    drills[i]['client_arrive'] = client_arrive;
                }
                break
            }
        }
        this.setState({ drills: drills });
    }

    getTotalShifts(
        {
            club_unit = "",
            club = "",
            date_start = "",
            date_stop = "",
            manager = "",
        }
    ) {
        var uri = new URL(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/managers/statistic/');
        uri.searchParams.set("club", club);
        uri.searchParams.set("club_unit", club_unit);
        uri.searchParams.set("date_start", date_start);
        uri.searchParams.set("date_stop", date_stop);
        uri.searchParams.set("manager", manager);
        fetch(uri.toString(), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (this.props.logged_in) {
                this.setState(
                    {
                        total_shifts: json.results,
                        total_shifts_date_start: json.date_start,
                        total_shifts_date_stop: json.date_stop,
                    }
                );
            }
        })
    }

    renderNewUserTicket() {
        return (
            <div className='background_lock_wrapper' id='background_lock_id' ref={this.modal_ref}>
            <div className='add_new_user_form_wrapper'>
                <AddTicket
                    clubUnitId={this.state.ticketClubUnitId}
                    handleAddUserTicket={(!this.state.remove_ticket) ? this.handleUpdateUserTicket.bind(this) : this.removeTicket.bind(this)}
                    club_units={this.props.club_units}
                    handleDeclineUserTicket={this.handleDeclineUserTicket}
                    logged_in={this.props.logged_in}
                    correct_ticket={this.state.correct_ticket}
                    disciplines={this.state.ticketDisciplines}
                    coaches={this.state.ticketCoaches}
                    accept={this.state.accept}
                    remove_ticket={this.state.remove_ticket}
                    handleSetUserTicketData={this.handleSetUserTicketData.bind(this)}
                    user={this.props.user}
                />
            </div>
            </div>
        )
    }

    renderTicketsList(tickets, user_id) {
        return tickets.map((ticket) => {
            var coaches = '';
            var disciplines = '';
            for (let i = 0; i < ticket.coaches.length; i++) {
                coaches += JSON.stringify(
                    {
                        value: ticket.coaches[i]['id'],
                        label: ticket.coaches[i]['first_name'] + ' ' + ticket.coaches[i]['last_name'],
                    }
                ) + ';'
            }
            for (let i = 0; i < ticket.discipline.length; i++) {
                disciplines += JSON.stringify(
                    {
                        value: ticket.discipline[i]['id'],
                        label: ticket.discipline[i]['name'],
                    }
                ) + ';'
            }
            var ticket_warning = (ticket.payed) ? '' : 'warning_font';
            var payed = (ticket.payed) ? 1 : 0;
            var active = (ticket.active) ? 1 : 0;
            return (
                <div
                    className='ticket_btn_info_wrapper'
                    key={'ticket_info-' + ticket.id}
                    id={'ticket_info-' + ticket.id}
                >
                    <div
                        className={'add_drill_btn show_ticket_info_btn ' + ticket_warning}
                        id={'show_ticket_info_id-' + ticket.id}
                        onClick={this.handleShowTicketInfo}
                    >
                        {ticket.ticket.ticket}
                    </div>
                    <div className='ticket_info_wrapper' id={'ticket_info_wrapper_id-' + ticket.id}>
                        <div className='user_ticket_info_wrapper'>
                            <div className='user_ticket_editor_wrapper'>
                                <input
                                    type='button'
                                    className='add_sketch_btn'
                                    id={'update_user_ticket-' + user_id + '-' + ticket.id}
                                    data-client={user_id}
                                    data-ticket={ticket.ticket.id}
                                    data-club_unit={ticket.ticket.club_unit[0]}
                                    data-start_date={ticket.start_date}
                                    data-expire_date={ticket.expire_date}
                                    data-coaches={coaches}
                                    data-disciplines={disciplines}
                                    data-user_ticket={ticket.id}
                                    data-ticket_discount={ticket.discount}
                                    data-ticket_name={ticket.ticket.ticket}
                                    data-ticket_payed={payed}
                                    data-ticket_active={active}
                                    value='&#9998;'
                                    onClick={
                                        (e) => {this.handleCorrectTicket(ticket)}
                                    }
                                />
                                <input
                                    type='button'
                                    className='add_sketch_btn'
                                    id={'close_user_ticket-' + user_id + '-' + ticket.id}
                                    value='&#10008;'
                                    onClick={
                                        (e) => {this.handleCloseTicket(ticket, 'close')}
                                    }
                                />
                            </div>
                            <div>
                                <ul name="disciplines" id={"discipline-" + ticket.id} className='disciplines_list_in_ticket'>
                                    <p className='ticket_info'>{ticket.start_date} - {ticket.expire_date}</p>
                                    <p className='ticket_info'>Осталось тренировок: {ticket.lost_drills >= 0 ? ticket.lost_drills : -1}</p>
                                    <p className='ticket_info'>Закончено тренировок: {ticket.completed_drills.length}</p>
                                    {this.renderDisciplineList(ticket)}
                                    {this.renderCoachList(ticket)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    handleUpdateDataState(state) {
        this.setState({update_data_request: state});
    }

    handleDeclineNewDrill = (e) => {
        this.setState({new_drill: false, new_drill_form: ''});
    }

    handleChangeDrill = (drill) => {
        this.setState(
            {
                new_drill: true,
                new_drill_form: <NewDrill
                    weekday={this.state.weekday}
                    handleBackgroundClick={this.handleBackgroundClick}
                    correct={true}
                    clubUnitId={parseInt(drill.drill.club.id)}
                    club_units={this.props.club_units}
                    handleUpdateDataState={this.handleUpdateDataState.bind(this)}
                    handleDeclineNewDrill={this.handleDeclineNewDrill.bind(this)}
                    logged_in={this.props.logged_in}
                    updateData={this.updateData.bind(this)}
                    modal_ref={this.modal_ref}
                    exist_type={parseInt(drill.drill_type.id)}
                    exist_discipline={parseInt(drill.drill.discipline.id)}
                    exist_drill_time_start={drill.drill.drill_time_start}
                    exist_drill_time_stop={drill.drill.drill_time_stop}
                    exist_weekday={parseInt(drill.drill.weekday)}
                    exist_coaches={
                        Array.from(
                            drill.coaches, (coach) => coach.id
                        ).toString()
                    }
                    exist_repeatable={drill.drill_repeatable}
                    exist_drill_date={drill.drill_date}
                    exist_drill_id={parseInt(drill.id)}
                    user={this.props.user}
                />
            }
        );
    }

    handleAddDrill = (e) => {
        this.setState(
            {
                new_drill: true,
                new_drill_form: <NewDrill
                    weekday={this.state.weekday}
                    handleBackgroundClick={this.handleBackgroundClick}
                    clubUnitId={this.state.clubUnitId}
                    club_units={this.props.club_units}
                    handleUpdateDataState={this.handleUpdateDataState.bind(this)}
                    handleDeclineNewDrill={this.handleDeclineNewDrill.bind(this)}
                    logged_in={this.props.logged_in}
                    updateData={this.updateData.bind(this)}
                    modal_ref={this.modal_ref}
                    user={this.props.user}
                />
            }
        );
    }

    handleAddUser = (e) => {
        this.setState(
            {
                displayed_form: <SignupForm
                                    handle_signup={this.handle_signup}
                                    addClass='manager_user_creation'
                                />,
                add_client_form: true
            }
        );
    }

    handleApplyFilters = (e) => {
        if (e.constructor == Object) {
            if (e["type"] === "club") {
                this.setState({club: parseInt(e["value"])}, () => { this.updateData(); });
            }
            else if (e["type"] === "club_unit") {
                this.setState({club_unit: parseInt(e["value"])}, () => { this.updateData(); });
            }
            else if (e["type"] === "coach") {
                this.setState({filter_coach: parseInt(e["value"])}, () => { this.updateData(); });
            }
            else if (e["type"] === "drill_type") {
                this.setState({filter_drill_type: parseInt(e["value"])}, () => { this.updateData(); });
            }
        }
        else {
            var club_selector = document.getElementById(this.props.device + '_club_filter_selector');
            var club_unit_selector = document.getElementById(this.props.device + '_club_units_filter_selector');
            let club_id = '';
            let club_unit_id = '';
            if (club_selector && club_selector.options[club_selector.selectedIndex].id !== '') {
                club_id = club_selector.options[club_selector.selectedIndex].id.split('-')[club_selector.options[club_selector.selectedIndex].id.split('-').length - 1];
            }
            if (club_unit_selector && club_unit_selector.options[club_unit_selector.selectedIndex].id !== '') {
                club_unit_id = club_unit_selector.options[club_unit_selector.selectedIndex].id.split('-')[club_unit_selector.options[club_unit_selector.selectedIndex].id.split('-').length - 1];
            }
            this.setState({club: club_id, club_unit: club_unit_id}, () => { this.updateData(); });
        }
    }

    updateFieldValueById(element_id, value) {
        var field = document.getElementById(element_id)
        if (field) {
            field.value = value
        }
    }

    handleChangeStartStop = (e) => {
        var first_day = new Date(this.state.date_start);
        var last_day = new Date(this.state.date_stop);
        var day_diff = 1;
        if (this.props.device === "desktop") {
            day_diff = 7;
        }
        if (e.target.id === 'day-back-arrow') {
            first_day.setDate(first_day.getDate() - day_diff);
            last_day.setDate(last_day.getDate() - day_diff);
        }
        else if (e.target.id === 'day-forward-arrow') {
            first_day.setDate(first_day.getDate() + day_diff);
            last_day.setDate(last_day.getDate() + day_diff);
        }
        else if (e.target.id === 'calendar_week_start_id') {
            first_day = new Date(e.target.value);
            last_day.setDate(first_day.getDate() + day_diff);
            last_day.setMonth(first_day.getMonth());
            last_day.setFullYear(first_day.getFullYear());
        }
        this.setState(
            {
                date_start: first_day.toISOString().split('T')[0],
                date_stop: last_day.toISOString().split('T')[0],
                lock_spinner: true,
            }, () => {
                this.updateFieldValueById('calendar_week_start_id', this.state.date_start)
                this.updateData()
            }
        );
    }

    handleShowMenu = (e) => {
        var menu = document.getElementById('nav_menu_wrapper-id');
        var show_btn = document.getElementById('show_nav_menu_wrapper_id');
        if (menu.style.display === 'block') {
            menu.style.display = 'none';
            show_btn.style.display = 'block';
        }
        else {
            menu.style.display = 'block';
            show_btn.style.display = 'none';
        }
    }

    renderTabButtons() {
        if (parseInt(localStorage.getItem('user_type')) > 1) {
            return (
                <div className='nav_menu_wrapper' id='nav_menu_wrapper-id' ref={this.nav_menu_ref}>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='profile_btn_id' href={`${process.env.REACT_APP_NEW_DESIGN_DOMAIN}/profile?access_token=${localStorage.getItem('token')}`}>
                            <img src='/icons/profile.png' alt='profile' width='20px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.profile}</span>
                                : ''
                            }
                        </a>
                    </div>
                    {parseInt(localStorage.getItem('user_type')) > 2
                        ? <div className='nav_btn_wrapper'>
                              <a className='tab_btn' id='club_manage_id' href='/club/manage'>
                                  <img src='/icons/office.png' alt='request' height='25px' />
                                  {this.props.device === 'desktop'
                                      ? <span className='tab_btn_text'>{locales.menu.club}</span>
                                      : ''
                                  }
                              </a>
                          </div>
                        : ''
                    }
                    {parseInt(localStorage.getItem('user_type')) > 1
                        ? <div className='nav_btn_wrapper'>
                              <a className='tab_btn' id='club_manage_id' href='/club/statistic'>
                                  <img src='/icons/statistic.png' alt='request' height='25px' />
                                  {this.props.device === 'desktop'
                                      ? <span className='tab_btn_text'>{locales.menu.statistic}</span>
                                      : ''
                                  }
                              </a>
                          </div>
                        : ''
                    }
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='clients_btn_id' href={`${process.env.REACT_APP_NEW_DESIGN_DOMAIN}/clients?access_token=${localStorage.getItem('token')}`}>
                            <img src='/icons/clients.png' alt='clients' width='20px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.clients}</span>
                                : ''
                            }
                        </a>
                    </div>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='clients_btn_id' href={`${process.env.REACT_APP_NEW_DESIGN_DOMAIN}/coaches?access_token=${localStorage.getItem('token')}`}>
                            <img src='/icons/whistle.png' alt='whistle' width='20px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.coaches}</span>
                                : ''
                            }
                        </a>
                    </div>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='calendar_btn_id' href={`${process.env.REACT_APP_NEW_DESIGN_DOMAIN}/calendar?access_token=${localStorage.getItem('token')}`}>
                            <img src='/icons/calendar.png' alt='calendar' width='20px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.calendar}</span>
                                : ''
                            }
                        </a>
                    </div>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='bids_btn_id' href={`${process.env.REACT_APP_NEW_DESIGN_DOMAIN}/bids?access_token=${localStorage.getItem('token')}`}>
                            <img src='/icons/bid.png' alt='bids' width='20px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.bids}</span>
                                : ''
                            }
                        </a>
                    </div>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='shifts_btn_id' href='/shifts'>
                            <img src='/icons/sandclock.png' alt='sandclock' height='18px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.time_shifting}</span>
                                : ''
                            }
                        </a>
                    </div>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='bids_btn_id' href={`${process.env.REACT_APP_NEW_DESIGN_DOMAIN}/service_items?access_token=${localStorage.getItem('token')}`}>
                            <img src='/icons/basket.png' alt='service_items' height='25px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.service_items}</span>
                                : ''
                            }
                        </a>
                    </div>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='bids_btn_id' href={`${process.env.REACT_APP_NEW_DESIGN_DOMAIN}/notifications?access_token=${localStorage.getItem('token')}`}>
                            <img src='/icons/bell.png' alt='bell' height='20px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.notifications}</span>
                                : ''
                            }
                        </a>
                    </div>
                    {parseInt(localStorage.getItem('user_type')) > 2
                        ? <div className='nav_btn_wrapper'>
                              <a className='tab_btn' id='ticket_changing_id' href='/ticket_changing'>
                                  <img src='/icons/request.png' alt='request' height='20px' />
                                  {this.props.device === 'desktop'
                                      ? <span className='tab_btn_text'>{locales.menu.ticket_edit_request}</span>
                                      : ''
                                  }
                              </a>
                          </div>
                        : ''
                    }
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='bids_btn_id' href='/market'>
                            <img src='/icons/market.png' alt='bell' height='20px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.market}</span>
                                : ''
                            }
                        </a>
                    </div>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='bids_btn_id' href='/rent_control'>
                            <img src='/icons/rent.png' alt='rent' height='20px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.rent}</span>
                                : ''
                            }
                        </a>
                    </div>
                </div>
            )
        }
        else if (parseInt(localStorage.getItem('user_type')) === 1) {
            return (
                <div className='nav_menu_wrapper' id='nav_menu_wrapper-id' ref={this.nav_menu_ref}>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='profile_btn_id' href='/'>
                            <img src='/icons/profile.png' alt='profile' width='20px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.profile}</span>
                                : ''
                            }
                        </a>
                    </div>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='clients_btn_id' href='/clients'>
                            <img src='/icons/clients.png' alt='clients' width='20px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.clients}</span>
                                : ''
                            }
                        </a>
                    </div>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='calendar_btn_id' href='/calendar'>
                            <img src='/icons/calendar.png' alt='calendar' width='20px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.calendar}</span>
                                : ''
                            }
                        </a>
                    </div>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='bids_btn_id' href='/bids'>
                            <img src='/icons/bid.png' alt='bids' width='20px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.bids}</span>
                                : ''
                            }
                        </a>
                    </div>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='club_manage_id' href='/club/statistic'>
                            <img src='/icons/statistic.png' alt='request' height='25px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.statistic}</span>
                                : ''
                            }
                        </a>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='nav_menu_wrapper' id='nav_menu_wrapper-id' ref={this.nav_menu_ref}>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='profile_btn_id' href='/'>
                            <img src='/icons/profile.png' alt='profile' width='20px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.profile}</span>
                                : ''
                            }
                        </a>
                    </div>
                    <div className='nav_btn_wrapper'>
                        <a className='tab_btn' id='calendar_btn_id' href='/calendar'>
                            <img src='/icons/calendar.png' alt='clients' width='20px' />
                            {this.props.device === 'desktop'
                                ? <span className='tab_btn_text'>{locales.menu.calendar}</span>
                                : ''
                            }
                        </a>
                    </div>
                    {this.props.permissions.bids
                        ? <div className='nav_btn_wrapper'>
                              <a className='tab_btn' id='bids_btn_id' href='/bids'>
                                  <img src='/icons/bid.png' alt='bids' width='20px' />
                                  {this.props.device === 'desktop'
                                      ? <span className='tab_btn_text'>{locales.menu.bids}</span>
                                      : ''
                                  }
                              </a>
                          </div>
                        : ''
                    }
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <div className='nav_menu_container'>
                {this.renderTabButtons()}
                <div className='show_nav_menu_wrapper' id='show_nav_menu_wrapper_id'>
                    <input type='button' className='show_nav_menu_btn' id='show_nav_menu_id' onClick={this.handleShowMenu} value='&#9776;' />
                </div>
                </div>
                <div className='tab_wrapper'>
                    <Router>
                        <Routes>
                            <Route
                              path="/calendar"
                              element={
                                <div className='tab_content_wrapper' id='calendar_tab_id'>
                                  <Calendar
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    handleDrillInfo={this.handleDrillInfo.bind(this)}
                                    handleAddDrill={this.handleAddDrill.bind(this)}
                                    handleChangeDrill={this.handleChangeDrill.bind(this)}
                                    clubUnitId={this.state.clubUnitId}
                                    update_data_request={this.state.update_data_request}
                                    drills={this.state.drills}
                                    date_start={this.state.date_start}
                                    date_stop={this.state.date_stop}
                                    handleChangeStartStop={this.handleChangeStartStop.bind(this)}
                                    clubs={this.props.clubs}
                                    club_units={this.props.club_units}
                                    updateDrills={this.updateDrills.bind(this)}
                                    handleDeclineNewDrill={this.handleDeclineNewDrill.bind(this)}
                                    updateData={this.updateData.bind(this)}
                                    handleClientFullDrillList={this.handleClientFullDrillList.bind(this)}
                                    client_relevant_drills={this.state.client_relevant_drills}
                                    modal_ref={this.modal_ref}
                                    handleRemoveScheduleDrill={this.handleRemoveScheduleDrill.bind(this)}
                                    handleDeclineRemoveDrillSchedule={this.handleDeclineRemoveDrillSchedule.bind(this)}
                                    removeDrillSchedule={this.removeDrillSchedule.bind(this)}
                                    remove_schedule_accept={this.state.remove_schedule_accept}
                                    remove_drill_id={this.state.remove_drill_id}
                                    handleApplyFilters={this.handleApplyFilters.bind(this)}
                                    updateCurrentShift={this.props.updateCurrentShift}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path="/clients"
                              element={
                                <div className='tab_content_wrapper' id='clients_tab_id'>
                                  <UsersList
                                    add_client_form={this.state.add_client_form}
                                    displayed_form={this.state.displayed_form}
                                    catchError={this.props.catchError}
                                    handleBackgroundClick={this.handleBackgroundClick.bind(this)}
                                    handleAddUser={this.handleAddUser.bind(this)}
                                    handleUpdateSketches={this.handleUpdateSketches.bind(this)}
                                    renderTicketsList={this.renderTicketsList.bind(this)}
                                    update_data='no' logged_in={this.props.logged_in}
                                    list_type='full' club_units={this.props.club_units}
                                    add_notify={this.props.add_client_notify}
                                    handleCreateNotification={this.props.handleCreateNotification.bind(this)}
                                    handleAddNotify={this.props.handleAddNotify}
                                    handleDeclineNewNotify={this.props.handleDeclineNewNotify}
                                    notify_client_id={this.props.notify_client_id}
                                    modal_ref={this.modal_ref}
                                    modal_users_ref={this.modal_users_ref}
                                    setNotifyType={this.props.setNotifyType}
                                    device={this.props.device}
                                    updateCurrentShift={this.props.updateCurrentShift}
                                    updateTicket={this.updateTicket.bind(this)}
                                    handleShowTicketInfo={this.handleShowTicketInfo.bind(this)}
                                    renderDisciplineList={this.renderDisciplineList.bind(this)}
                                    renderCoachList={this.renderCoachList.bind(this)}
                                    handleCloseTicket={this.handleCloseTicket.bind(this)}
                                    clients_fts={this.state.clients_fts}
                                    handleReplaceFts={this.handleReplaceFts.bind(this)}
                                    history={this.props.history}
                                    replace={true}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path="/coaches"
                              element={
                                <div className='tab_content_wrapper' id='clients_tab_id'>
                                  <CoachesList
                                    add_client_form={this.state.add_client_form}
                                    displayed_form={this.state.displayed_form}
                                    catchError={this.props.catchError}
                                    handleBackgroundClick={this.handleBackgroundClick.bind(this)}
                                    handleAddUser={this.handleAddUser.bind(this)}
                                    handleUpdateSketches={this.handleUpdateSketches.bind(this)}
                                    update_data='no' logged_in={this.props.logged_in}
                                    club_units={this.props.club_units}
                                    updateCurrentShift={this.props.updateCurrentShift}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path="/bids"
                              element={
                                <div className='tab_content_wrapper' id='bids_tab_id'>
                                  <BidsList
                                    update_data='no'
                                    handleUpdateBidComment={this.handleUpdateBidComment}
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    handleBackgroundClick={this.handleBackgroundClick.bind(this)}
                                    club_units={this.props.club_units}
                                    add_notify={this.props.add_bid_notify}
                                    notify_bid_id={this.props.notify_bid_id}
                                    handleCreateNotification={this.props.handleCreateNotification}
                                    handleDeclineNewNotify={this.props.handleDeclineNewNotify}
                                    handleAddNotify={this.props.handleAddNotify}
                                    setNotifyType={this.props.setNotifyType}
                                    device={this.props.device}
                                    updateCurrentShift={this.props.updateCurrentShift}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path="/shifts"
                              element={
                                <div className='tab_content_wrapper' id='shifts_tab_id'>
                                  <ManagerCalendar
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    getTotalShifts={this.getTotalShifts.bind(this)}
                                    total_shifts={this.state.total_shifts}
                                    total_shifts_date_start={this.state.total_shifts_date_start}
                                    total_shifts_date_stop={this.state.total_shifts_date_stop}
                                    updateCurrentShift={this.props.updateCurrentShift}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path="/time_shifting"
                              element={
                                <div className='tab_content_wrapper' id='shifts_tab_id'>
                                  <ManagerCalendar
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    getTotalShifts={this.getTotalShifts.bind(this)}
                                    total_shifts={this.state.total_shifts}
                                    total_shifts_date_start={this.state.total_shifts_date_start}
                                    total_shifts_date_stop={this.state.total_shifts_date_stop}
                                    updateCurrentShift={this.props.updateCurrentShift}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path="/"
                              element={
                                <div className='tab_content_wrapper' id='profile_tab_id'>
                                  <Profile
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    club_units={this.props.club_units}
                                    updateCurrentShift={this.props.updateCurrentShift}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path="/service_and_items"
                              element={
                                <div className='tab_content_wrapper' id='service_items_tab_id'>
                                  <ServicesItems
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    club_units={this.props.club_units}
                                    updateCurrentShift={this.props.updateCurrentShift}
                                    history={this.props.history}
                                    device={this.props.device}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path="/service_items"
                              element={
                                <div className='tab_content_wrapper' id='service_items_tab_id'>
                                  <ServicesItems
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    club_units={this.props.club_units}
                                    updateCurrentShift={this.props.updateCurrentShift}
                                    history={this.props.history}
                                    device={this.props.device}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path='/product/:id'
                              element={
                                <div className='tab_content_wrapper' id='product_page_id'>
                                  <ProductManage
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    history={this.props.history}
                                    service='0'
                                    sex={this.state.sex}
                                    age={this.state.age}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            />
                            <Route
                              path='/service/:id'
                              element={
                                <div className='tab_content_wrapper' id='service_page_id'>
                                  <ProductManage
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    history={this.props.history}
                                    service='1'
                                    sex={this.state.sex}
                                    age={this.state.age}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            />
                            <Route
                              path="/notifications"
                              element={
                                <div className='tab_content_wrapper' id='notification_tab_id'>
                                  <Notifications
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    list_type='full'
                                    renderTicketsList={this.renderTicketsList.bind(this)}
                                    updateCurrentShift={this.props.updateCurrentShift}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path="/ticket_changing"
                              element={
                                <div className='tab_content_wrapper' id='tickets_tab_id'>
                                  <TicketChangeRequest
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path='/ticket_changing/:id'
                              element={
                                <div className='tab_content_wrapper' id='ticket_page_id'>
                                  <FullTicketChangeRequest
                                      logged_in={this.props.logged_in}
                                      catchError={this.props.catchError}
                                      history={this.props.history}
                                      user={this.props.user}
                                  />
                                </div>
                              }
                            />
                            <Route
                              path='/client/attendance/:id'
                              element={
                                <div className='tab_content_wrapper' id='client_attendance_page_id'>
                                  <ClientAttendance
                                      logged_in={this.props.logged_in}
                                      catchError={this.props.catchError}
                                      device={this.props.device}
                                      clients_fts={this.state.clients_fts}
                                      handleReplaceFts={this.handleReplaceFts.bind(this)}
                                      history={this.props.history}
                                      user={this.props.user}
                                  />
                                </div>
                              }
                            />
                            <Route
                              path="/club/manage"
                              element={
                                <div className='tab_content_wrapper' id='club_manage_tab_id'>
                                  <ClubManage
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path="/market"
                              element={
                                <div className='tab_content_wrapper' id='notification_tab_id'>
                                  <Market
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    device={this.props.device}
                                    history={this.props.history}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path="/rent_control"
                              element={
                                <div className='tab_content_wrapper' id='rent_tab_id'>
                                  <RentControl
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    device={this.props.device}
                                    history={this.props.history}
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path="/club/statistic"
                              element={
                                <div className='tab_content_wrapper' id='rent_tab_id'>
                                  <StatisticPage
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    device={this.props.device}
                                    history={this.props.history}
                                    club_units={this.props.club_units}
                                    renderDisciplineList={this.renderDisciplineList.bind(this)}
                                    handleShowTicketInfo={this.handleShowTicketInfo.bind(this)}
                                    renderCoachList={this.renderCoachList.bind(this)}
                                    handleAddNotify={this.props.handleAddNotify}
                                    handleUpdateSketches={this.handleUpdateSketches.bind(this)}
                                    renderTicketsList={this.renderTicketsList.bind(this)}
                                    update_data='no'
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                            <Route
                              path="/statistic"
                              element={
                                <div className='tab_content_wrapper' id='rent_tab_id'>
                                  <StatisticPage
                                    logged_in={this.props.logged_in}
                                    catchError={this.props.catchError}
                                    device={this.props.device}
                                    history={this.props.history}
                                    club_units={this.props.club_units}
                                    renderDisciplineList={this.renderDisciplineList.bind(this)}
                                    handleShowTicketInfo={this.handleShowTicketInfo.bind(this)}
                                    renderCoachList={this.renderCoachList.bind(this)}
                                    handleAddNotify={this.props.handleAddNotify}
                                    handleUpdateSketches={this.handleUpdateSketches.bind(this)}
                                    renderTicketsList={this.renderTicketsList.bind(this)}
                                    update_data='no'
                                    user={this.props.user}
                                  />
                                </div>
                              }
                            >
                            </Route>
                        </Routes>
                    </Router>
                    <div id='traffic_users_wrapper_id'>
                        {this.state.clubUnitId !== '' && this.state.users_list && this.state.choosen_drill
                            ? <UsersList
                                add_client_form={this.state.add_client_form}
                                displayed_form={this.state.displayed_form}
                                catchError={this.props.catchError}
                                handleBackgroundClick={this.handleBackgroundClick.bind(this)}
                                handleAddUser={this.handleAddUser.bind(this)}
                                handleUpdateSketches={this.handleUpdateSketches.bind(this)}
                                renderTicketsList={this.renderTicketsList.bind(this)}
                                update_data='no' logged_in={this.props.logged_in}
                                list_type='tickets'
                                clubUnitId={this.state.clubUnitId}
                                drill={this.state.choosen_drill}
                                club_units={this.props.club_units}
                                handleUpdateDrillClients={this.handleUpdateDrillClients.bind(this)}
                                handleCreateNotification={this.props.handleCreateNotification.bind(this)}
                                modal_ref={this.modal_ref}
                                modal_users_ref={this.modal_users_ref}
                                device={this.props.device}
                                updateCurrentShift={this.props.updateCurrentShift}
                                clients_fts={this.state.clients_fts}
                                handleReplaceFts={this.handleReplaceFts.bind(this)}
                                history={this.props.history}
                                user={this.props.user}
                              />
                            : ''
                        }
                    </div>
                    <div id='traffic_client_wrapper_id'>
                        {this.state.clubUnitId !== '' && this.state.client_arrive && this.state.choosen_drill
                            ? <ClientArrive
                                logged_in={this.props.logged_in}
                                handleBackgroundClick={this.handleBackgroundClick.bind(this)}
                                drill={this.state.choosen_drill}
                                handleUpdateDrillClients={this.handleUpdateDrillClients.bind(this)}
                                unsetChoosenDrill={this.unsetChoosenDrill.bind(this)}
                                catchError={this.props.catchError}
                                updateCurrentShift={this.props.updateCurrentShift}
                                user={this.props.user}
                              />
                            : ''
                        }
                    </div>
                </div>
                {this.state.new_drill
                    ? <div className='new_drill_modal_wrapper'>
                        {this.state.new_drill_form}
                    </div>
                    : ''
                }
                {this.state.addTicket
                    ? <div>{this.renderNewUserTicket()}</div>
                    : ''
                }
                {this.state.lock_spinner
                    ? <Spinner />
                    : <div></div>
                }
                <div id="toast-wrapper-id">
                    <div id="toast-body-id">
                    </div>
                </div>
            </div>
        )
    }
}

class NewDrill extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            disciplines: [],
            coaches: [],
            clubUnitId: (this.props.clubUnitId && this.props.correct) ? this.props.clubUnitId : '',
            get_data: true,
            new_drill_data: {
                drill_type: null,
                discipline: null,
                coaches: null,
                drill_time_start: null,
                drill_time_stop: null,
                weekday: null,
            },
            drill_types: [],
            single_drill: (!this.props.exist_repeatable && this.props.correct) ? true : false,
            lock_spinner: true,
        }
    }

    componentDidMount() {
        if (this.state.clubUnitId === '' && this.props.clubUnitId !== '') {
            this.setState({clubUnitId: this.props.clubUnitId}, () => {this.getDrillData();});
        }
        else if (this.state.get_data && this.state.clubUnitId !== '') {
            this.getDrillData();
        }
        this.setState(
            {
                single_drill: (parseInt(localStorage.getItem("user_type")) <= 1) ? true : this.state.single_drill,
            }
        );
    }

    getDrillData() {
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/drill_creation_data/?club_unit=' + this.state.clubUnitId, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (json === undefined) {
                this.props.catchError(401);
                return
            }
            if (this.props.logged_in) {
                this.setState(
                    {
                        coaches: json.coaches,
                        disciplines: json.disciplines,
                        drill_types: json.drill_types,
                        get_data: false,
                        lock_spinner: false,
                    }
                );
            }
        })
        this.props.handleUpdateDataState(true);
    }

    handleAddNewDrill = (e) => {
        let drill_data = this.state.new_drill_data;
        drill_data['club_unit'] = this.state.clubUnitId;
        if (document.getElementById('single_drill_check').checked || parseInt(localStorage.getItem('user_type')) < 2) {
          drill_data['drill_date'] = document.getElementById('single_drill_date').value;
          drill_data['single'] = true;
        }
        if (this.props.correct) {
            drill_data["id"] = this.props.exist_drill_id;
            drill_data["drill_date"] = this.props.exist_drill_date;
        }
        this.setState({lock_spinner: true}, () => {
            fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/schedule/', {
              method: (this.props.exist_drill_id) ? 'POST' : 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
              },
              body: JSON.stringify(this.state.new_drill_data)
            })
            .then(res => {
                if (res.status === 401) {
                    this.props.catchError(res.status);
                    return
                }
                else if (res.status === 406) {
                    res.json().then((json) => {
                        this.props.updateCurrentShift(json);
                    });
                    return
                }
                const response = res.json();
                return response;
            })
            .then(json => {
                if (!json) {
                    return
                }
                this.props.updateData();
                this.setState({lock_spinner: false});
            })
            this.props.handleDeclineNewDrill(e);
        })
    }

    renderFilter() {
        return this.props.club_units.map((unit) => {
            return (
                <p>
                    <input
                        type='radio'
                        id={'unit-' + unit.id}
                        key={'checkkey-' + unit.id}
                        data-unit_id={unit.id}
                        onClick={
                            (e) => {
                                this.setState(
                                    {clubUnitId: parseInt(e.target.dataset.unit_id)},
                                    () => {
                                        if (this.state.get_data) {
                                            this.getDrillData();
                                        }
                                    }
                                )
                            }
                        }
                    />
                    {unit.name}
                </p>
            )
        })
    }

    render() {
        if (this.state.clubUnitId === '') {
            return (
                <div
                    className='background_lock_wrapper'
                    id='background_lock_id'
                    ref={this.props.modal_ref}
                >
                    <div className='new_drill_form_wrapper'>
                        {this.renderFilter()}
                    </div>
                </div>
            )
        }
        else {
            var show_drill_date = '';
            var hide_single_drill = '';
            if (parseInt(localStorage.getItem("user_type")) === 1) {
                var show_drill_date = 'show_class';
                var hide_single_drill = 'hide_class';
            }
            return (
                <div
                    className='background_lock_wrapper'
                    id='background_lock_id'
                    ref={this.props.modal_ref}
                >
                    <div className='new_drill_form_wrapper'>
                        {this.state.lock_spinner
                            ? <div className='loader_container_wrapper'>
                              <div className="loader loader_for_container"></div>
                            </div>
                            : <div>
                                  <div className='hide_drill_clients_list_wrapper'>
                                      <input type='button' className='hide_drill_clients_list' id='hide_drill_clients_list_btn_id' onClick={this.props.handleBackgroundClick} value='&#10008;' />
                                  </div>
                                  <Select
                                      options={
                                          Array.from(this.state.drill_types, (val) => new Object({"value": val.id, "label": val.name}))
                                      }
                                      onChange={
                                          (e) => {
                                              var drill_data = this.state.new_drill_data;
                                              drill_data['drill_type'] = (e) ? e["value"] : null;
                                              this.setState(
                                                  {
                                                      new_drill_data: drill_data,
                                                  }
                                              )
                                          }
                                      }
                                      styles={select_styles}
                                      isClearable={true}
                                      menuPosition={'fixed'}
                                      placeholder={"Тип тренировки"}
                                      defaultValue={
                                          (this.props.exist_drill_id) ? Array.from(
                                              this.state.drill_types, (val) => new Object({"value": val.id, "label": val.name})
                                          ).filter(
                                              t => t["value"] == this.props.exist_type
                                          )[0] : ''
                                      }
                                  />
                                  <Select
                                      options={
                                          Array.from(this.state.disciplines, (val) => new Object({"value": val.id, "label": val.name}))
                                      }
                                      onChange={
                                          (e) => {
                                              var drill_data = this.state.new_drill_data;
                                              drill_data['discipline'] = (e) ? e["value"] : null;
                                              this.setState(
                                                  {
                                                      new_drill_data: drill_data,
                                                  }
                                              )
                                          }
                                      }
                                      styles={select_styles}
                                      isClearable={true}
                                      menuPosition={'fixed'}
                                      placeholder={"Вид спорта"}
                                      defaultValue={
                                          (this.props.exist_drill_id) ? Array.from(
                                            this.state.disciplines, (val) => new Object({"value": val.id, "label": val.name})
                                          ).filter(
                                              t => t["value"] == this.props.exist_discipline
                                          )[0] : ''
                                      }
                                  />
                                  <div className='drill_time_wrapper'>
                                      <label className='drill_time_label'>Время начала и время окончания</label>
                                      <input
                                          key='drill_time_start'
                                          id='drill_time_start'
                                          type='time'
                                          placeholder='Время начала'
                                          className='add_ticket_btn'
                                          onChange={
                                              (e) => {
                                                  var drill_data = this.state.new_drill_data;
                                                  drill_data['drill_time_start'] = e.target.value;
                                                  this.setState(
                                                      {
                                                          new_drill_data: drill_data,
                                                      }
                                                  )
                                              }
                                          }
                                          defaultValue={this.props.exist_drill_time_start}
                                      />
                                      <input
                                          key='drill_time_stop'
                                          id='drill_time_stop'
                                          type='time'
                                          placeholder='Время окончания'
                                          className='add_ticket_btn'
                                          onChange={
                                              (e) => {
                                                  var drill_data = this.state.new_drill_data;
                                                  drill_data['drill_time_stop'] = e.target.value;
                                                  this.setState(
                                                      {
                                                          new_drill_data: drill_data,
                                                      }
                                                  )
                                              }
                                          }
                                          defaultValue={this.props.exist_drill_time_stop}
                                      />
                                  </div>
                                  <Select
                                      options={
                                          Array.from(this.props.weekday, (val, index) => new Object({"value": index, "label": val}))
                                      }
                                      onChange={
                                          (e) => {
                                            if (this.props.correct) {
                                              var drill_data = this.state.new_drill_data;
                                              drill_data['weekday'] = e["value"];
                                              this.setState(
                                                  {
                                                      new_drill_data: drill_data,
                                                  }
                                              );
                                            }
                                            else {
                                              var drill_data = this.state.new_drill_data;
                                              drill_data['weekday'] = Array.from(e, (val) => val["value"]);
                                              this.setState(
                                                  {
                                                      new_drill_data: drill_data,
                                                  }
                                              );
                                            }
                                          }
                                      }
                                      styles={select_styles}
                                      isClearable={true}
                                      isMulti={(!this.props.correct) ? true : false}
                                      menuPosition={'fixed'}
                                      placeholder={"Дни недели"}
                                      defaultValue={
                                        (this.props.exist_drill_id) ? Array.from(
                                            this.props.weekday, (val, index) => new Object({"value": index, "label": val})
                                        ).filter(
                                            t => t["value"] == this.props.exist_weekday - 1
                                        )[0] : ''
                                      }
                                  />
                                  <Select
                                      options={
                                          Array.from(this.state.coaches, (val) => new Object({"value": val.id, "label": val.first_name + ' ' + val.last_name}))
                                      }
                                      onChange={
                                          (e) => {
                                              var drill_data = this.state.new_drill_data;
                                              drill_data['coaches'] = Array.from(e, (val) => val["value"]);
                                              this.setState(
                                                  {
                                                      new_drill_data: drill_data,
                                                  }
                                              )
                                          }
                                      }
                                      styles={select_styles}
                                      isClearable={true}
                                      isMulti={true}
                                      menuPosition={'fixed'}
                                      placeholder={"Тренер"}
                                      defaultValue={
                                        (this.props.exist_drill_id) ? Array.from(
                                            this.state.coaches, (val) => new Object({"value": val.id, "label": val.first_name + ' ' + val.last_name})
                                        ).filter(
                                            t => this.props.exist_coaches.includes(t["value"])
                                        ) : ''
                                      }
                                  />
                                  <div className='single_drill_choose_wrapper'>
                                      <p
                                          className={hide_single_drill}
                                      >
                                          {this.props.single_drill
                                              ? <input
                                                    type='checkbox'
                                                    key='single_drill_check'
                                                    id='single_drill_check'
                                                    onChange={
                                                      (e) => {
                                                          if (e.target.checked) {
                                                              this.setState({single_drill: true});
                                                          }
                                                          else {
                                                              this.setState({single_drill: false});
                                                          }
                                                      }
                                                    }
                                                    checked
                                                />
                                              : <input
                                                    type='checkbox'
                                                    key='single_drill_check'
                                                    id='single_drill_check'
                                                    onChange={
                                                      (e) => {
                                                          if (e.target.checked) {
                                                              this.setState({single_drill: true});
                                                          }
                                                          else {
                                                              this.setState({single_drill: false});
                                                          }
                                                      }
                                                    }
                                                />
                                          }
                                          Разовая тренировка
                                      </p>
                                      {this.state.single_drill
                                          ? <div
                                                id='single_drill_date_wrapper_id'
                                            >
                                                <label>Выбери дату тренировки</label>
                                                <input
                                                    key='single_drill_date'
                                                    id='single_drill_date'
                                                    type='date'
                                                    placeholder='Дата тренировки'
                                                    className='add_ticket_btn'
                                                    defaultValue={this.props.exist_drill_date}
                                                />
                                            </div>
                                          : ''
                                      }
                                  </div>
                                  <button id='add-drill' key='drill-accept' className='drill_traffic_btn' onClick={this.handleAddNewDrill}>&#10004;</button>
                                  <button id='decline-drill' key='drill-decline' className='drill_traffic_btn' onClick={this.props.handleDeclineNewDrill}>&#10008;</button>
                              </div>
                        }
                    </div>
                </div>
            )
        }
    }
}

class ClientArrive extends(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            client_drill_arrive: false,
            drill_ended: false,
        }
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/management/traffic/?drill=' + this.props.drill, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
            .then(res => {
                if (res.status === 401) {
                    this.props.catchError(res.status);
                    return
                }
                const response = res.json();
                return response;
            })
            .then(json => {
                if (this.props.logged_in) {
                    if (new Date() > new Date(json.drill_date + ' ' + json.drill_time)) {
                        this.setState(
                            {
                                client_drill_arrive: json.client_arrive,
                                drill_ended: true,
                            }
                        );
                    }
                    else {
                        this.setState({client_drill_arrive: json.client_arrive});
                    }
                }
            })
    }

    handleArriveDrill = (e) => {
        if (e.target.id.split('-')[0] === 'arrive') {
            var data = {drill: this.props.drill, arrive: true};
        }
        else if (e.target.id.split('-')[0] === 'unarrive') {
            var data = {drill: this.props.drill, arrive: false};
        }
        fetch(process.env.REACT_APP_BACKEND_URI + '/api/v1/user/management/traffic/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(data)
        })
        .then(res => {
            if (res.status === 401) {
                this.props.catchError(res.status);
                return
            }
            else if (res.status === 406) {
                res.json().then((json) => {
                    this.props.updateCurrentShift(json);
                });
                return
            }
            const response = res.json();
            return response;
        })
        .then(json => {
            if (!json) {
                return
            }
            if (this.props.logged_in) {
                if (json.client_drill) {
                    this.setState({
                        client_drill_arrive: true},
                        () => {
                           this.props.handleUpdateDrillClients(
                               {
                                   drill: this.props.drill,
                                   counter: 1,
                                   client_arrive: true,
                               }
                           );
                           this.props.unsetChoosenDrill();
                        });
                }
                else {
                    this.setState({
                        client_drill_arrive: false},
                        () => {
                           this.props.handleUpdateDrillClients(
                               {
                                   drill: this.props.drill,
                                   counter: -1,
                                   client_arrive: false,
                               }
                           );
                          this.props.unsetChoosenDrill();
                        });
                }
            }
        })
    }

    render() {
        if (!this.state.drill_ended) {
            return (
                <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_ref}>
                    <div className='add_new_user_form_wrapper'>
                        <div className='hide_drill_clients_list_wrapper'>
                            <input type='button' className=' hide_drill_clients_list' id='hide_drill_clients_list_btn_id' onClick={this.props.handleBackgroundClick} value='&#10008;' />
                        </div>
                        {this.state.client_drill_arrive
                            ? <div className='show_filters_btn_wrapper clients_arrive_drill_wrapper'>
                                  <div className='filter_apply_btn unarrive_drill_btn' id='unarrive-drill-id' onClick={this.handleArriveDrill}>
                                      <span className='arrive_drill_btn_text' id='unarrive-drill-span-id'>Отменить</span>
                                  </div>
                              </div>
                            : <div className='show_filters_btn_wrapper clients_arrive_drill_wrapper'>
                                  <div className='filter_apply_btn arrive_drill_btn' id='arrive-drill-id' onClick={this.handleArriveDrill}>
                                      <span className='arrive_drill_btn_text' id='arrive-drill-span-id'>Запланировать</span>
                                  </div>
                              </div>
                        }
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='background_lock_wrapper' id='background_lock_id' ref={this.props.modal_ref}>
                    <div className='add_new_user_form_wrapper'>
                        <div className='hide_drill_clients_list_wrapper'>
                            <input type='button' className=' hide_drill_clients_list' id='hide_drill_clients_list_btn_id' onClick={this.props.handleBackgroundClick} value='&#10008;' />
                        </div>
                        {this.state.client_drill_arrive
                            ? <div className='show_filters_btn_wrapper clients_arrive_drill_wrapper'>
                                  <div className='unarrive_drill_btn' id='unarrive-drill-id'>
                                      <span className='arrive_drill_btn_text' id='unarrive-drill-span-id'>
                                        Тренировка закончена
                                      </span>
                                  </div>
                              </div>
                            : <div className='show_filters_btn_wrapper clients_arrive_drill_wrapper'>
                                  <div className='arrive_drill_btn' id='arrive-drill-id'>
                                      <span className='arrive_drill_btn_text' id='arrive-drill-span-id'>
                                        Тренировка пропущена
                                      </span>
                                  </div>
                              </div>
                        }
                    </div>
                </div>
            )
        }
    }
}

export default Table;
